import './types/core-ext';
import { createApp } from 'vue';
import { setupApp } from '@/setup/setup-app';
import { i18nFactory } from '@/setup/i18n';
import { routerFactory } from '@/router/router';
import { store } from '@/store';
import PlayApp from '@/PlayApp.vue';

const app = createApp(PlayApp);

// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
const { router, i18n } = setupApp(app, routerFactory, i18nFactory, () => store);

app.provide('appComponents', {
    ready: true,
    router,
    i18n,
    store,
});

router.isReady().then(() => app.mount('#app'));
