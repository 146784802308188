import { sendKeapEvent } from '@/play-editor/frame-handshake';
import split from 'lodash/split';
import { env } from '@/env';

export const frameRequestTypes = {

    ready: 'PLAY_READY',
    init: 'PLAY_INIT',
    executeCapability: 'PLAY_EXECUTE_CAPABILITY',
    automationRedirect: 'PLAY_AUTOMATION_REDIRECT',
};

let embedAllowList;

const FrameState = {
    get allowedParentFrames() {
        embedAllowList ??= split(env.VUE_APP_EMBED_ALLOW_LIST, ',').map((e) => e.trim()).filter((e) => e);

        return embedAllowList;
    },

    get embedded() {
        return window.parent !== window;
    },
};

export function isEmbedded() {
    return FrameState.embedded;
}

export function sendClose() {
    throw new Error('Not implemented');
}

/**
 * Makes a request to the outer frame, passing [requestData] as input args.  Will wait for a response on channel's port.
 *
 * @return {Promise<*>}
 */
export function executeFrameRequest(keapRequestType, requestData = null) {
    return sendKeapEvent(window.top, keapRequestType, requestData);
}
