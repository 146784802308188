import { AuthState } from '@/store/auth/types';
import { GetterTree } from 'vuex';
import { State } from '@/store/play-root-state';

import { stateLog } from '@/store/config/types';
import { HostSystem, TenantAccess } from '@/generated/play-api';
import { Edition } from '@/shared/edition/edition.constants';

const log = stateLog('auth', 'getters');

export enum AppAccessType {
    none,
    coach,
    account,
}
const getters = <GetterTree<AuthState, State>>{
    isLoggedIn: ({ session, user }:AuthState) => {
        return Boolean(session?.jwt && user?.id);
    },

    // will return first name if exists, if not last name if exists, if not then email.
    // will also capitalize first letter if returning the name
    getUserGivenName: ({ user }:AuthState) => {
        const { givenName, familyName, username } = user;
        const name = givenName || familyName;

        return name ? `${name.charAt(0).toUpperCase()}${name.slice(1)}` : username;
    },

    appAccessChecker: ({ accounts, roles }: AuthState) => (appName:string) => {
        if (accounts?.apps.find((a) => a.account.legacyId === appName)) {
            return AppAccessType.account;
        }

        if (roles?.isAdmin || roles?.isCoachAdmin) {
            return AppAccessType.coach;
        }

        if (accounts?.extraApps.find((a) => a.tenantId === appName)) {
            return AppAccessType.coach;
        }

        if (accounts?.coachingTenants?.includes(appName) && roles?.isCoach) {
            return AppAccessType.coach;
        }

        return AppAccessType.none;
    },

    getHostSystem: ({ accounts, session: { coreAppId } }: AuthState) => {
        log.info('Checking hostSystem for', coreAppId, 'Details', accounts);

        if (coreAppId && accounts) {
            const foundApp = accounts.apps
                .map(({ account }) => account)
                .find((account) => account.legacyId === coreAppId);

            if (foundApp) {
                switch (foundApp.edition.name) {
                case Edition.LITE:
                case Edition.PRO:
                case Edition.MAX:
                    return HostSystem.KEAP_WEB;
                case Edition.ULTIMATE:
                    return HostSystem.CLASSIC;
                default:
                    return foundApp.edition.name ? HostSystem.KEAP_WEB : HostSystem.CLASSIC;
                }
            } else if (accounts.coachingTenants?.includes(coreAppId)) {
                return HostSystem.KEAP_WEB;
            }

            if (accounts.extraApps.find((access:TenantAccess) => access.tenantId === coreAppId)) {
                // They are mapped using the table
                return HostSystem.GENERAL;
            }
        }
        log.info('Found nothing from', coreAppId, 'Details', accounts);

        return null;
    },
};

export default getters;
