import { PlayClient } from '@/generated/play-api/PlayClient';
import { KeyValues } from '@/types/core-types';
import { env } from '@/env';

export * from '@/generated/play-api';

export const playClientTs = new PlayClient({
    BASE: env.VUE_APP_CONTENT_API_URL,
    WITH_CREDENTIALS: true,
});

export function fillModel<T>(schema?: ModelMetaSchema): T {
    if (schema == null) return {} as T;

    return schema.properties.keySet().reduce((ob, prop) => {
        ob[prop] = null;

        return ob;
    }, <KeyValues>{}) as T;
}

export function fillMissingFields<T>(base: Partial<T>, schema?: ModelMetaSchema): T {
    if (schema == null) return base as T;

    return schema.properties.keySet().reduce((ob, prop) => {
        if (!Object.hasOwn(ob, prop)) {
            ob[prop] = null;
        }

        return ob;
    }, base as KeyValues) as T;
}

export type ModelMetaSchema = {
    description?: string;
    properties: {
        [key in string]?: ModelPropertyDefinition;
    }
}

export type ContainsDefinition = {
    readonly type?: string;
}

export type ModelPropertyDefinition = {
    type?: string;
    description?: string;
    properties?: {
        [key in string]?: ModelPropertyDefinition;
    };
    readonly contains?: ReadonlyArray<ContainsDefinition> | ContainsDefinition;
    isRequired?: boolean;
    format?: string;
    minLength?: number;
}
