import { FormProvider, PropertyProvide } from '@/model/form/form-provider-types';
import { ModelProperty } from '@/client/play-client';
import { Dict } from '@/types/core-types';
import asserts from '@/shared/asserts';
import { createPropertyProvider } from '@/model/form/create-property-provider';

export function setupFormProviderPropHelpers(self: FormProvider) {
    function createProperty<T>(prop: string | ModelProperty): PropertyProvide<T> {
        const propertyDto = typeof prop === 'string' ? self.properties[prop] : prop;

        asserts(propertyDto, `Ensure that ${prop} exists on ${self.source.name}`);

        return createPropertyProvider<T>(self, propertyDto);
    }
    ///
    /// INITALIZE PROP HELPERS
    ///
    /// Initialize properties last - doing so helps ensure we don't get any weird circular dependencies, specifically
    /// that nothing will attempt to create propHelper instances before the form is initialized
    const propHelpers = <Dict<PropertyProvide<unknown>>>{};

    self.get = <T>(name: string | ModelProperty): PropertyProvide<T> => {
        const nameValue = typeof name !== 'string' ? (name).name : name;

        // eslint-disable-next-line no-return-assign
        return (propHelpers[nameValue] ??= createProperty<T>(nameValue)) as PropertyProvide<T>;
    };

    self.getAll = (props: ModelProperty[] | string[]): PropertyProvide<unknown>[] => {
        return props.map((x) => self.get(x));
    };

    for (const r of self.properties.valueSet()) {
        propHelpers[r.name] = createPropertyProvider(self, r);
    }
}
