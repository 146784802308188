import { FormProvider } from '@/model/form/form-provider-types';
import { ModelProperty } from '@/client/play-client';
import asserts from '@/shared/asserts';

import { formLogger } from '@/model/form/provide-form-logger';

const log = formLogger.child('focus', true);

export function setupFormProviderFocus(self: FormProvider) {
    const { state } = self;

    self.focusQuestion = (question: ModelProperty) => {
        asserts(question, 'To blur, use blurQuestion');
        state.focusedQuestion = question;
    };

    self.blurQuestion = (question: ModelProperty) => {
        if (self.isFocused(question)) {
            if (state.answers[question.name] == null) {
                /// If we set null here, then the server will drop those properties
                /// so keep as empty string.
                vset(state.answers, question.name, '');
            }
            log.info('Blur question: ', question.name);
            state.focusedQuestion = null;
        }
    };

    self.isFocused = (question: ModelProperty) => {
        return state.focusedQuestion?.name === question.name;
    };
}
