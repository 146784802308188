/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum NavigateDestination {
    SETTINGS = 'SETTINGS',
    MY_PROFILE = 'MY_PROFILE',
    BUSINESS_PROFILE = 'BUSINESS_PROFILE',
    REPORTS = 'REPORTS',
    INTEGRATIONS = 'INTEGRATIONS',
    DOMAINS = 'DOMAINS',
    USERS = 'USERS',
    API = 'API',
    DASHBOARD = 'DASHBOARD',
    PLAYBOOK = 'PLAYBOOK',
    CONTENT_ASSISTANT = 'CONTENT_ASSISTANT',
    CONTENT_WRITE_COPY = 'CONTENT_WRITE_COPY',
    CONTENT_CREATE_AUTOMATION = 'CONTENT_CREATE_AUTOMATION',
    GETTING_STARTED = 'GETTING_STARTED',
    RECENT = 'RECENT',
    FAVORITES = 'FAVORITES',
    SETUP = 'SETUP',
    CONTACTS = 'CONTACTS',
    ADD_CONTACT = 'ADD_CONTACT',
    COMPANIES = 'COMPANIES',
    CONTACT_GROUPS = 'CONTACT_GROUPS',
    AFFILIATES = 'AFFILIATES',
    TAGS = 'TAGS',
    ADD_TAG = 'ADD_TAG',
    ADD_NOTE = 'ADD_NOTE',
    CUSTOM_FIELDS = 'CUSTOM_FIELDS',
    IMPORT_CONTACTS = 'IMPORT_CONTACTS',
    IMPORT_COMPANIES = 'IMPORT_COMPANIES',
    DUPLICATES = 'DUPLICATES',
    MY_DAY = 'MY_DAY',
    APPOINTMENTS = 'APPOINTMENTS',
    CREATE_APPOINTMENT = 'CREATE_APPOINTMENT',
    TASKS = 'TASKS',
    BUSINESS_LINE = 'BUSINESS_LINE',
    MARKETING_NUMBER = 'MARKETING_NUMBER',
    EMAIL_BROADCAST = 'EMAIL_BROADCAST',
    CREATE_EMAIL_BROADCAST = 'CREATE_EMAIL_BROADCAST',
    TEXT_BROADCAST = 'TEXT_BROADCAST',
    PIPELINE = 'PIPELINE',
    CREATE_PIPELINE = 'CREATE_PIPELINE',
    OPPORTUNITIES = 'OPPORTUNITIES',
    QUOTES = 'QUOTES',
    INVOICES = 'INVOICES',
    ADD_INVOICE = 'ADD_INVOICE',
    CONNECT_PAYMENT_PROCESSOR = 'CONNECT_PAYMENT_PROCESSOR',
    ECOMMERCE = 'ECOMMERCE',
    PRODUCTS = 'PRODUCTS',
    RECURRING_PAYMENTS = 'RECURRING_PAYMENTS',
    CHECKOUT_FORMS = 'CHECKOUT_FORMS',
    CREATE_CHECKOUT_FORM = 'CREATE_CHECKOUT_FORM',
    ORDER_FORMS = 'ORDER_FORMS',
    PUBLIC_FORMS = 'PUBLIC_FORMS',
    CREATE_PUBLIC_FORM = 'CREATE_PUBLIC_FORM',
    INTERNAL_FORMS = 'INTERNAL_FORMS',
    LANDING_PAGE = 'LANDING_PAGE',
    CREATE_LANDING_PAGE = 'CREATE_LANDING_PAGE',
    SETUP_GOOGLE_REVIEWS = 'SETUP_GOOGLE_REVIEWS',
    EASY_AUTOMATION = 'EASY_AUTOMATION',
    ADVANCED_AUTOMATION = 'ADVANCED_AUTOMATION',
    ZAPIER = 'ZAPIER',
    FILES = 'FILES',
}
