<template>
    <div
        class="card-container pq-array-item"
        :data-qa="`array-item-card-${questionName}`"
        @click="emit('click')"
    >
        <div :class="['card list-item']">
            <div class="drag-icon-container drag-target">
                <Icon name="draggable" class="drag-icon" />
            </div>

            <div class="field-select drag-target">
                <div class="field-label" :title="modelValue">
                    <template v-if="$slots.value">
                        <slot name="value" />
                    </template>
                    <template v-else />
                </div>
                <DsEditableText :model-value="modelValue" @update:model-value="changeAnswer($event)">
                    <span class="array-item-text">{{ modelValue }}</span>
                </DsEditableText>
            </div>

            <div :class="['field-actions', { 'action-invalid': cardError }]">
                <slot name="trailing">
                    <Tooltip>
                        <template #reference>
                            <Icon
                                v-if="cardError"
                                name="alert-triangle"
                                class="warning-icon"
                            />
                        </template>
                        <template #default>
                            <span>{{ $t('problem') }}</span>
                        </template>
                    </Tooltip>
                    <DsTooltip>
                        <template #reference>
                            <DsIcon
                                v-if="isSuggestion"
                                :name="KeapIcon.WAND"
                                class="warning-icon is-suggestion"
                            />
                        </template>
                        <template #default>
                            {{ $t('suggestion') }}
                        </template>
                    </DsTooltip>
                    <DsTooltip>
                        <template #reference>
                            <DsIconButton
                                :name="KeapIcon.X_CIRCLE"
                                :data-qa="`array-item-delete-button-${questionName}`"
                                @click="removeItem"
                            />
                        </template>
                        <template #default>
                            <div class="tooltip-text">
                                {{ $t('remove') }}
                            </div>
                        </template>
                    </DsTooltip>
                </slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts">export default { name: 'PlayQuestionArrayItem' }; </script>
<script lang="ts" setup>

import { ModelProperty } from '@/generated/play-api';

interface Props {
    modelValue?: string;
    isSuggestion?: boolean;
    question?: ModelProperty;
}
const props = defineProps<Props>();
const emit = defineEmits(['change', 'remove', 'click']);
const mouseOver = ref(false);

const cardError = ref();
const fieldOptions = {};
const isOnlyItem = false;

const questionName = computed(() => props.question?.name ?? '');

function setMouseOver(value:boolean) {
    mouseOver.value = value;
}

function changeAnswer(answer:unknown) {
    emit('change', answer);
}

function removeItem(item:unknown) {
    emit('remove', item);
}
</script>

<style lang="scss" rel="stylesheet/scss">
    .editing-container {
        input {
            max-width: 25rem;
        }
    }
</style>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "@/coach/coach";

    .card-container {
        margin-bottom: calc(1rem / 16);
    }

    .menu-item {
        @include padding(calc(1rem / 2) 1rem calc(1rem / 4) 1rem);

        font-size: $font-size-md;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: calc(290rem / 16);

        &.sms {
            align-items: start;
        }
    }

    button {
        --icon-color: #{$color-gray-600};
    }

    .card {
        display: flex;
        flex-direction: row;
        border-radius: calc(1rem / 4) 0 0 calc(1rem / 4);
        cursor: grab;

        &.ignore-elevation {
            box-shadow: $elevation-z1;
        }
    }

    .list-item {
        margin: auto;
    }

    .card-error {
        border: 2px solid $color-red;
    }

    .drag-icon {
        --icon-margin: auto;

        cursor: grab;
        margin-top: auto;
        margin-bottom: auto;
    }

    .drag-icon-container {
        flex-grow: 0;
        margin: auto;

        // Not sure why I had to hard-code this height here to avoid weird padding offset
        height: calc(1rem * 1.5);
    }

    .sms-optin {
        padding-top: calc(1rem / 2.5);

        &.setup {
            padding-top: calc(1rem / 4);
        }
    }

    .field-select {
        flex-grow: 1;
        justify-self: stretch;
        line-height: $font-size-md;

        // padding: calc(1rem / 4) 0 1rem 0;
        font-size: $font-size-md;
        margin-bottom: auto;
        margin-top: auto;
        padding: 1rem calc(1rem / 2);
    }

    .field-label {
        margin-right: 1rem;
        font-size: $font-size-md;
        line-height: $line-height-md;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: normal;
    }

    .collapsible {
        --collapsible-inner-padding: 0;

        display: block;
        grid-column: 2 / 2;
        align-content: space-between;

        @media ($small) {
            padding: 0;
        }
    }

    .expand-field {
        @include margin-start(calc(1rem / 2));

        align-self: center;
    }

    .field-actions {
        flex-grow: 0;
        justify-self: end;
        align-items: center;
        align-self: center;
        display: flex;
        margin-top: calc(1rem / 4);
        margin-bottom: calc(1rem / 4);

        @include margin-end(calc(1rem / 2));
    }

    .helper-text-input {
        margin-bottom: 1rem;
    }

    .helper-text-input:last-child {
        padding-bottom: 1rem;
    }

    .field-options {
        --input-field-margin-top: calc(1rem / 2);
        --input-margin-bottom: calc(1rem / 2);

        padding-bottom: 1rem;

        small {
            color: $color-gray-800;
        }
    }

    .action-invalid {
        width: calc(1rem * 3);
    }

    .error-text {
        margin-bottom: calc(1rem / 4);
    }

    .card-dragging {
        cursor: grabbing;

        .card {
            transform: rotate(2deg);
        }
    }

    .is-suggestion {
        cursor: grabbing;
    }

    .warning-icon {
        @include margin-end(calc(1rem / 2));

        --icon-color: #{$color-red};
    }

    .field-actions,
    .drag-icon {
        --icon-color: #{$color-gray-600};

        margin-left: $spacing-100;
    }

    .card-placeholder {
        margin: calc(1rem / 2);
        padding: calc(1rem * 1.5);
        background-color: $color-gray-100;

        .card {
            display: none;
        }

        .icon {
            cursor: unset;
        }
    }

    .pq-array-item {
        display: flex;
        align-items: center;
    }

    .card.list-item {
        flex: auto;
    }

    .array-item-text {
        font-size: $font-size-md;
        font-weight: $font-weight-regular;
        white-space: pre-wrap;
    }
</style>
<i18n>
{
    "en-us": {
      "problem": "There is a problem with this item",
      "suggestion": "This was a suggestion",
      "edit": "Edit this item",
      "remove": "Remove this item"
    }
}
</i18n>
