/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiPromptMessage } from '../models/AiPromptMessage';
import type { CoachClientTags } from '../models/CoachClientTags';
import type { CoachingClientResults } from '../models/CoachingClientResults';
import type { DefaultPlaybook } from '../models/DefaultPlaybook';
import type { HostSystem } from '../models/HostSystem';
import type { PlayStats } from '../models/PlayStats';
import type { V2CoachingClientDetail } from '../models/V2CoachingClientDetail';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CoachingV2Service {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Coaching Clients 1
     * @returns CoachingClientResults getCoachingClients_1 200 response
     * @throws ApiError
     */
    public getCoachingClients1(): CancelablePromise<CoachingClientResults> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/coach/clients',
        });
    }

    /**
     * Get Coaching Client 1
     * @param clientId
     * @param defaultPlaybook
     * @param hostSystem
     * @returns V2CoachingClientDetail getCoachingClient_1 200 response
     * @throws ApiError
     */
    public getCoachingClient1(
        clientId: string,
        defaultPlaybook?: DefaultPlaybook | null,
        hostSystem?: HostSystem | null,
    ): CancelablePromise<V2CoachingClientDetail> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/coach/clients/{clientId}',
            path: {
                'clientId': clientId,
            },
            query: {
                'defaultPlaybook': defaultPlaybook,
                'hostSystem': hostSystem,
            },
        });
    }

    /**
     * Update Coach Tenant Tags
     * Updates tags for a coach tenant
     * @param tenantId The tenant to add
     * @param requestBody The updated list of tags
     * @returns any Success
     * @throws ApiError
     */
    public updateCoachTenantTags(
        tenantId: string,
        requestBody: CoachClientTags,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/coach/clients/{tenantId}/tags',
            path: {
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get PlayStats
     * @param playId
     * @returns PlayStats getPlayStats 200 response
     * @throws ApiError
     */
    public getPlayStats(
        playId: string,
    ): CancelablePromise<PlayStats> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/coach/play-stats/{playId}',
            path: {
                'playId': playId,
            },
        });
    }

    /**
     * Generate PlayTemplate Section Content
     * Generates content for a play template asset section
     * @param playTemplateId the ID of the play this asset belongs to.
     * @param templateAssetId the ID of the asset we are updating.
     * @param sectionId the ID of the section we are generating for
     * @param requestBody The answers for a play
     * @returns string Success
     * @throws ApiError
     */
    public generatePlayTemplateSectionContent(
        playTemplateId: string,
        templateAssetId: string,
        sectionId: string,
        requestBody: any,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/coach/templates/{playTemplateId}/assets/{templateAssetId}/sections/{sectionId}/content',
            path: {
                'playTemplateId': playTemplateId,
                'templateAssetId': templateAssetId,
                'sectionId': sectionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Generate PlayTemplate Section Prompt
     * Gets a prompt for a PlaySection
     * @param playTemplateId the ID of the play this asset belongs to.
     * @param templateAssetId the ID of the asset we are updating.
     * @param sectionId the ID of the section we are generating for
     * @param requestBody The answers for a play
     * @returns AiPromptMessage Success
     * @throws ApiError
     */
    public generatePlayTemplateSectionPrompt(
        playTemplateId: string,
        templateAssetId: string,
        sectionId: string,
        requestBody: any,
    ): CancelablePromise<Array<AiPromptMessage>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/coach/templates/{playTemplateId}/assets/{templateAssetId}/sections/{sectionId}/prompt',
            path: {
                'playTemplateId': playTemplateId,
                'templateAssetId': templateAssetId,
                'sectionId': sectionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
