import { CustomInspectorState, DevtoolsPluginApi } from '@vue/devtools-api';
import { InspectorHelper, toInspectorProperties } from '@/setup/devtools/inspector-helper';
import { store } from '@/store';
import { writeClipboardFromDevTools } from '@/setup/devtools/inspector-utils';

const authInspectorId = 'auth';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function AuthInspector<R, D extends DevtoolsPluginApi<R>>(api: D): InspectorHelper {
    const { auth } = store.state;

    return {
        id: authInspectorId,
        label: 'Auth',
        nodeActions: [
            {
                icon: 'content_copy',
                tooltip: 'Copy user token',
                async action() {
                    const jwt = store.state.auth.session?.jwt;

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    await writeClipboardFromDevTools(jwt);
                },
            },
            {
                icon: 'logout',
                tooltip: 'Delete user token',
                async action() {
                    await store.dispatch('auth/LOGOUT');
                    // eslint-disable-next-line no-console
                    console.warn('User token has been deleted');
                },
            },
        ],

        load() {
            const { info: session } = toInspectorProperties(auth.session ?? {});
            const { info: user } = toInspectorProperties(auth.user ?? {});
            const { info: account } = toInspectorProperties(auth.accounts ?? {});

            return <CustomInspectorState>{
                session,
                user,
                account,
            };
        },

        update: async (field, value) => {
            const [cat, prop] = field;

            switch (cat) {
            case 'invalidate':
                if (value === true) {
                    await store.dispatch('auth/SET_JWT');
                }

                return true;
            default:
                // @ts-ignore ...fdsaf
                vset(auth.state[cat], prop, value);

                return true;
            }
        },
    };
}
