import amp from 'amplitude-js';
import { logger } from '@/shared/logging';

import { env } from '@/env';

const enabled = () => env.VUE_APP_AMPLITUDE_V2_INSTANCE_NAME
    && env.VUE_APP_AMPLITUDE_V2_KEY
    && env.VUE_APP_THIRD_PARTY_LOGGING !== 'disabled';

const ampInst = amp.getInstance(env.VUE_APP_AMPLITUDE_V2_INSTANCE_NAME);

const log = logger('amplitude');

/**
 * @type AmplitudeClient & Identify
 */
export const amplitude = AmplitudeWrapper(ampInst);

if (enabled()) {
    amplitude.init(env.VUE_APP_AMPLITUDE_V2_KEY);
}

/**
 * Wraps an existing amplitude client instance, and if logging is disabled, it converts methods to a noop.
 *
 * @param {AmplitudeClient} instance The actual instance
 * @return {AmplitudeClient & Identify}
 */
function AmplitudeWrapper(instance) {
    return new Proxy(instance, {
        get(target, name, receiver) {
            if (enabled() && name in instance) {
                if (typeof instance[name] === 'function' && !name.startsWith('_')) {
                    return new Proxy(instance[name], {
                        apply: (ft, thisArg, argumentsList) => { // (B)
                            try {
                                if (name.substring(0, 1) !== '_') {
                                    log.info(`amplitude.${name}`, argumentsList);
                                }

                                return Reflect.apply(ft, instance, argumentsList);
                            } catch (e) {
                                log.warn(`${name}: error`, e);
                            }

                            return null;
                        },
                    });
                }

                return Reflect.get(target, name, receiver);
            }

            if (enabled()) {
                const identify = new amp.Identify();

                if (name in identify) {
                    return new Proxy(identify[name], {
                        apply: (functionTarget, thisArg, argumentsList) => { // (B)
                            try {
                                log.info(`amplitude.Identify.${name}(${argumentsList})`);

                                const result = Reflect.apply(functionTarget, identify, argumentsList);

                                // Also invoke the identify function
                                amplitude.identify(identify);

                                return result;
                            } catch (e) {
                                log.warn(`${name}: error`, e);
                            }

                            return null;
                        },
                    });
                }
                throw Error(`Invalid name: ${name}`);
            }

            // Return noop
            return (...args) => {
                log.debug(`amplitude.${name}(${JSON.stringify(args)}) (disabled)`);
            };
        },
    });
}
