<template>
    <div class="spinner-container">
        <Spinner />
    </div>
</template>
<script>
export default {
    name: 'FullPageSpinner',
};

</script>

<style lang="scss" scoped>
.spinner-container {
    display: flex;
    align-items: center;
    background-color: $color-paper;
    justify-content: center;
    inset: 0;
    opacity: 0.8;
    position: absolute;
}
</style>
