import { properCase } from '@/play-editor/play.utils';
import {
    PlaybookCategory,
    V3PlayTag, V3PlayTagCategory, V3PlayTemplateInfo, V3TaggedTemplate,
} from '@/generated/play-api';
import { camelCase, snakeCase } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type intl = ((...args: any[])=>string);

export type PlayTagKey = string;

export const PLAYBOOK_KEY = 'playbook';

export function playTagKeyOf(category: string, tag: string): string {
    return `${category}:${tag}`;
}

export function playTagName(tagName:string): string {
    const [category, ...nameParts] = tagName.split(':');
    const name = nameParts.join(':');

    asserts(name, 'Should have a valid name. Did the tag not contain a colon?');

    return name;
}

export function playbookTagName(category: PlaybookCategory): string {
    return camelCase(category);
}

export function playbookCategoryFromTagName(tagName: string): PlaybookCategory {
    asserts(tagName.startsWith('playbook:'), 'The tag must be in the playbook category');

    const enumName = snakeCase(playTagName(tagName)).toUpperCase() as PlaybookCategory;

    asserts(() => Object.values(PlaybookCategory).includes(enumName), 'Should exist as Playbook category');

    return enumName;
}

export type LocalizedPlayCategoryInfo = {
    id: string;
    title: string;
    description: string;
    illustration: string;
    tenantId: string | null;
};

export type PlayTagWithId = V3PlayTag & {id:string};

export type LocalizedPlayCategory = LocalizedPlayCategoryInfo & {
    id:string;
    hasTags: boolean;
    tags: LocalizedPlayTag[];
    tenantId: string | null;
};

export type LocalizedPlayTag = LocalizedPlayCategoryInfo & {
    tagId: PlayTagKey;
    plays: V3TaggedTemplate[];
    tenantId: string | null;
};

export function localizeCategories(
    templateList?: V3PlayTemplateInfo[],
    cats?: V3PlayTagCategory[],
    localize?: intl,
): LocalizedPlayCategory[] {
    const result = [] as LocalizedPlayCategory[];

    if (cats != null && localize != null) {
        for (const sourceCat of cats) {
            const {
                visible, name, tenantId,
            } = sourceCat;

            if (visible !== false) {
                const tags = (sourceCat.tags ?? []).map((sourceTag) => {
                    const {
                        id: tagId, name: tagName, templates = [], tenantId: tenant,
                    } = sourceTag;

                    return {
                        id: tagId,
                        tagId: playTagKeyOf(name, tagName),
                        title: localize(`categories.${name}.${tagName}.title`, properCase(tagName)),
                        description: localize(`categories.${name}.${tagName}.description`),
                        illustration: localize(`categories.${name}.${tagName}.illustration`),
                        plays: templates,
                        tenantId: tenant,
                    };
                });

                result.push({
                    id: name,
                    title: localize(`categories.${name}.title`, properCase(name)),
                    description: localize(`categories.${name}.description`),
                    illustration: localize(`categories.${name}.illustration`),
                    hasTags: tags.length > 0,
                    tenantId,
                    tags,
                });
            }
        }
    }

    return result;
}
