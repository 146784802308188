<script lang="ts">export default { name: 'MenuList' }; </script>
<template>
    <div :class="['menu-list-container', {dense, 'align-start': alignStart}]">
        <div class="menu-header">
            <slot name="header" />
        </div>
        <ul :class="['menu-list', {dense, 'align-start': alignStart}]">
            <slot />
        </ul>
    </div>
</template>
<script lang="ts" setup>
defineProps<{
    dense?: boolean;
    alignStart?: boolean;
}>();
</script>
<style lang="scss" scoped>
    .menu-list-container.align-start {
        text-align: start;
    }

    .menu-list {
        @include dropdown-list;

        &.dense {
            padding: 0;

            >:first-of-type {
                border-radius: $border-radius $border-radius 0 0;
                padding-top: 1rem;
            }

            >:last-of-type {
                border-radius: 0 0 $border-radius $border-radius;
                padding-bottom: 0.8rem;
            }
        }
    }
</style>
