<template>
    <div>
        <div class="welcome-wrapper">
            <div class="welcome-message">
                <span data-qa="title" class="title">
                    {{ $t('title') }}
                </span>

                <span class="subtitle">
                    {{ $t('subtitle') }}
                </span>

                <div class="onboarding-options">
                    <OnboardingCard
                        illustration="activity-color"
                        :has-ds-illustration="true"
                        :title="$t('loginMessageTitle')"
                        :text="$t('loginMessage')"
                        class="onboarding-card"
                        @card-action="forceLogout"
                    />

                    <OnboardingCard
                        illustration="leads-gray"
                        :has-ds-illustration="true"
                        :title="$t('newAccountMessageTitle')"
                        :text="allowNewAccountCreation ? t('newAccountMessage') : t('accountRequiredMessage')"
                        class="onboarding-card"
                        @card-action="noLoginAction"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">export default { name: 'AccessDeniedPage' }; </script>
<script lang="ts" setup>
import OnboardingCard from '@/onboarding/components/OnboardingCard.vue';
import { env } from '@/env';

const { t } = useCore();
const allowNewAccountCreation = ref(false);

function noLoginAction() {
    if (allowNewAccountCreation.value) {
        window.location.href = `https://signin-intg.infusiontest.com/registration/createInfusionsoftId?returnUrl=${encodeURIComponent(window.location.origin)}%3FnewAccount%3dyes`;
    } else {
        window.location.href = 'https://try.keap.com/?utm_source=keap-copy-generator&utm_campaign=plays-signup-screen';
    }
}

function forceLogout() {
    // this.$router.push({ name: 'logout', query: { forceLogout: 'yes' } });
    window.location.assign(`${env.VUE_APP_CAS_URL}/login?service=${encodeURIComponent(window.location.origin)}`);
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .onboarding-options {
        display: flex;
        justify-content: center;
        margin-bottom: $spacing-600;
        gap: $spacing-300;

        @media ($small) {
            max-width: calc(638rem / 16);
            text-align: center;
            display: block;
            margin: 0 auto;
        }
    }

    .onboarding-card {
        width: calc(300rem / 16);
        padding: $spacing-200;
    }

    .access-denied-notice {
        padding: $spacing-100;
    }

    .welcome-wrapper {
        display: flex;
        align-items: center;
    }

    .welcome-message {
        flex: 4;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: $spacing-600;
    }

    .title {
        font-family: $font-family-secondary-title;
        font-weight: $font-weight-bold;
        font-size: calc(32rem / 16);
        margin-bottom: $spacing-200;

        @media ($extra-small) {
            font-size: calc(28rem / 16);
        }
    }

    .subtitle {
        color: $color-text-subtle;
        font-size: $font-size-md;
        margin-bottom: $spacing-200;
    }

    .notice-container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
    }

    .notice-card {
        display: flex;
        flex-direction: column;
        margin: $spacing-400;
    }
</style>

<i18n lang="yaml">
en-us:
    title: Welcome to AI Automation Assistant
    subtitle: Login or create a new account
    login: Log In
    createNewAccount: Create new account
    loginMessageTitle: Have a Keap login?
    loginMessage: If you already have a Keap account, click here to login now.
    newAccountMessageTitle: No Keap login?
    newAccountMessage: If you do not already have a Keap account, click here
    accountRequiredMessage: You will need a Keap Account in order to use AI Automation Assistant. Click here to create a free trial account
    keapFreeTrialLinkText: Click here to create a free trial account
</i18n>
