import { logger, Logger } from '@/shared/logging';
import {
    CreateFunction, DataStoreOperations,
    GetFunction, InitializeStoreFunction, ListFunction,
    Operation,
    RemoveFunction, SearchFunction, SyncFunction,
    UpdateFunction,
} from '@/integration/datastore/base-types';
import {
    ModelKey, ModelKeyName, ModelKeyOrName, ModelKeys,
} from '@/integration/datastore/model-keys';
import { HostSystem } from '@/generated/play-api';

export const storeLog = logger('ds');

/**
 * A datastore represents handling logic for loading, selecting, creating, and editing various records related to plays,
 * i.e. {@link ModelSchema}.  These include companies, appointments, tags, products, brand promise, etc.  Sometimes
 * these records will be owned by another system, and sometimes they are managed by the play-service database.
 *
 * Not all operations are supported by all DataStore implementations.  The {@link this.supports} property will
 * indicate which operations have been supplied, and the rest of the application should respond accordingly.
 *
 * - {@link PlayModelDataStore} Uses ad-hoc models from the play-web backend
 * - {@link RemoteDataStore} Maps all (or some) operations to a different backend
 * -
 */
export abstract class DataStore {
    /**
     * Reference to the appId that this store is connecting to
     */
    readonly appId: string;

    /**
     * Whether this store is ready for requests
     */
    readonly ready: Promise<unknown>;

    /**
     * What type of model this store represents.
     */
    readonly modelType: ModelKey;

    /**
     * The key (string) form of [this.modelType]
     */
    readonly key: ModelKeyName;

    /**
     * Where the data resides:  either generic (stored in play-service), keap-web (it comes from the keap-bff backend) or
     * max-classic (comes from the legacy spa API)
     */
    readonly hostSystem: HostSystem;

    /**
     * A function used to initialize this store.
     */
    readonly initializeStore: InitializeStoreFunction;

    /**
     * see {@link GetFunction}
     */
    readonly get: GetFunction;

    /**
     * see {@link CreateFunction}
     */
    readonly create: CreateFunction;

    /**
     * see {@link UpdateFunction}
     */
    readonly update: UpdateFunction;

    /**
     * see {@link RemoveFunction}
     */
    readonly remove: RemoveFunction;

    /**
     * see {@link ListFunction}
     */
    readonly list: ListFunction;

    /**
     * see {@link SearchFunction}
     */
    readonly search: SearchFunction;

    /**
     * see {@link SyncFunction}
     */
    readonly sync: SyncFunction;

    /**
     * Indicates which operations are supported by this datastore.
     */
    readonly supports: Record<Operation, boolean>;

    readonly log: Logger;

    protected constructor(appId: string, key: ModelKeyOrName, hostSystem: HostSystem, operations: DataStoreOperations) {
        this.appId = appId;
        const modelType = ModelKeys.of(key);

        const {
            create, list, update, remove, search, get, sync, initializeStore,
        } = operations;

        const log = storeLog.child(modelType.name);

        this.log = log;
        this.modelType = modelType;
        this.key = modelType.key;
        this.hostSystem = hostSystem;
        this.get = get;
        this.create = create;
        this.update = update;
        this.remove = remove;
        this.list = list;
        this.search = search;
        this.sync = sync;

        this.supports = {
            [Operation.CREATE]: create != null,
            [Operation.UPDATE]: update != null,
            [Operation.REMOVE]: remove != null,
            [Operation.LIST]: list != null,
            [Operation.SEARCH]: search != null,
            [Operation.GET]: get != null,
            [Operation.SYNC]: sync != null,
        };

        if (initializeStore) {
            this.ready = initializeStore.call(this);
        } else {
            this.ready = Promise.resolve();
        }
    }

    get canCreate() {
        return this.supports[Operation.CREATE] === true;
    }

    get canGet() {
        return this.supports[Operation.GET] === true;
    }

    get canUpdate() {
        return this.supports[Operation.UPDATE] === true;
    }

    get canRemove() {
        return this.supports[Operation.REMOVE] === true;
    }

    get canList() {
        return this.supports[Operation.LIST] === true;
    }

    get canSearch() {
        return this.supports[Operation.LIST] === true;
    }
}
