<script lang="ts">export default { name: 'ModelDropdown' }; </script>
<template>
    <div>
        <div v-if="error">
            <div class="error">
                {{ $t('thereWasAnError') }}{{ error }}
            </div>
        </div>
        <div v-else-if="isCardRef">
            <RefSelectorInput
                v-model="model"
                :question="question"
                @edit="editAndUpdate"
                @add="composeAndUpdate"
                @delete="deleteSelected"
                @valid="isRefValid = true"
            />
        </div>
        <div v-else class="input-field">
            <RefCard
                v-if="!loading"
                v-model="model"
                :schema="modelSchema"
                :defer-loading="null"
                @update-search="updateQuery"
                @blur="emit('blur')"
                @add="composeAndUpdate"
                @delete="deleteSelected"
                @edit="editAndUpdate"
                @valid="isRefValid = true"
            />
            <div>
                <slot name="helperText" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import RefSelectorInput from '@/play-editor/controls/RefSelectorInput.vue';
import { computed, ref } from 'vue';
import { modelMixin } from '@/play-editor/mixins/v3/modelMixin';
import { ModelProperty, PropertyValidation, RefType } from '@/generated/play-api';
import { DEFAULT_EVENT_NAME, useVModel2 } from '@/play-editor/mixins/v3/v-model';
import { injectDataScope } from '@/model/form/DataScope';
import ModelEditPage from '@/play-editor/model/ModelEditPage.vue';
import { useCore } from '@/shared/shared-providers';
import RefCard from '@/play-editor/controls/ref/RefCard.vue';

/**
 * Handles selecting ref instances, using either a card display, or a normal drop-down
 */
const props = withDefaults(defineProps<{
            multiple?: boolean;
            without?: unknown[];
            question: ModelProperty;
            modelValue?: string;
            pageTitle?: string;
            pageDescription?: string;
            label?: string;
            required?: boolean;
            helpText?: string;
            isCardRef?: boolean;
            validationError?: PropertyValidation[];
            name?: string;
            refType: RefType; }>(), {
    without: (() => []),
});

const emit = defineEmits([DEFAULT_EVENT_NAME, 'created', 'refresh-model', 'blur']);
const model = useVModel2(props);

const isRefValid = ref(false);
const query = ref('');

function updateQuery(value:string) {
    query.value = value;
}

const error = ref(false);
const {
    confirm, t, log, appId,
} = useCore();
const mixin = modelMixin(appId.value, props.refType, ModelEditPage);
const {
    composeModel, modelSchemaState, modelListState,
    modelsById, deleteModel, editModel,
} = mixin;
const dataScope = injectDataScope();
const loading = computed(() => !(modelListState.isReady.value && modelSchemaState.isReady.value));
const modelSchema = modelSchemaState.state;

async function composeAndUpdate() {
    try {
        const { pageTitle, pageDescription } = props;

        const result = await composeModel({
            pageTitle,
            pageDescription,
            dataScope,
        });

        const { id } = result ?? {};

        log.info('Compose complete', result);

        if (id) {
            emit('created', id);
            model.value = id;
        }
    } catch (e) {
        log.severe(e);
    }
}

async function deleteSelected() {
    const modelTitle = modelsById.value[model.value]?.title || t('deleteConfirmDefaultModelTitle')?.toString();

    if (await confirm({
        message: t('deleteConfirmMessage', { modelTitle }),
        title: t('deleteConfirmTitle'),
    })) {
        const deleteId = model.value;

        model.value = null;
        await deleteModel(deleteId);
    }
}

async function editAndUpdate() {
    const { pageTitle, pageDescription } = props;
    const result = await editModel(model.value, {
        pageTitle,
        pageDescription,
        dataScope,
    });

    const { id } = result ?? {};

    if (id) {
        log.info('Sending model/edit refresh for', result);
        model.value = id;
        emit('refresh-model');
    } else {
        log.info('No record:  emitting nothing', result);
    }
}

// ...toRefs(data),
// ...computed,
// ...mixin,

// function fullErrorMessage(errorObj:PropertyValidation):string {
//     if (errorObj.additionalHelp) {
//         return `${errorObj.message}. ${errorObj.additionalHelp}`;
//     }
//
//     return errorObj.message;
// }

</script>
<style lang="scss" scoped>
    .edit-modal-inner {
        padding: 1rem;
        height: 100%;
    }

    .multi-select-row {
        display: flex;
        flex-direction: row;
    }

    .multi-select {
        flex-grow: 2;
    }
</style>
<i18n>
{
    "en-us": {
        "createWithoutLabel": "Create one",
        "createTitle": "Create {article} {title}",
        "thereWasAnError": "There was an error"
    }
}
</i18n>
