/* eslint-disable @typescript-eslint/no-explicit-any,no-case-declarations */
import { DevtoolsPluginApi, setupDevtoolsPlugin } from '@vue/devtools-api';

import { isReactive, isRef } from 'vue';
import { get } from '@vueuse/core';
import { dict, KeyValues, PropertyKey } from '@/types/core-types';
import { TimelineLog, TrackExec } from '@/setup/devtools/playDevToolsApi';
import { setupToolsInspector } from '@/setup/devtools/inspector-tools';
import { determineObjectType } from '@/setup/devtools/inspector-helper';

let devtoolsApi: DevtoolsPluginApi<PlayDevtoolsData>;
let trackId = 0;

export interface PlayDevtoolsData {
    log?: boolean;
}

const registeredTimelines = <Record<string, boolean>>{};

let startColor = 0xff984f;

export function setupDevtools(app: unknown, data: PlayDevtoolsData) {
    const devtools = {
        track<R>(label: string | TimelineLog<R>, exec?: TrackExec<R>): R {
            const payload = <KeyValues>{};

            const config: TimelineLog<R> = typeof label === 'string' ? { label } : label;

            exec ??= config.exec;

            if (data.log === false || devtoolsApi == null) {
                return exec == null ? null : exec(payload);
            }
            const {
                label: finalLabel = 'Unknown', category = 'Plays', subtitle, logResponse = true,
            } = config;

            if (!Object.hasOwn(registeredTimelines, category)) {
                registeredTimelines[category] = true;
                devtoolsApi.addTimelineLayer({
                    id: category,
                    color: startColor += 1,
                    label: category,
                });
            }
            const groupId = `track${trackId++}`;

            devtoolsApi.addTimelineEvent({
                layerId: category,
                event: {
                    time: Date.now(),
                    data: {
                        label: finalLabel,
                    },
                    title: finalLabel,
                    subtitle,
                    groupId,
                },
            });

            if (exec == null) {
                return null;
            }
            const res = exec(payload);

            void Promise.resolve(res).then((execResult) => {
                devtoolsApi.addTimelineEvent({
                    layerId: category,
                    event: {
                        time: Date.now(),
                        data: {
                            ...payload,
                            label: finalLabel,
                            done: true,
                            ...(execResult == null || !logResponse ? {} : { result: execResult }),
                        },

                        title: finalLabel,
                        subtitle,
                        groupId,
                    },
                });
            });

            return res;
        },
    };

    /// Gets all entries, whether symbol or string
    const allEntries = (input: Record<PropertyKey, unknown>) => {
        input ??= {};

        return <[PropertyKey, unknown][]>[
            ...Object.getOwnPropertyNames(input ?? {}).map((n) => [n, input[n]]),
            ...Object.getOwnPropertySymbols(input ?? {}).map((n) => [n, input[n]]),
        ];
    };

    /** Whether the object in question represents a Vue component */
    const isComponent = (value: any) => {
        return typeof value === 'object' && (value?.$options || value?._Ctor);
    };

    /** Converts a {@link PropertyKey} to a string */
    function debugLabel(k: PropertyKey): string {
        return (typeof k === 'string' ? k : typeof k === 'number' ? k.toString() : (k).description);
    }

    function stateFor(obj: Record<PropertyKey, unknown>) {
        const res = dict();

        for (const [p, value] of allEntries(obj)) {
            if (typeof value !== 'function') {
                res[debugLabel(p)] = get(value);
            }
        }

        return res;
    }

    const setupDenyList = <PropertyKey[]>[
        '__sfc',
        'router',
        'route',
        'core',
        'store',
        'log',
        'props',
        'emit',
        't',
    ];

    setupDevtoolsPlugin({
        id: 'play-web-plugin',
        label: 'Play Web Plugin',
        packageName: 'play-web-plugin',
        app,
    }, (api) => {
        if (api != null) {
            devtoolsApi ??= api;
        }

        /// Refresh all data every second
        setInterval(() => {
            api.notifyComponentUpdate();
        }, 1000);

        api.on.inspectComponent((payload) => {
            /// Exposes all setupState, since the plugin doesn't do this by default in Vue2 mode
            for (const [key, value] of allEntries(payload.componentInstance._setupState)) {
                if (!setupDenyList.includes(key) && typeof value !== 'function' && !isComponent(value)) {
                    payload.instanceData.state.push({
                        type: 'setup',
                        objectType: determineObjectType(value),
                        key: debugLabel(key),
                        value: get(value),
                        raw: get(value)?.toString(),
                        editable: false,
                    });
                }
            }

            /// Exposes all provider state
            allEntries(payload.componentInstance._provided)
                .filter(([k]) => payload.componentInstance.$parent?._provided[k] == null)
                .distinct(([k]) => k).forEach(([key, value]) => {
                    payload.instanceData.state.push({
                        type: 'provide',
                        objectType: isRef(value) ? 'ref' : isReactive(value) ? 'reactive' : 'other',
                        key: (typeof key === 'string' ? key : (key as symbol).description),
                        value: stateFor(value as Record<PropertyKey, unknown>),
                        editable: false,
                    });
                });
        });

        api.on.visitComponentTree((payload) => {
            const node = payload.treeNode;

            allEntries(payload.componentInstance._provided).map(([k]) => k)
                .filter((k) => payload.componentInstance.$parent?._provided[k] == null)
                .map((k) => (typeof k === 'string' ? k : (k as symbol).description))
                .distinct()
                .forEach((k) => {
                    node.tags.push({
                        label: k,
                        textColor: 0xFFFFFF,
                        backgroundColor: 0x33984f,
                    });
                });

            /// For dynamic form fields, displays the field name
            if (payload.componentInstance.$options?.name === 'PropertyFormField') {
                const fieldName = payload.componentInstance.property?.name;

                if (fieldName) {
                    node.tags.push({
                        label: fieldName,
                        textColor: 0xFFFFFF,
                        backgroundColor: 0x0000FF,
                    });
                }
            }
        });

        setupToolsInspector(api);
    });

    return devtools;
}
