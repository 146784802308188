import { level, logger } from '@/shared/logging';
import { KeyValues } from '@/types/core-types';
import { expandToObject } from '@/shared/shared.utils';
import { App } from 'vue';

export const componentLogger = logger('components');

/**
 * Binds log functions so they can be accessed from within Vue components and mixin.
 */
export function setupVueLoggers(app: App<unknown>): void {
    Object.defineProperties(app.config.globalProperties, {
        ...expandToObject(Object.keys(level), (levelName) => ({
            value(this: KeyValues, ...args: unknown[]) {
                const log = componentLogger.child(this.$options?.name || 'Anonymous');

                // @ts-ignore It's okay here - these are going to be log functions
                return log ? log[levelName](...args) : null;
            },
            writable: false,
        })).filterEntries((k) => !(app.config.globalProperties)[k]),
    });
}
