import {
    FrameService, HostSystem, PlayHost, PlayHostInit,
} from '@/integration/host-system-api';
import {
    computed, inject, InjectionKey, provide, reactive, watch,
} from 'vue';
import { appendCalculated } from '@/play-editor/mixins/v3/computedReactive';
import { HostSystems } from '@/integration/host-systems';
import { DataStoreRegistry } from '@/integration/datastore/data-store-registry';
import { hostProviderKey } from '@/play-editor/provider/provider-key-names.constants';
import { CoreProvide } from '@/shared/core-provide.types';

export const HostProvideKey: InjectionKey<HostProvide> = Symbol('hostProvider');

export type HostProvide = {
    host?: PlayHost;
    readonly ready: Promise<unknown>;
    initialized: boolean;
    frame: FrameService;
    hostInit: PlayHostInit;
}

export function injectHostProvider() {
    return inject(HostProvideKey, {
        host: null,
        ready: Promise.resolve(),
        initialized: false,
        hostInit: null,
        frame: {
            state: {
                ready: true,
                isControlled: false,
                forceChoosePlay: false,
            },
        },
    });
}

export function provideHostProvider(frameService: FrameService, core: CoreProvide): HostProvide {
    const { log, store } = core;

    const hostSystem = computed<HostSystem>(() => store.getters['auth/getHostSystem']);
    const appId = computed<string>(() => store.state.auth.session.coreAppId);

    const self = appendCalculated(reactive({
        hostInit: null as PlayHostInit,
        host: null as PlayHost,
        initialized: false,
        get ready() {
            return self.host?.ready ?? Promise.resolve();
        },
    }), {
        frame() {
            return frameService;
        },
    }) as HostProvide;

    watch([hostSystem, appId], async ([newHostSystem, newAppId]) => {
        log.info(`HostProvider with newSourceType:${newHostSystem} and appId:${newAppId}`);

        if (!newHostSystem || !newAppId) {
            self.hostInit = null;
            self.host = null;
            self.initialized = true;
        } else {
            self.host = HostSystems[newHostSystem];
            self.initialized = false;

            if (!self.host) {
                throw new Error('This should not happen');
            }

            self.hostInit = await self.host.initialize(newAppId, frameService);
            self.initialized = true;

            if (self.hostInit.stores) {
                for (const ds of self.hostInit.stores) {
                    DataStoreRegistry.registerStore(ds);
                }
            }
        }
    });

    /// Legacy
    provide(hostProviderKey, self);

    provide(HostProvideKey, self);

    return self;
}
