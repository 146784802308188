import moment, {
    Duration, Moment, MomentInput, unitOfTime,
} from 'moment';
import { TimerRelativeType } from '@/generated/play-api';

export const DISPLAY_DATE_FORMAT_WITH_YEAR = 'LLL'; // MMM D, YYYY

/**
 * A map of relevant moment time units
 */
export const MomentUnit = <Record<string, unitOfTime.Base>>{
    hours: 'hours',
    minutes: 'minutes',
    days: 'days',
    months: 'months',
};

export type FormatDateOptions = string|{
    format?: string;
    tzOffset?: number;
}

const defaultFormatOptions = (format?: string, tzOffset?: number) => ({
    format: format ?? DISPLAY_DATE_FORMAT_WITH_YEAR,
    tzOffset: tzOffset ?? new Date().getTimezoneOffset(),
});

/**
 * Formats a date and time, using a specified format and output timezone.
 *
 * @param value A date-like input: string, number, number[], Moment, or Date
 * @param format Either a format string or options
 * @param tzOffset The timezone the output date should be in
 */
export function formatDateTime(value: MomentInput, format?: FormatDateOptions, tzOffset?: number): string {
    if (value) {
        const date = moment(value);

        const options = typeof format === 'string' ? defaultFormatOptions(format, tzOffset)
            : defaultFormatOptions(format?.format, tzOffset ?? format?.tzOffset);

        return date.utcOffset(options.tzOffset).format(options.format);
    }

    return null;
}

export function toDate(value: MomentInput): Date {
    return moment(value).toDate();
}

/**
 * Applies a duration adjustment to an existing date
 * @param startDate The original date
 * @param adjustmentType Whether to add or subtract a duration
 * @param duration The duration, as an ISO-compliant string, or a Moment.Duration instance
 */
export function adjustDate(startDate: DateSource, adjustmentType: TimerRelativeType, duration: string | Duration): Moment {
    const originalDate = moment(startDate);

    if (!duration) return originalDate;
    const isoDuration = moment.duration(duration);

    switch (adjustmentType) {
    case TimerRelativeType.BEFORE:
        return originalDate.subtract(isoDuration);
    default:
        return originalDate.add(isoDuration);
    }
}

export type DateSource = number | string | Date | Moment;

export function daysBetween(startDate: DateSource, endDate: DateSource): number {
    return moment(endDate).diff(moment(startDate), MomentUnit.days);
}

export function fromTodayDaily(value: string | Date | Moment) {
    moment.updateLocale('en', {
        calendar: {
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            sameElse: 'L',
        },
    });

    return moment(value).endOf('day').fromNow();
}

export function ts() {
    return moment().toJSON();
}
