/* eslint-disable @typescript-eslint/no-explicit-any */
import asserts from '@/shared/asserts';
import _get from 'lodash/get';
import {
    Dict, KeyValues, PropertyKey, Struct,
} from '@/types/core-types';
import parseInt from 'lodash/parseInt';
import { isEmbedded } from '@/play-editor/frame';
import { env } from '@/env';

export enum LoadStatus {
    idle, loading, loaded, error
}

export function rangeOf(first:number, second?:number):number[] {
    const result = <number[]>[];

    const start = second == null ? 0 : first;
    const end = second == null ? first : second;

    for (let i = start; i <= end; i++) {
        result.push(i);
    }

    return result;
}

export function propOrValue<T>(value:unknown, prop:string, otherwise:T = null): T {
    switch (typeof value) {
    case 'object':
        return ((value as KeyValues)[prop] ?? otherwise) as T;
    default:
        return (value ?? otherwise) as T;
    }
}

export function getSequentialName(name: string, currentNames: string[]) {
    let sequentialNumber = 2;
    let modifiedTitle: string = name;

    while (currentNames.includes(modifiedTitle)) {
        const numberMatch = modifiedTitle.match(/\d+$/);

        if (numberMatch) {
            const existingNumber = parseInt(numberMatch[0], 10);

            modifiedTitle = modifiedTitle.replace(/\d+$/, String(sequentialNumber > existingNumber ? sequentialNumber : existingNumber + 1));
        } else {
            modifiedTitle = `${modifiedTitle} ${sequentialNumber}`;
        }

        sequentialNumber++;
    }

    return modifiedTitle;
}

export function boolOf(value:unknown) {
    if (value == null) return false;

    if (typeof value === 'string') {
        return value?.toLowerCase() === 'true';
    }

    return Boolean(value);
}

export function randomString(length = 10, chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ') {
    let result = '';

    for (let i = length; i > 0; --i) {
        result += chars[Math.floor(Math.random() * chars.length)];
    }

    return result;
}

export function toNumber(of:any): number {
    switch (typeof of) {
    case 'undefined':
        return 0;
    case 'object':
        return illegalArg();
    case 'boolean':
        return of ? 0 : 1;
    case 'function':
        return illegalArg();
    case 'symbol':
        return parseInt(of.description);
    case 'bigint':
        return parseInt(of.toString());
    case 'string':
        return parseInt(of);
    case 'number':
        return of;
    default:
        return 0;
    }
}

/**
 * Takes in an object, and maps the entries, returning another object
 * @param obj
 * @param mapper
 * @param filter - Optionally inspect each mapped entry for inclusion
 * @return Object
 */
export function remapEntries<K extends PropertyKey, V, VV>(
    obj: Struct<K, V>,
    mapper: ((key: K, value: V) => [K, VV]),
    filter?: ((key: K, value: VV) => boolean),
): Struct<K, VV> {
    obj ??= <Struct<K, V>>{};
    filter ??= () => true;

    asserts.isObject(obj);
    asserts.isFunction(mapper);

    return obj.entrySet().reduce((result: Struct<K, VV>, [k, v]) => {
        const [key, value] = mapper(k, v);

        if (filter(key, value)) {
            Object.assign(result, { [key]: value });
        }

        return result;
    }, <Struct<K, VV>>{});
}

/**
 * Determines if the value is empty:
 * - If the object is an Array, checks the length
 * - Otherwise, checks truthiness
 */
export function isEmptyValue(value: any | null): boolean {
    return Array.isArray(value) ? Boolean(value?.length < 1) : !value;
}

/**
 * Gets a value from an object using a path, which can be a dot-separated string, or an Array
 * @param {object} source
 * @param {string|array} paths
 * @param defaultValue
 * @returns {*}
 */
export function getPath(source: any, paths: string | string[], defaultValue?: any): any | null {
    if (Array.isArray(paths)) {
        paths = paths.join('.');
    }

    return _get(source, paths, defaultValue);
}

/**
 * Takes in an object, and maps the values, returning another object with the same keys
 * @param obj
 * @param mapper
 * @param filter Optional filter use to restrict entries in the final result
 * @return Object
 */
export function remapValues<K extends PropertyKey, V, VV>(obj: Struct<K, V>, mapper: ((key: K, value: V) => VV), filter?: ((value: VV) => boolean)) {
    obj ??= <Struct<K, V>>{};
    filter ??= () => true;

    asserts.isObject(obj);
    asserts.isFunction(mapper);

    return obj.entrySet().reduce((result, [k, v]) => {
        const value = mapper(k, v);

        if (filter(value)) {
            Object.assign(result, { [k]: value });
        }

        return result;
    }, <Struct<K, VV>>{});
}

export function expandToObject<T extends string|number, V>(array: T[], mapper: (input: T) => V): Struct<T, V> {
    return array.reduce((prev, next) => {
        // @ts-ignore This is fine
        prev[next] = mapper(next);

        return prev;
    }, {}) as Struct<T, V>;
}

export type Mapper<F, T> = ((from: F) => T);

export function keyed<T extends KeyValues>(array: T[], key?: string | Mapper<T, string>): Dict<T> {
    key ??= ((self) => self.toString());

    return (array ?? []).reduce((prev, next) => {
        const keyType = typeof key;
        let keyValue;
        let keyMapper;

        switch (keyType) {
        case 'string':
            keyValue = next[key as string] as string;
            prev[keyValue] = next;
            break;

        case 'function':
            keyMapper = key as Mapper<T, string>;

            keyValue = keyMapper(next);
            prev[keyValue] = next;
            break;
        default:
            throw new Error('Expected string or key for function');
        }

        return prev;
    }, <Dict<T>>{});
}

/**
 * Reduce that doesn't require passing the previous value back.
 * @param array
 * @param reduceFn
 * @param initial
 * @returns {*}
 */
export function simpleReduce<T, R>(array: T[], reduceFn: ((prev: R, next: T, index: number) => R | undefined), initial: R): R {
    return array?.reduce((acc, next, index) => {
        const returned = reduceFn(acc, next, index);

        return returned === undefined ? acc : returned;
    }, initial);
}

export async function delay(amount = 300) {
    asserts(amount, 'Amount must be a valid value');

    return new Promise((res) => setTimeout(res, amount));
}

/**
 * Sets local storage value with an expiration time.
 * @param key
 * @param value
 * @param seconds
 */
export function setWithExpiry(key: string, value: any, seconds: number) {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        value,
        expiry: now.getTime() + (seconds * 1000),
    };

    localStorage.setItem(key, JSON.stringify(item));
}

export type ExpiringEntry<T> = {
    value: T;
    expiry: number;
}

/**
 * Gets local storage value if it hasn't expired (deletes it if it has expired).
 * @param key
 * @returns {*}
 */
export function getWithExpiry<T>(key: string): T {
    const itemStr = localStorage.getItem(key);

    // if the item doesn't exist, return null
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr) as ExpiringEntry<T>;
    const now = new Date();

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key);

        return null;
    }

    return item.value;
}

export function illegalState<T>(message?: string): T {
    throw new Error(message ?? 'Illegal state');
}

export function illegalArg<T>(message?: string): T {
    throw new Error(message ?? 'Illegal argument');
}

export const notImplemented = () => illegalState('Not implemented');

export type UniqueKeyParams = {
    propertyName?: string;
    propertyPrefix?: string;
}

export function camelize(text: string, elementPrefix = true): string {
    const result = text.replace(/^([A-Z])|[\s-_]+(\w)/g, (match, p1, p2) => {
        if (p2) return p2.toUpperCase();

        return p1.toLowerCase();
    });

    return elementPrefix ? `@${result}` : result;
}

export function camelToTitle(str: string) {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => {
        return s.toUpperCase();
    });
}

export function toTitleCase(str: string) {
    // Convert the string to lowercase and split it into an array of words
    const words = str.toLowerCase().split('_').join(' ').split(' ');

    // Capitalize the first letter of each word
    const titleCaseWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

    // Join the words back together with spaces
    return titleCaseWords.join(' ');
}

export function localStoreKey(name: string) {
    const base = [env.VUE_APP_FIREBASE_PROJECT_ID, name];

    if (isEmbedded()) {
        base.push('embed');
    }

    return base.join(':');
}

export function removeProperty(obj: any, property: string): any {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [property]: removedProperty, ...rest } = obj;

    return rest;
}

/**
 * Method to scroll into view port, if it's outside the viewport
 *
 * @param {Object} target - DOM Element
 * @param {Object} defaultScrollContainer - Scroll container to use (defaults to parent)
 * @returns {undefined}
 */
export const scrollIntoViewIfNeeded = (target: HTMLElement, defaultScrollContainer?: Element) => {
    const scrollContainer = defaultScrollContainer || findScrollContainer(target);

    scrollContainer.scroll({ behavior: 'auto', top: 0 });

    if (scrollContainer) {
        const { top, bottom } = target.getBoundingClientRect();
        const containerTop = scrollContainer.getBoundingClientRect().top;
        const containerBottom = scrollContainer.getBoundingClientRect().bottom;

        if (top < containerTop || bottom > containerBottom) {
            if (top < containerTop) {
                scrollContainer.scroll({
                    behavior: 'smooth',
                    top: target.offsetTop,
                });
            } else {
                scrollContainer.scroll({
                    behavior: 'smooth',
                    top: scrollContainer.scrollTop + top - containerTop,
                });
            }
        }
    }
};

const findScrollContainer = (element: Element): Element | null => {
    if (element === null || element === document.body) {
        return null;
    }

    if (isScrollable(element)) {
        return element;
    }

    return findScrollContainer(element.parentNode as Element);
};

const isScrollable = (element: Element): boolean => {
    const computedStyle = window.getComputedStyle(element);

    return (
        computedStyle.overflow === 'scroll'
        || computedStyle.overflow === 'auto'
        || computedStyle.overflow === 'hidden'
    );
};
