<template>
    <div class="unknown" :data-qa="`unknown-field`">
        {{ $t('unknownType') }}
    </div>
</template>

<script lang="ts">
export default {
    name: 'PlayQuestionUnknown',
    props: {
        value: [Object, String, Array, Number, Boolean],
    },
};
</script>

<style lang="scss" scoped>
    .unknown {
        margin-bottom: 1rem;
    }
</style>
<i18n>
{
    "en-us": {
        "unknownType": "Unknown Type"
    }
}
</i18n>
