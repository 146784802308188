import createPersistedState from 'vuex-persistedstate';

import config from './config';
import overlays from './overlays';
import auth from './auth';
import flags from './flags';
import suggestions from './suggestions';

import { logger } from '@/shared/logging';
import cloneDeep from 'lodash/cloneDeep';
import { PlayStore, State } from '@/store/play-root-state';
import { createStore } from 'vuex';
import { env } from '@/env';
import { localStoreKey } from '@/shared/shared.utils';

// @todo Remove jwt from persistent path
const vuexPersistPaths = [
    'auth.session.jwt',
    'auth.hasEverLoggedIn',
    'auth.user',
    'auth.session.coreAppId',
    'auth.session.coachId',
    'flags',
    'suggestions.latestSuggestions',
];

if (!env.isProd) {
    vuexPersistPaths.push('config.localConfig');
}

const log = logger('store');
const persistedKey = localStoreKey('vuex');

/**
 * We lazy initialize the vuex store
 */

function createPlayStore() {
    return createStore<State>({
        getters: {
            allState: (state) => state,
        },
        modules: {
            overlays,
            auth,
            flags,
            config,
            suggestions,
        },
        plugins: [
            (store: PlayStore) => store.subscribe((mutation, state) => {
                const { jwt } = state.auth.session;

                log.debug('Checking user details', mutation, state);

                if (jwt && !state.auth.accounts && !state.auth.userDetailsFetched) {
                    log.info('Loading user details:', mutation, state);

                    return store.dispatch('auth/FETCH_USER_DETAILS');
                }

                return null;
            }),

            createPersistedState({
                key: persistedKey,
                paths: vuexPersistPaths,
                rehydrated: (store) => {
                    // @ts-ignore The old version of vuex doesn't do this properly
                    const { state } = store;

                    if (!env.isProd) {
                        log.info('Merging persisted values', {
                            ...cloneDeep(state.config),
                        });
                        // @ts-ignore Not sure why this doesn't work
                        store.commit('config/MERGE_CONFIG');
                        log.info('Merged config', cloneDeep(state.config.values));
                    }
                },
            }),
        ],
    });
}

export const store = createPlayStore();

export default {
    store,
};
