/* eslint-disable @typescript-eslint/no-explicit-any */
import { createI18n, I18n } from 'vue-i18n';
import enUs from '../assets/i18n/en-us.yaml';
import { App } from 'vue';
import { splitWords } from '@/play-editor/play.utils';

export const i18nFactory = (app: App): I18n => {
    const i18n = createI18n({
        locale: 'en-us',
        globalInjection: false,
        fallbackLocale: 'en-us',
        silentTranslationWarn: true,
        silentFallbackWarn: true,
        fallbackWarn: false,
        fallbackRoot: true,
        missingWarn: false,
        postTranslation: (translated, key) => {
            if (translated === key) {
                return splitWords(translated);
            }

            return translated;
        },
        legacy: false,
        allowComposition: true,
        messages: {
            'en-us': enUs,
        },
        missing: () => {
            // do nothing
        },
        numberFormats: {
            'en-us': {
                currency: {
                    style: 'currency',
                    currency: 'USD',
                },
                currencyNoCents: {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                },
                oneDecimal: {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                },
                percent: {
                    style: 'percent',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                },
            },
        },
    });

    app.use(i18n);

    return i18n as I18n;
};
