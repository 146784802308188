/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PlayTemplateAdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Sync Core Templates
     * Syncs core in-memory templates with the database
     * @returns any Success
     * @throws ApiError
     */
    public syncCoreTemplates(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v3/play-templates',
        });
    }

    /**
     * Sync Tags
     * Syncs core in-memory tags with the database
     * @returns any Success
     * @throws ApiError
     */
    public syncTags(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v3/play-templates/tags',
        });
    }

    /**
     * Sync Core Template
     * Syncs a single in-memory templates with the database
     * @param templateOrSlug
     * @returns any Success
     * @throws ApiError
     */
    public syncCoreTemplate(
        templateOrSlug: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v3/play-templates/{templateOrSlug}/sync',
            path: {
                'templateOrSlug': templateOrSlug,
            },
        });
    }

}
