import {
    FormProvider, KeyedMetaMutations, PropertyMergeData, PropertyProvide,
} from '@/model/form/form-provider-types';
import { ModelProperty } from '@/generated/play-api';
import { Struct } from '@/types/core-types';
import { react } from '@/play-editor/mixins/v3/computedReactive';
import { PropertyMerge } from '@/model/form/PropertyMerge';
import { computed, ref } from 'vue';
import { formLogger } from '@/model/form/provide-form-logger';

/**
 * Creates a single property provider that's tied to the {@link form} FormProvider.
 *
 * @param form The form this property belongs to
 * @param property
 */
export function createPropertyProvider<T>(form: FormProvider, property: ModelProperty): PropertyProvide<T> {
    const prop = <PropertyProvide<T>>{};

    const helperMergeData = <Struct<keyof ModelProperty, PropertyMergeData>>{};

    prop.property = property;
    prop.name = property.name;
    prop.form = form;
    prop.state = react({
        generatingDefaultValue: false,
        generatingDefaultValueMessage: null,
        validationErrors: [],
        metaData: {},
        value: null,
        hasValue: false as boolean,
        focused: null,
    }, {
        validationErrors: () => form.state.validationErrors[property.name] ?? [],
        metaData: () => prop.form.getPropMeta(prop.name),
        value: () => prop.form.state.answers[prop.name],
        hasValue: () => {
            const allKeys = Object.keys(prop.form.state.answers);

            return allKeys.includes(prop.name);
        },
        focused: () => prop.form.isFocused(prop.property),
    }, {
        deep: true, // Because of validationErrors
    });

    prop.syncSuggestionsMeta = (change, payload) => {
        // eslint-disable-next-line no-void
        void form.syncSuggestionsMeta(prop.property, change, payload);
    };

    prop.blur = () => form.blurQuestion(property);
    prop.focus = () => form.focusQuestion(property);
    prop.updateAnswer = (answer: T, meta?: KeyedMetaMutations) => form.updateAnswer(
        property,
        answer,
        meta,
    );

    prop.helperMerge = (names: (keyof ModelProperty)[]) => {
        // eslint-disable-next-line no-return-assign
        const mergeProps = names.associateKeys<string, PropertyMergeData>((name) => helperMergeData[name] ??= PropertyMerge(
            formLogger,
            name,
            computed(() => prop.state.value),
            prop.form.scope,
            prop.property,
            ref(prop.form.mergePlayId),
            form.appId,
        ));

        const values = mergeProps.mapValues((p: PropertyMergeData) => {
            // Trigger a read
            p.merged.value;

            return p.merged;
        });

        return {
            update() {
                for (const mergeProp of mergeProps.valueSet()) {
                    // eslint-disable-next-line no-void
                    void mergeProp.update();
                }
            },
            values,
        };
    };

    return prop;
}
