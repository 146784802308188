import { ArrayEvent, ArrayEventPayload } from '@/play-editor/play.constants';
import asserts from '@/shared/asserts';
import cloneDeep from 'lodash/cloneDeep';
import { distinct } from '@/play-editor/play-utils';
import { KeyValues } from '@/types/core-types';

export type IndexedItem = KeyValues & {
    index:number;
}

/**
 * Keeps the suggestions metadata in sync with the order of the list as the user changes them.
 * @param meta The current suggestions metadata
 * @param change The type of change
 * @param payload The change payload
 * @return A copy of the metadata, reordered
 */
export function reorderMeta(meta:IndexedItem[], change:ArrayEvent, payload:ArrayEventPayload): KeyValues[] {
    if (!meta || !Array.isArray(meta)) {
        return [];
    }

    const distinctByIndex = (array:IndexedItem[]) => distinct(array, 'index');

    let original:IndexedItem[] = distinctByIndex(cloneDeep(meta));
    const findByIndex = (idx:number) => original.find(({ index }) => index === idx);

    const { index: payloadIndex, oldIndex } = payload;

    switch (change) {
    case ArrayEvent.move: {
        const found = findByIndex(oldIndex);
        const myIndex = original.indexOf(found);
        const movedDown = oldIndex > payloadIndex;

        original.forEach((item, iter) => {
            if (movedDown) {
                if (item.index >= payloadIndex && item.index < oldIndex && iter !== myIndex) {
                    item.index += 1;
                }
            } else if (item.index <= payloadIndex && item.index > oldIndex && iter !== myIndex) {
                item.index -= 1;
            }
        });

        if (found) {
            found.index = payloadIndex;
        }
    }

        break;
    case ArrayEvent.remove:
        original = original.filter(({ index }) => payloadIndex !== index);
        original.forEach((item) => {
            if (item.index >= payloadIndex) {
                item.index -= 1;
            }
        });

        break;
    case ArrayEvent.update:
        break;
    case ArrayEvent.add:
        original.forEach((item) => {
            item.index += 1;
        });
        break;
    default:
        asserts(false, 'Should have matched one of these cases');
    }

    return distinctByIndex(original);
}
