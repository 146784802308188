<template>
    <div class="play-question-container">
        <div v-if="questionDescription || questionLabel" class="question-description">
            {{ questionDescription ? questionDescription : questionLabel }}<span
                class="required"
            >{{ (required(question.validators)) ? t('shared.requiredSymbol') : '' }}</span>
        </div>
        <div v-if="questionAdditionalDescription" class="question-additional-description">
            {{ questionAdditionalDescription }}
        </div>
        <div class="question-text">
            <AbsolutePlacement>
                <template #reference>
                    <DsCheckbox
                        v-model="model"
                        :label="questionLabel"
                        :data-qa="`input-${question.name}`"
                        class="question-input"
                        :class="{
                            warning: !isValidationSevere && hasValidationError,
                            invalid: hasValidationError,
                        }"
                        :submitted="hasValue"
                        :invalid="hasValidationError"
                        @blur="onBlur"
                        @focus="onFocus"
                        @keyup.enter="$emit('submit')"
                    >
                        <!-- Displays error messages from the server -->
                        <template v-if="hasValidationError" #error>
                            <div
                                v-for="error in validationError"
                                :key="error.message"
                                :class="{ 'validation-error': true, warning: !isValidationSevere && hasValidationError }"
                            >
                                {{ fullErrorMessage(error) }}
                                <!-- Also show the help message to guide them further, since it is hidden while errors are displayed -->
                                <slot name="helperText" />
                            </div>
                        </template>

                        <!-- Displays helperText with current value merged in -->
                        <template #help>
                            <slot name="helperText">
                                &nbsp;
                            </slot>
                        </template>
                    </DsCheckbox>
                </template>
                <template #default>
                    <PlaySuggestions
                        v-if="generatesSuggestions && missingFields"
                        :disabled="missingFields.length > 0"
                        :excludes="model"
                        :allow-multiple="false"
                        :question="question"
                        @accept="(sugg: any) => suggestion = sugg"
                    />
                </template>
            </AbsolutePlacement>
        </div>
    </div>
</template>

<script lang="ts">export default { name: 'PlayQuestionCheckbox' }; </script>
<script lang="ts" setup>

import PlaySuggestions from '@/play-editor/PlaySuggestions.vue';
import { metaKeys } from '@/play-editor/play.constants';
import { computed, ref } from 'vue';
import { PlayQuestionCoreEvents, questionMixin } from '@/play-editor/mixins/v3/questionMixin';
import AbsolutePlacement from '@/shared/components/AbsolutePlacement.vue';
import { PlayService } from '@/play-editor/play-service';
import { useAppId } from '@/play-editor/provider/provide-app-id';
import { useCore } from '@/shared/shared-providers';
import { ModelProperty, PropertyValidation } from '@/generated/play-api';
import { KeyValues } from '@/types/core-types';
import { required } from '../question-validation';
import { DEFAULT_EVENT_NAME } from '@/play-editor/mixins/v3/v-model';

const props = defineProps<{
    propMeta?: KeyValues | null;
    labelMerged?: string;
    descriptionMerged?: string;
    focused?: boolean;
    question?: ModelProperty;
    validationError?: PropertyValidation[];
    name?: string;
    modelValue: string;
    hasValue?: boolean;
}>();

const emit = defineEmits([...PlayQuestionCoreEvents, DEFAULT_EVENT_NAME]);
const playService = PlayService(useAppId(useCore()));
const {
    questionDescription, questionAdditionalDescription,
    questionLabel, model, getMetaArray,
    missingFields, hasValidationError,
    isValidationSevere,
    fullErrorMessage, generatesSuggestions,
} = questionMixin<string>(props, emit, '');

const question = computed(() => props.question as ModelProperty);

const suggestion = ref(null);

function onFocus() {
    emit('focus');
}

function onBlur(): void {
    const [suggestion] = getMetaArray(metaKeys.suggestions);

    if (suggestion) {
        const { resultId, item } = suggestion;

        if (item !== props.modelValue) {
            playService.addAcceptInteraction(resultId, item, props.modelValue);
        }
    }
    emit('blur');
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import "src/styles/main";

// These unscoped styles are for replacing the typical red with orange
.input-field.warning input.submitted:invalid,
.input-field.warning input.submitted:invalid:focus {
    border: 1px solid $color-orange-600;
    border-color: $color-orange-600;
}

</style>

<style lang="scss" rel="stylesheet/scss" scoped>
@import "src/styles/main";
@import "play-question";

.help-popover {
    min-height: 130px;
    padding: 1rem;
}

.validation-error.warning {
    color: $color-orange-600;
}

.dropdown-menu-group {
    color: $color-text-subtle;
    font-size: $font-size-xs;
    padding: 0 1rem;
}

.input-trailing-suggest {
    cursor: pointer;
}

.dropdown-menu-list {
    width: calc(500rem / 16);
}

.no-suggestions {
    color: $color-text-disabled;
}

.suggestions-header {
    margin-top: 1rem;
    margin-bottom: calc(1rem / 2);
}

.results-container {
    margin: auto;
    align-content: center;
    text-align: center;
}

.field-error {
    color: $color-red;
    margin-top: calc(1rem / 2);
}

.play-question-list {
    margin-bottom: 1rem;
}

.input-form {
    margin-top: 1rem;
}

.inline-input {
    margin-bottom: calc(1rem / 4);
    padding-bottom: calc(1rem / 4);
}

.pqt-popover-container {
    width: 100%;
}
</style>
<i18n>
{
    "en-us": {
    }
}
</i18n>
