// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck The components aren't registering as correct types
/* eslint-disable vue/multi-word-component-names */
import { App } from 'vue';
import '../types/core-ext';
import {
    icons, illustrations,
    DsAvatar,
    DsButton,
    DsButtonToggle,
    DsButtonToggleOption,
    DsFilledButton,
    DsIconButton,
    DsOutlineButton,
    DsTextButton,
    DsCard,
    DsCardBody,
    DsCardFooter,
    DsCardHeader,
    DsCheckbox,
    DsCheckboxGroup,
    DsChip,
    DsCollapsible,
    DsColorField,
    DsColorPicker,
    DsSaturation,
    DsDatePicker,
    DsBodyClickHandler,
    DsMediaQuery,
    DsDropdown,
    DsDropdownMenu,
    DsTouchOverlay,
    DsEditable,
    DsEditableText,
    DsErrorBanner,
    DsErrorBannerContainer,
    DsGuide,
    DsHtmlPreview,
    DsIcon,
    DsSvgIcon,
    DsIllustration,
    DsInfiniteScroll,
    DsInlineAlert,
    DsInlineAlertFooterLink,
    DsInputField,
    DsLink,
    DsListItem,
    DsLogo,
    DsMenuItemSubtext,
    DsConfirmDialog,
    DsConfirmDialogContainer,
    DsModal,
    DsModalHeader,
    DsModalHeaderTitle,
    DsMultiSelectMenuItem,
    DsMultiselect,
    DsMultiselectMenu,
    DsFocusOnKeyboardOnly,
    DsPhoneInput,
    DsPlaceholder,
    DsPopover,
    DsProgressBar,
    DsPulseGraphic,
    DsRadio,
    DsRadioGroup,
    DsSelectField,
    DsSpinner,
    DsStatus,
    DsTableGrid,
    DsTableGridCell,
    DsTableGridColumn,
    DsTableGridHeader,
    DsTableGridTrowser,
    DsTab,
    DsTabHeader,
    DsTabHeaderItem,
    DsTabHeaderLink,
    DsTabLinks,
    DsTabset,
    DsTextHighlighter,
    DsTextAreaField,
    DsToast,
    DsToastContainer,
    DsToastGroup,
    DsToggleSwitch,
    DsTooltip,
    DsTrowser,
    DsFileUploadInput,
} from '@/shared/utils/design-system-imports.util';
import '@/shared/directives';
import { Store } from 'vuex';
import { State } from '@/store/play-root-state';
import MenuListItem from '@/shared/components/menu/MenuListItem.vue';
import MenuList from '@/shared/components/menu/MenuList.vue';
import MenuListDivider from '@/shared/components/menu/MenuListDivider.vue';
import MenuListHeading from '@/shared/components/menu/MenuListHeading.vue';
import { ConfirmArgs } from '@/ConfirmArgs';
import { createDesignSystem } from '../../../@infusionsoft/design-system';

export function setupDesignSystem(app: App, store: Store<State>) {
    const ds = createDesignSystem({
        components: [],
        icons,
        illustrations,
        errorBanner: {
            selector: '#content',
            topPxOffset: '56px',
        },

        onOverlayActive: () => {
            store.commit('overlays/SET_OVERLAY_ACTIVE', true);
        },
        onOverlayInactive: () => {
            store.commit('overlays/SET_OVERLAY_ACTIVE', false);
        },
    });

    app.use(ds);

    app.mixin({
        beforeCreate() {
            this.$confirmation = async function (args: ConfirmArgs): Promise<boolean> {
                try {
                    const t = this.$t.bind(this);
                    const {
                        confirmLabel = t('confirm.confirmLabel'),
                        confirmButtonLabel = t('confirm.confirmLabel'),
                        cancelLabel = t('confirm.cancelLabel'),
                        destructive = false,
                        ...otherArgs
                    } = args;

                    await this.$confirm({
                        ...otherArgs,
                        confirmLabel,
                        confirmButtonLabel,
                        cancelLabel,
                        destructive,
                    });

                    return true;
                } catch (e) {
                    return false;
                }
            };
        },
    });

    const Vue = app;

    // Just for Intellij below
    Vue.component('DsAvatar', DsAvatar);
    Vue.component('DsButton', DsButton);
    Vue.component('DsButtonToggle', DsButtonToggle);
    Vue.component('DsButtonToggleOption', DsButtonToggleOption);
    Vue.component('DsFilledButton', DsFilledButton);
    Vue.component('DsIconButton', DsIconButton);
    Vue.component('DsOutlineButton', DsOutlineButton);
    Vue.component('DsTextButton', DsTextButton);
    Vue.component('DsCard', DsCard);
    Vue.component('DsCardBody', DsCardBody);
    Vue.component('DsCardFooter', DsCardFooter);
    Vue.component('DsCardHeader', DsCardHeader);
    Vue.component('DsCheckbox', DsCheckbox);
    Vue.component('DsCheckboxGroup', DsCheckboxGroup);
    Vue.component('DsChip', DsChip);
    Vue.component('DsCollapsible', DsCollapsible);
    Vue.component('DsColorField', DsColorField);
    Vue.component('DsColorPicker', DsColorPicker);
    Vue.component('DsSaturation', DsSaturation);
    Vue.component('DsDatePicker', DsDatePicker);
    Vue.component('DsBodyClickHandler', DsBodyClickHandler);
    Vue.component('DsMediaQuery', DsMediaQuery);
    Vue.component('DsDropdown', DsDropdown);
    Vue.component('DsDropdownMenu', DsDropdownMenu);
    Vue.component('DsTouchOverlay', DsTouchOverlay);
    Vue.component('DsEditable', DsEditable);
    Vue.component('DsEditableText', DsEditableText);
    Vue.component('DsErrorBanner', DsErrorBanner);
    Vue.component('DsErrorBannerContainer', DsErrorBannerContainer);
    Vue.component('DsGuide', DsGuide);
    Vue.component('DsHtmlPreview', DsHtmlPreview);
    Vue.component('DsIcon', DsIcon);
    Vue.component('DsSvgIcon', DsSvgIcon);
    Vue.component('DsIllustration', DsIllustration);
    Vue.component('DsInfiniteScroll', DsInfiniteScroll);
    Vue.component('DsInlineAlert', DsInlineAlert);
    Vue.component('DsInlineAlertFooterLink', DsInlineAlertFooterLink);
    Vue.component('DsInputField', DsInputField);
    Vue.component('DsLink', DsLink);
    Vue.component('DsListItem', DsListItem);
    Vue.component('DsLogo', DsLogo);
    Vue.component('DsMenuItemSubtext', DsMenuItemSubtext);
    Vue.component('DsConfirmDialog', DsConfirmDialog);
    Vue.component('DsConfirmDialogContainer', DsConfirmDialogContainer);
    Vue.component('DsModal', DsModal);
    Vue.component('DsModalHeader', DsModalHeader);
    Vue.component('DsModalHeaderTitle', DsModalHeaderTitle);
    Vue.component('DsMultiSelectMenuItem', DsMultiSelectMenuItem);
    Vue.component('DsMultiselect', DsMultiselect);
    Vue.component('DsMultiselectMenu', DsMultiselectMenu);
    Vue.component('DsFocusOnKeyboardOnly', DsFocusOnKeyboardOnly);
    Vue.component('DsPhoneInput', DsPhoneInput);
    Vue.component('DsPlaceholder', DsPlaceholder);
    Vue.component('DsPopover', DsPopover);
    Vue.component('DsProgressBar', DsProgressBar);
    Vue.component('DsPulseGraphic', DsPulseGraphic);
    Vue.component('DsRadio', DsRadio);
    Vue.component('DsRadioGroup', DsRadioGroup);
    Vue.component('DsSelectField', DsSelectField);
    Vue.component('DsSpinner', DsSpinner);
    Vue.component('DsStatus', DsStatus);
    Vue.component('DsTableGrid', DsTableGrid);
    Vue.component('DsTableGridCell', DsTableGridCell);
    Vue.component('DsTableGridColumn', DsTableGridColumn);
    Vue.component('DsTableGridHeader', DsTableGridHeader);
    Vue.component('DsTableGridTrowser', DsTableGridTrowser);
    Vue.component('DsTab', DsTab);
    Vue.component('DsTabHeader', DsTabHeader);
    Vue.component('DsTabHeaderItem', DsTabHeaderItem);
    Vue.component('DsTabHeaderLink', DsTabHeaderLink);
    Vue.component('DsTabLinks', DsTabLinks);
    Vue.component('DsTabset', DsTabset);
    Vue.component('DsTextHighlighter', DsTextHighlighter);
    Vue.component('DsTextAreaField', DsTextAreaField);
    Vue.component('DsToast', DsToast);
    Vue.component('DsToastContainer', DsToastContainer);
    Vue.component('DsToastGroup', DsToastGroup);
    Vue.component('DsToggleSwitch', DsToggleSwitch);
    Vue.component('DsTooltip', DsTooltip);
    Vue.component('DsTrowser', DsTrowser);
    Vue.component('DsFileUploadInput', DsFileUploadInput);

    Vue.component('Avatar', DsAvatar);
    // eslint-disable-next-line vue/no-reserved-component-names
    // Vue.component('Button', DsButton);
    Vue.component('ButtonToggle', DsButtonToggle);
    Vue.component('ButtonToggleOption', DsButtonToggleOption);
    Vue.component('FilledButton', DsFilledButton);
    Vue.component('IconButton', DsIconButton);
    Vue.component('OutlineButton', DsOutlineButton);
    Vue.component('TextButton', DsTextButton);
    Vue.component('Card', DsCard);
    Vue.component('CardBody', DsCardBody);
    Vue.component('CardFooter', DsCardFooter);
    Vue.component('CardHeader', DsCardHeader);
    // Vue.component('Checkbox', DsCheckbox);
    Vue.component('CheckboxGroup', DsCheckboxGroup);
    Vue.component('Chip', DsChip);
    Vue.component('Collapsible', DsCollapsible);
    Vue.component('ColorField', DsColorField);
    Vue.component('ColorPicker', DsColorPicker);
    Vue.component('Saturation', DsSaturation);
    Vue.component('DatePicker', DsDatePicker);
    Vue.component('BodyClickHandler', DsBodyClickHandler);
    Vue.component('MediaQuery', DsMediaQuery);
    Vue.component('Dropdown', DsDropdown);
    Vue.component('DropdownMenu', DsDropdownMenu);
    Vue.component('TouchOverlay', DsTouchOverlay);
    Vue.component('Editable', DsEditable);
    Vue.component('EditableText', DsEditableText);
    Vue.component('ErrorBanner', DsErrorBanner);
    Vue.component('ErrorBannerContainer', DsErrorBannerContainer);
    Vue.component('Guide', DsGuide);
    Vue.component('HtmlPreview', DsHtmlPreview);
    Vue.component('Icon', DsIcon);
    Vue.component('SvgIcon', DsSvgIcon);
    Vue.component('Illustration', DsIllustration);
    Vue.component('InfiniteScroll', DsInfiniteScroll);
    Vue.component('InlineAlert', DsInlineAlert);
    Vue.component('InlineAlertFooterLink', DsInlineAlertFooterLink);
    Vue.component('InputField', DsInputField);
    // eslint-disable-next-line vue/no-reserved-component-names
    // Vue.component('Link', DsLink);
    Vue.component('ListItem', DsListItem);
    Vue.component('Logo', DsLogo);
    Vue.component('MenuItemSubtext', DsMenuItemSubtext);
    Vue.component('ConfirmDialog', DsConfirmDialog);
    Vue.component('ConfirmDialogContainer', DsConfirmDialogContainer);
    Vue.component('Modal', DsModal);
    Vue.component('ModalHeader', DsModalHeader);
    Vue.component('ModalHeaderTitle', DsModalHeaderTitle);
    Vue.component('MultiSelectMenuItem', DsMultiSelectMenuItem);
    Vue.component('Multiselect', DsMultiselect);
    Vue.component('MultiselectMenu', DsMultiselectMenu);
    Vue.component('FocusOnKeyboardOnly', DsFocusOnKeyboardOnly);
    Vue.component('PhoneInput', DsPhoneInput);
    Vue.component('Placeholder', DsPlaceholder);
    Vue.component('Popover', DsPopover);
    Vue.component('ProgressBar', DsProgressBar);
    Vue.component('PulseGraphic', DsPulseGraphic);
    Vue.component('Radio', DsRadio);
    Vue.component('RadioGroup', DsRadioGroup);
    Vue.component('SelectField', DsSelectField);
    Vue.component('Spinner', DsSpinner);
    Vue.component('Status', DsStatus);
    Vue.component('TableGrid', DsTableGrid);
    Vue.component('TableGridCell', DsTableGridCell);
    Vue.component('TableGridColumn', DsTableGridColumn);
    Vue.component('TableGridHeader', DsTableGridHeader);
    Vue.component('TableGridTrowser', DsTableGridTrowser);
    Vue.component('Tab', DsTab);
    Vue.component('TabHeader', DsTabHeader);
    Vue.component('TabHeaderItem', DsTabHeaderItem);
    Vue.component('TabHeaderLink', DsTabHeaderLink);
    Vue.component('TabLinks', DsTabLinks);
    Vue.component('Tabset', DsTabset);
    Vue.component('TextHighlighter', DsTextHighlighter);
    Vue.component('TextAreaField', DsTextAreaField);
    Vue.component('Toast', DsToast);
    Vue.component('ToastContainer', DsToastContainer);
    Vue.component('ToastGroup', DsToastGroup);
    Vue.component('ToggleSwitch', DsToggleSwitch);
    Vue.component('Tooltip', DsTooltip);
    Vue.component('Trowser', DsTrowser);
    Vue.component('FileUploadInput', DsFileUploadInput);

    // Used to be in DS, but was removed.
    Vue.component('MenuListItem', MenuListItem);
    Vue.component('MenuList', MenuList);
    Vue.component('MenuListHeading', MenuListHeading);
    Vue.component('MenuListDivider', MenuListDivider);
}
