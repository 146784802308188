/**
 * COPIED FROM keap-web
 */

export enum EventType {
    date= 'date',
    appointment= 'appointment',
    invoiceDue= 'invoice_due',
}

export enum RangeOption {
    at= 'at',
    between= 'between',
}

export enum OffsetType {
    before = 'before',
    after = 'after',
    none = 'none',
}
