<script lang="ts">export default { name: 'AccountSwitcherRoot' }; </script>
<template>
    <div>
        <div class="account-switcher" :class="{ active: isOpen }">
            <header>
                <DsIconButton v-if="showCloseButton" name="x" @click="close" />

                <h4>{{ $t('switcher.switchAccounts') }}</h4>
            </header>

            <div v-show="!isError && !showEmptyState" class="account-list">
                <div class="input-container">
                    <DsInputField
                        ref="input"
                        v-model.trim="searchText"
                        type="search"
                        class="search-input"
                        :placeholder="t('switcher.search.placeholder')"
                    />
                </div>

                <div v-if="isAdmin" class="account-list-header">
                    <span>{{ t('switcher.tools') }} </span>
                </div>
                <div v-if="isAdmin" class="list">
                    <div class="account-list-item">
                        <div class="account-link-box">
                            <a
                                class="account-link"
                                title="Testing"
                                @click="navigateTo({ name: RouteName.frameTest }, false)"
                            >
                                <p class="account-name semibold">{{ t('routes.frameTest') }}</p>
                                <p class="legacy-id light">{{ t('routes.frameTestDescription') }}</p>
                            </a>
                        </div>
                    </div>
                </div>
                <div v-if="isCoach" class="account-list-header">
                    <span>{{ t('coachingPortal') }}</span> <span>{{
                        t('switcher.role', {role: isAdmin ? 'Admin' : isCoachAdmin ? 'Coach Admin' : isCoach ? 'Coach' : 'None'})
                    }}</span>
                </div>
                <div class="list">
                    <div v-if="isCoach" class="account-list-item">
                        <div class="coach-link-box">
                            <DsButton
                                class="account-link"
                                @click="navigateTo({ name: RouteName.coachRoot })"
                            >
                                {{ t('customers') }}
                            </DsButton>
                            <DsButton
                                class="account-link"
                                @click="navigateTo({ name: RouteName.coachTemplates })"
                            >
                                {{ t('templates') }}
                            </DsButton>
                            <DsButton
                                v-if="isCoachAdmin"
                                variant="outline"
                                class="account-link"
                                :title="t('admin')"
                                @click="navigateTo({ name: RouteName.coachAdmin })"
                            >
                                {{ t('admin') }}
                            </DsButton>
                        </div>
                    </div>
                </div>
                <div class="account-list-header">
                    <span>{{ $t('switcher.accounts') }} </span>
                    <span v-if="hasCount">{{ accountsCount }}</span>
                </div>

                <div class="list">
                    <div
                        v-for="account in accounts"
                        :key="`account-${account.id}`"
                        class="account-list-item"
                    >
                        <div class="account-link-box">
                            <a
                                class="account-link"
                                :title="account.legacyId"
                                @click="navigateTo(account.route)"
                            >
                                <p class="account-name semibold">{{ account.name }}</p>
                                <p v-if="account.legacyId" class="legacy-id light">{{ account.legacyId }}</p>
                            </a>
                        </div>
                    </div>

                    <DsInfiniteScroll
                        ref="infiniteScroll"
                        :no-more-data="noMoreData"
                        :loading="isLoading"
                        :limit="PAGE_SIZE"
                        @load="fetchMore"
                    >
                        <div class="placeholder-container placeholder">
                            <div v-for="n in 3" :key="`account-placeholder-${n}`">
                                <div class="placeholder-top">
                                    <DsPlaceholder :rows="placeholderRows" class="placeholder" />
                                </div>

                                <div class="placeholder-bottom">
                                    <DsPlaceholder :rows="placeholderRows" class="placeholder" />
                                </div>
                            </div>
                        </div>
                    </DsInfiniteScroll>
                </div>
            </div>

            <div v-show="isError" class="error">
                <span class="error-message">{{ $t('switcher.requestError') }}</span>

                <DsTextButton class="reload-button" @click="reloadAccounts">
                    {{ $t('switcher.reloadAccounts') }}
                </DsTextButton>
            </div>

            <div v-show="showEmptyState" class="empty-state-box">
                <h3 class="semibold">
                    {{ $t('switcher.emptyState.title') }}
                </h3>

                <i18n-t tag="p" keypath="switcher.emptyState.body" scope="global">
                    <a
                        href="/logout"
                        rel="noopener"
                    >
                        {{ $t('switcher.emptyState.link') }}
                    </a>

                    <img src="../images/empty-state.png" />
                </i18n-t>
            </div>
            <DsInlineAlert type="subtle" class="missing-account-instructions">
                <i18n-t tag="div" keypath="switcher.missingAccount.body">
                    <a
                        href="https://keap.co/cgsupport"
                        target="_blank"
                        rel="noopener"
                    >
                        {{ $t('switcher.missingAccount.link') }}
                    </a>
                </i18n-t>
            </DsInlineAlert>
            <DsTextButton class="logout-button" @click="goToUrl('/logout')">
                {{ t('switcher.logout') }}
            </DsTextButton>
            <div v-if="store.state.auth.user" class="current-user">
                {{ t('switcher.current', { email: store.state.auth.user.username }) }}
            </div>
        </div>
        <slot />
    </div>
</template>
<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import { useCore } from '@/shared/shared-providers';
import { injectAccountSwitcher } from '@/shared/components/AccountSwitchProvider';
import { RawLocation } from '@/shared/compat';

const PAGE_SIZE = 30;

const { store, router } = useCore();
const {
    state, reloadAccounts, fetchMore, close,
} = injectAccountSwitcher();

const {
    isOpen, accountsCount, accounts, hasCount,
    isLoading, noMoreData, showCloseButton,
    showEmptyState, searchText, isError,
} = toRefs(state);

const placeholderRows = [{ height: '0.5rem', boxes: [1] }];

const isCoach = computed(() => store.state.auth.roles.isCoach);
const isCoachAdmin = computed(() => store.state.auth.roles.isCoachAdmin);
const isAdmin = computed(() => store.state.auth.roles.isAdmin);

function goToUrl(url: string) {
    window.location.href = url;
}

function navigateTo(location: RawLocation, replace = true) {
    isOpen.value = false;

    return replace ? router.replace(location) : router.push(location);
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
@import "@/coach/coach";

$header-height: calc(75rem / 16);

.account-switcher {
    @include transition(transform);

    transform: translateX(100%);
    background-color: $color-paper;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    width: calc(400rem / 16);
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-bottom: $spacing-400;

    @media ($small) {
        width: 100%;
    }

    &.active {
        transform: translateX(0);
        box-shadow: $drop-shadow-z2;
    }

    .current-user {
        font-style: italic;
        text-align: center;
        font-size: $font-size-xs;
    }
}

.missing-account-instructions {
    margin: $spacing-200;
}

.close-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

header {
    background-color: $color-paper;
    display: flex;
    align-items: center;
    height: $nav-height;
    padding: 0 $spacing-200;

    h4 {
        @include margin-start($spacing-100);
    }
}

.account-list {
    width: 100%;
    overflow: auto;
    flex-grow: 1;
}

.account-list-header {
    display: flex;
    justify-content: space-between;
    background-color: $color-gray-200;
    padding: $spacing-100 $spacing-300;
}

.empty-state-box {
    padding: $spacing-400;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: $color-gray-800;

    @media ($small) {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    img {
        width: calc(200rem / 16);
    }

    h3 {
        margin-bottom: $spacing-050;
    }

    p {
        padding-bottom: $spacing-400;
        font-size: calc(16rem / 16);
        text-align: center;
    }
}

.list {
    padding: 0 $spacing-300;
    margin-bottom: $spacing-400;
}

.placeholder-top {
    width: 100%;
    margin: $spacing-300 0 $spacing-200;
}

.placeholder-bottom {
    width: 25%;
}

.account-list-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-grow: 5;
}

.account-link-box {
    flex-grow: 4;
}

.coach-link-box {
    display: flex;
    gap: $spacing-100;
    padding-top: $spacing-200;
}

.account-link {
    text-decoration-color: $color-gray-800;
}

.account-name {
    color: $color-gray-800;
    margin: $spacing-200 0 0;
}

.legacy-id {
    color: $color-gray-800;
    margin: 0;
}

.input-container {
    padding: 0 $spacing-200 $spacing-200;
}

.search-input {
    --input-margin-bottom: 0;
}

.error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $spacing-300;
}

.error-message {
    background-color: $color-red-050;
    border-radius: $border-radius;
    color: $color-red-900;
    padding: $spacing-200 $spacing-300;
}

.reload-button {
    margin: $spacing-400;
}

[dir="rtl"] {
    .account-switcher {
        transform: translateX(-100%);
        right: auto;
        left: 0;

        &.active {
            transform: translateX(0);
        }
    }
}

.button-box {
    margin-top: $spacing-200;
}
</style>

<i18n lang="json">
{
    "en-us": {
        "coachingPortal": "Coaching Portal",
        "customers": "Customers",
        "templates": "Templates",
        "admin": "Coach admin",
        "switcher": {
            "tools": "Development Tools",
            "header": "Account Switcher",
            "switchAccounts": "Select an Account",
            "accounts": "Accounts",
            "coachTools": "Coaching tools",
            "role": "(Role: {role})",
            "search": {
                "placeholder": "Search accounts"
            },
            "reloadAccounts": "Reload accounts",
            "requestError": "There was a technical error while getting your accounts list. Please try again.",
            "missingAccount": {
                "body": "If you don't see an account you think should be here, please fill out a {0}.",
                "link": "AI Automation Assistant support request"
            },
            "emptyState": {
                "title": "Well, this is awkward",
                "body": "You logged in successfully, but it looks like this login doesn't have access to any AI Automation Assistant accounts. You can try {0} using a different email address.",
                "link": "logging in"
            },
            "logout": "Login with a different email",
            "current": "Current: {email}"
        }
    }
}
</i18n>
