<template>
    <main id="main-content">
        <div class="welcome-wrapper">
            <div class="welcome-message">
                <Illustration name="notes-color" />

                <span class="title">{{ $t('title') }}</span>

                <span class="subtitle">
                    {{ $t('subtitle') }}
                </span>
            </div>
        </div>
    </main>
</template>

<script>

import { ProviderName } from '@/play-editor/play.constants';

export default {
    name: 'NewAccount',
    inject: [ProviderName.accountSwitcherProvider],
    data() {
        return {
            switcherIsOpen: true,
        };
    },

    mounted() {
        this.accountSwitcherProvider.open(false);
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.welcome-wrapper {
    display: flex;
    align-items: center;
    margin-right: calc(400rem / 16);
}

.welcome-message {
    flex: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: $spacing-600;
}

.title {
    font-family: $font-family-secondary-title;
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
    margin: $spacing-100 0;
}

.subtitle {
    color: $color-text-subtle;
    font-size: $font-size-md;
    margin-bottom: $spacing-200;
}
</style>

<i18n>
{
    "en-us": {
        "title": "Welcome to AI Automation Assistant!",
        "subtitle": "To start, select an account in the right panel",
        "showAccount": "Show account list"
    }
}
</i18n>
