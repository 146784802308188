import { changeElementTagName, exportContentToText } from '@/play-editor/content-export';
import asserts from '@/shared/asserts';

import { MimeTypes } from '@/play-editor/play.constants';
import { ExportDestination } from '@/play-editor/play.exporters';
import { getCustomElementDefinitions } from '@/shared/custom-element-definitions';

export function generateExportContent(content:string, exportDestination: ExportDestination) {
    asserts(exportDestination, 'Must provide export definition');
    const { mimeType, exportOptions = {}, transformOptions } = exportDestination;
    let output = content;

    if (mimeType === MimeTypes.text) {
        output = exportContentToText(content, exportOptions);
    }

    asserts.containsValue(MimeTypes, mimeType, `Must be a valid mime-type: was ${mimeType}`);

    if (transformOptions) {
        const {
            trimNewlinesBetweenTags = false,
            trimSpaceBetweenTags = false,
            transformer,
            elementMapping = {},
            collapsedElements = [],
        } = transformOptions;
        let div: Element = document.createElement('div');

        if (trimNewlinesBetweenTags || trimSpaceBetweenTags) {
            let regex;

            if (trimSpaceBetweenTags) {
                const inlineTagNames = ['span', 'u', 'b', ...Object.entries(getCustomElementDefinitions()).filter(([, { inline }]) => inline).map(([k]) => k)];

                regex = new RegExp(`>\\s+<?!(${inlineTagNames.join('|')})`, 'g');

                output = output.replaceAll(regex, '><');
            } else {
                output = output
                    .replaceAll(/\n\s*</g, '<')
                    .replaceAll(/>\n/g, '>')
                    .replaceAll(/\s\s+/g, ' ');
            }
        }
        div.innerHTML = output;

        if (collapsedElements?.length > 0) {
            div.querySelectorAll(collapsedElements.join(',')).forEach((element) => {
                element.replaceWith(...element.childNodes);
            });
        }
        Object.entries(elementMapping ?? {}).forEach(([fromElement, toElement]) => {
            div.querySelectorAll(fromElement).forEach((from) => {
                changeElementTagName(from, toElement);
            });
        });

        if (transformer != null) {
            const transformResult = transformer(div);

            if (transformResult) {
                div = transformResult;
            }
        }
        const processedOutput = div.innerHTML;

        if (processedOutput !== output) {
            output = processedOutput;
        }
    }

    return exportDestination.processor ? exportDestination.processor(output) : output;
}

export async function copyToClipboard(inputContent:string, exportDestination:ExportDestination) {
    const content = generateExportContent(inputContent, exportDestination);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const clipboard = (<any>window).clipboardData || navigator.clipboard;
    const { mimeType } = exportDestination;

    if (mimeType === MimeTypes.html) {
        const text = new Blob([content], { type: MimeTypes.text });
        const html = new Blob([content], { type: MimeTypes.html });
        // eslint-disable-next-line no-undef
        const item = new ClipboardItem({
            [MimeTypes.text]: text,
            [MimeTypes.html]: html,
        }, {
            presentationStyle: 'inline',
        });

        await clipboard.write([item]);
    } else if (mimeType === MimeTypes.text) {
        await clipboard.writeText(content);
        // clipboardData['text/plain'] = new Blob([textContent], { type: 'text/plain' });
    } else {
        asserts(false, 'Wrong type');
    }

    return content;
}
