/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    inject, InjectionKey, onBeforeUnmount, reactive,
} from 'vue';
import { NavigationCompleterService, NavigationService } from '@/router/NavigationService';
import { RouteObserver, RouteSettings } from '@/router/RouteObserver';
import { useCore, useLog } from '@/shared/shared-providers';
import { fireEvent, PlayEvent } from '@/events';
import asserts from '@/shared/asserts';
import { RawLocation, RouteNavigator } from '@/shared/navigation';

export const PageStackNavigatorKey: InjectionKey<NavigationService<RouteSettings>> = Symbol('pageStackNavigatorKey');
export const NavigatorKey: InjectionKey<NavigationService<unknown>> = Symbol('navigatorKey');
export const RouterNavigatorKey: InjectionKey<RouteNavigator<RawLocation>> = Symbol('routerNavigatorKey');

export function useNavigator(): NavigationService<unknown> {
    return inject(NavigatorKey);
}

export type PageStackNavigator = NavigationService<RouteSettings>;

export function usePageStackNavigator(): PageStackNavigator {
    return inject(PageStackNavigatorKey);
}

export function injectRouterNavigator(): RouteNavigator<RawLocation> {
    return inject(RouterNavigatorKey);
}

export type RouteCompletionData<T> = {
    routeCompletionValue?: T;
    routeErrorValue?: unknown;
    routeCompleter?: RouteObserver<T>,
    hasFinalized: boolean;
};

export function injectPageStackCompleter<C>(requireCompleter = false): NavigationCompleterService<C, RouteSettings> {
    const nav = inject(PageStackNavigatorKey);

    const data = reactive({
        routeCompletionValue: null,
        routeErrorValue: null,
        hasFinalized: false,
    }) as RouteCompletionData<C>;

    const { name } = useCore();
    const log = useLog('completer');

    function completeRoute(result: C|Error, update = true) {
        asserts(!data.hasFinalized, 'Should not be completing route after finalizes');

        if (result) {
            if (result instanceof Error) {
                log.info('Complete with error', result);
                data.routeCompletionValue = null;
                data.routeErrorValue = result;
            } else {
                log.info('Complete with value', result);
                data.routeCompletionValue = result;
                data.routeErrorValue = null;
            }

            if (update) {
                data.routeCompleter?.update(result);
            }
        }
    }

    data.routeCompleter = nav.acceptObserver(name, requireCompleter);

    if (data.routeCompleter == null && requireCompleter) {
        log.warn('MISSING COMPLETER:', data.routeCompleter);
    }

    function finalize(isUnmount = false) {
        if (data.routeCompleter) {
            if (!data.hasFinalized) {
                data.hasFinalized = true;
                log.info('Completing promise', {
                    value: data.routeCompletionValue,
                    error: data.routeErrorValue,
                });

                if (data.routeErrorValue) {
                    data.routeCompleter.error(data.routeErrorValue);
                } else {
                    data.routeCompleter.complete(data.routeCompletionValue);
                }
            } else if (!isUnmount) {
                log.warn('Promise already complete');
            }
        } else if (requireCompleter) {
            log.info('No observers to notify');
        }
    }

    onBeforeUnmount(() => finalize(true));

    return <NavigationCompleterService<C, RouteSettings>>{
        promise: data.routeCompleter?.done,
        ...nav,
        complete(result?: C|Error):void {
            completeRoute(result);
        },
        back(result?: C|Error):void {
            completeRoute(result, false);
            fireEvent(PlayEvent.navigateBack);
            finalize();
            nav.back();
        },
    };
}
