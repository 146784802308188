import { ModelSchema } from '@/generated/play-api';
import { ModelKey, ModelKeyOrName } from '@/integration/datastore/model-keys';
import { PreparedModel } from '@/integration/datastore/base-types';
import { Dict, KeyValues } from '@/types/core-types';
import { ProviderName } from '@/play-editor/play.constants';
import {
    ComputedRef, inject, Ref,
} from 'vue';
import { DataScopeData } from '@/model/form/DataScope';
import asserts from '@/shared/asserts';
import { until, UseAsyncStateReturn } from '@vueuse/core';

export type AsyncState<T> = UseAsyncStateReturn<T, [], false>;
export type AsyncStateDict<T> = Dict<AsyncState<T>>;

export async function waitAsync<T>(asyncState:AsyncState<T>):Promise<T> {
    const stateValue = await until(asyncState.state).toMatch((e) => e != null, { deep: true });

    return stateValue as T;
}

export type ComposeParams = {
    pageTitle?: string;
    pageDescription?: string;
    dataScope: DataScopeData;
}

export type ModelServiceCache = {
    schemas: AsyncStateDict<ModelSchema>;
    models: Dict<Dict<PreparedModel>>;
    lists: AsyncStateDict<PreparedModel[]>;
}

export type TenantModelServiceProvider = {
    getModelService(appId:string): TenantModelService;
};

export type TenantModelService = {
    models: Dict<Dict<PreparedModel>>;
    loadModelSchema(refType: ModelKeyOrName): AsyncState<ModelSchema>;
    getModels(key: ModelKeyOrName): Dict<PreparedModel>;
    getCachedModel(key: ModelKeyOrName, modelId: string): PreparedModel | Promise<PreparedModel>;
    cacheModel(key: ModelKeyOrName, modelId: string, model: PreparedModel): void;
    loadModel(type: ModelKeyOrName, modelId: string, force?: boolean): Promise<PreparedModel>;
    loadList(type: ModelKeyOrName, force?: boolean): AsyncState<PreparedModel[]>;
    deleteModel(type: ModelKeyOrName, modelId:string): Promise<boolean>;
    createModel(type: ModelKeyOrName, data: PreparedModel): Promise<PreparedModel>;
    updateModel(type: ModelKeyOrName, modelId: string, data: KeyValues): Promise<PreparedModel>;
    searchList(type: ModelKeyOrName, term?: string): Promise<PreparedModel[]>;
}

export interface ModelTypeService {
    loadModel(id: string) : Promise<PreparedModel>;
    searchList(searchTerm: string) : Promise<PreparedModel[]>;
    editModel(modelId: string, params: ComposeParams) : Promise<PreparedModel>;
    deleteModel(modelId: string) : Promise<boolean>;
    composeModel (params: ComposeParams) : Promise<PreparedModel>;
    saveModel(modelId: string | null, data: PreparedModel) : Promise<PreparedModel>;
    modelListState: AsyncState<PreparedModel[]>;
    modelsById: ComputedRef<Dict<PreparedModel>>;
    isReady: Ref<boolean>;
    refTypeKey: string;
    refTypeObj: ModelKey;
    readonly modelSchemaState: AsyncState<ModelSchema>;
}

export function injectModelService(appId:string):TenantModelService {
    asserts(appId != null, 'Injected model service must have appId');
    const modelProvider = inject<TenantModelServiceProvider>(ProviderName.modelProvider);

    asserts(modelProvider != null, 'modelProvider was not provided');

    return modelProvider.getModelService(appId);
}
