import { RemoteDataStore } from '@/integration/datastore/data-store-remote';
import { ModelKeyNames, ModelKeys } from '@/integration/datastore/model-keys';
import { HostSystem } from '@/integration/host-system-api';
import { listAppointmentTypes } from '@/integration/hosts/keap-web/api';
import { ModelData, PreparedModel } from '@/integration/datastore/base-types';
import { env } from '@/env';
import { storeLog } from '@/integration/datastore/data-store-api';

const log = storeLog.child('appointmentType', true);

export function AppointmentTypeStore(appId:string) {
    return RemoteDataStore.of(appId, ModelKeyNames.Consult, HostSystem.KEAP_WEB, {
        async remoteList() {
            try {
                const appts = await listAppointmentTypes(appId);

                const data = appts.map(mapAppointmentType);

                return {
                    data,
                    totalCount: data.length,
                };
            } catch (e) {
                log.severe('Error fetching remote: Continuing:', e);

                return {
                    data: [],
                    totalCount: 0,
                };
            }
        },

        async remoteGet(id) {
            /// Right now, there's no way to look up a single number - so, we try to look back to the cache
            const allAppointments = await listAppointmentTypes(appId);
            const found = allAppointments.find((appt) => appt.id === id || appt.bookingLink === id);

            if (!found) {
                throw new Error('Could not found appointment type');
            }

            return mapAppointmentType(found);
        },
    });
}

export function mapAppointmentType({
    name, id, bookingLink, durationMinutes,
}: ModelData):PreparedModel {
    return {
        type: ModelKeys.of(ModelKeyNames.Consult),
        valid: true,
        data: {
            ignored: true,
            sourceId: id,
            consultLength: `PT${durationMinutes.toString()}M`,
            consultName: name,
            hostSystem: HostSystem.KEAP_WEB,
            bookingLink: `${env.VUE_APP_BOOKING_BASE_URL}${bookingLink.toString()}`,
        },
        id: id.toString(),
        hostSystem: HostSystem.KEAP_WEB,
        sourceId: id.toString(),
        errors: [],
        title: name?.toString() ?? 'Consult',
        description: '',

    };
}
