import { KeyValues } from '@/types/core-types';

type ExtraParams = {
    channel?: MessageChannel;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type KeapMessageHandler = (keapRequestType:string, keapRequestData:any) => Promise<any>;

/**
 * Sends a postMessage to a window or frame, using a common keap message payload.
 *
 * This function also transfers a [MessagePort] to the target window, which should be used to pass any response back.
 *
 * See [handleKeapEvents] for a function that can respond to these events.
 *
 * @param target The target window/frame to send the message to
 * @param keapRequestType The type of request
 * @param requestData input data, if any
 * @param onMessageError To be invoked if there's an error while sending the message
 * @param channel An optional MessageChannel - if none is provided, then a new one will be created.
 * @returns {Promise<*>}
 */

export function sendKeapEvent(
    target:Window,
    keapRequestType:string,
    requestData:KeyValues|null|undefined,
    { channel }: ExtraParams = {},
) {
    const { port1, port2 } = channel ?? new MessageChannel();

    return new Promise((resolve, reject) => {
        port1.onmessage = ({ data: { success, response, error } }) => {
            try {
                success ? resolve(response) : reject(error);
            } finally {
                port1.close();
            }
        };
        port1.start();

        const testPort = channel ? port2 : null;

        try {
            target.postMessage({ keapRequestType, requestData, testPort }, '*', [port2]);
        } catch (error) {
            port1.close();
            reject(new Error(`Failed to send: ${(<Error>error).message}`));
        }
    });
}

/**
 * Constructs an EventMessage handler that processes keap cross-window events.
 *
 * The listener looks for events with a specified payload, dispatches them to a handler function, and then sends
 * the response back through the provided MessagePort (if one was provided when the MessageEvent was dispatched).
 *
 * See [sendKeapEvent] for the sending side.
 *
 * ```
 * window.addEventListener('message', listenForKeapEvents(()=>{}, {onMessageError: ()=>{}});
 * ```
 *
 * @param onKeapMessage A callback function that takes in two parameters: keapEventType and requestData, and returns a Promise<*> containing the result of processing htat message
 * @returns An EventMessage listener
 */
export const handleKeapEvents = (onKeapMessage:KeapMessageHandler) => {
    return async ({
        data: {
            keapRequestType, requestData = {}, testPort,
        }, ports: [responsePort],
    }:MessageEvent) => {
        const port = responsePort ?? testPort;

        try {
            if (keapRequestType) {
                const response = await onKeapMessage(<string>keapRequestType, requestData);

                if (port) port.postMessage({ success: true, response });
            }
        } catch (error) {
            if (port) port.postMessage({ success: false, error: `${(<Error>error).message}` });
        }
    };
};
