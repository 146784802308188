/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * What type of action is indicated by this step
 */
export enum ChecklistActionType {
    HREF = 'HREF',
    VIDEO = 'VIDEO',
    NAVIGATE = 'NAVIGATE',
    MEDIA = 'MEDIA',
    PUBLISH = 'PUBLISH',
}
