/* eslint-disable @typescript-eslint/no-explicit-any */
import { reactive } from 'vue';
import { PlayChecklist } from '@/generated/play-api';
import { Dict } from '@/types/core-types';
import { PlayLaunchResult } from '@/integration/capabilities/capability-types';
import { PlayProvider, PlayProviderParams, PlayState } from '@/play-editor/provider/vue3/play-provider-types';
import { createPlayProviderState } from '@/play-editor/provider/vue3/play-provider-computed';
import { configurePlayProviderMethods } from '@/play-editor/provider/vue3/play-provider-methods';
import { injectHostProvider } from '@/play-editor/provider/provider-types';
import { useCore } from '@/shared/shared-providers';
import { useAppId } from '@/play-editor/provider/provide-app-id';
import asserts from '@/shared/asserts';
import { logger } from '@/shared/logging';

export const fallbackPlayProvider = () => createPlayProvider({
    onClose: () => {
        // Nothing to do
    },
    appId: 'anon',
});

export function createPlayProvider(params: PlayProviderParams): PlayProvider {
    params.hostProvider ??= injectHostProvider();
    params.coreProvider ??= useCore();
    params.appId ??= useAppId(params.coreProvider).value;
    params.log = logger('provider.PlayProvider');

    asserts(params.appId != null, 'Should have appId by this point');

    const playState = createPlayProviderState(params, reactive<PlayState>({
        play: null,
        lastSave: null,
        playTemplate: null,
        pageModel: null,
        isError: false,
        isSaving: false,
        isPreparing: false,
        isLaunchingPlay: false,
        isEditingQuestions: false,
        isEditingQuestionsOverride: false,
        hasAnyModifications: false,
        checklists: null as PlayChecklist[],
        modified: {} as Dict<Dict<any>>,
        previewData: null,
        justLaunched: false,
        launchResult: null as PlayLaunchResult,
        highlightFields: false,
        originalAnswers: {},
        currentSave: null,
        focusedAsset: null,
        activeStep: -1,
        isOpen: true,
    }));

    const self = configurePlayProviderMethods(playState, params);

    return self;
}
