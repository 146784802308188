import { KeyValues } from '@/types/core-types';
import { logger, LogSettings } from '@/shared/logging';

export type ConfigState = {
    staticConfig: KeyValues;
    localConfig: KeyValues;
    values: PlayConfig;
}

export type PlayConfig = {
    logging?: LogSettings;
}

const log = logger('store');

export function stateLog(name:string, type:'actions'|'mutations'|'getters'|null) {
    const logName = type == null ? `state.${name}` : `state.${name}:${type}`;

    return log.child(logName);
}
