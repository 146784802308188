import {
    ComputedRef, inject, InjectionKey, ref,
} from 'vue';
import { KeyValues } from '@/types/core-types';
import { notImplemented } from '@/shared/shared.utils';

export enum ModalTransitionMode {
    none = 'none',
    force = 'force',
    parentOnly = 'parentOnly',
}

export type NestedModalProvide = {
    parent: boolean;
}

export const NestedModalProviderKey: InjectionKey<NestedModalProvide> = Symbol('nestedModalProvider');

export function isModalNested(): boolean {
    return inject(NestedModalProviderKey, { parent: false }).parent;
}

export type ModalRouterViewProvide = {
    fallback: boolean;
    close: (() => void);
    isClosing: (() => void);
    defaultSlots: ComputedRef<KeyValues>,
};

export const fallbackModalRouterProvider = <ModalRouterViewProvide>{
    fallback: true,
    close: notImplemented,
    isClosing: notImplemented,
    defaultSlots: ref({}),
};

export const ModalRouterViewKey: InjectionKey<ModalRouterViewProvide> = Symbol('modalScaffoldProvider');

export function injectModalRouter():ModalRouterViewProvide {
    return inject(ModalRouterViewKey, fallbackModalRouterProvider);
}
