import { illegalState } from '@/shared/shared.utils';
import {
    FormProvider, FormSourceType, ModelFormSource, PlayFormSource,
} from '@/model/form/form-provider-types';
import {
    playClientTs, PropertyPrimitiveType, PropertyValidation, ValidationResult,
} from '@/client/play-client';
import { toAnswerArray } from '@/play-editor/play-utils';
import { QuestionValidation } from '@/play-editor/question-validation';
import { ref } from 'vue';
import { ValidationErrors } from '@/play-editor/provider/vue3/provider-types';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import { asyncDebounce } from '@/shared/utils/async-debounce';

export function setupFormProviderValidation(self: FormProvider) {
    const log = self.log.child('validation', true);
    /// Validation errors from the server - merged into state.validationErrors
    const serverValidation = ref(<ValidationErrors>{});

    /// Manual validation errors from the client- merged into state.validationErrors
    const overrideValidation = QuestionValidation();

    function updateValidation():ValidationErrors {
        log.debug('Update validation from:', cloneDeep(overrideValidation.value), cloneDeep(serverValidation.value));
        self.state.validationErrors = cloneDeep(merge(merge({}, overrideValidation.value), serverValidation.value));

        return self.state.validationErrors;
    }

    async function performValidate() {
        const allErrors = QuestionValidation();

        /// Clear all non-ref errors
        self.properties.valueSet().filter((property) => {
            return !(property.type.ref != null || property.type.array?.listType?.ref != null);
        })
            .forEach((p) => allErrors.clearError(p.name));

        const fromServer = await validatePropertiesServer();

        fromServer.errors.forEach((error) => {
            allErrors.addError(error.propertyId, error);
        });

        serverValidation.value = allErrors.value;

        return updateValidation();
    }

    self.performValidate = performValidate;
    self.validate = asyncDebounce(performValidate, 500);

    self.setValidationError = (question, error?: PropertyValidation|PropertyValidation[]) => {
        if (!error) {
            log.info(`Clearing manual error for ${question.name}`);
            overrideValidation.clearError(question.name);
        } else if (overrideValidation.get(question.name).length === 0) {
            log.info(`Setting manual error for ${question.name}`, error);
            overrideValidation.setError(question.name, error);
        }

        updateValidation();
    };

    self.sanitize = () => {
        const { state } = self;
        const propHelpers = self.getAll(self.properties.valueSet());

        /// Only look at properties that have been set

        const existingProperties = propHelpers.valueSet().filter(({ property }) => Object.hasOwn(state.answers, property.name));

        for (const helper of existingProperties) {
            const { property } = helper;
            const questionType = property.type.base;

            const currentAnswer = state.answers[helper.name];

            if (questionType === PropertyPrimitiveType.ARRAY) {
                vset(state.answers, helper.name, toAnswerArray(currentAnswer));
            } else if (questionType === PropertyPrimitiveType.TEXT && !currentAnswer) {
                vset(state.answers, helper.name, null);
            }
        }
    };

    async function validatePropertiesServer(): Promise<ValidationResult> {
        const { state, appId } = self;

        switch (self.sourceType) {
        case FormSourceType.play:
        // eslint-disable-next-line no-case-declarations
            const sc = self.source as PlayFormSource;

            return playClientTs.playTemplateV2.validateQuestionV2(appId, sc.playTemplateId, state.answers);

        case FormSourceType.modelRef:
        // eslint-disable-next-line no-case-declarations
            const ms = self.source as ModelFormSource;

            return playClientTs.modelDefinition.validateModelData(appId, ms.refType.category, ms.refType.name, state.answers);
        default:
            return illegalState('Invalid state; should be model or play');
        }
    }
}
