import state from './state';
import mutations from './mutations';
import { Module } from 'vuex';
import { FlagsState } from '@/store/flags/types';
import { State } from '@/store/play-root-state';

export default <Module<FlagsState, State>>{
    namespaced: true,
    state,
    mutations,
};
