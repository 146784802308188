import { RouteRecordRaw } from 'vue-router';

export function meta(name: string = null, isPublic = false) {
    return {
        title: name === null ? undefined : `pages.${name}`,
        isPublic,
    };
}

export function normalizeRoutes(routes:RouteRecordRaw[]): RouteRecordRaw[] {
    for (const route of routes) {
        route.meta ??= {};

        if (!Object.hasOwn(route.meta, 'isPublic')) {
            route.meta.isPublic = false;
        }

        if (route.children != null) {
            normalizeRoutes(route.children);
        }
    }

    return routes;
}
