/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UserRoleType {
    ROLE_PACKAGING = 'ROLE_PACKAGING',
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_COACH_ADMIN = 'ROLE_COACH_ADMIN',
    ROLE_COACH = 'ROLE_COACH',
}
