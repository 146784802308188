<template>
    <component :is="as" :style="style">
        <slot :prop-form="propForm" />
    </component>
</template>
<script lang="ts">
import {
    computed,
    defineComponent, inject, PropType, provide,
} from 'vue';
import { ModelProperty } from '@/generated/play-api';
import {
    defaultSuggestionGenerator,
    FormProviderKey,
    PropertyFormProviderKey,
} from '@/model/form/form-provider-types';
import { DEFAULT_SUGGESTION_COUNT } from '@/play-editor/play.constants';
import { providePropertySuggestions } from '@/play-editor/mixins/v3/providePropertySuggestions';

export default defineComponent({
    name: 'PropertyProvider',
    props: {
        as: {
            type: String,
            default: 'div',
        },
        index: {
            type: Number,
            required: true,
        },
        prop: Object as PropType<ModelProperty>,
    },
    setup(props) {
        const formProvider = inject(FormProviderKey);
        const propForm = formProvider.get(props.prop);
        const suggestionMeta = propForm.property.suggestionGenerator ?? defaultSuggestionGenerator(propForm.property);

        providePropertySuggestions(propForm);

        provide(PropertyFormProviderKey, propForm);

        return {
            style: computed(() => `z-index: ${50 - props.index}`),
            propForm,
            ...formProvider,
            ...propForm,
            suggestionMeta,
            DEFAULT_SUGGESTION_COUNT,
        };
    },

});
</script>
