<template>
    <div class="question-textarea">
        <div v-if="questionDescription || questionLabel" class="question-description">
            {{ questionDescription ? questionDescription : questionLabel }}<span class="required">{{ (validators.required) ? t('shared.requiredSymbol') : '' }}</span>
        </div>
        <div v-if="questionAdditionalDescription" class="question-additional-description">
            {{ questionAdditionalDescription }}
        </div>
        <div class="question-text">
            <AbsolutePlacement>
                <template v-if="generatesSuggestions" #default>
                    <PlaySuggestions
                        v-if="generatesSuggestions"
                        :disabled="missingFields.length > 0"
                        :allow-multiple="null"
                        :question="question"
                        @accept="model = $event"
                    />
                </template>
                <template #reference>
                    <TextAreaField
                        v-model="model"
                        :label="questionLabel"
                        :data-qa="`textarea-${question.name}`"
                        auto-grow
                        :class="{
                            warning: !isValidationSevere && hasValidationError,
                            invalid: hasValidationError,
                        }"
                        :maxlength="validators.maxSize ?? 9999"
                        :submitted="hasValue"
                        :invalid="hasValidationError"
                        @blur="onBlur"
                    >
                        <!-- Displays helperText with current value merged in -->
                        <template v-if="!hasValidationError" #help>
                            <slot name="helperText" />
                        </template>

                    <!-- Suggestions trailing icon and menu-->
                    </TextAreaField>
                    <!-- Displays error messages from the server -->
                    <div v-if="hasValidationError" class="validation-container">
                        <div
                            v-for="error in validationError"
                            :key="error.message"
                            :class="{ 'error-text': true, 'validation-error': true, warning: !isValidationSevere && hasValidationError }"
                        >
                            {{ fullErrorMessage(error) }}
                            <!-- Also show the help message to guide them further, since it is hidden while errors are displayed -->
                            <slot name="helperText" />
                        </div>
                    </div>
                </template>
            </AbsolutePlacement>
        </div>
    </div>
</template>
<script lang="ts">export default { name: 'PlayQuestionLongText' }; </script>
<script lang="ts" setup>

import PlaySuggestions from '@/play-editor/PlaySuggestions.vue';
import { metaKeys } from '@/play-editor/play.constants';
import AbsolutePlacement from '@/shared/components/AbsolutePlacement.vue';
import { ModelProperty } from '@/generated/play-api';
import { PlayService } from '@/play-editor/play-service';
import { useAppId } from '@/play-editor/provider/provide-app-id';
import { useCore } from '@/shared/shared-providers';
import { computed } from 'vue';
import { PlayQuestionCoreEvents, questionMixin } from '@/play-editor/mixins/v3/questionMixin';
import { QuestionPropsType } from '@/shared/proptypes/QuestionPropsType';
import { propertyValidatorHelper } from '@/generated-ext/property-validator-helpers';

const { t } = useCore();
const props = defineProps<QuestionPropsType<string>>();
const emit = defineEmits(PlayQuestionCoreEvents);
const playService = PlayService(useAppId(useCore()));
const {
    questionDescription, questionAdditionalDescription,
    questionLabel, model, getMetaArray,
    missingFields, hasValidationError,
    isValidationSevere,
    fullErrorMessage, generatesSuggestions,
} = questionMixin<string>(props, emit, '');

const question = computed(() => props.question as ModelProperty);
const validators = computed(() => propertyValidatorHelper(question.value.validators));

function onBlur() {
    const [suggestion] = getMetaArray(metaKeys.suggestions);

    if (suggestion) {
        const { resultId, item } = suggestion;

        if (item !== props.modelValue) {
            playService.addAcceptInteraction(resultId, item, props.modelValue);
        }
    }
    emit('blur');
}

</script>

<style lang="scss" rel="stylesheet/scss">
    @import "src/styles/main";

    // These unscoped styles are for replacing the typical red with orange
    .input-field.warning input.submitted:invalid,
    .input-field.warning input.submitted:invalid:focus {
        border: 1px solid $color-orange-600;
        border-color: $color-orange-600;
    }

</style>
<style lang="scss" rel="stylesheet/scss" scoped>
    @import "src/styles/main";
    @import "play-question";

    .question-textarea {
        // display:flex;
        position: relative;
    }

    .text-area-suggestion {
        position: absolute;
        display: inline-block;
        top: -30px;
        right: -150px;
    }

    .validation-container {
        margin-bottom: 1.5rem;
    }

    .invalid {
        --input-margin-bottom: 0;
    }

    .validation-error.warning {
        color: $color-orange-600;
    }

    .dropdown-menu-group {
        color: $color-text-subtle;
        font-size: $font-size-xs;
        padding: 0 1rem;
    }

    .input-trailing-suggest {
        cursor: pointer;
    }

    .dropdown-menu-list {
        width: calc(500rem / 16);
    }

    .no-suggestions {
        color: $color-text-disabled;
    }

    .suggestions-header {
        margin-top: 1rem;
        margin-bottom: calc(1rem / 2);
    }

    .results-container {
        margin: auto;
        align-content: center;
        text-align: center;
    }

    .field-error {
        color: $color-red;
        margin-top: calc(1rem / 2);
    }

    .play-question-list {
        margin-bottom: 1rem;
    }

    .input-form {
        margin-top: 1rem;
    }

    .inline-input {
        margin-bottom: calc(1rem / 4);
        padding-bottom: calc(1rem / 4);
    }
</style>
<i18n>
{
    "en-us": {

    }
}
</i18n>
