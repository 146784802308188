import { PropertyValidator, SizeValidationType } from '@/generated/play-api';

interface PropertyValidatorSummary {
    required?: boolean;
    maxSize?: number;
    minSize?: number;
}

export function propertyValidatorHelper(validators?: PropertyValidator[] | null): PropertyValidatorSummary {
    if (validators == null) return {};
    const sizeValidator = validators.find(({ size }) => size != null)?.size;

    return {
        required: Boolean(validators.find(({ required }) => required === true)),
        maxSize: sizeValidator?.sizeType === SizeValidationType.MAX ? sizeValidator.size : undefined,
        minSize: sizeValidator?.sizeType === SizeValidationType.MIN ? sizeValidator.size : undefined,
    };
}
