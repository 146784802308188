/* eslint-disable no-console, no-alert */
import { logger } from '@/shared/logging';

const log = logger('devtools.util');

export function writeClipboardFromDevTools(value:unknown) {
    return new Promise((resolve, reject) => {
        const asyncCopyFn = (() => {
            try {
                void navigator.clipboard.writeText(value?.toString()).then(() => {
                    log.info('COPIED TO CLIPBOARD: ', value);
                    resolve(value);
                });
            } catch (e) {
                reject(e);
            }
            window.removeEventListener('focus', asyncCopyFn);
        });

        window.addEventListener('focus', asyncCopyFn);
        console.log('Hit <Tab> to give focus back to document (or we will face a DOMException);');
        window.alert('Your value was copied.');
    });
}
