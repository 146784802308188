import { level, logger } from '@/shared/logging';
import asserts from '@/shared/asserts';

/**
 * An event bus that collects results from listeners.  Used in cases where a parent operation
 * should wait until listeners complete processing, and/or where the child needs to cancel the
 * parent process.
 */
export function AsyncBus() {
    const handlers = {};

    /**
     * Represents all listeners for a specified event name
     * @param eventName
     */
    function Handler({ eventName }) {
        asserts(eventName);
        let listeners = [];
        const log = logger(`async-bus.${eventName}`, level.debug);

        return {
            log,
            get listeners() {
                return listeners;
            },

            addListener(fn, debug = null) {
                if (fn && !listeners.includes(fn)) {
                    log.info('add', fn, debug ?? 'anonymous');
                    listeners.push(fn);
                } else {
                    log.info('add:failed', fn, debug ?? 'anonymous');
                }
            },

            removeListener(fn, debug = null) {
                log.debug('remove', fn, debug ?? 'anonymous');
                listeners = listeners.filter((listener) => listener !== fn);
            },

            /**
             * Invokes all listeners and returns their results.  The data that's returned for each listener
             * has two properties { result, error} - the error will be caught during processing.
             *
             * @param payload Optional payload
             */
            async send(payload = null) {
                log.info('send:start');

                if (listeners.length === 0) {
                    return Promise.resolve([]);
                }
                const all = await Promise.all(listeners
                    .map(async (handler) => {
                        try {
                            const result = await handler(payload);

                            log.info(' -send:success', handler, result);

                            return { result };
                        } catch (error) {
                            log.info(' -send:error', handler, error);

                            return { error };
                        }
                    }));

                log.info('send:done', all);

                return all;
            },

            /**
             * Executes each listener and ensures that none of the results match the provided
             * filter
             *
             * @param payload
             * @param filter
             */
            async check(payload, filter = null) {
                filter ??= ({ error, result } = {}) => {
                    return result === false || error != null;
                };
                const results = await this.send(payload);
                const matched = results.find(filter);

                log.info('send:check', !matched);

                return !matched;
            },
        };
    }

    function getHandler(eventName) {
        handlers[eventName] ??= Handler({ eventName });

        return handlers[eventName];
    }

    return {
        async send(eventName, payload) {
            return getHandler(eventName).send(payload);
        },

        async check(eventName, payload, filter = null) {
            return getHandler(eventName).check(payload, filter);
        },

        addListener(eventName, fn, debug = null) {
            return getHandler(eventName).addListener(fn, debug);
        },

        removeListener(eventName, fn, debug = null) {
            getHandler(eventName).removeListener(fn, debug);
        },
    };
}

const instance = AsyncBus();

export default instance;
