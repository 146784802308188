import { NavigateDestination } from '@/generated/play-api';

export const Capability = {
    launchPlay: 'launchPlay',
    /**
     * Opens a specific type of record
     * @param {string} recordType The type of record to be opened
     */
    navigate(recordType: NavigateDestination): string {
        return `${recordType?.toString()?.toLowerCase()}.navigate`;
    },
};
