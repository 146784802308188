<script lang="ts">export default { name: 'MenuListItem' }; </script>
<template>
    <li
        v-bind="$attrs"
        :class="[
            ...toArray($attrs.class),
            'menu-list-item', `border-${borderStyle}`, {
                dense,
                selected,
                disabled,
                delete: isDelete,
                'with-leading': hasLeading,
                'with-trailing': hasTrailing,
                'with-subtitle': hasSubtitle,
            }]"
        @click.prevent="select"
    >
        <div v-if="hasLeading" class="leading">
            <slot name="leading">
                <Icon :name="leadingIcon" class="icon" />
            </slot>
        </div>

        <div class="content">
            <div>
                <slot>
                    {{ label }}
                </slot>
            </div>

            <div v-if="hasSubtitle" class="subtitle">
                <slot name="subtitle">
                    <div :style="{ 'max-width': subtitleMaxWidth ? subtitleMaxWidth : 'auto' }" :class="{ 'subtitle-text': true, ellipsis: truncateSubtitle }">
                        {{ subtitle }}
                    </div>
                </slot>
            </div>
        </div>

        <div v-if="hasTrailing" class="trailing">
            <slot name="trailing">
                <Icon v-if="trailingIcon" :name="trailingIcon" />
            </slot>
        </div>
    </li>
</template>

<script lang="ts" setup>
import { computed, useSlots } from 'vue';
import { KeyValues } from '@/types/core-types';
import { toArray } from '@/shared/type.utils';

const props = withDefaults(defineProps<{
        label?: string;
        dense?: boolean;
        subtitle?: string;
        truncateSubtitle?: boolean;
        subtitleMaxWidth?: string;
        selected?: boolean;
        disabled?: boolean;
        destructive?: boolean;
        negative?: boolean;
        borderStyle?: string;
        leadingIcon?: string;
        trailingIcon?: string;}>(), {
    truncateSubtitle: true,
    subtitleMaxWidth: '15rem',
    borderStyle: 'none',
});

const slots = useSlots();

const isDelete = computed(() => Boolean(props.negative || props.destructive));
const hasSubtitle = computed(() => slots.subtitle || props.subtitle);
const hasLeading = computed(() => slots.leading || props.leadingIcon);
const hasTrailing = computed(() => slots.trailing || props.trailingIcon);

const emit = defineEmits(['select', 'click']);

function select(event: KeyValues) {
    emit('select', event);
}

</script>

<style lang="scss" scoped>
    @import "~@/styles/common";

    .menu-list-item {
        @include dropdown-list-item;

        width: 100%;
        overflow: clip;

        &.dense {
            --list-item-padding: #{$spacing-125};
            --icon-size: 1.2rem;

            padding: var(--list-item-padding, $spacing-200);
            font-size: $font-size-sm;
        }

        &.delete {
            --icon-color: #{$color-red};

            color: #{$color-red};
        }

        &:active {
            --icon-color: #{$color-white};

            background-color: #{$dropdown-color-selected};
            color: #{$color-white};

            &:hover {
                --icon-color: #{$color-text-normal};

                color: #{$color-text-normal};
            }
        }

        &:active.delete {
            --icon-color: white;

            background-color: #{$color-red};
            color: white;
        }

        .content {
            flex-grow: 1;
        }

        .leading {
            @include padding-end($spacing-150);
            @include padding-start($spacing-050);

            display: flex;
            color: var(--menu-list-item-leading-color, $color-gray);
        }

        .trailing {
            @include padding-start($spacing-150);
            @include padding-end($spacing-050);

            color: var(--menu-list-item-leading-color);
            display: flex;
        }

        .subtitle {
            font-size: $font-size-xs;
            line-height: $line-height-sm;
            color: var(--menu-list-item-subtitle-color);

            .ellipsis {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .subtitle-text {
                padding-right: 0.625 * $spacing-200;
            }
        }

        .with-subtitle {
            padding-top: 0.625 * $spacing-200;
            padding-bottom: 0.625 * $spacing-200;
        }
    }

    .border-full {
        border-bottom: solid 1px $color-ink-200;
    }

    .border-partial {
        @include padding-end(0, true);

        padding-bottom: 0;

        .leading-slot {
            padding-bottom: var(--list-item-padding, #{$spacing-200});
        }

        .list-item-info {
            @include padding-end(var(--list-item-padding, #{$spacing-200}));

            padding-bottom: var(--list-item-padding, #{$spacing-200});
            border-bottom: solid 1px $color-ink-200;
        }
    }
</style>
