/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AssetType {
    EMAIL = 'email',
    LANDING_PAGE = 'landingPage',
    HTML_PAGE = 'htmlPage',
    SNIPPET_PAGE = 'snippetPage',
    SOCIAL_SNIPPETS = 'socialSnippets',
    EMAIL_PS = 'emailPs',
    SMS = 'sms',
    INSTRUCTIONS = 'instructions',
    CONTENT = 'content',
}
