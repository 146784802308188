/* eslint-disable @typescript-eslint/no-explicit-any */
import { Logger } from '@/shared/logging';
import { ModelProperty, PropertyPrimitiveType } from '@/generated/play-api';
import {
    computed, ref, Ref, watch,
} from 'vue';
import { DataScopeData } from '@/model/form/DataScope';
import { VALUE_ALT_REPLACE_PATTERN, VALUE_LIQUID_REPLACE_PATTERN } from '@/play-editor/play.constants';

import cloneDeep from 'lodash/cloneDeep';
import { PlayService } from '@/play-editor/play-service';
import moment from 'moment-timezone';

export function PropertyMerge(
    log: Logger,
    name: keyof ModelProperty,
    model: Ref<unknown>,
    scope: DataScopeData,
    property: ModelProperty,
    playId: Ref<string>,
    appId: string,
) {
    const playService = PlayService(appId);
    const baseText = computed(() => {
        // We want to handle merging this question's value on the client, so let's replace the liquid merge token
        // with something different so the server won't try to merge it.
        return (property[name] as string)?.replaceAll(VALUE_LIQUID_REPLACE_PATTERN, VALUE_ALT_REPLACE_PATTERN);
    });

    const template = ref(baseText.value);

    const merged = computed(() => {
        const templateValue = template.value || baseText.value || '';
        const templateText = templateValue.replaceAll(/\{\{.*?}}/g, '');

        const q = property;
        const v = model.value as any;
        let answerValue = (Array.isArray(model.value) ? q.exampleValue : (v?.trim != null ? v?.trim() : v)) || q.exampleValue;

        if (q.type.base === PropertyPrimitiveType.DATETIME) {
            log.info(answerValue);
            const zone = moment.tz.guess();

            answerValue = `${moment(new Date(answerValue)).tz(zone).format('MMMM Do h:mmA')} ${zone}`;
        } else if (q.type.base === PropertyPrimitiveType.DURATION) {
            const duration = moment.duration(answerValue);

            answerValue = duration.humanize(true, { m: 60 });
        }

        return !templateText ? templateText : templateText
            .replaceAll(VALUE_ALT_REPLACE_PATTERN, answerValue)
            .replaceAll(VALUE_LIQUID_REPLACE_PATTERN, answerValue);
    });

    const doUpdate = () => {
        // We want to handle merging this question's value on the client, so let's replace the liquid merge token
        // with something different so the server won't try to merge it.
        const current = baseText.value?.replaceAll(VALUE_LIQUID_REPLACE_PATTERN, VALUE_ALT_REPLACE_PATTERN) ?? '';

        if (current?.includes('{{') && current.includes('}}')) {
            void playService.executeMerge({
                template: current,
                params: cloneDeep(scope.flatten()),
                playId: playId.value,
            }).then(({ response }) => {
                if (response) {
                    template.value = response;

                    log.debug(`New helper text: ${name}`, response);
                }
            });
        }
        template.value = current;
    };

    // const update = debounce(doUpdate, 100, { leading: true });

    watch(baseText, doUpdate, { immediate: true });

    doUpdate();

    log.debug(`Prop: ${name}: ${merged.value}`);

    return { merged, update: doUpdate };
}
