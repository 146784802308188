/* eslint-disable @typescript-eslint/no-explicit-any */
import { Play } from '@/generated/play-api';
import { Mapper } from '@/shared/shared.utils';
import { KeyValues } from '@/types/core-types';

export function safePush<T>(array: T[], item: T): void {
    if (Array.isArray(array)) {
        array.push(item);
    } else {
        array = [item];
    }
}

export function repeatString(pattern: string, count: number) {
    if (count < 1) {
        return '';
    }
    let result = '';

    while (count > 1) {
        if (count && 1) {
            result += pattern;
        }
        count >>= 1;
        pattern += pattern;
    }

    return result + pattern;
}

export function toAnswerArray(currentAnswer:string[]|string) {
    if (typeof currentAnswer === 'string') {
        return currentAnswer.split(',').map((v) => v.trim());
    }

    if (Array.isArray(currentAnswer)) {
        return currentAnswer;
    }

    if (currentAnswer == null) {
        return [];
    }

    return [currentAnswer];
}

export function distinct<T extends KeyValues>(array: T[], using?: string | Mapper<T, any>): T[] {
    return array.distinct(using);
}

export function without<T>(array:Array<T>, toRemove:T|T[]):Array<T> {
    return array.without(toRemove);
}

/**
 * Merges data from a play and a template
 * @param play
 */
export function mergePlayAndTemplate(play:Play): Play {
    return play;
}
