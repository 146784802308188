import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';
import { Module } from 'vuex';
import { AuthState } from '@/store/auth/types';
import { State } from '@/store/play-root-state';

export default <Module<AuthState, State>>{
    actions,
    getters,
    mutations,
    namespaced: true,
    state,
};
