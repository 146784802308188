import {
    TenantAccess, UserAccount, UserRoleType,
} from '@/client/play-client';
import { LoadStatus } from '@/shared/shared.utils';

export type UserDetails = {
    id:string;
    casId?:string;
    givenName:string;
    familyName:string;
    username:string;
    exp: number;
}

export type AccountInfo = {
    apps: UserAccount[];
    extraApps: TenantAccess[];
    coachingTenants: string[];
}

export type JwtClaims = {
    email?:string;
    firstName?:string;
    id?:string;
    lastName?:string;
    displayName?:string;
    exp?:number;
}

export type UserRoleState = {
    loadState: LoadStatus;
    roles: UserRoleType[];
    isAdmin: boolean;
    isCoachAdmin:boolean;
    isCoach:boolean;
}

export const defaultRoleState:UserRoleState = {
    isAdmin: false,
    isCoach: false,
    isCoachAdmin: false,
    loadState: LoadStatus.idle,
    roles: [],
};

export type AuthState = {
    failed: boolean;
    hasEverLoggedIn: boolean;
    user?: UserDetails;
    roles: UserRoleState;
    accounts: (AccountInfo|null);
    session: {
        jwt?: string|null;
        coreAppId?: string|null;
        coachId?: string|null;
    };
    userDetailsFetched: false;
};
