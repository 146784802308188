import { watchCalculated } from '@/play-editor/mixins/v3/computedReactive';
import { Dict, KeyValues } from '@/types/core-types';
import { FrameService } from '@/integration/host-system-api';
import {
    ModelPropertyGroup,
    NavigateDestination,
    PlayContentState as PlayStateEnum,
    PlayLaunchLink,
    V2PlayTemplateAssetInfo,
} from '@/generated/play-api';
import { LaunchLinkType, LaunchLinkTypeName } from '@/play-editor/play.constants';
import { formatDateTime } from '@/shared/date.utils';
import { Capability } from '@/integration/capabilities/capability-keys';
import { LaunchPlayMeta, PlayLaunchManualStep } from '@/integration/capabilities/capability-types';
import { keyed } from '@/shared/shared.utils';
import {
    ExtendedLaunchLink,
    PlayProviderParams,
    PlayState,
} from '@/play-editor/provider/vue3/play-provider-types';
import { PageModel } from '@/play-editor/play/PlayModel';
import cloneDeep from 'lodash/cloneDeep';
import { firstLetterUpper } from '@/shared/string.utils';

export function createPlayProviderState({ hostProvider, focusedPlayId }:PlayProviderParams, self: PlayState): PlayState {
    watchCalculated<PlayState>(self, {

        modifiedAssets(): Dict<boolean> {
            const modified = self.modified.mapEntries((assetId, updatedSections) => [assetId, Object.keys(updatedSections ?? {}).length > 0]);

            return modified;
        },

        launchLinks(): PlayLaunchLink[] {
            const newLaunchLinks = cloneDeep(self.play?.launchLinks ?? []);

            return newLaunchLinks;
        },

        frameProvide(): FrameService {
            return hostProvider.frame ?? { state: { ready: true } };
        },

        isControlled(): boolean {
            return Boolean(hostProvider.frame?.state.isControlled);
        },

        sequences(): ExtendedLaunchLink[] {
            const newSequences = cloneDeep(self.play?.launchLinks
                ?.filter((f:PlayLaunchLink) => f.linkType === LaunchLinkType.sequence)
                ?.map((f:PlayLaunchLink) => ({
                    ...f,
                    linkTypeName: LaunchLinkTypeName[f.linkType] ?? firstLetterUpper(f.linkType),
                    updateTime: formatDateTime(f.updateTime),
                    capabilityKey: Capability.navigate(NavigateDestination.EASY_AUTOMATION),
                })) ?? []);

            return newSequences;
        },

        launchAdditionalSteps(): PlayLaunchManualStep[] | null {
            return self.launchResult?.additionalSteps || null;
        },

        canLaunchPlay(): boolean {
            return hostProvider?.initialized && hostProvider.host.hasCapability(Capability.launchPlay);
        },

        hasAutomations(): boolean {
            return self.playTemplate?.sequences?.length > 0 ?? false;
        },

        launchPlayLabel(): string {
            return hostProvider.host?.capabilities?.info<LaunchPlayMeta>(Capability.launchPlay)?.launchLabel || 'Launch automation';
        },

        hasLaunchItems(): boolean {
            return self.sequences?.length > 0 || self.checklists?.some((c) => c.checklistKey === 'launch');
        },

        isReady(): boolean {
            return Boolean(self.play?.id && self.playTemplate?.id);
        },

        playId(): string | null {
            return self.play?.id ?? focusedPlayId?.value;
        },

        answers(): KeyValues {
            return self.play?.answers ?? {};
        },

        propertyGroups(): ModelPropertyGroup[] {
            return self.playTemplate?.groups.sort((a, b) => a.sortOrder - b.sortOrder) ?? [];
        },

        assets(): Dict<V2PlayTemplateAssetInfo> {
            const assetList = self.playTemplate?.assets || [];

            return keyed(assetList, 'assetKey');
        },

        hasAnyModifications(): boolean {
            return self.modified.valueSet().some((changes) => changes?.keySet()?.length > 0);
        },

        createdFromColumn(): boolean {
            return self.play != null;
        },

        isEditingQuestions(): boolean {
            return self.isEditingQuestionsOverride || self.play?.state === PlayStateEnum.QUESTIONS;
        },

        pageModel: () => PageModel.of(self.playTemplate?.groups.sort((a, b) => a.sortOrder - b.sortOrder) ?? []),
    }, { deep: true });

    return self;
}
