import { MutationTree } from 'vuex';
import { SuggestionsState } from '@/store/suggestions/types';
import { stateLog } from '@/store/config/types';
import { SuggestionItem } from '@/model/form/SuggestionItem';

export type UpdateSuggestions = {
    key: string; suggestions: SuggestionItem[];
}
const log = stateLog('suggestions', 'mutations');

export default <MutationTree<SuggestionsState>>{
    SET_SUGGESTIONS(state: SuggestionsState, { key, suggestions }:UpdateSuggestions) {
        log.info(`Updating cached suggestions for ${key}`, suggestions);
        state.latestSuggestions[key] = suggestions;
    },
};
