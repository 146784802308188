<script lang="ts">export default { name: 'PlayQuestionEditor' }; </script>
<template>
    <ModalScaffold
        key="summaryPage"
        :show-close="!needsPop"
        :show-back="needsPop"
        emit-close
        @close="closeQuestions"
    >
        <template #title>
            <div class="modal-header">
                <EditableText :model-value="play.title" @update:model-value="updatePlayDetails($event)">
                    <h4 class="title">
                        {{ play.title }}
                    </h4>
                </EditableText>
            </div>
        </template>
        <div class="questions-outer">
            <div class="questions-layout">
                <div class="questions-panel">
                    <PlayQuestionSummary
                        :needs-pop="needsPop"
                        :valid-pages="isValidByPage"
                        :closing="closing"
                        :page-model="pageModel"
                        @focus-page="pushPage"
                        @generate-play="generatePlay"
                        @close="closeQuestions"
                    />
                </div>
            </div>
        </div>
    </ModalScaffold>
</template>

<script lang="ts" setup>

import {
    computed,
    onBeforeMount, reactive, toRef,
} from 'vue';
import { injectFormProvider } from '@/model/form/inject-form';
import { PlayLifecycleEvent } from '@/play-editor/play.constants';
import { asyncBus } from '@/events';
import asserts from '@/shared/asserts';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import PlayQuestionPage from '@/play-editor/play/PlayQuestionPage.vue';
import { useCore } from '@/shared/shared-providers';
import { injectPlayStateProvider } from '@/play-editor/provider/vue3/ProviderKeys';
import { injectPageStackCompleter } from '@/shared/components/navigation-inject';
import ModalScaffold from '@/shared/components/ModalScaffold.vue';
import PlayQuestionSummary from '@/play-editor/PlayQuestionSummary.vue';
import { logLifecycle } from '@/play-editor/provider/vue3/lifecycle';

const props = defineProps<{
    /**
     * Indicates that this page was loaded directly on the parent page, rather than being pushed as a separate route.
     * This is important because it controls whether closing the page should pop a route, or whether we should notify
     * the parent page
     */
    needsPop?: boolean;
}>();

const emit = defineEmits(['change-page', 'done-with-questions', 'close']);

logLifecycle();
const navigationService = injectPageStackCompleter();
// TODO: Eric, are we OK to move to navigationService?
// const navigationService = injectNavigator();
const { state: playState, updatePlayDetails, ...playProvider } = injectPlayStateProvider();
const formProvider = injectFormProvider(false);
const { t, confirm, log } = useCore();

const state = reactive({
    // This field is computed below
    isSummaryPageFocused: computed(() => !navigationService.canGoBack),
    closing: false,
    launchLinksOpen: false,
    isModalOpen: true,
    isMoreMenuOpen: false,
    editingName: false,
    editNameValue: '',
});

const {
    generateDefaultValues,
    sanitize,
    validate,
    performValidate,
    state: formState,
    pageModel,
    groups,
} = formProvider;

/// DATA PROPS

const initialAnswers = cloneDeep(formState.answers);

/// LIFECYCLE, WATCHES
onBeforeMount(() => {
    const { __meta = {} } = initialAnswers;

    merge(formState.meta, __meta ?? {});

    sanitize();
    validate();

    return generateDefaultValues();
});

/// Functions
async function pushPage(newPage: number) {
    asserts(newPage > -1);

    const results = await asyncBus.check(PlayLifecycleEvent.beforeChangePage);

    if (!results) {
        log.info('Next was cancelled');
    } else {
        const focusedPage = groups[newPage];

        asserts(focusedPage, `Should have page at index ${newPage}`);

        await navigationService.push(() => ({
            component: PlayQuestionPage,
            key: `pushed-page-${newPage}`,
            params: {
                propertyPageIndex: newPage,
                closing: state.closing,
                pageTitle: focusedPage.title,
                needsPop: props.needsPop,
            },
            listeners: {
                close() {
                    // When an individual question page is closed, we should pop the page
                    backToSummary();
                },
            },
        }));

        await performValidate();
    }
}

function backToSummary() {
    if (!state.isSummaryPageFocused) {
        navigationService.back(null);
    }
}

async function closeQuestions() {
    if (state.closing) {
        log.info('Already closing!');

        return;
    }
    state.closing = true;
    await formProvider.saveAnswers();

    // Sometimes this page is pushed as a route, and sometimes this
    // page is directly included (to avoid the transition)
    if (props.needsPop) {
        await navigationService.back(null);
        await playProvider.doneWithQuestions();
        emit('done-with-questions');
    } else {
        emit('close');
    }
}

const play = toRef(playState, 'play');
const isValidByPage = toRef(formState, 'isValidByPage');
const closing = toRef(state, 'closing');

async function generatePlay() {
    await performValidate();

    /// A bit complicated, but:
    /// We persist the form provider, which will update the state in the play provider.
    /// If we don't do this, we could end up with stale data
    await formProvider.saveAnswers();

    if (!formState.isModelValid) {
        if (await confirm({
            message: t('models.validationError'),
            title: t('models.validationTitle'),
            confirmLabel: t('models.validationContinue'),
            cancelLabel: t('models.validationCancel'),
        })) {
            // We've already saved answers.
            // Just close out questions
            emit('close');
        }
    } else {
        state.closing = true;
        await playProvider.doneWithQuestions();
        emit('done-with-questions');
    }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
@import "../../styles/main";
@import "../../play-editor/properties/play-question";
</style>

<i18n>
{
    "en-us": {

    }
}
</i18n>
