import state from './state';
import mutations from './mutations';
import actions from './actions';
import { Module } from 'vuex';
import { ConfigState } from '@/store/config/types';
import { State } from '@/store/play-root-state';

export default <Module<ConfigState, State>>{
    namespaced: true,
    state,
    mutations,
    actions,
};
