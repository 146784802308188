<template>
    <div>
        <div v-if="!isCardRef">
            <div class="question-description">
                {{ questionDescription ? questionDescription : questionLabel }}<span class="required">{{ required(question.validators) ? t('shared.requiredSymbol') : '' }}</span>
            </div>
            <div v-if="questionAdditionalDescription" class="question-additional-description">
                {{ questionAdditionalDescription }}
            </div>
        </div>
        <ModelDropdown
            v-if="question.type.ref.refType"
            v-model="model"
            :ref-type="question.type.ref.refType"
            :question="question"
            :label="questionDescription"
            :prop-info="propInfo"
            :is-card-ref="bool(isCardRef)"
            :data-qa="`ref-dropdown-${question.name}`"
            :required="required(question.validators)"
            :page-title="questionLabel"
            :page-description="questionDescription"
            @blur="emit('blur')"
            @refresh-model="sendRefresh"
        >
            <template #helperText>
                <slot name="helperText" />
            </template>
        </ModelDropdown>
    </div>
</template>
<script lang="ts">export default { name: 'PlayQuestionRef' }; </script>
<script lang="ts" setup>
import ModelDropdown from '@/play-editor/controls/ModelDropdown.vue';
import { RefDisplayType } from '@/generated/play-api';
import {
    computed, watch,
} from 'vue';
import { useLog } from '@/shared/shared-providers';
import { KeyValues } from '@/types/core-types';
import {
    questionMixin as questionMixinFn,
    PlayQuestionCoreEvents,
} from '@/play-editor/mixins/v3/questionMixin';
import { required } from '@/play-editor/question-validation';
import { QuestionPropsType } from '@/shared/proptypes/QuestionPropsType';

const props = defineProps<QuestionPropsType<string>>();

const question = computed(() => props.question);
const emit = defineEmits([...PlayQuestionCoreEvents, 'refresh-model']);
const log = useLog((props.question as KeyValues).name);
const {
    prop, questionDescription, questionAdditionalDescription, questionLabel, propInfo, model,
} = questionMixinFn<string>(props, emit, '');

watch(model, (value) => {
    if (value && typeof value !== 'string') {
        log.warn(`INVALID TYPE: ${value}`);

        if (typeof value === 'object') {
            const { id } = value;

            if (id) {
                model.value = id;
            }
        }
    }
}, { immediate: true });

const isCardRef = computed(() => prop.value.displayOptions?.ref?.refDisplayType === RefDisplayType.CARD);

function sendRefresh() {
    log.info('PlayQuestion send refresh');
    emit('refresh-model');
}

</script>
<style lang="scss" scoped>
    @import "../../play-editor/properties/play-question";
</style>
<i18n>
{
    "en-us": {
    }
}
</i18n>
