/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiInteraction } from '../models/AiInteraction';
import type { AiRequest } from '../models/AiRequest';
import type { AiResponse } from '../models/AiResponse';
import type { Object } from '../models/Object';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AiOperationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Record AIInteraction
     * Records an interaction for an ai result
     * @param tenantId
     * @param resultId
     * @param requestBody
     * @returns Object Success
     * @throws ApiError
     */
    public recordAiInteraction(
        tenantId: string,
        resultId: string,
        requestBody: AiInteraction,
    ): CancelablePromise<Object> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenantId}/aiop/operation_results/{resultId}/interactions',
            path: {
                'tenantId': tenantId,
                'resultId': resultId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Execute AIOperation
     * Executes a single AI operation by calling the backend python engine
     * @param tenantId
     * @param aiClientVersion
     * @param operationId
     * @param versionId
     * @param requestBody
     * @returns AiResponse executeAIOperation 200 response
     * @throws ApiError
     */
    public executeAiOperation(
        tenantId: string,
        aiClientVersion: string,
        operationId: string,
        versionId: string,
        requestBody?: AiRequest,
    ): CancelablePromise<AiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenantId}/aiop/{aiClientVersion}/{operationId}/versions/{versionId}',
            path: {
                'tenantId': tenantId,
                'aiClientVersion': aiClientVersion,
                'operationId': operationId,
                'versionId': versionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
