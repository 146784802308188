import {
    ComponentState,
    CustomInspectorNode, CustomInspectorState, DevtoolsPluginApi, InspectorNodeTag,
} from '@vue/devtools-api';
import { KeyValues } from '@/types/core-types';
import {
    isReactive, isReadonly, isRef,
} from 'vue';

export const playInspectorId = 'plays-tools';

/**
 * Registers an inspector in Vue devtools. The {@link InspectorHelper} interface is recursive, so you can nest different
 * types of child Inspectors that handle different concerns. see the {@link InspectorHelper} for more information.
 *
 * @param api A reference to the api being used
 * @param root The root node to register
 */
export function registerInspector<R, D extends DevtoolsPluginApi<R>>(api: D, root: InspectorHelper) {
    const rootNode = root.id;
    const {
        label, children: inspectors, icon: rootIcon,
    } = root;

    api.addInspector({
        id: rootNode,
        label,
        icon: rootIcon,
        actions: [root, ...inspectors].filter((e) => e.actions != null).flatMap((i) => i.actions),
        nodeActions: [root, ...inspectors].filter((e) => e.nodeActions != null).flatMap((i) => i.nodeActions),
    });

    api.on.getInspectorTree((payload) => {
        if (payload.inspectorId === rootNode) {
            payload.rootNodes = inspectors.flatMap((inspect) => buildRootNodes(inspect));
        }
    });

    api.on.getInspectorState(async (payload) => {
        if (payload.inspectorId === rootNode) {
            const {
                inspector, childId,
            } = findInspector(payload.nodeId, inspectors);

            if (inspector && (inspector.load || inspector.nodeActions)) {
                let state = <CustomInspectorState>{};

                if (inspector.nodeActions) {
                    state.Actions = inspector.nodeActions.map(({ action, tooltip, icon }) => ({
                        editable: false,
                        key: tooltip,
                        value: {
                            _custom: {
                                type: 'function',
                                objectType: 'function',
                                display: 'hover ->',
                                tooltip: 'Click the small hover icon',
                                value: null,
                                abstract: false,
                                readOnly: true,
                                actions: [{
                                    icon,
                                    tooltip,
                                    action,
                                }],
                            },
                        },
                    }));
                }

                if (inspector.load) {
                    state = { ...state, ...await inspector.load(childId) };
                }
                payload.state = state;
            } else {
                payload.state = {};
            }
        }
    });

    api.on.editInspectorState(async (payload) => {
        if (payload.inspectorId === rootNode) {
            const { path, state: { value } } = payload;

            const {
                inspector, childId,
            } = findInspector(payload.nodeId, inspectors);

            if (inspector && inspector.update) {
                const result = await inspector.update(path, value, childId);

                if (result) {
                    api.sendInspectorState(payload.inspectorId);
                    api.sendInspectorTree(payload.inspectorId);
                }
            }
        }
    });
}

/**
 * Walks a tree looking for a child inspector that knows how to handle a node with a specified path.
 */
function findInspector(searchNodeId: string, inspectors: InspectorHelper[]): InspectionResult {
    const handleInspector = (inspector: InspectorHelper, parentId?: string): InspectionResult => {
        const nodeId = !parentId ? inspector.id : `${parentId}.${inspector.id}`;

        if (searchNodeId === nodeId) {
            // eslint-disable-next-line no-await-in-loop
            return { inspector, nodeId };
        }

        if (searchNodeId.startsWith(`${nodeId}:`) && !inspector.children) {
            return {
                inspector, nodeId, childId: searchNodeId.substring(nodeId.length + 1),
            };
        }

        if (inspector.children) {
            for (const child of inspector.children) {
                const res = handleInspector(child, nodeId);

                if (res) {
                    return res;
                }
            }
        }

        return null;
    };

    /// Apply to each top-level inspector
    for (const inspector of inspectors) {
        // eslint-disable-next-line @typescript-eslint/await-thenable,no-await-in-loop
        const res = handleInspector(inspector);

        if (res != null) return res;
    }

    return { nodeId: searchNodeId };
}

export function buildCustomState({
    key, display, value, actions,
}: {key:string; display?:string; value?:string, actions?:InspectorAction[]}):ComponentState {
    return {
        type: 'number',
        editable: false,
        key,
        value: {
            _custom: {
                type: 'function',
                objectType: 'function',
                display,
                tooltip: 'Click the small hover icon',
                value,
                abstract: false,
                readOnly: true,
                actions,
            },
        },
    };
}

/**
 * Represents the inspector tree, but makes it easier to attach operations to the tree without having to mess with
 * the nested nodeIds
 */
export type InspectorHelper = {

    /**
     * ID of this inspector - this is how it will be represented in the tree, with it's parent inspector's id appended
     */
    id: string;

    /**
     * The name of an MDI (material) icon to use.  This only applies to top-level inspectors.
     */
    icon?: string;

    /**
     * Label for this item
     */
    label: string;

    /**
     * A list of child inspectors
     */
    children?: InspectorHelper[];

    /**
     * A list of tags to apply to this node
     */
    tags?: InspectorNodeTag[];

    /**
     * Loads state for this node (and potentially for a child).  If {@link children} property is provided, then we will
     * call the {@link load} from there instead
     *
     * @param child Optional, a child being requested.  If child is empty, then we load state for this inspector
     */
    load?: (child?: string) => Promise<CustomInspectorState> | CustomInspectorState;

    /**
     * Actions added to the main header bar when this inspector is focused. These are always visible
     */
    actions?: InspectorAction[];

    /**
     * Actions that will be added to the right panel when this inspector is focused
     */
    nodeActions?: InspectorAction[];

    /**
     * Updates a field for this node.
     *
     * @param field The path to the field being edited
     * @param value The new value
     * @param child Optionally, if the edit came from a child
     */
    update?: (field: string[], value: unknown, child?: string) => unknown;
}

/**
 * Definition for an action:  These show up either in the inspector window, or on the header bar.
 */
export type InspectorAction = {
    /**
     * A label or tooltip describing the action
     */
    tooltip: string;
    /**
     * A valid material design icon
     */
    icon: string;
    action: (nodeId?: string) => void | Promise<void>;
}

/**
 * Parameters for converting an object into inspector state
 */
export type InspectorOpts = {
    editable?: boolean;
    key?: string;
}

/**
 * Dumps an object to the inspector
 */
export function toInspectorProperties(obj: KeyValues, opts?: InspectorOpts): CustomInspectorState {
    const { editable, key = 'info' } = opts ?? {};

    return {
        [key ?? 'info']: Object.entries(obj).map(([k, v]) => ({
            value: v,
            editable,
            raw: v?.toString(),
            objectType: determineObjectType(v),
            key: k,
        })),
    };
}

export function determineObjectType(value: unknown) {
    return isRef(value)
        ? isReadonly(value)
            ? 'computed' : 'ref'
        : isReactive(value) ? 'reactive' : 'other';
}

export type InspectionResult = {
    inspector?: InspectorHelper;
    nodeId: string;
    childId?: string;
}

function buildRootNodes(inspector: InspectorHelper, parentId?: string): CustomInspectorNode[] {
    const {
        label, children, tags = [],
    } = inspector;
    let { id } = inspector;

    if (parentId) {
        id = `${parentId}.${id}`;
    }

    const self: CustomInspectorNode = { id, label, tags };

    if (children) {
        self.children = children.flatMap((child) => buildRootNodes(child, id));
    }

    return [self];
}
