import {
    CreateFunction,
    GetFunction,
    ListFunction,
    PreparedModel,
    RemoteModelStatus,
    UpdateFunction,
} from '@/integration/datastore/base-types';

import asserts from '@/shared/asserts';
import { PlayModelDataStore } from '@/integration/datastore/data-store-play-model';
import { DataStore, storeLog } from '@/integration/datastore/data-store-api';
import { DataStoreBuilder } from '@/integration/datastore/data-store-builder';
import { ModelKeyOrName, ModelKeys } from '@/integration/datastore/model-keys';
import { HostSystem } from '@/generated/play-api';
import { updateRemoteStatus } from '@/play-editor/model/model.utils';

type RemoteStoreParams = {
    remoteList: ListFunction;
    remoteGet: GetFunction;
    remoteCreate?: CreateFunction;
    remoteUpdate?: UpdateFunction;
};

const remoteLog = storeLog.child('remote', true);

/**
 * This store delegates most calls to {@link PlayModelDataStore}, with these common exceptions:
 *
 * - list/search will hit the remote backend directly
 * - When a record is selected, it will be copied as a PlayModel
 * - a sync method is provided to keep data in sync
 * - optionally, an update method is provided, which will send updates back to the remote system
 *
 * @param rawModelType
 * @param modelSource The model source that should be recorded for records managed by this store
 * @param params The list/get operations specific to this entity
 *
 * @constructor
 */
export class RemoteDataStore extends DataStore {
    defaultModelStore: DataStoreBuilder;

    remoteList: ListFunction;

    remoteGet: GetFunction;

    remoteCreate?: CreateFunction;

    remoteUpdate?: UpdateFunction;

    static of(appId:string, modelKey: ModelKeyOrName, modelSystem: HostSystem, params: RemoteStoreParams): RemoteDataStore {
        return new RemoteDataStore(appId, modelKey, modelSystem, params);
    }

    private constructor(appId: string, key: ModelKeyOrName, modelSystem: HostSystem, params: RemoteStoreParams) {
        const modelType = ModelKeys.of(key);
        const modelKeyName = modelType.key;
        const log = remoteLog.child(modelKeyName);

        asserts(modelType, 'Must have model type');

        const fallbackDataStore = PlayModelDataStore(appId, modelType, modelSystem);

        const {
            remoteUpdate, remoteList, remoteGet, remoteCreate,
        } = params;

        async function doSync(input: PreparedModel) {
            const createResult = await fallbackDataStore.create(input);

            createResult.remoteStatus = RemoteModelStatus.synced;

            return createResult;
        }

        async function sync(input: PreparedModel) {
            try {
                return await fallbackDataStore.get(input.id);
            } catch (e) {
                return doSync(input);
            }
        }

        super(appId, modelType, modelSystem, {
            async list(listParams) {
                const [remoteResults, localResults] = await Promise.all([
                    remoteList(listParams),
                    fallbackDataStore.list(listParams),
                ]);
                const combinedList = [...localResults.data];

                for (const localModel of combinedList) {
                    updateRemoteStatus(localModel, true);
                }
                const localResultRemoteKeys = localResults.data.map((d) => `${d.hostSystem}-${d.sourceId}`);

                for (const item of remoteResults.data) {
                    if (!localResultRemoteKeys.includes(`${modelSystem}-${item.id}`)) {
                        item.remoteStatus = RemoteModelStatus.notSynced;
                        combinedList.push(item);
                    }
                }

                return {
                    cursor: localResults.cursor,
                    data: combinedList,
                    totalCount: combinedList.length,
                };
            },

            sync,
            async get(id) {
                try {
                    const normalLookup = await fallbackDataStore.get(id);

                    return normalLookup;
                } catch (e) {
                    if (!remoteGet) {
                        throw e;
                    } else {
                        const remoteData = await remoteGet(id);

                        return doSync(remoteData);
                    }
                }
            },

            create: !remoteCreate ? fallbackDataStore.create : async function(post) {
                const completed = await remoteCreate(post);

                return sync(completed);
            },

            async update(id, data) {
                let result = data;

                if (remoteUpdate) {
                    result = await remoteUpdate(id, data);
                    log.info(`[id=${id}] Update succeeded`);
                }

                return fallbackDataStore.update(id, result);
            },

            remove(id: string) {
                return fallbackDataStore.remove(id);
            },
        });

        this.remoteList = remoteList;
        this.remoteCreate = remoteCreate;
        this.remoteUpdate = remoteUpdate;
        this.remoteGet = remoteGet;
        this.defaultModelStore = fallbackDataStore;
    }
}
