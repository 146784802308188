import { htmlToText, SelectorDefinition } from 'html-to-text';

export function exportContentToText(originalContent:string, params: {removeListPrefix?:boolean, selectors?: SelectorDefinition[]} = {}) {
    const { removeListPrefix = false, selectors = [] } = params;
    const listOptions = {
        itemPrefix: removeListPrefix ? ' ' : undefined,
    };

    return htmlToText(originalContent, {
        selectors: [
            { selector: 'p', format: 'paragraph' },
            { selector: 'ol', format: 'unorderedList', options: listOptions },
            { selector: 'ul', format: 'unorderedList', options: listOptions },
            { selector: 'h1', format: 'heading', options: { uppercase: false } },
            { selector: 'h2', format: 'heading', options: { uppercase: false } },
            { selector: 'h3', format: 'heading', options: { uppercase: false } },
            { selector: 'h4', format: 'heading', options: { uppercase: false } },
            { selector: 'h5', format: 'heading', options: { uppercase: false } },
            { selector: 'h6', format: 'heading', options: { uppercase: false } },
            { selector: 'blockquote', format: 'paragraph', options: { uppercase: false } },
            { selector: 'keap-form-template', format: 'heading', options: { uppercase: false } },
            { selector: 'keap-button-template', format: 'heading', options: { uppercase: false } },

            { selector: 'kopy-p', format: 'paragraph' },
            { selector: 'kopy-ol', format: 'unorderedList', options: listOptions },
            { selector: 'kopy-ul', format: 'unorderedList', options: listOptions },
            { selector: 'kopy-h1', format: 'heading', options: { uppercase: false } },
            { selector: 'kopy-h2', format: 'heading', options: { uppercase: false } },
            { selector: 'kopy-h3', format: 'heading', options: { uppercase: false } },
            { selector: 'kopy-h4', format: 'heading', options: { uppercase: false } },
            { selector: 'kopy-h5', format: 'heading', options: { uppercase: false } },
            { selector: 'kopy-h6', format: 'heading', options: { uppercase: false } },
            { selector: 'kopy-blockquote', format: 'paragraph', options: { uppercase: false } },
            { selector: 'keap-form-template', format: 'heading', options: { uppercase: false } },
            { selector: 'keap-button-template', format: 'heading', options: { uppercase: false } },
            ...(selectors || []),
        ],
        wordwrap: false,
    });
}

/**
 * Changes a document element from one tag to another
 */
export function changeElementTagName(element: Element, newTagName: string) {
    const replacement = document.createElement(newTagName);

    // Copy the children
    while (element.firstChild) {
        replacement.appendChild(element.firstChild); // *Moves* the child
    }

    for (let index = element.attributes.length - 1; index >= 0; --index) {
        replacement.attributes.setNamedItem(element.attributes[index].cloneNode() as Attr);
    }

    element.replaceWith(replacement);
}

export function collapseElement(element:Element):void {
    element.replaceWith(...element.childNodes);
}

export type ElementOptions = {
    document?: Document;
    tagName?: string;
    innerHTML?: string;
    childNodes?: Node[];
    attributes?: Record<string, string>;
    build?: ((element:Element)=>void);
}

export function createElement(options: ElementOptions): Element {
    const {
        document: optionDoc, build, childNodes, tagName = 'div', attributes = {}, innerHTML,
    } = options;
    const element = (document ?? optionDoc).createElement(tagName);

    if (childNodes) {
        element.append(...childNodes);
    } else if (innerHTML) {
        element.innerHTML = innerHTML;
    }

    if (attributes) {
        Object.entries(attributes).forEach(([key, value]) => element.setAttribute(key, value));
    }

    if (build) {
        build(element);
    }

    return element;
}

export function createElements(html: string): Element[] {
    const element = createElement({
        innerHTML: html,
    });
    const iterator = document.createTreeWalker(element, Node.ELEMENT_NODE);
    const elements:Element[] = [];
    let el:Node;

    // eslint-disable-next-line no-cond-assign
    while (el = iterator.nextNode()) {
        elements.push(el as Element);
    }

    return elements;
}
