/* eslint-disable @typescript-eslint/no-unused-vars */
import { App } from 'vue';
import { setupDevtools } from '@/setup/devtools/playDevToolsPluginSetup';
import { env } from '@/env';
import { TrackExec } from '@/setup/devtools/playDevToolsApi';

export default {
    install(app: App) {
        let devtools: ReturnType<typeof setupDevtools>;

        app.mixin({
            methods: {
                track<R>(label: string, exec?: () => R):R {
                    return (devtools ?? fallbackDevtools).track(label, exec);
                },
            },
        });

        if (env.isDev) {
            if (app.config.globalProperties.playDevtools) {
                devtools = setupDevtools(app, { log: true });
            }
        }
    },
};

const fallbackDevtools = {
    track<R>(label: string, exec?: TrackExec<R>):R {
        if (exec == null) return null;

        return exec({});
    },
};
