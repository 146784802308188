<template>
    <Card data-qa="onboarding-card" @click="$emit('card-action')">
        <CardBody class="onboarding-card-body">
            <Illustration v-if="hasDsIllustration" :name="illustration" class="onboarding-illustration" />

            <svg v-else class="onboarding-illustration">
                <use :xlink:href="`#${illustration}`" />
            </svg>

            <h4 class="onboarding-card-title">
                {{ title }}
            </h4>
            <p class="onboarding-card-text">
                {{ text }}
            </p>
        </CardBody>
    </Card>
</template>

<script>

export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        illustration: {
            type: String,
            required: true,
        },
        hasDsIllustration: Boolean,
    },
    emits: ['card-action'],
};
</script>

<style lang="scss" scoped>
.onboarding-card-body {
    text-align: center;
    justify-content: center;
    padding: $spacing-250 $spacing-100;
    height: calc(202rem / 16);
}

.onboarding-card-title {
    font-size: $font-size-lg;
    margin-bottom: $spacing-100;
    font-weight: $font-weight-semibold;
}

.onboarding-card-text {
    color: $color-ink-800;
    margin: 0;
}

.onboarding-illustration {
    max-width: calc(120rem / 16);
    height: calc(64rem / 16);
}
</style>
