import asserts from '@/shared/asserts';
import { Dict } from '@/types/core-types';

export type ModelKey = {
    category:string;
    name:string;
    key:string;
};

const modelKeyCache = <Dict<ModelKey>>{};

function updateCache(name:string, value:ModelKey):ModelKey {
    modelKeyCache[name.toLowerCase()] ??= value;

    return value;
}

export const ModelKeys = {
    of(source: unknown): ModelKey {
        asserts(source != null, 'We got a blank source for a key!');

        if (typeof source === 'string') {
            asserts(source !== '[object Object]', 'A toString object was passed in');

            if (modelKeyCache[source]) return modelKeyCache[source];
            const [category, name, ...other] = source.split(':');

            asserts(other == null || other.length === 0, `Should only have two parts, but had ${other}`);
            asserts(category && name, `Must have category & name (category=${category}; name=${name}; original=${source})`);

            return updateCache(source, {
                category, name, key: source,
            });
        }

        if (typeof source === 'object') {
            const { category, name, key } = <ModelKey>source;

            asserts(category && name, 'Must have category and name');

            if (category && name && key) {
                return updateCache(key, <ModelKey>source);
            }
            const keyValue = `${category}:${name}`;

            if (modelKeyCache[keyValue]) return modelKeyCache[keyValue];

            return updateCache(keyValue, {
                category, name, key: keyValue,
            });
        }
        throw new Error(`Invalid key! ${source}`);
    },
};

export type ModelKeyOrName = ModelKeyName|ModelKey;

export type ModelKeyName =string;
export enum ModelKeyNames {
    Consult= 'keap:consult',
    User= 'keap:company',
}
