/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiResponse } from '../models/AiResponse';
import type { ExpandAnswersRequest } from '../models/ExpandAnswersRequest';
import type { GenerateSuggestionsRequest } from '../models/GenerateSuggestionsRequest';
import type { ModelSchema } from '../models/ModelSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ModelDefinitionService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Public ModelDefinitions
     * Lists all available model definitions
     * @returns ModelSchema Success
     * @throws ApiError
     */
    public listPublicModelDefinitions(): CancelablePromise<Array<ModelSchema>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/schema',
        });
    }

    /**
     * Load Public ModelDefinition
     * Retrieves a single modelDefinition by ID, merging any dynamic values
     * @param category
     * @param name
     * @returns ModelSchema Success
     * @throws ApiError
     */
    public loadPublicModelDefinition(
        category: string,
        name: string,
    ): CancelablePromise<ModelSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/schema/{category}/{name}',
            path: {
                'category': category,
                'name': name,
            },
        });
    }

    /**
     * List ModelDefinitions
     * Lists all available model definitions
     * @param tenant
     * @returns ModelSchema Success
     * @throws ApiError
     */
    public listModelDefinitions(
        tenant: string,
    ): CancelablePromise<Array<ModelSchema>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/tenant/{tenant}/schema',
            path: {
                'tenant': tenant,
            },
        });
    }

    /**
     * Load ModelDefinition
     * Retrieves a single modelDefinition by ID, merging any dynamic values
     * @param tenant
     * @param category
     * @param name
     * @returns any Success
     * @throws ApiError
     */
    public loadModelDefinition(
        tenant: string,
        category: string,
        name: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/tenant/{tenant}/schema/{category}/{name}',
            path: {
                'tenant': tenant,
                'category': category,
                'name': name,
            },
        });
    }

    /**
     * Expand Model Answers
     * Expands all properties for a given model
     * @param tenant
     * @param category
     * @param name
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public expandModelAnswers(
        tenant: string,
        category: string,
        name: string,
        requestBody: ExpandAnswersRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/schema/{category}/{name}/expanded',
            path: {
                'tenant': tenant,
                'category': category,
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Generate Default Value for Model
     * Generates defaultValue for a model property
     * @param tenant
     * @param category
     * @param name
     * @param propertyId
     * @param requestBody
     * @returns AiResponse Success
     * @throws ApiError
     */
    public generateDefaultValueForModel(
        tenant: string,
        category: string,
        name: string,
        propertyId: string,
        requestBody: GenerateSuggestionsRequest,
    ): CancelablePromise<AiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/schema/{category}/{name}/properties/{propertyId}/defaultValue',
            path: {
                'tenant': tenant,
                'category': category,
                'name': name,
                'propertyId': propertyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Generate Suggestions for Model
     * Generates suggestions for a ModelProperty
     * @param tenant
     * @param category
     * @param name
     * @param propertyId
     * @param requestBody
     * @returns AiResponse Success
     * @throws ApiError
     */
    public generateSuggestionsForModel(
        tenant: string,
        category: string,
        name: string,
        propertyId: string,
        requestBody: GenerateSuggestionsRequest,
    ): CancelablePromise<AiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/schema/{category}/{name}/properties/{propertyId}/suggestions',
            path: {
                'tenant': tenant,
                'category': category,
                'name': name,
                'propertyId': propertyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Validate Model Data
     * Validates data for a particular model
     * @param tenant
     * @param category
     * @param name
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public validateModelData(
        tenant: string,
        category: string,
        name: string,
        requestBody: any,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/schema/{category}/{name}/validate',
            path: {
                'tenant': tenant,
                'category': category,
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
