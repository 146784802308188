import { changeElementTagName, createElement } from '@/play-editor/content-export';
import { ExportDestinationKey, MimeTypes } from '@/play-editor/play.constants';
import { logger } from '@/shared/logging';

const log = logger('exporters');

export type ExportDestination = {
  key: ExportDestinationKey;
  mimeType: MimeTypes;
  processor?: ((content?:string)=> string|null);
  exportOptions?: ExportOptions;
  transformOptions?: TransformOptions;
};

export type TransformOptions = {
    collapsedElements?: string[];
    elementMapping?: Record<string, string>;
    trimNewlinesBetweenTags?: boolean;
    trimSpaceBetweenTags?: boolean;
    transformer: ((div:Element)=> void|Element);
};

export type ExportOptions = {
    removeListPrefix?: boolean;
}

export const exportDestinations = <Record<ExportDestinationKey, ExportDestination>>{
    [ExportDestinationKey.unlayer]: {
        key: ExportDestinationKey.unlayer,
        mimeType: MimeTypes.text,
        processor: (content) => content?.toString()?.replaceAll('\n', '\n\n'),
        exportOptions: {
            removeListPrefix: true,
        },
    },

    [ExportDestinationKey.unlayerJson]: {
        key: ExportDestinationKey.unlayerJson,
        mimeType: MimeTypes.html,
        processor: () => {
            throw new Error('Not implemented');
        },
    },

    [ExportDestinationKey.html]: {
        key: ExportDestinationKey.html,
        mimeType: MimeTypes.html,
    },

    [ExportDestinationKey.simpleHtml]: {
        key: ExportDestinationKey.simpleHtml,
        mimeType: MimeTypes.html,
        transformOptions: {
            collapsedElements: ['kopy-signature', 'signature', 'kopy-for-each'],
            elementMapping: {
                'keap-merge': 'span',
                'kopy-merge': 'span',
                'kopy-em': 'span',
                'kopy-ul': 'ul',
                'kopy-ol': 'ol',
                'kopy-span': 'span',
                'kopy-link': 'a',
                'keap-button-template': 'button',
                'keap-call-to-action': 'a',
                'keap-p': 'p',
                'kopy-p': 'p',
            },
            trimNewlinesBetweenTags: true,
            transformer(content: Element) {
                function onEachElement(element:Element) {
                    const tagName = element.tagName?.toLowerCase();

                    if (tagName === 'kopy-testimonial' || tagName === 'testimonial') {
                        /// A hack
                        /// Find the content values, and recreate them in a blockquote
                        const comment = element.querySelector('.comment');
                        const name = element.querySelector('.name');
                        const descriptor = element.querySelector('.descriptor');

                        const nameDescriptorText = [
                            name.textContent, descriptor.textContent,
                        ].map((t) => t.trim()).join(', ');

                        const newContents = createElement({
                            tagName: 'blockquote',
                            innerHTML: [`"${comment.textContent.trim()}"`, nameDescriptorText].join('\n\n'),
                        });

                        element.replaceWith(newContents);
                    } else if (tagName?.startsWith('kopy-') || tagName?.startsWith('keap-')) {
                        changeElementTagName(element, tagName.substring(5));
                    } else if (tagName?.startsWith('li')) {
                        element.querySelectorAll('p,kopy-p').forEach((ptag) => {
                            ptag.replaceWith(...ptag.childNodes);
                        });
                    } else if (tagName === 'a' && element.getAttribute('url')) {
                        element.setAttribute('href', element.getAttribute('url'));
                        element.removeAttribute('url');
                    }
                }
                const iterator = document.createNodeIterator(content, NodeFilter.SHOW_ELEMENT, () => NodeFilter.FILTER_ACCEPT);

                let currentNode:Node;

                // eslint-disable-next-line no-cond-assign
                while ((currentNode = iterator.nextNode())) {
                    if (currentNode.nodeType === Node.ELEMENT_NODE) {
                        // this.info('  inspecting', currentNode);
                        onEachElement(currentNode as Element);
                    } else {
                        log.debug(`Skipping node: ${currentNode.nodeType}`, currentNode);
                    }
                }

                if (content.childElementCount === 1) {
                    // One root element
                    const firstElement = content.firstElementChild;

                    firstElement.replaceWith(...firstElement.childNodes);
                }
            },
        },

        exportOptions: {
        },
    },

    [ExportDestinationKey.text]: {
        key: ExportDestinationKey.text,
        mimeType: MimeTypes.text,
    },
};
