import { Router } from 'vue-router';
import { componentLogger } from '@/setup/setup-vue-logging';
import { State } from '@/store/play-root-state';
import { Store } from 'vuex';
import { Dict, Emit, KeyValues } from '@/types/core-types';
import assert from 'assert';
import { ProviderName } from '@/play-editor/play.constants';
import { CoreProvide } from '@/shared/core-provide.types';
import { useAppId } from '@/play-editor/provider/provide-app-id';
import { InjectionKey, Ref } from 'vue';
import { LogFunction, Logger } from '@/shared/logging';
import { localStoreKey } from '@/shared/shared.utils';

export type BaseProvider = {
    /**
     * Whether or not this provider is a fallback provider
     */
    fallback?: boolean;
}

export const VueRouterProviderKey: InjectionKey<Router> = Symbol('router');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function defineEmitter<T extends string>(obj: any): Emit<T> {
    if (typeof obj === 'object') {
        obj = obj.emit;
    }

    assert.isFunction(obj);

    return obj as Emit<T>;
}

/**
 * Provides the core interactions that may be used by specific components.
 *
 * There are specific functions for retrieving single items:
 *
 * - {@link useLog}
 * - {@link injectH}
 *
 * This is provided by App
 */
export function useCore(): CoreProvide {
    const coreProvide = inject<CoreProvide>(ProviderName.coreProvider);
    const vm = getCurrentInstance();

    const appId = useAppId(coreProvide);
    const { name } = vm.proxy.$options;

    const { t, n } = useI18n();

    return {
        ...coreProvide,
        log: componentLogger.child(name ?? 'Anonymous'),
        t,
        n,
        name: name ?? 'Anonymous',
        appId,
    };
}

export function useProgress<R, P extends unknown[]>(callback: (...input: P) => Promise<R>): [(...input: P) => Promise<R>, Ref<boolean>] {
    const isProgress = ref(false);

    return [async (...input: P) => {
        isProgress.value = true;

        try {
            return await callback(...input);
        } finally {
            isProgress.value = false;
        }
    }, isProgress];
}

export function injectStore(): Store<State> {
    return useCore().store;
}

const logOnceKeys = ref(dict<boolean>());
const logOnceKeysPersist = useLocalStorage<Dict<boolean>>(
    localStoreKey('logOnce'),
    dict<boolean>(),
    { deep: true },
);

export type LogOnceOptions = {
    log?:Logger;
    child?:string;
    persist?:boolean;
}

/**
 * Logs only once, can also lean on local storage
 */
export function useLogOnce({ log, child, persist }: LogOnceOptions = {}): Logger {
    log ??= useLog(child);
    const keys = persist ? logOnceKeysPersist : logOnceKeys;

    return <Logger>{
        ...log,
        ...['info', 'debug', 'warn', 'error', 'severe'].keyed((e) => e)
            .mapValues((v) => {
                const actual = (log as KeyValues)[v] as LogFunction;

                return (...args: unknown[]) => {
                    asserts(typeof args[0] === 'string', 'Must have a first param string');

                    if (!keys.value[args[0]]) {
                        keys.value = {
                            ...keys.value,
                            [args[0]]: true,
                        };
                        actual(...args);
                    }
                };
            }),
    };
}

export function useLog(child?: string) {
    const { log } = useCore();

    if (child != null) {
        return log.child(child, true);
    }

    return log;
}

export function getOrProvide<T>(key: string|InjectionKey<T>, factory: ()=> T, doProvide:boolean) {
    let provider = inject<T>(key, null);

    if (provider == null) provider = factory();
    if (doProvide) provide<T>(key, provider);

    return provider;
}
