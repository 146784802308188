import { ModelProperty, PropertyValidation } from '@/generated/play-api';
import { injectSuggestionsProvider, OptionItem } from '@/play-editor/mixins/questionMixin';
import {
    computed, onBeforeMount, onBeforeUnmount, PropType, Ref, toRefs,
} from 'vue';
import { metaKeys, PlayLifecycleEvent } from '@/play-editor/play.constants';
import { asyncBus } from '@/events';
import { logger } from '@/shared/logging';
import { Emit, KeyValues } from '@/types/core-types';
import { DEFAULT_EVENT_NAME, useVModel2 } from '@/play-editor/mixins/v3/v-model';
import { useComputed } from '@/play-editor/mixins/v3/computedReactive';
import { maxSize, minSize } from '@/play-editor/question-validation';
import { QuestionPropsType } from '@/shared/proptypes/QuestionPropsType';
import { propertyValidatorHelper } from '@/generated-ext/property-validator-helpers';

export const QUESTION_PROPS = {
    propMeta: {
        type: Object as PropType<KeyValues>,
        required: true,
    },
    labelMerged: String,
    descriptionMerged: String,
    focused: Boolean,
    question: {
        type: Object as PropType<ModelProperty>,
        required: true,
    },
    validationError: Array as PropType<PropertyValidation[]>,
    name: { type: String, required: true },
    hasValue: Boolean,
};

export const PlayQuestionCoreEvents = ['focus', 'blur', 'blur-question', 'focus-question', DEFAULT_EVENT_NAME];

const log = logger('questionMixin3');

export type QuestionSetup<T> = KeyValues & {
    model: Ref<T>;
};

/**
 * Can be used as an alternative to the questionMixin, for composition API.
 *
 * This mixin sets up resources for working with a single question.  It is typically wired into individual question controls, and
 * ensures that changes to the question are propagated correctly.
 *
 * @param props The props passed to setup
 * @param emit An event emitter
 * @param defaultValue for this type of question
 * @param onQuestionPageChange
 */
export function questionMixin<T>(props: QuestionPropsType<T>, emit: Emit<'blur-question'|'focus-question'|'update:model-value'>, defaultValue?: T, onQuestionPageChange?: (() => unknown)) {
    const suggestionsProvider = injectSuggestionsProvider();

    if (onQuestionPageChange != null) {
        onBeforeMount(() => asyncBus.addListener(PlayLifecycleEvent.beforeChangePage, onQuestionPageChange));
        onBeforeUnmount(() => asyncBus.removeListener(PlayLifecycleEvent.beforeChangePage, onQuestionPageChange()));
    }

    const {
        question, labelMerged, descriptionMerged, validationError, propMeta, modelValue, hasValue,
    } = toRefs(props);

    const model = useVModel2(props, { defaultValue });

    const currentValueOption = computed<OptionItem>({
        set(x: OptionItem) {
            model.value = x?.id as T;
        },
        get(): OptionItem {
            return {
                id: model.value as string,
                label: model.value as string,
            };
        },
    });

    const {
        questionLabel, questionDescription, questionAdditionalDescription, suggestions,
    } = useComputed({
        questionLabel() {
            return labelMerged.value || question.value.label;
        },

        suggestions() {
            return suggestionsProvider.data.suggestions ?? [];
        },

        questionDescription() {
            return descriptionMerged.value || question.value.description;
        },

        questionAdditionalDescription() {
            return question.value.additionalDescription;
        },
    });

    const computedProps = useComputed({
        questionValidators() {
            return question.value?.validators ?? [];
        },

        questionValidatorSummary() {
            return propertyValidatorHelper(question.value?.validators);
        },

        propInfo() {
            return {
                model,
                prop: question.value,
            };
        },

        hasFieldDescription() {
            return questionLabel.value && questionLabel.value !== questionDescription.value;
        },

        minSize() {
            return minSize(question.value.validators, 1);
        },

        maxSize() {
            return maxSize(question.value.validators, 10);
        },

        generatesSuggestions() {
            return suggestionsProvider.data.generatesSuggestions;
        },

        missingFields() {
            return suggestionsProvider.data.missingFields;
        },

        options() {
            if (model.value && !suggestions.value
                .find((s) => s.item === modelValue.value)) {
                return <OptionItem[]>[
                    currentValueOption.value,
                    ...suggestions.value.map((d) => ({
                        id: d.item,
                        label: d.item,
                    })),
                ];
            }

            return suggestions.value.map((d) => ({
                id: d.item,
                label: d.item,
            }));
        },

        /**
         * Checks if the validation errors are actually errors, and not just warnings
         * @return {boolean}
         */
        isValidationSevere() {
            for (const item of (validationError.value || [])) {
                if (item.error) {
                    return true;
                }
            }

            return false;
        },

        hasValidationError() {
            return hasValue.value && validationError.value?.length > 0;
        },
    });

    function getMeta<X>(key: string, defValue?: X): X {
        return (propMeta.value[key] ?? defValue) as X;
    }

    function getMetaArray(key: string) {
        const meta = getMeta(key, []);

        if (!Array.isArray(meta)) {
            log.warn(`Found a non array for ${key}`, meta);

            return [];
        }

        return meta;
    }

    return {
        suggestionsMeta: computed<KeyValues>(() => {
            return getMetaArray(metaKeys.suggestions);
        }),
        hasValue,
        model,
        modelOrDefault: computed(() => model.value || defaultValue),
        currentValueOption,
        defaultValue,
        questionLabel,
        questionDescription,
        questionAdditionalDescription,
        suggestions,
        ...computedProps,
        getMeta,
        getMetaArray,
        emitBlur(): void {
            emit('blur-question', question.value);
        },

        emitFocus(): void {
            emit('focus-question', question.value);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        fullErrorMessage(errorObj: any) {
            if (errorObj.additionalHelp) {
                return `${errorObj.message}. ${errorObj.additionalHelp}`;
            }

            return errorObj.message;
        },
        suggestionsProvider,
        prop: computed<ModelProperty>(() => question.value),
    };
}
