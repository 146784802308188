/* eslint-disable no-console */

export function convertEmailContentToQuill(content) {
    const el = document.createElement('div');

    el.innerHTML = content ?? '';
    // Find any buttons
    el.querySelectorAll('kopy-button').forEach((button) => {
        const link = document.createElement('a');

        link.innerHTML = button.innerHTML;
        link.setAttribute('kopy-id', button.getAttribute('kopy-id'));
        button.replaceWith(link);
    });

    const inner = el.innerHTML;

    console.log('Email converted', inner);

    return inner;
}

export function mapCheckoutForm(forms) {
    return forms;
}

export function mapUser(user) {
    return user;
}

export function mapCompanyProfile(account) {
    return account;
}

export function mapKeapPhoneInfo(info) {
    return info;
}
