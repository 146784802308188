<template>
    <TooltipWithDelay
        :disabled="invalidItems.length === 0"
        :position="PopoverPosition.top"
        :dismiss-delay="200"
        :show-delay="50"
    >
        <template #reference>
            <slot name="default" />
        </template>
        <template #default>
            <div class="invalid-fields">
                <div class="invalid-fields-title">
                    {{ title || $t(invalidItems.length > 1 ? 'errorWithFields' : 'errorWithFieldSingle') }}
                </div>
                <ul class="invalid-field-list">
                    <li
                        v-for="prop in invalidItems"
                        :key="prop"
                        class="property-link"
                    >
                        {{ t('shared.listItem', { item: prop || $t('invalidItem') }) }}
                    </li>
                </ul>
            </div>
        </template>
    </TooltipWithDelay>
</template>
<script lang="ts">export default { name: 'NextControl' }; </script>
<script lang="ts" setup>
import TooltipWithDelay from '@/shared/components/TooltipWithDelay.vue';
import { injectFormProvider } from '@/model/form/inject-form';
import { PopoverPosition } from '@/play-editor/PopoverPosition';

const props = defineProps<{title?:string; invalidItems: string[]}>();

const { focusQuestion } = injectFormProvider(true);

</script>
<style lang="scss" scoped>
    .invalid-fields-title {
        margin-bottom: $spacing-200;
    }

    .invalid-field-list {
        li {
            text-align: left;
        }
    }

    .property-link {
        cursor: pointer;
        margin-bottom: $spacing-050;

        &:hover {
            color: white;

            > span {
                text-decoration: underline;
            }
        }
    }
</style>
<i18n>
{
    "en-us": {
        "invalidItem": "Invalid item",
        "errorWithFields": "Some fields on this page are invalid:",
        "errorWithFieldSingle": "One of the fields has an error:"
    }
}
</i18n>
