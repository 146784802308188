/* eslint-disable no-console */
import { KeyValues } from '@/types/core-types';
import { allLoggers, LevelSettings, logger } from '@/shared/logging';
import asserts from '@/shared/asserts';
import cloneDeep from 'lodash/cloneDeep';
import { Store } from 'vuex';
import { State } from '@/store/play-root-state';
import { env } from '@/env';

function normalizeLevel(value:LevelSettings|number):LevelSettings {
    return (typeof (value) === 'number')
        ? {
            level: value,
        } : value;
}

export function setupKeapDevtools(store: Store<State>) {
    if (!env.PROD) {
        window.keapDevTools ??= <KeyValues>{};

        window.keapDevTools.logger = updateLogSettings;

        window.keapDevTools.vlogger = (name: string, update: LevelSettings|number) => updateLogSettings(name ? `components.${name}` : 'components', update);

        window.keapDevTools.root = (update: LevelSettings|number) => updateLogSettings(update);

        window.keapDevTools.loggers = () => {
            console.log('LOGGERS', cloneDeep(allLoggers()));
        };

        window.keapDevTools.configOverrides = () => {
            console.log('CONFIG', cloneDeep(store.state.config.localConfig));
        };
    }

    async function updateLogSettings(path: string|LevelSettings|number, value?:LevelSettings|number) {
        if (value || (path && typeof path !== 'string')) {
            if (typeof path === 'string') {
                value = normalizeLevel(value);
                path = `logging.levels.${path}`;
            } else {
                // Setting the root log levels
                asserts(value == null, 'Setting default log levels cannot provide an extra value');
                value = normalizeLevel(path);
                path = 'logging.levels';
            }
            await store.dispatch('config/SET_CONFIG_OVERRIDE', { path, value });
        }

        asserts(typeof path === 'string');
        // eslint-disable-next-line no-console
        console.log(`LOGGER: ${path}`, cloneDeep(logger(path).settings));
    }
}
