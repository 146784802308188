/* eslint-disable no-console,no-case-declarations */
import {
    allLoggers, levels, logger, Logger, logSettings,
} from '@/shared/logging';
import { store } from '@/store';
import { ComponentState, DevtoolsPluginApi } from '@vue/devtools-api';
import { Dict, KeyValues, VoidCallback } from '@/types/core-types';
import { buildCustomState, InspectorHelper, playInspectorId } from '@/setup/devtools/inspector-helper';
import { ref, watch } from 'vue';

const loggingInspectorId = 'logging';

export function LoggingInspector<R, D extends DevtoolsPluginApi<R>>(api: D) {
    const isExpanded = ref(null);

    watch(isExpanded, () => {
        api.sendInspectorTree(playInspectorId);
    });

    function refreshTree() {
        api.sendInspectorTree(playInspectorId);
        api.sendInspectorState(playInspectorId);
    }

    return <InspectorHelper>{
        id: loggingInspectorId,
        label: 'Logging',
        load() {
            if (isExpanded.value == null) {
                isExpanded.value = true;
            }

            const rootSetting = logSettings().levels?.level ?? levels.warn;

            return {
                'root logger': [
                    buildLoggerState(rootSetting, null, refreshTree),
                ],
            };
        },
        get nodeActions() {
            return !isExpanded.value ? [
                {
                    action() {
                        isExpanded.value = true;
                    },
                    icon: 'unfold_more',
                    tooltip: 'Expand',
                }] : [
                {
                    action() {
                        isExpanded.value = false;
                    },
                    icon: 'unfold_less',
                    tooltip: 'Collapse',
                },
            ];
        },
        get children() {
            return isExpanded.value ? allLoggerData().valueSet().map(txLog(refreshTree)) : [];
        },
    };
}

export type LoggingNode = {
    level: number;
    logName:string;
    path: string;
    label:string;
    children: Dict<LoggingNode>,
}

function txLog(callback:VoidCallback) {
    return (log:KeyValues): InspectorHelper => {
        const {
            label, children, level, path,
        } = log;

        return {
            id: label,
            label,
            load() {
                const state = allLoggers()[path] ?? logger(path);

                return toLogInspector(state, path, callback);
            },
            children: [
                ...Object.values(children).map(txLog(callback)),
            ],
            tags: level != null ? [{
                label: levels[level],
                ...labelColor(level),
            },
            ] : [],
        };
    };
}

function labelColor(level:number) {
    switch (level) {
    case 0:
        return { textColor: 0x333333, backgroundColor: 0xbbbbbb };
    case 1:
        return { textColor: 0xFFFFFF, backgroundColor: 0x009900 };
    case 2:
        return { textColor: 0x000000, backgroundColor: 0xFFFF00 };
    case 3:
        return { textColor: 0xFFFFFF, backgroundColor: 0xFF0033 };
    case 4:
        return { textColor: 0xFFFFFF, backgroundColor: 0xFF0000 };
    default:
        return {
            textColor: 0x000000,
            backgroundColor: 0xFFFFFF,
        };
    }
}

function allLoggerData(): Dict<LoggingNode> {
    const loggers = <Dict<LoggingNode>>{};

    allLoggers().entrySet().forEach(([, value]) => {
        const parts = value.name.split('.');

        let path = '';
        let curr = { level: null as unknown, children: loggers };

        for (const part of parts) {
            if (path !== '') {
                path += '.';
            }
            path += part;

            if (!curr.children[path]) {
                curr.children[path] = {
                    path,
                    logName: path,
                    label: part,
                    level: logger(path).settings.level,
                    children: <KeyValues>{},
                };
            }

            curr = curr.children[path];
        }
        curr.level = value.settings.level;
    });

    return loggers;
}

function toLogInspector(log: Logger, path:string, onChange:VoidCallback):Record<string, ComponentState[]> {
    const children = <ComponentState[]>[];

    const { color = '#000000', bg = '#FFFFFF', style = {} } = log.settings ?? {};

    if (color) {
        style.color = color;
    }

    if (bg) {
        style['background-color'] = bg;
    }

    Object.entries(style).forEach(([k, v]) => children.push({
        type: 'string',
        key: k,
        value: v,
        editable: true,
    }));

    return {
        style: children,
        level: [buildLoggerState(log.settings.level, path, onChange)],
    };
}

function buildLoggerState(currentLevel: number, path?:string, onChange?: VoidCallback):ComponentState {
    const suffix = path != null ? `.${path}` : '';

    const updateLevel = async (newLevel:number) => {
        if (newLevel < 0) {
            newLevel = 0;
        } else if (newLevel > 3) {
            newLevel = 3;
        }
        await store.dispatch('config/SET_CONFIG_OVERRIDE', {
            path: `logging.levels${suffix}`,
            value: { level: newLevel },
        });
        onChange?.call(null);
    };

    return buildCustomState({
        key: 'level',
        display: levels[currentLevel],
        value: levels[currentLevel],
        actions: [
            {
                async action() {
                    if (currentLevel > 0) {
                        await updateLevel(currentLevel - 1);
                    }
                },
                icon: currentLevel <= 0 ? 'disabled' : 'remove',
                tooltip: 'Change log level',
            },
            {
                async action() {
                    if (currentLevel < 3) {
                        await updateLevel(currentLevel + 1);
                    }
                },
                icon: currentLevel >= 3 ? 'disabled' : 'add',
                tooltip: 'Change log level',
            },
            {
                async action() {
                    await updateLevel(null);
                },
                icon: 'clear',
                tooltip: 'Clear the log level',
            },
        ],
    });
}
