import { RouteName } from '@/router/route-names';
import { RouteRecordRaw } from 'vue-router';

export const coachPortalRoutes: RouteRecordRaw[] = [
    {
        path: 'coach',
        meta: { title: 'Coaching Portal' },
        component: () => import('@/coach/CoachRoot.vue'),
        props: true,
        children: [
            {
                path: 'templates',
                meta: { title: 'routes.coachTemplates' },
                component: () => import('@/coach/CoachTemplates.vue'),
                props: true,
                children: [
                    {
                        path: '',
                        meta: { title: 'routes.coachTemplates' },
                        component: () => import('@/coach/CoachTemplateEmpty.vue'),
                        name: RouteName.coachTemplates,
                        props: true,
                    },

                    {
                        path: ':templateId/:blockEditing',
                        meta: { title: 'routes.coachTemplates' },
                        component: () => import('@/coach/CoachTemplate.vue'),
                        name: RouteName.coachTemplate,
                        props: true,
                    },
                ],
            },
            {
                path: ':focusedClientId?',
                meta: { title: 'routes.coachingPortal' },
                name: RouteName.coachRoot,
                component: () => import('@/coach/CoachHome.vue'),
                props: true,
            },
            {
                path: ':focusedClientId/playbook',
                meta: { title: 'routes.playbook' },
                component: () => import('@/playbook/CoachPlaybookWrapper.vue'),
                name: RouteName.coachPlaybook,
                props: (route) => ({
                    appId: route.params.focusedClientId,
                    modalTitle: route.query.modalTitle,
                }),
            },
            {
                path: ':focusedClientId/playbook-editor/:initialFocusedPlayId?',
                meta: { title: 'routes.coachingPortal' },
                component: () => import('@/coach/PlaybookEditor.vue'),
                name: RouteName.clientPlaybookEditor,
                props: (route) => ({
                    ...route.params,
                    returnBack: route.query.returnBack === 'true',
                }),
            },
            {
                path: ':focusedClientId?/copy/:focusedPlayId',
                meta: { title: 'routes.copyGenerator' },
                component: () => import('@/play-editor/play/PlayPage.vue'),
                props: (route) => ({
                    appId: route.params.focusedClientId,
                    focusedPlayId: route.params.focusedPlayId,
                }),
                name: RouteName.coachPlay,
            },

        ],
    },
];
