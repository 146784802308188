<template>
    <PlayTooltip
        ref="tooltip"
        :is-open="bool(isOpen)"
        :disabled="disabled"
        :position="position"
        :wrap="wrap"
        @click="onClick"
    >
        <template #reference>
            <span>
                <slot name="reference" />
            </span>
        </template>

        <template #default>
            <span>
                <slot name="default" />
            </span>
        </template>
    </PlayTooltip>
</template>
<script lang="ts">export default { name: 'TooltipWithDelay' }; </script>
<script lang="ts" setup>

import {
    ref, watch,
} from 'vue';
import { TooltipStatus } from '@/shared/components/TooltipStatus';
import { useMouseInElement } from '@vueuse/core';
import PlayTooltip from '@/shared/components/PlayTooltip.vue';
import { TimerHandle } from '@/shared/type.utils';
import { PopoverPosition } from '@/play-editor/PopoverPosition';

const SHOW_STATUS = [TooltipStatus.SHOWING, TooltipStatus.ACTIVE];
const HIDE_STATUS = [TooltipStatus.HIDING, TooltipStatus.IDLE, TooltipStatus.CLICKED, TooltipStatus.INIT];

 interface TooltipWithDelayProps {
        delay?: number;
        showDelay?: number;
        dismissDelay?: number,

        /**
         * Position of the tooltip relative to its reference
         */
        position?: PopoverPosition;

        /**
         * Boolean to be able to disable a tooltip from being able to open
         */
        disabled?: boolean,

        /**
         * Boolean to force tooltips to wrap with max width
         */
        wrap?:boolean,
    }
const props = withDefaults(defineProps<TooltipWithDelayProps>(), {
    delay: 300,
    position: PopoverPosition.bottom,
});

const status = ref(TooltipStatus.INIT);
const isOpen = ref(false);
const tooltip = ref();

let timeout: TimerHandle = null;

const { isOutside } = useMouseInElement(tooltip, { handleOutside: true });

function updateTimeout(value:boolean, delay: number|null) {
    clearTimeout(timeout);
    // eslint-disable-next-line no-return-assign
    timeout = setTimeout(() => isOpen.value = value, delay ?? props.delay);
}

watch(isOutside, (outside) => {
    if (!outside) {
        if (!SHOW_STATUS.includes(status.value)) {
            status.value = TooltipStatus.SHOWING;
            updateTimeout(true, props.showDelay);
        }
    } else if (!HIDE_STATUS.includes(status.value)) {
        status.value = TooltipStatus.HIDING;
        updateTimeout(false, props.dismissDelay);
    }
}, { immediate: true, deep: true });

function onClick() {
    clearTimeout(timeout);
    isOpen.value = false;
}

</script>
