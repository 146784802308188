export type PropertyKey = string | number | symbol;
export type Predicate<T> = ((item: T) => boolean);
export type IdentityOrUpdate<T> = (item: T) => (T|undefined);
export type InstanceOrIdentity<T> = T | IdentityOrUpdate<T>;
// noinspection ES6UnusedImports

export type Struct<K extends keyof any, V> = {
    mapEntries<KK extends PropertyKey, T>(mapper: (key: K, source: V) => [KK, T]): Struct<KK, T>;
    mapValues<T>(mapper: (source: V) => T): Struct<K, T>;
    filterEntries(predicate: (key: K, value: V) => boolean): Struct<K, V>;
    valueSet(): V[];
    entrySet(): [K, V][];
    keySet(): K[];
    getByPath<T>(paths: string | string[], defaultValue?: T): T | null;
} & { [P in K]: V; };

export type Dict<V> = Struct<PropertyKey, V>;
export type KeyValues = Struct<PropertyKey, any>;

export function dict<V>(value?: KeyValues): Struct<string, V> {
    return (value ?? {}) as Struct<string, V>;
}

export function struct<K extends PropertyKey, V>(value?: Record<K, V>): Struct<K, V> {
    return {} as Struct<K, V>;
}

/**
 * for vue3, converts boolean values to true or undefined
 *
 * @param value
 */
export function bool(value:unknown) :true|undefined{
    return value?true:undefined;
}

export function vbind<P extends object>(value:P) :P {
    return value.mapEntries((key,value)=> [key, value === false ? undefined: value]) as P;
}


export function array<V>(): V[] {
    return [];
}


export type PromiseArgs<T> = {
    resolve?: (result: T | null) => void;
    reject?: (error?: Error) => void;
}

export type DropdownCategory = {
    category: { value: string, label?: string },
    options: DropdownOption[],
}

export type DropdownOption = {
    value: string;
    label?: string;
    helpText?: string;
}

export type TagDropdownOption = DropdownOption & {
    tenantId: string | null;
}

export type VoidCallback = () => unknown;

export type Emit<T extends string> = ((name: T, ...opt: any[]) => void);

export type Results<T> = {
    result?: T;
    success: boolean;
    error?: any;
}

export type Dimension = {
    x?: number;
    y?: number;
}
