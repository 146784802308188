/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppointmentType } from '../models/AppointmentType';
import type { AppointmentTypeList } from '../models/AppointmentTypeList';
import type { CreateTag } from '../models/CreateTag';
import type { Tag } from '../models/Tag';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProxyService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List ApptTypes
     * Lists all AppointmentType records for a provider
     * @param tenant
     * @param provider
     * @returns AppointmentTypeList Success
     * @throws ApiError
     */
    public listApptTypes(
        tenant: string,
        provider: string,
    ): CancelablePromise<AppointmentTypeList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/tenant/{tenant}/appointmentTypes',
            path: {
                'tenant': tenant,
            },
            query: {
                'provider': provider,
            },
        });
    }

    /**
     * List ApptType by Link
     * Get AppointmentType by booking link
     * @param tenant
     * @param bookingLink
     * @returns AppointmentType Success
     * @throws ApiError
     */
    public listApptTypeByLink(
        tenant: string,
        bookingLink: string,
    ): CancelablePromise<AppointmentType> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/tenant/{tenant}/appointmentTypes/{bookingLink}',
            path: {
                'tenant': tenant,
                'bookingLink': bookingLink,
            },
        });
    }

    /**
     * Create Tag
     * Creates a new Tag record
     * @param tenant
     * @param requestBody
     * @returns Tag Success
     * @throws ApiError
     */
    public createTag(
        tenant: string,
        requestBody: CreateTag,
    ): CancelablePromise<Tag> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/tenant/{tenant}/tags',
            path: {
                'tenant': tenant,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
