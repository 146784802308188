import { KeyValues } from '@/types/core-types';
import cloneDeep from 'lodash/cloneDeep';
import { watch } from 'vue';
import { ExpandRefs, FormProvider, PropertyProvide } from '@/model/form/form-provider-types';
import { PreparedModel } from '@/integration/datastore/base-types';
import { useCore } from '@/shared/shared-providers';
import { asyncDebounce } from '@/shared/utils/async-debounce';

/**
 * Part of the FormProvider setup:
 *
 * This will fetch expanded refs, and populate the values into state.expandedRefs.
 *
 * @param expandRefsFunction The function used to expand refs
 * @param self The FormProvider
 */
export function setupRefExpansion(expandRefsFunction: ExpandRefs, self: FormProvider) {
    const { t } = useCore();
    const log = self.log.child('expansion', true);

    const { state } = self;

    const propHelpers = self.getAll(self.properties.valueSet());
    ///
    /// LAST: Sync expanded refs when they are updated
    ///
    const debounceSyncRefs = asyncDebounce((answers: KeyValues) => expandRefsFunction(answers), 500);

    /**
     * Fetches expanded refs, and copies over only the ref/reflist property types
     */
    async function updateExpandedRefs() {
        const data = await debounceSyncRefs(state.answers);

        for (const prop of propHelpers) {
            updateExpandedRef(prop, data);
        }
        log.info(' - EXPAND:', cloneDeep(state.expandedRefs));
    }

    function updateExpandedRef(prop: PropertyProvide<unknown>, data?: KeyValues) {
        if (prop.property.type.ref != null || prop.property.type.array?.listType?.ref != null) {
            const expandedData = data[prop.name];

            vset(state.expandedRefs, prop.name, expandedData);

            if (expandedData) {
                const records: PreparedModel[] = Array.isArray(expandedData) ? expandedData : [expandedData];
                const allErrors = records.some((r) => r.valid === false);

                if (allErrors) {
                    self.setValidationError(prop, {
                        code: 'invalid',
                        error: true,
                        propertyId: prop.name,
                        message: t('models.refListBadRecord'),
                        additionalHelp: null,
                    });
                } else if (self.state.validationErrors[prop.name]) {
                    self.setValidationError(prop);
                }
            }
        }
    }

    const debounceExpand = asyncDebounce(updateExpandedRefs, 1000, { maxWait: 5000 });

    self.refreshRefs = async () => {
        await debounceExpand();
        log.info('REFRESH REFS: DONE');
    };

    for (const prop of propHelpers.valueSet()) {
        log.debug('PROP', prop);

        if (prop.property.type.ref != null || prop.property.type.array?.listType?.ref != null) {
            log.debug(`Watching ${prop.name} for ref changes`);
            watch(() => cloneDeep(state.answers[prop.name]), (newValue, oldValue) => {
                log.debug('UPDATE REF', prop.name, { newValue, oldValue });

                return debounceExpand();
            });
        }
    }

    /// Initial ref expansion
    void debounceExpand();
}
