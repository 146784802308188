/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum KeapIcon {
    ACTIVITY = 'activity',
    ADD = 'add',
    ADD_CIRCLE = 'add-circle',
    ADD_FILL = 'add-fill',
    ALERT_CIRCLE = 'alert-circle',
    ALERT_OCTAGON = 'alert-octagon',
    ALERT_TRIANGLE = 'alert-triangle',
    ALERT_TRIANGLE_FILL = 'alert-triangle-fill',
    ALIGN_CENTER = 'align-center',
    ARROW_DOWN = 'arrow-down',
    ARROW_DOWN_FILL = 'arrow-down-fill',
    ARROW_LEFT = 'arrow-left',
    ARROW_LONG_RIGHT = 'arrow-long-right',
    ARROW_RIGHT = 'arrow-right',
    ARROW_RIGHT_CIRCLE = 'arrow-right-circle',
    ARROW_RIGHT_FILL = 'arrow-right-fill',
    ARROW_UP = 'arrow-up',
    ARROW_UP_FILL = 'arrow-up-fill',
    ARROW_UP_RIGHT = 'arrow-up-right',
    ATTACH = 'attach',
    AWARD = 'award',
    BAR_CHART_2 = 'bar-chart-2',
    BOLD = 'bold',
    BOX = 'box',
    BOOK_OPEN = 'book-open',
    BRIEFCASE = 'briefcase',
    BUSINESS_CARD = 'business-card',
    CALENDAR = 'calendar',
    CALENDAR_FILL = 'calendar-fill',
    CALENDAR_X = 'calendar-x',
    CAMPAIGN = 'campaign',
    CHART = 'chart',
    CHECK = 'check',
    CHECK_CIRCLE = 'check-circle',
    CHECK_SQUARE = 'check-square',
    CHEVRON_DOWN = 'chevron-down',
    CHEVRON_LEFT = 'chevron-left',
    CHEVRON_RIGHT = 'chevron-right',
    CHEVRON_UP = 'chevron-up',
    CIRCLE_FILL = 'circle-fill',
    CLIPBOARD = 'clipboard',
    CLICK = 'click',
    CLOCK = 'clock',
    CLOCK_FILLED = 'clock-filled',
    CLOUD_LIGHTNING = 'cloud-lightning',
    CODE = 'code',
    COLLAPSE = 'collapse',
    COMPANY = 'company',
    COMPASS = 'compass',
    CONTACT_ADD_USER_PLUS = 'contact-add-user-plus',
    CONTACT_USER = 'contact-user',
    CONTACTS_USERS = 'contacts-users',
    CONVERSATION = 'conversation',
    COPY = 'copy',
    CREDIT_CARD = 'credit-card',
    DOLLAR = 'dollar',
    DOWNLOAD = 'download',
    DRAGGABLE = 'draggable',
    EDIT = 'edit',
    EMAIL = 'email',
    EMAIL_FILL = 'email-fill',
    EMAIL_OPEN = 'email-open',
    EXPAND = 'expand',
    EXPAND_VERTICAL = 'expand-vertical',
    EXTERNAL_LINK = 'external-link',
    EYE_HIDE = 'eye-hide',
    EYE_SHOW = 'eye-show',
    FACEBOOK = 'facebook',
    FILE = 'file',
    FILTER = 'filter',
    FIRE = 'fire',
    FOLDER = 'folder',
    FOLDER_FILL = 'folder-fill',
    FORM = 'form',
    FORM_FILL = 'form-fill',
    GIFT = 'gift',
    HAMBURGER = 'hamburger',
    HELP_CIRCLE = 'help-circle',
    HOME = 'home',
    IMAGE = 'image',
    INFO_CIRCLE = 'info-circle',
    INFO_CIRCLE_FILL = 'info-circle-fill',
    INSTAGRAM = 'instagram',
    INTEGRATIONS = 'integrations',
    ITALIC = 'italic',
    KEAP_UP_RIGHT = 'keap-up-right',
    KEY_DELETE = 'key-delete',
    LAYOUT = 'layout',
    LIGHTBULB = 'lightbulb',
    LIGHTNING_FILL = 'lightning-fill',
    LINK = 'link',
    LINK_HORIZ = 'link-horiz',
    LIST = 'list',
    LIST_CARDS = 'list-cards',
    LIST_UNORDERED = 'list-unordered',
    LOADER = 'loader',
    LOCATION = 'location',
    LOCK_LOCKED_FILL = 'lock-locked-fill',
    LOG_OUT = 'log-out',
    MARK_UNREAD = 'mark-unread',
    MAXIMIZE = 'maximize',
    MAXIMIZE_2 = 'maximize-2',
    MEGAPHONE = 'megaphone',
    MERGE_FIELD = 'merge-field',
    MESSAGE = 'message',
    MESSAGE_FILL = 'message-fill',
    MESSAGE_NONE = 'message-none',
    MESSAGE_SQUARE = 'message-square',
    MESSAGE_SQUARE_FILL = 'message-square-fill',
    MESSAGES_AUTOMATED = 'messages-automated',
    MIC_ON = 'mic-on',
    MIC_OFF = 'mic-off',
    MINIMIZE = 'minimize',
    MINUS = 'minus',
    MINUS_CIRCLE = 'minus-circle',
    MINUS_SQUARE = 'minus-square',
    MINUS_FILL = 'minus-fill',
    MOON_STARS_FILL = 'moon-stars-fill',
    MORE = 'more',
    MORE_HORIZONTAL = 'more-horizontal',
    MORE_VERTICAL = 'more-vertical',
    MOVE = 'move',
    NOTE = 'note',
    NOTE_FILL = 'note-fill',
    NOTES = 'notes',
    NOTES_FILL = 'notes-fill',
    NOTIFICATION = 'notification',
    OCTAGON = 'octagon',
    PACKAGE = 'package',
    PAUSE_FILL = 'pause-fill',
    PENCIL = 'pencil',
    PENCIL_EDIT = 'pencil-edit',
    PHONE = 'phone',
    PHONE_CALL = 'phone-call',
    PHONE_CALL_FILL = 'phone-call-fill',
    PHONE_DIALPAD = 'phone-dialpad',
    PHONE_FILL = 'phone-fill',
    PHONE_FORWARDED_FILL = 'phone-forwarded-fill',
    PHONE_INCOMING = 'phone-incoming',
    PHONE_INCOMING_FILL = 'phone-incoming-fill',
    PHONE_MISSED = 'phone-missed',
    PHONE_MISSED_FILL = 'phone-missed-fill',
    PHONE_OFF_FILL = 'phone-off-fill',
    PHONE_OUTGOING = 'phone-outgoing',
    PHONE_OUTGOING_FILL = 'phone-outgoing-fill',
    PIPELINE = 'pipeline',
    PIPELINE_FILL = 'pipeline-fill',
    PLAY = 'play',
    PLAY_CIRCLE = 'play-circle',
    PLAY_FILL = 'play-fill',
    PLAYBOOK = 'playbook',
    PLUS_SQUARE = 'plus-square',
    PRINTER = 'printer',
    PROFILE = 'profile',
    QUOTE = 'quote',
    RECEIPT = 'receipt',
    RECURRING = 'recurring',
    REFRESH_CW = 'refresh-cw',
    REPLACE = 'replace',
    RENAME = 'rename',
    ROTATE_CCW = 'rotate-ccw',
    RSS = 'rss',
    SALES = 'sales',
    SALES_FILL = 'sales-fill',
    SAVE = 'save',
    SEARCH = 'search',
    SEND = 'send',
    SETTINGS = 'settings',
    SHARE = 'share',
    SHARE_2 = 'share-2',
    SHOPPING_CART = 'shopping-cart',
    SHUFFLE = 'shuffle',
    SIDEBAR_EXPAND = 'sidebar-expand',
    SLASH = 'slash',
    SORT = 'sort',
    SQUARE = 'square',
    STAR = 'star',
    STAR_FILL = 'star-fill',
    TAG = 'tag',
    TAG_FILL = 'tag-fill',
    TARGET = 'target',
    TASKS = 'tasks',
    TASKS_FILL = 'tasks-fill',
    TEMPLATES = 'templates',
    THUMBS_DOWN = 'thumbs-down',
    THUMBS_UP = 'thumbs-up',
    TOGGLE_LEFT = 'toggle-left',
    TOGGLE_RIGHT = 'toggle-right',
    TRASH = 'trash',
    TRASH_2 = 'trash-2',
    TRENDING_UP = 'trending-up',
    TYPE = 'type',
    USER = 'user',
    USER_CHECK = 'user-check',
    USER_FILL = 'user-fill',
    USER_MINUS = 'user-minus',
    USER_PLUS = 'user-plus',
    USERS = 'users',
    VIDEO = 'video',
    VIDEO_FILL = 'video-fill',
    VIDEO_OFF_FILL = 'video-off-fill',
    VIEW_MOBILE = 'view-mobile',
    VOICEMAIL = 'voicemail',
    VOLUME_2 = 'volume-2',
    VOLUME_X = 'volume-x',
    WAND = 'wand',
    X = 'x',
    X_CIRCLE = 'x-circle',
    X_CIRCLE_FILL = 'x-circle-fill',
    X_SQUARE = 'x-square',
    ZAP = 'zap',
    ZAP_FILL = 'zap-fill',
    ZAP_OFF = 'zap-off',
}
