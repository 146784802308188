/* eslint-disable no-case-declarations,no-await-in-loop */
import { DevtoolsPluginApi } from '@vue/devtools-api';
import { playInspectorId, registerInspector } from '@/setup/devtools/inspector-helper';
import { LoggingInspector } from '@/setup/devtools/inspector-logging';
import { AuthInspector } from '@/setup/devtools/inspector-auth';

/**
 * Main entrypoint for Vue tools for our project
 */
export function setupToolsInspector<R, D extends DevtoolsPluginApi<R>>(api: D) {
    registerInspector(api, {
        id: playInspectorId,
        label: 'Play',
        icon: 'build',
        children: [
            LoggingInspector(api),
            AuthInspector(api),
        ],
    });
}
