import { env } from '@/env';
import { RouteName } from '@/router/route-names';

export const devRoutes = env.isDev ? [{
    path: '/tools/dev/frameTest',
    name: RouteName.frameTest,
    component: () => import('@/entry/FrameTest.vue'),
},
{
    name: RouteName.mergeTest,
    path: '/:coachId?/:appId/merge',
    meta: { title: 'routes.mergeTest' },
    component: () => import('@/play-editor/MergeTestPage.vue'),
    props: true,
}] : [];
