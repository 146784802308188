/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiResponse } from '../models/AiResponse';
import type { CreatePreview } from '../models/CreatePreview';
import type { GenerateSuggestionsRequest } from '../models/GenerateSuggestionsRequest';
import type { PlayTags } from '../models/PlayTags';
import type { SampleData } from '../models/SampleData';
import type { SampleDataDetail } from '../models/SampleDataDetail';
import type { V2CreatePlayRequest } from '../models/V2CreatePlayRequest';
import type { V2MergeRequest } from '../models/V2MergeRequest';
import type { V2MergeResponse } from '../models/V2MergeResponse';
import type { V2PlayTemplate } from '../models/V2PlayTemplate';
import type { V2PlayTemplateInfo } from '../models/V2PlayTemplateInfo';
import type { ValidationResult } from '../models/ValidationResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PlayTemplateV2Service {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List SampleData Sets
     * @returns SampleDataDetail listSampleDataSets 200 response
     * @throws ApiError
     */
    public listSampleDataSets(): CancelablePromise<Array<SampleDataDetail>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/sample-data-sets',
        });
    }

    /**
     * Create SampleData Set
     * @param requestBody
     * @returns SampleDataDetail createSampleDataSet 200 response
     * @throws ApiError
     */
    public createSampleDataSet(
        requestBody: SampleData,
    ): CancelablePromise<SampleDataDetail> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/sample-data-sets',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get SampleData Set
     * @param id
     * @returns SampleDataDetail getSampleDataSet 200 response
     * @throws ApiError
     */
    public getSampleDataSet(
        id: string,
    ): CancelablePromise<SampleDataDetail> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/sample-data-sets/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Delete SampleData
     * @param sampleDataId
     * @returns any deleteSampleData 200 response
     * @throws ApiError
     */
    public deleteSampleData(
        sampleDataId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/sample-data-sets/{sampleDataId}',
            path: {
                'sampleDataId': sampleDataId,
            },
        });
    }

    /**
     * List PlayTemplate Tags V2
     * @param tenant
     * @returns PlayTags listPlayTemplateTagsV2 200 response
     * @throws ApiError
     */
    public listPlayTemplateTagsV2(
        tenant: string,
    ): CancelablePromise<PlayTags> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/tenant/{tenant}/play-categories',
            path: {
                'tenant': tenant,
            },
        });
    }

    /**
     * Lists all available play templates
     * Lists all available play templates
     * @param tenant The tenant calling the endpoint
     * @returns V2PlayTemplateInfo A PlayDto
     * @throws ApiError
     */
    public listPlayTemplatesV2(
        tenant: string,
    ): CancelablePromise<Array<V2PlayTemplateInfo>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/tenant/{tenant}/play-templates',
            path: {
                'tenant': tenant,
            },
        });
    }

    /**
     * Merge V2
     * @param tenant
     * @param requestBody
     * @returns V2MergeResponse mergeV2 200 response
     * @throws ApiError
     */
    public mergeV2(
        tenant: string,
        requestBody: V2MergeRequest,
    ): CancelablePromise<Record<string, V2MergeResponse>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/play-templates/merge/execute',
            path: {
                'tenant': tenant,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Merge Test V2
     * @param tenant
     * @param requestBody
     * @returns V2MergeResponse mergeTestV2 200 response
     * @throws ApiError
     */
    public mergeTestV2(
        tenant: string,
        requestBody: any,
    ): CancelablePromise<V2MergeResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/play-templates/merge/test',
            path: {
                'tenant': tenant,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get PlayTemplate V2
     * Retrieves a single playTemplate by ID, merging any dynamic values
     * @param tenant The tenant calling the endpoint
     * @param id The primary key for the template
     * @param requestBody
     * @returns V2PlayTemplate Success
     * @throws ApiError
     */
    public getPlayTemplateV2(
        tenant: string,
        id: string,
        requestBody?: any,
    ): CancelablePromise<V2PlayTemplate> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/play-templates/{id}',
            path: {
                'tenant': tenant,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Generates suggestion for a section
     * Generates suggestion for a section
     * @param tenant The tenant calling the endpoint
     * @param playTemplateId the ID of the play being generated for
     * @param assetKey the asset key containing the section we are generating for
     * @param sectionKey the sectionKey for the section to generate suggestions for
     * @param requestBody
     * @returns AiResponse The AI response
     * @throws ApiError
     */
    public generateSectionSuggestion(
        tenant: string,
        playTemplateId: string,
        assetKey: string,
        sectionKey: string,
        requestBody: GenerateSuggestionsRequest,
    ): CancelablePromise<AiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/play-templates/{playTemplateId}/assets/{assetKey}/sections/{sectionKey}/suggestions',
            path: {
                'tenant': tenant,
                'playTemplateId': playTemplateId,
                'assetKey': assetKey,
                'sectionKey': sectionKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Create Play V2
     * Clones a play template into a new play
     * @param tenant The tenant calling the endpoint
     * @param playTemplateId The play template used to clone the new play
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public createPlayV2(
        tenant: string,
        playTemplateId: string,
        requestBody?: V2CreatePlayRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/play-templates/{playTemplateId}/plays',
            path: {
                'tenant': tenant,
                'playTemplateId': playTemplateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Create Play Preview V2
     * Creates a play, using data from another tenant
     * @param tenant The tenant calling the endpoint
     * @param playTemplateId The play template used to clone the new play
     * @param requestBody Details for creating the play
     * @returns any The play preview
     * @throws ApiError
     */
    public createPlayPreviewV2(
        tenant: string,
        playTemplateId: string,
        requestBody: CreatePreview,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/play-templates/{playTemplateId}/previews',
            path: {
                'tenant': tenant,
                'playTemplateId': playTemplateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Generate Default Value V2
     * @param tenant The tenant calling the endpoint
     * @param playTemplateId the ID of the play being generated for
     * @param questionId the question being generated
     * @param requestBody
     * @returns AiResponse Success
     * @throws ApiError
     */
    public generateDefaultValueV2(
        tenant: string,
        playTemplateId: string,
        questionId: string,
        requestBody: GenerateSuggestionsRequest,
    ): CancelablePromise<AiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/play-templates/{playTemplateId}/properties/{questionId}/defaultValue',
            path: {
                'tenant': tenant,
                'playTemplateId': playTemplateId,
                'questionId': questionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Generate Suggestions V2
     * Generates suggestions for question
     * @param tenant The tenant calling the endpoint
     * @param playTemplateId the ID of the play being generated for
     * @param questionId the question being generated
     * @param requestBody
     * @returns AiResponse The suggestions
     * @throws ApiError
     */
    public generateSuggestionsV2(
        tenant: string,
        playTemplateId: string,
        questionId: string,
        requestBody: GenerateSuggestionsRequest,
    ): CancelablePromise<AiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/play-templates/{playTemplateId}/properties/{questionId}/suggestions',
            path: {
                'tenant': tenant,
                'playTemplateId': playTemplateId,
                'questionId': questionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Validate Question V2
     * @param tenant
     * @param playTemplateId
     * @param requestBody
     * @returns ValidationResult validateQuestionV2 200 response
     * @throws ApiError
     */
    public validateQuestionV2(
        tenant: string,
        playTemplateId: string,
        requestBody: any,
    ): CancelablePromise<ValidationResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/play-templates/{playTemplateId}/validate',
            path: {
                'tenant': tenant,
                'playTemplateId': playTemplateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
