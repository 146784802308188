import { levels, Logger } from '@/shared/logging/logging-api';

// @ts-ignore OK to define as null
const noop = () => { return null; };

export function NoopLogger(): Logger {
    return <Logger>{
        info: noop,
        warn: noop,
        severe: noop,
        error: noop,
        debug: noop,
        settings: {
            level: levels.off,
        },
        configure: noop,
        child: NoopLogger,
    };
}

export const noopLogger = NoopLogger();
