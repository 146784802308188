/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The current status of content generation for this Play.
 *
 * - NOT_APPLICABLE: This Play is not configured with content.
 * - QUESTIONS: The user needs to complete the Play questions.
 * - CONTENT: The user is editing the play content
 */
export enum PlayContentState {
    NOT_APPLICABLE = 'NOT_APPLICABLE',
    QUESTIONS = 'QUESTIONS',
    CONTENT = 'CONTENT',
}
