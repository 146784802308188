/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountQueryStatus } from '../models/AccountQueryStatus';
import type { CloneMigrationResponse } from '../models/CloneMigrationResponse';
import type { CoachClientTags } from '../models/CoachClientTags';
import type { CoachTenants } from '../models/CoachTenants';
import type { Roles } from '../models/Roles';
import type { TenantAccess } from '../models/TenantAccess';
import type { UserRoleType } from '../models/UserRoleType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Clone Play
     * Clones a play from one tenant to another, including all linked models and questions
     * @param playId
     * @param fromTenant
     * @param toTenant
     * @returns CloneMigrationResponse clonePlay 200 response
     * @throws ApiError
     */
    public clonePlay(
        playId: string,
        fromTenant: string,
        toTenant: string,
    ): CancelablePromise<CloneMigrationResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/admin/clone/play/{playId}/{fromTenant}/{toTenant}',
            path: {
                'playId': playId,
                'fromTenant': fromTenant,
                'toTenant': toTenant,
            },
        });
    }

    /**
     * Clone Tenant
     * Clones a play from one tenant to another, including all linked models and questions
     * @param fromTenant
     * @param toTenant
     * @returns CloneMigrationResponse cloneTenant 200 response
     * @throws ApiError
     */
    public cloneTenant(
        fromTenant: string,
        toTenant: string,
    ): CancelablePromise<CloneMigrationResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/admin/clone/tenant/{fromTenant}/{toTenant}',
            path: {
                'fromTenant': fromTenant,
                'toTenant': toTenant,
            },
        });
    }

    /**
     * Add Play TenantAccess
     * @param coachId
     * @param tenantId
     * @returns TenantAccess addPlayTenantAccess 200 response
     * @throws ApiError
     */
    public addPlayTenantAccess(
        coachId: string,
        tenantId: string,
    ): CancelablePromise<TenantAccess> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/admin/{coachId}/{tenantId}/playTenantAccess',
            path: {
                'coachId': coachId,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * Check AccountStatus
     * Check to see if tenant is valid and
     * @param coachId
     * @param tenantId
     * @returns AccountQueryStatus Success
     * @throws ApiError
     */
    public checkAccountStatus(
        coachId: string,
        tenantId: string,
    ): CancelablePromise<AccountQueryStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/coaches/{coachId}/checktenant/{tenantId}',
            path: {
                'coachId': coachId,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * Add Coach Tenant
     * Adds a tenant to the portal for a coach
     * @param coachId
     * @param tenantId
     * @param requestBody
     * @returns CoachTenants Success
     * @throws ApiError
     */
    public addCoachTenant(
        coachId: string,
        tenantId: string,
        requestBody?: CoachClientTags,
    ): CancelablePromise<CoachTenants> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/coaches/{coachId}/tenant/{tenantId}',
            path: {
                'coachId': coachId,
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Remove Coach Tenant
     * Deletes a tenant from a coach
     * @param coachId
     * @param tenantId
     * @returns CoachTenants Success
     * @throws ApiError
     */
    public removeCoachTenant(
        coachId: string,
        tenantId: string,
    ): CancelablePromise<CoachTenants> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/coaches/{coachId}/tenant/{tenantId}',
            path: {
                'coachId': coachId,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * Get User Roles
     * Gets user roles for existing user
     * @param userId
     * @returns Roles Success
     * @throws ApiError
     */
    public getUserRoles(
        userId: string,
    ): CancelablePromise<Roles> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/users/{userId}/roles',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * Add User Role
     * Adds a role to an existing user
     * @param userId
     * @param roleId
     * @returns Roles Success
     * @throws ApiError
     */
    public addUserRole(
        userId: string,
        roleId: UserRoleType,
    ): CancelablePromise<Roles> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/users/{userId}/roles/{roleId}',
            path: {
                'userId': userId,
                'roleId': roleId,
            },
        });
    }

    /**
     * Remove User Role
     * Deletes a role from a user
     * @param userId
     * @param roleId
     * @returns Roles Success
     * @throws ApiError
     */
    public removeUserRole(
        userId: string,
        roleId: UserRoleType,
    ): CancelablePromise<Roles> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/users/{userId}/roles/{roleId}',
            path: {
                'userId': userId,
                'roleId': roleId,
            },
        });
    }

}
