/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SequenceStartType {
    APPOINTMENT = 'appointment',
    FORM = 'form',
    LANDING_PAGE = 'landingPage',
    STAGE_MOVE = 'stageMove',
    TAG = 'tag',
    PRODUCT_PURCHASED = 'productPurchased',
}
