import { OverlayState } from '@/store/overlays/types';
import { MutationTree } from 'vuex';
import { RouteObserver } from '@/router/RouteObserver';

export default <MutationTree<OverlayState>>{
    REGISTER_OBSERVER(state, observer: RouteObserver<unknown>) {
        state.pendingObserver = observer;
    },

    CLEAR_OBSERVER(state) {
        state.pendingObserver = null;
    },

    SET_OVERLAY_ACTIVE(state, active:boolean) {
        checkOverlay(state, active, 'overlay-active');
    },

    OPEN_ACCOUNT_SWITCHER_PANEL(state:OverlayState) {
        state.accountSwitcherPanelOpen = true;
    },

    CLOSE_ACCOUNT_SWITCHER_PANEL(state:OverlayState) {
        state.accountSwitcherPanelOpen = false;
    },
};

const checkOverlay = (state:OverlayState, active:boolean, className:string) => {
    if (active) {
        state.overlaysActive++;
    } else if (state.overlaysActive > 0) {
        state.overlaysActive--;
    }

    setTimeout(() => {
        if (state.overlaysActive > 0) {
            document.body.classList.add(className);
        } else {
            document.body.classList.remove(className);
        }
    });
};
