/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PlaybookCategory {
    ONLINE_LEAD_SOURCE = 'ONLINE_LEAD_SOURCE',
    ONLINE_LEAD_CAPTURE = 'ONLINE_LEAD_CAPTURE',
    OFFLINE_LEAD_SOURCE = 'OFFLINE_LEAD_SOURCE',
    OFFLINE_LEAD_CAPTURE = 'OFFLINE_LEAD_CAPTURE',
    NEW_LEAD_FOLLOW_UP = 'NEW_LEAD_FOLLOW_UP',
    HOT_LEAD_CONVERSION = 'HOT_LEAD_CONVERSION',
    UNCONVERTED_LEADS = 'UNCONVERTED_LEADS',
    WOW_NEW_CUSTOMER = 'WOW_NEW_CUSTOMER',
    MULTIPLY = 'MULTIPLY',
    SAVE_TIME = 'SAVE_TIME',
}
