/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    computed, isReactive, reactive, Ref, toRefs, watch, WatchOptions,
} from 'vue';

import { Logger } from '@/shared/logging';
import cloneDeep from 'lodash/cloneDeep';
import asserts from '@/shared/asserts';

export type WatchOpts = WatchOptions & {log?:Logger};

export function watchCalculated<T extends object>(target: T, computedProps: { [key in keyof T]?: (() => T[key]) }, opts?: WatchOptions&{log?:Logger}): void {
    let k: keyof T;

    // eslint-disable-next-line guard-for-in
    for (k in computedProps) {
        const computedVal = computedProps[k];
        const kk = k;

        vset(target, k, cloneDeep(computedVal()));
        watch(() => cloneDeep(computedVal()), (newValue) => {
            opts?.log?.debug(`Got update for prop ${kk.toString()}: `, newValue);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            vset(target as any, kk?.toString(), newValue);
        }, opts ?? { deep: true });
    }
}

export function appendCalculated<T extends object, C>(target: T, computedProps: {[key in keyof C]: (()=> C[key])}, opts?: WatchOptions&{log?:Logger}): T & C {
    const { log, ...watchOpts } = opts ?? { deep: true };
    let k: keyof C;

    asserts(isReactive(target), 'Must pass reactive instance');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const combined = target as any;

    // eslint-disable-next-line guard-for-in
    for (k in computedProps) {
        const computedVal = computedProps[k];
        const kk = k;

        vset(combined, k, cloneDeep(computedVal()));
        watch(() => cloneDeep(computedVal()), (newValue) => {
            log?.debug(`Got update for prop ${kk.toString()}: `, newValue);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            vset(combined, kk as string|number, newValue);
        }, watchOpts);
    }

    return combined as T & C;
}

export function useComputed<C extends object>(computedProps: {[key in keyof C]: (()=> C[key])}): { [k in keyof C]?: Ref<C[k]> } {
    let k: keyof C;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const combined = {} as any;

    // eslint-disable-next-line guard-for-in
    for (k in computedProps) {
        const kk = k;

        combined[kk] = computed(() => computedProps[kk]());
    }

    return combined;
}

export function useCalculated<C extends object>(computedProps: {[key in keyof C]: (()=> C[key])}, opts?: WatchOptions&{log?:Logger}): C {
    let k: keyof C;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const combined = reactive({}) as C;

    const log = opts?.log;

    // eslint-disable-next-line guard-for-in
    for (k in computedProps) {
        const kk = k;
        const computedVal = computedProps[k];

        vset(combined, kk as string|number, cloneDeep(computedVal()));
        watch(() => cloneDeep(computedVal()), (newValue) => {
            log?.debug(`Got update for prop ${kk.toString()}: `, newValue);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            vset(combined, kk as string|number, newValue);
        }, opts ?? { deep: true });
    }

    return combined;
}

/**
 * This is similiar to {@link watchCalculated} but it will first create a reactive clone, and then publish changes
 *
 * @param initial Initial values, these will be deep-cloned and will not respond to future updates
 * @param computedProps
 */
export function react<T extends object>(initial: T, computedProps: { [key in keyof T]?: (() => T[key]) }, opts?: WatchOpts): T {
    asserts(!isReactive(initial), 'Initial value should not be reactive. If you already have a reactive instance'
        + ', then use watchCalculated');

    const target = reactive<T>(cloneDeep(initial)) as T;

    watchCalculated(target, computedProps, opts);

    return target;
}

export function exportReactive<T extends object>(initial: T): {[key in keyof T]?: Ref<T[key]>} {
    return toRefs(reactive(initial) as T);
}
