<template>
    <div class="card-container" :data-qa="`array-item-card-${question.name}`" @click="$emit('click')">
        <div class="card-elements">
            <div class="card-body field-select drag-target">
                <div class="field-label" :title="value">
                    <template v-if="$slots.value">
                        <slot name="value" />
                    </template>
                    <template v-else>
                        <span>{{ value }}</span>
                    </template>
                </div>
            </div>

            <div :class="['field-actions', { 'action-invalid': cardError }]">
                <Icon
                    v-if="cardError"
                    name="alert-triangle"
                    class="warning-icon"
                />
                <Icon
                    v-if="isSuggestion"
                    name="lightbulb"
                    class="warning-icon is-suggestion"
                />
                <slot name="trailing" />
            </div>
        </div>
    </div>
</template>

<script>

import authMixin from '../../shared/mixins/auth.mixin';

export default {
    name: 'PlayQuestionSortableRefItem',
    mixins: [authMixin],
    props: {
        value: String,
        isSuggestion: Boolean,
        question: Object,
    },
    emits: ['change', 'remove', 'click'],

    data() {
        return {
            cardError: null,
        };
    },

    computed: {
        fieldOptions() {
            return {};
        },

        fieldLabel() {
            return null;
        },
        isOnlyItem() {
            return false;
        },
    },

    methods: {
        setMouseOver(value) {
            this.mouseOver = value;
        },

        handleInput() {
            // do nothing
        },

        changeAnswer(answer) {
            this.$emit('change', answer);
        },

        handleFieldUpdate() {
            // do nothings
        },

        removeItem(item) {
            this.$emit('remove', item);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .card-elements {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }

    .card-body {
        flex-grow: 1;
        width: 100%;
    }

</style>
<i18n>
{
    "en-us": {

    }
}
</i18n>
