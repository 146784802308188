import { Capabilities } from '@/integration/capabilities/capabilities';
import asserts from '@/shared/asserts';
import { Logger, logger } from '@/shared/logging';
import {
    FrameService,
    HostInitializeFunction,
    PlayHost,
    PlayHostInfo,
    PlayHostInit,
} from '@/integration/host-system-api';
import { Completer } from '@/shared/Completer';
import { HostSystem } from '@/generated/play-api';

export class DefaultPlayHost implements PlayHost {
    key: HostSystem;

    name: string;

    private readonly initializer: HostInitializeFunction;

    /**
     * Local and remote capabilities combined
     */
    capabilities: Capabilities;

    log:Logger;

    frame: FrameService;

    private readonly completer: Completer<void>;

    get ready() {
        return this.completer.done;
    }

    constructor(key: HostSystem, details: PlayHostInfo) {
        const { name, initialize } = details;

        this.log = logger('host').child(key);

        this.key = key;
        this.name = name;
        this.initializer = initialize;
        this.capabilities = Capabilities.of();
        this.completer = Completer.create();

        this.frame = {
            state: {
                ready: true,
                isControlled: false,
                forceChoosePlay: false,
            },
        };
    }

    async initialize(appId: string, frame:FrameService): Promise<PlayHostInit> {
        this.frame = frame;
        const result = await this.initializer(appId);
        const { localCapabilities, remoteCapabilities } = result;

        this.capabilities = Capabilities.from(remoteCapabilities, localCapabilities);
        asserts({ remoteCapabilities, localCapabilities }, 'Init should have produced a value');

        this.completer.complete();

        return result;
    }

    hasCapability(key: string): boolean {
        return this.capabilities.contains(key);
    }

    async executeCapability<I, O>(capability: string, input: I): Promise<O> {
        this.log.info(`INVOKE ${capability}; input=`, input);

        const result = await this.capabilities.execute<I, O>(capability, input);

        this.log.info(`-> RESULT ${capability}; input=`, input, 'result=', result);

        return result;
    }

    async tryExecuteCapability<I, O>(capability: string, input: I): Promise<O | null> {
        this.log.info(`INVOKE ${capability}; input=`, input);

        const result = await this.capabilities.tryExecute<I, O>(capability, input);

        return result;
    }
}
