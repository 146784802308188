import { MutationTree } from 'vuex';
import {
    AccountInfo, AuthState, defaultRoleState, UserDetails,
} from '@/store/auth/types';
import { UserRoleType } from '@/generated/play-api';
import { LoadStatus } from '@/shared/shared.utils';

const mutations = <MutationTree<AuthState>>{
    SET_USER_DETAILS_FETCHED(state, value) {
        state.userDetailsFetched = value;
    },

    SET_APP_ID(state:AuthState, appId:string) {
        state.session ??= {};
        state.session.coreAppId = appId?.toLowerCase();
    },

    SET_COACH_CLIENT_ID(state: AuthState, appId:string) {
        state.session.coreAppId = appId?.toLowerCase();
    },

    START_LOADING_ROLES(state) {
        state.roles = {
            ...defaultRoleState,
            loadState: LoadStatus.loading,
        };
    },

    SET_USER_ROLES(state, roles:UserRoleType[]) {
        state.roles.roles = roles;
        state.roles.isAdmin = roles.includes(UserRoleType.ROLE_ADMIN);
        state.roles.isCoachAdmin = roles.some((role) => [UserRoleType.ROLE_COACH_ADMIN, UserRoleType.ROLE_ADMIN].includes(role));
        state.roles.isCoach = roles.some((role) => [UserRoleType.ROLE_COACH, UserRoleType.ROLE_COACH_ADMIN, UserRoleType.ROLE_ADMIN].includes(role));
        state.roles.loadState = LoadStatus.loaded;
    },

    CLEAR_ROLE_STATE(state) {
        state.roles = defaultRoleState;
    },

    SET_COACH_ID(state, coachId) {
        state.session ??= {};
        state.session.coachId = coachId;
    },

    SET_USER_DETAILS(state, userDetails: AccountInfo) {
        state.session ??= {};
        state.accounts = userDetails;
    },

    SESSION_START(state, { jwt, user }:{jwt:string, user:UserDetails}) {
        state.failed = false;
        state.user = user;
        state.accounts = null;
        state.hasEverLoggedIn = true;
        state.session ??= {};
        state.session.jwt = jwt;
    },

    SESSION_END(state) {
        state.failed = false;
        state.user = null;
        state.session ??= {};
        state.accounts = null;
        state.session.jwt = null;
        state.roles = defaultRoleState;
    },

    SESSION_FAIL(state) {
        state.failed = true;
        state.user = null;
        state.session ??= {};
        state.accounts = null;
        state.session.jwt = null;
    },

    SESSION_UNAUTHORIZED(state) {
        state.failed = true;
        state.user = null;
        state.accounts = null;
        state.session ??= {};
        state.session.jwt = null;
    },
};

export default mutations;
