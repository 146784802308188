<script lang="ts">export default { name: 'NestedRouter' }; </script>
<template>
    <RouterView v-if="keepAlive" v-slot="{ Component, ...params }">
        <KeepAlive :exclude="exclude">
            <component :is="Component" v-bind="params" />
        </KeepAlive>
    </RouterView>
    <RouterView v-else />
</template>

<script lang="ts" setup>
import { PageNames } from '@/shared/page-names';

withDefaults(defineProps<{
    keepAlive?: boolean;
    exclude?: string | string[]
}>(), {
    keepAlive: true,
    exclude: PageNames.PlayPage,
});
</script>
