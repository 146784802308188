import assertIn, { AssertionError } from 'assert';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';

import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import isBoolean from 'lodash/isBoolean';
import isDate from 'lodash/isDate';
import { toArray, typeNameOf } from '@/shared/type.utils';
import { KeyValues } from '@/types/core-types';

const assert = assertIn as (KeyValues & typeof assertIn);

Object.entries({
    isFunction, isObject, isArray, isString, isNumber, isBoolean, isDate,
}).forEach(([name, typeCheckFn]) => {
    const checkName = name.substring(2);

    assert[name] = (actual:unknown, allowNull = false) => {
        if (actual == null && allowNull) {
            return actual;
        }
        assertIn(typeCheckFn(actual), `Must be ${checkName} but was ${typeNameOf(actual)}`);

        return actual;
    };

    assert[`isNot${checkName}`] = (actual:unknown, allowNull = false) => {
        if (actual == null && allowNull) {
            return actual;
        }
        assertIn(actual != null || allowNull, 'Must not be null');
        assertIn(!typeCheckFn(actual), `Must not be ${checkName} but was ${typeNameOf(actual)}`);

        return actual;
    };
});

assert.load = () => {
    // empty
};

function debugPrintArray(array:unknown[]) {
    return `[${array.map((item) => `"${item}"`).join(', ')}]`;
}

assert.containsAll = function(actualInput:unknown, expectedInput:unknown, name:string, label:string) {
    const expected = toArray(expectedInput);
    const actual = toArray(actualInput);

    const difference = differenceWith(expected, actual, isEqual);

    if (difference.length > 0) {
        throw new AssertionError({
            message: `Expected ${name ?? typeNameOf(actual)} to contain all ${label ?? 'items'}: 
            \n - expected: ${debugPrintArray(expected)}\n - actual: ${debugPrintArray(actual)}\n - missing: ${debugPrintArray(difference)}`,
            /** The `actual` property on the error instance. */
            actual,
            /** The `expected` property on the error instance. */
            expected,
            /** The `operator` property on the error instance. */
            operator: 'contains,',
        });
    }
};

assert.containsKeys = function(actual:unknown, expected:unknown, name:string) {
    expected = toArray(expected, {
        object: Object.keys,
    });

    assert.containsAll(toArray(actual, { object: Object.keys }), expected, name, 'keys');
};

assert.containsValue = function (actual:unknown, expected:unknown, name:string) {
    assert.containsAll(toArray(actual, { object: Object.values }), toArray(expected), name, 'values');
};

assert.containsValues = assert.containsValue;
