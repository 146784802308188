<template>
    <li class="menu-list-heading">
        <slot>
            {{ label }}
        </slot>
    </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'MenuListHeading',
    inheritAttrs: false,
    props: {
        label: String,
    },
});
</script>

<style lang="scss" scoped>
    .menu-list-heading {
        --menu-list-heading-background-color: transparent;
        --menu-list-heading-text-color: #{$color-text-subtle};
        --menu-list-heading-padding-top: #{$spacing-100};

        padding: var(--menu-list-heading-padding-top) 1rem 0.75rem;
        font-size: $font-size-sm;
        line-height: $line-height-sm;
        text-decoration: none;
        user-select: none;
        background-color: var(--menu-list-heading-background-color);
        color: var(--menu-list-heading-text-color);
    }
</style>
