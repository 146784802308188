const FIRST_LETTER = /([A-Za-z])(.+)/;

export function firstLetterLower(input: string) {
    const [, first, rest] = FIRST_LETTER.exec(input);

    return first.toLowerCase() + rest;
}

export function firstLetterUpper(input: string) {
    const [, first, rest] = FIRST_LETTER.exec(input);

    return first.toUpperCase() + rest;
}
