import { Struct } from '@/types/core-types';

export type TutorialState = {
    users: Struct<PropertyKey, TutorialStatus>;
    app: TutorialStatus;
}

export type TutorialStatus = Struct<string, boolean>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function dict<D extends Struct<K, V>, K extends PropertyKey, V>(source?:any): D {
    return (source ?? {}) as D;
}
