import {
    inject, InjectionKey,
    onBeforeUnmount, provide, reactive, toRefs, watch,
} from 'vue';
import { KeyValues } from '@/types/core-types';
import { FrameService } from '@/integration/host-system-api';
import { executeFrameRequest, frameRequestTypes, isEmbedded } from '@/play-editor/frame';
import { expandToObject, illegalState } from '@/shared/shared.utils';
import { handleKeapEvents } from '@/play-editor/frame-handshake';
import { HostSystem } from '@/generated/play-api';

import { useCore } from '@/shared/shared-providers';
import { RouteName } from '@/router/route-names';
import { CoreProvide } from '@/shared/core-provide.types';
import { watchCalculated } from '@/play-editor/mixins/v3/computedReactive';

export const FrameServiceInjectKey: InjectionKey<FrameService> = Symbol('frameServiceProviderKey');

export function injectFrameService() {
    return inject(
        FrameServiceInjectKey,
        () => illegalState<FrameService>('Missing frame service!'),
        true,
    );
}

export function provideFrameService(core?: CoreProvide, doProvide = true): FrameService {
    const TARGET_ROUTES = [RouteName.embed, RouteName.plays, RouteName.compose, RouteName.playbook];

    const { log, store, router } = core ?? useCore();

    /**
     * Provides integration with a parent frame - used whenever this application is embedded into another frame.
     */

    const data = reactive({
        wrapperId: `copy-generator-${Date.now()}`,
        initialized: false,
        ready: false,
        state: 'Loading',
        isLaunchingPlay: false,
        isControlled: false,
        embedded: false,
        forceChoosePlay: false,
        capabilities: {},
        hostSystem: HostSystem.GENERAL,
        hostSystemName: null,
        supportedTypes: [],
        eventListener: null,
        modelProvider: null,
    });

    let frameProvider: FrameService;

    if (!isEmbedded()) {
        watchCalculated(data, {
            hostSystem: () => store.getters['auth/getHostSystem'],
            hostSystemName: () => store.getters['auth/getHostSystem']?.n,
        });
        data.ready = true;
        data.initialized = true;

        frameProvider = { state: data };
    } else {
        onBeforeUnmount(() => {
            window.removeEventListener('message', data.eventListener);
        });

        const FrameProvider = () => {
            const {
                embedded,
                forceChoosePlay,
                eventListener,
                hostSystem,
                hostSystemName,
                isControlled,
                ready,
                supportedTypes,
                capabilities,
            } = toRefs(data);

            async function initialize() {
                const currentRouteName = router.currentRoute.value.name;

                log.info('Initializing for: ', { route: currentRouteName });
                eventListener.value = handleKeapEvents(onMessage);
                window.addEventListener('message', eventListener.value);
                isControlled.value = isEmbedded();

                setTimeout(() => {
                    // if (!ready.value) {
                    //     log.warn('GIVING UP ON PARENT FRAME');
                    //     isControlled.value = false;
                    ready.value = true;
                    // }
                }, 0);

                log.info('Controlled: ', isControlled.value);

                switch (currentRouteName) {
                case RouteName.compose:
                    forceChoosePlay.value = true;
                    embedded.value = true;
                    break;
                default:
                    break;
                }

                const {
                    hostSystem: hostSystemQuery, sourceType, communicating,
                } = router.currentRoute.value.query ?? {};

                const extHostSystem = hostSystemQuery || sourceType;

                const hasToken = store.state.auth.session?.jwt;
                const appIdStore = store.state.auth.session?.coreAppId;
                const userIdStore = store.state.auth.user?.id;

                // if (communicating) {
                log.info('SENDING FRAME REQUEST');
                await sendReady();
                // }

                // The outer frame can pass query params instead of using frame communication.
                if (extHostSystem && hasToken && appIdStore && userIdStore) {
                    return handleInit({
                        hostSystem: extHostSystem,
                    });
                }

                /// Error state
                ready.value = true;

                return router.push({ name: RouteName.loggedOutEmbed, replace: true });
            }

            async function sendReady() {
                await executeFrameRequest(frameRequestTypes.ready);
                ready.value = true;
            }

            async function onMessage(keapRequestType: string, requestData: KeyValues) {
                switch (keapRequestType) {
                case frameRequestTypes.init:
                    log.info('AI Automation Assistant got init:', requestData);
                    await handleInit(requestData);
                    break;
                default:
                    log.info('On message', keapRequestType, requestData);
                    throw new Error(`Unknown keapRequestType: ${keapRequestType}`);
                }
            }

            async function handleInit(requestData: KeyValues): Promise<KeyValues[]> {
                const {
                    hostSystem: hostSystemParam,
                    hostSystemName: hostSystemNameParam,
                    capabilities: capabilitiesParam = [],
                    supportedTypes: supportedTypesParam = [],
                } = requestData;

                ready.value = true;
                hostSystem.value = hostSystemParam;
                hostSystemName.value = hostSystemNameParam ?? hostSystemParam;
                supportedTypes.value = supportedTypesParam;
                capabilities.value = expandToObject(capabilitiesParam, () => true);

                return Promise.resolve([]);
            }

            return { frameService: { state: data }, initialize };
        };

        const { frameService, initialize } = FrameProvider();

        frameProvider = frameService;

        watch(router.currentRoute, async () => {
            if (isEmbedded() && TARGET_ROUTES.includes(router.currentRoute.value.name as RouteName)) {
                if (!data.initialized) {
                    data.initialized = true;
                    await initialize();
                }
            } else if (router.currentRoute.value.name != null) {
                data.ready = true;
                data.initialized = true;
            }
        }, { immediate: true, deep: true });
    }

    if (doProvide) {
        provide(FrameServiceInjectKey, frameProvider);
        provide('frameServiceProvider', frameProvider);
    }

    return frameProvider;
}
