import { Struct } from '@/types/core-types';
import { dict } from '@/store/tutorials/types';

export type CapabilityInfo<I, O, M> = {
    handler: CapabilityHandler<I, O>;
    meta?: M;
}

/**
 * A capability handler is defined by it's input and output.  The generic args should match with the {@link Capability}
 */
export type CapabilityHandler<I, O> = (input: I) => Promise<O>;

export class Capabilities {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private readonly handlers: Struct<string, CapabilityInfo<any, any, any>>;

    static of():Capabilities {
        return new Capabilities();
    }

    static from(...capabilities: Array<Capabilities|null|undefined>):Capabilities {
        let base = Capabilities.of();

        for (const capability of capabilities) {
            if (capability != null) {
                base = base.merge(capability);
            }
        }

        return base;
    }

    private constructor() {
        this.handlers = dict();
    }

    /**
     * This function ensures that any handler registered will actually function with the correct input and output
     * parameters
     */
    register<I, O>(key: string, handler: CapabilityHandler<I, O>) {
        this.handlers[key] = {
            handler,
            meta: {},
        };
    }

    /**
     * This function ensures that any handler registered will actually function with the correct input and output
     * parameters
     */
    registerInfo<I, O, M>(key: string, handler: CapabilityInfo<I, O, M>) {
        this.handlers[key] = handler;
    }

    merge(other: Capabilities): Capabilities {
        const capabilities = Capabilities.of();

        Object.assign(capabilities.handlers, this.handlers);
        Object.assign(capabilities.handlers, other.handlers);

        return capabilities;
    }

    info<M>(key:string): M {
        const handler = this.handlers[key];

        return handler?.meta ?? {};
    }

    /**
     * Executes a capability.  If there is no capability registered, then undefined is returned
     *
     * @param key
     * @param input
     */
    tryExecute<I, O>(key: string, input: I): Promise<O>|undefined {
        const found = this.handlers[key];

        if (!found) {
            return undefined;
        }

        const handler = found.handler as CapabilityHandler<I, O>;

        return handler(input);
    }

    /**
     * Executes a capability.  If there is no capability registered, then an error is thrown.
     *
     * @param key
     * @param input
     */
    execute<I, O>(key: string, input: I): Promise<O> {
        const result = this.tryExecute<I, O>(key, input);

        if (result === undefined) {
            return Promise.reject(new Error(`No handler found for ${String(key)}`));
        }

        return result;
    }

    contains(key: string):boolean {
        return this.handlers[key] != null;
    }
}
