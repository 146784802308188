import { ModelSchema } from '@/generated/play-api';
import { KeyValues } from '@/types/core-types';
import { supply } from '@/shared/type.utils';
import { ComponentPublicInstance } from 'vue';

export function schemaI18n(instance: ComponentPublicInstance, schema: ModelSchema | null, $t?: (message: string, args?: KeyValues) => unknown) {
    const {
        modelType, article = 'a', title = '',
    } = schema ?? <KeyValues>{};

    const ogT = instance.$t.bind(instance);

    $t ??= (message: string, args?: KeyValues) => {
        return ogT(message, args);
    };

    function resolveOrDefaultSingle(code: string, defaultValue?: string): string {
        const resolved = $t(code)?.toString();

        if (resolved && resolved !== code) {
            return resolved?.toString();
        }

        return defaultValue;
    }

    function schemaCode({ prefix = 'models', suffix }: { prefix?: string, suffix?: string }): string {
        const code = [];

        if (prefix) code.push(prefix);
        if (modelType) code.push(...[modelType.category, modelType.name]);
        if (suffix) code.push(suffix);

        return code.join('.');
    }

    function getTitle() {
        return resolveOrDefaultSingle(schemaCode({ suffix: 'title' }), title)?.toLowerCase();
    }

    return {
        get addLabel(): string {
            const modelTitle = getTitle();

            return $t('models.add', { article, modelTitle })?.toString();
        },

        get chooseLabel(): string {
            const modelTitle = getTitle();

            return $t('models.choose', { article, modelTitle })?.toString();
        },
        get title() {
            return getTitle();
        },

        resolve({ prefix = 'models', suffix }: { prefix?: string, suffix?: string }, defaultValue?: string) {
            return resolveOrDefaultSingle(schemaCode({ prefix, suffix }), defaultValue);
        },
    };
}

export function resolveCode(
    t: (message: string, args?: KeyValues) => unknown,
    code:string,
    fallback?:(string|(()=>string)),
): string {
    const resolved = t(code)?.toString();

    if (resolved && resolved !== code) {
        return resolved?.toString();
    }

    return supply(fallback);
}
