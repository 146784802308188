/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    until, useAsyncState, UseAsyncStateOptions, UseAsyncStateReturn,
} from '@vueuse/core';
import { PlayStore } from '@/store/play-root-state';
import { watch } from 'vue';

/**
 * Behaves like {@link useAsyncState} but waits until the user is logged in before executing.
 */
export function useAsyncAuthState<Data, Shallow extends boolean = true>(store: PlayStore, promise: Promise<Data> | ((...args: any[]) => Promise<Data>), initialState: Data, options?: UseAsyncAuthStateOptions<Shallow>): UseAsyncStateReturn<Data, [], Shallow> {
    const initializer = useAsyncState<Data, [], Shallow>(promise, initialState, {
        ...options,
        immediate: false,
    });

    if (options?.refreshOnEachLogin !== false) {
        watch(() => store.getters['auth/isLoggedIn'], async (newValue, oldValue) => {
            if (newValue && !oldValue) {
                await initializer.execute();
            }
        }, { immediate: true });
    } else {
        void until(() => store.getters['auth/isLoggedIn'] === true).toBe(true).then(() => initializer.execute());
    }

    return initializer;
}

export type UseAsyncAuthStateOptions<Shallow extends boolean=true> = UseAsyncStateOptions<Shallow>&{
    refreshOnEachLogin?: boolean;
}
