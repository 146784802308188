import { ModelKey } from '@/integration/datastore/model-keys';
import { EventBusKey } from '@vueuse/core';

export enum ModelEventType {
    update, create, remove
}
export type ModelEvent = {
    eventType: ModelEventType;
    modelType: ModelKey;
    modelId?: string;
};

export const modelEventKey: EventBusKey<ModelEvent> = Symbol('modelEvent');
