import gql from 'graphql-tag';
import {
    Automation,
    AutomationInput,
    CreateAutomationMutation,
    CreateAutomationMutationVariables, GetAutomationNamesQuery,
} from '@/generated/keap-web-graphql';
import ApolloClient from 'apollo-boost';
import { createGraphClient } from '@/shared/graphql.util';

export enum TriggerActionType {
    add = 'add',
    remove = 'remove',
}

type TriggerModel = {
    id: string;
    type: string;
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    configJson: any;
    sourceId: string;
}

type StepModel = {
    id?: string;
    type?: string;
    name?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    configJson?: any,
}
export type CreateAutomation = {
    id?: string;
    name?: string;
    description?: string;
    removeTriggers?: TriggerModel[];
    triggers?: TriggerModel[];
    steps?: StepModel[];
};

type AutomationModel = {
    createdDate?: string;
    description?: string;
    enabledDate?: string;
    hasChanges?: boolean;
    id?: string;
    name?: string;
    status?: string;
    steps?: StepModel[];
    triggers?: TriggerModel[];
    removeTriggers?: TriggerModel[];
    updatedDate?: string;
}

export async function createAutomation(client: ApolloClient<unknown>, createAutomationPayload: CreateAutomation): Promise<AutomationModel> {
    const input = transformAutomationInput(createAutomationPayload);

    try {
        const automation = await persistAutomation(input);

        return transformAutomationOutput(automation);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error creating automation', e);
        throw e;
    }

    function transformAutomationOutput(automation: Automation): AutomationModel {
        if (automation == null) {
            return null;
        }
        const { steps = [], triggers = [] } = automation;

        return {
            ...automation,
            triggers: triggers.reduce((accumulator, trigger) => {
                // backend 'ADD' action triggers == frontend trigger list
                if (trigger.action === TriggerActionType.add) {
                    accumulator.push({
                        ...trigger,
                        configJson: trigger.configJson ? JSON.parse(trigger.configJson) : null,
                    });
                }

                return accumulator;
            }, []),
            removeTriggers: triggers.reduce((accumulator, trigger) => {
                // backend 'REMOVE' action triggers == frontend removeTrigger list
                if (trigger.action === TriggerActionType.remove) {
                    accumulator.push({
                        ...trigger,
                        configJson: trigger.configJson ? JSON.parse(trigger.configJson) : null,
                    });
                }

                return accumulator;
            }, []),
            steps: steps.map((step) => ({
                ...step,
                configJson: step.configJson ? JSON.parse(step.configJson) : null,
            })),
        };
    }

    function transformAutomationInput(automation: CreateAutomation): AutomationInput {
        if (automation == null) {
            return null;
        }

        const {
            steps = [], triggers = [], removeTriggers = [], ...rest
        } = automation;

        return {
            ...rest,
            triggers: [
                // backend accepts one trigger list (with different action types)
                ...triggers.map(({
                    id, type, name, configJson, sourceId,
                }) => ({
                    id,
                    type,
                    name,
                    action: TriggerActionType.add,
                    sourceId,
                    configJson: configJson ? JSON.stringify(configJson) : null,
                })),
                ...removeTriggers.map(({
                    id, type, name, configJson, sourceId,
                }) => ({
                    id,
                    type,
                    name,
                    action: TriggerActionType.remove,
                    sourceId,
                    configJson: configJson ? JSON.stringify(configJson) : null,
                })),
            ],
            steps: steps.map(({
                id, type, name, configJson,
            }, i) => ({
                id,
                type,
                name,
                sequenceNumber: i,
                configJson: configJson ? JSON.stringify(configJson) : null,
            })),
        };
    }

    async function persistAutomation(payload: AutomationInput): Promise<Automation> {
        const response = await client.mutate<CreateAutomationMutation, CreateAutomationMutationVariables>({
            mutation: gql`
                mutation createAutomation($payload: AutomationInput!) {
                    createAutomation(payload: $payload) {
                        id
                    }
                }
            `,
            variables: {
                payload,
            },
        });

        return response.data.createAutomation;
    }
}

export async function getAutomationNames(appId:string): Promise<string[]> {
    const response = await createGraphClient(appId).query<GetAutomationNamesQuery>({
        query: gql`
            query getAutomationNames {
                automationList(limit: 2000, tags: []) {
                    name
                }
            }
        `,
        variables: {
        },
        fetchPolicy: 'no-cache',
    });

    return response.data.automationList.map(({ name }) => name);
}
