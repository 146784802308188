/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Statuses for Checklists:
 * * WAITING: The checklist is in the playbook, but not currently in the checklist
 * * ACTIVE: The checklist is visible to the user, but not yet complete
 * * COMPLETE: All items were completed
 * * ARCHIVED: All items were completed, and the checklist is hidden
 */
export enum ChecklistStatus {
    WAITING = 'WAITING',
    ACTIVE = 'ACTIVE',
    COMPLETE = 'COMPLETE',
    ARCHIVED = 'ARCHIVED',
}
