<template>
    <div>
        <div class="welcome-wrapper">
            <div class="welcome-message">
                <span data-qa="title" class="title">
                    {{ $t('title') }}
                </span>

                <span class="subtitle">
                    {{ $t('subtitle') }}
                </span>

                <span class="subtitle">
                    {{ $t('refreshMessage') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'NotLoggedInEmbed',
    inheritAttrs: false,

};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .access-denied-notice {
        padding: $spacing-100;
    }

    .welcome-wrapper {
        display: flex;
        align-items: center;
    }

    .welcome-message {
        flex: 4;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: $spacing-600;
    }

    .title {
        font-family: $font-family-secondary-title;
        font-weight: $font-weight-bold;
        font-size: calc(32rem / 16);
        margin-bottom: $spacing-200;

        @media ($extra-small) {
            font-size: calc(28rem / 16);
        }
    }

    .subtitle {
        color: $color-text-subtle;
        font-size: $font-size-md;
        margin-bottom: $spacing-200;
    }

    .notice-container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
    }

    .notice-card {
        display: flex;
        flex-direction: column;
        margin: $spacing-400;
    }
</style>

<i18n lang="yaml">
en-us:
    title: Please Refresh
    subtitle: AI Automation Assistant has detected some items that are out of date.
    refreshMessage: Try refreshing the page, or coming back later

</i18n>
