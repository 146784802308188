/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiPromptMessage } from '../models/AiPromptMessage';
import type { AiResponse } from '../models/AiResponse';
import type { CreateAdhocChecklistItemTemplateRequest } from '../models/CreateAdhocChecklistItemTemplateRequest';
import type { CreateChecklistItemTemplateResponse } from '../models/CreateChecklistItemTemplateResponse';
import type { ExtendPlayTemplate } from '../models/ExtendPlayTemplate';
import type { GenerateSuggestionsRequest } from '../models/GenerateSuggestionsRequest';
import type { ModelSchema } from '../models/ModelSchema';
import type { PublishVersionRequest } from '../models/PublishVersionRequest';
import type { PushPlayTemplateChecklistRequest } from '../models/PushPlayTemplateChecklistRequest';
import type { UpdateChecklistItemTemplate } from '../models/UpdateChecklistItemTemplate';
import type { UpdateChecklistTemplateResponse } from '../models/UpdateChecklistTemplateResponse';
import type { UpdatePlayTemplateRequest } from '../models/UpdatePlayTemplateRequest';
import type { V2CreatePlayRequest } from '../models/V2CreatePlayRequest';
import type { V3CreateBlankPlayTemplateRequest } from '../models/V3CreateBlankPlayTemplateRequest';
import type { V3CreatePlayTemplateAssetInput } from '../models/V3CreatePlayTemplateAssetInput';
import type { V3CreatePlayTemplateSequenceInput } from '../models/V3CreatePlayTemplateSequenceInput';
import type { V3CreatePreview } from '../models/V3CreatePreview';
import type { V3CreateSequenceAssetResponse } from '../models/V3CreateSequenceAssetResponse';
import type { V3CreateShareRequest } from '../models/V3CreateShareRequest';
import type { V3MergeRequest } from '../models/V3MergeRequest';
import type { V3MergeResponse } from '../models/V3MergeResponse';
import type { V3PlayChecklistTemplate } from '../models/V3PlayChecklistTemplate';
import type { V3PlayTags } from '../models/V3PlayTags';
import type { V3PlayTemplate } from '../models/V3PlayTemplate';
import type { V3PlayTemplateAccess } from '../models/V3PlayTemplateAccess';
import type { V3PlayTemplateAsset } from '../models/V3PlayTemplateAsset';
import type { V3PlayTemplateInfo } from '../models/V3PlayTemplateInfo';
import type { V3PlayTemplateSectionDetails } from '../models/V3PlayTemplateSectionDetails';
import type { V3PlayTemplateSequence } from '../models/V3PlayTemplateSequence';
import type { V3PlayTemplateShare } from '../models/V3PlayTemplateShare';
import type { V3SequenceStart } from '../models/V3SequenceStart';
import type { V3TimerStep } from '../models/V3TimerStep';
import type { V3UpdatePlayTemplateAssetRequest } from '../models/V3UpdatePlayTemplateAssetRequest';
import type { V3UpdatePlayTemplateSequenceRequest } from '../models/V3UpdatePlayTemplateSequenceRequest';
import type { V3UserTemplates } from '../models/V3UserTemplates';
import type { ValidationResult } from '../models/ValidationResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PlayTemplateService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Generate PlayTemplate Section Content
     * Generates content for a play template asset section
     * @param playTemplateId the ID of the play this asset belongs to.
     * @param templateAssetId the ID of the asset we are updating.
     * @param sectionId the ID of the section we are generating for
     * @param requestBody The answers for a play
     * @returns string Success
     * @throws ApiError
     */
    public generatePlayTemplateSectionContent(
        playTemplateId: string,
        templateAssetId: string,
        sectionId: string,
        requestBody: any,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/coach/templates/{playTemplateId}/assets/{templateAssetId}/sections/{sectionId}/content',
            path: {
                'playTemplateId': playTemplateId,
                'templateAssetId': templateAssetId,
                'sectionId': sectionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Generate PlayTemplate Section Prompt
     * Gets a prompt for a PlaySection
     * @param playTemplateId the ID of the play this asset belongs to.
     * @param templateAssetId the ID of the asset we are updating.
     * @param sectionId the ID of the section we are generating for
     * @param requestBody The answers for a play
     * @returns AiPromptMessage Success
     * @throws ApiError
     */
    public generatePlayTemplateSectionPrompt(
        playTemplateId: string,
        templateAssetId: string,
        sectionId: string,
        requestBody: any,
    ): CancelablePromise<Array<AiPromptMessage>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/coach/templates/{playTemplateId}/assets/{templateAssetId}/sections/{sectionId}/prompt',
            path: {
                'playTemplateId': playTemplateId,
                'templateAssetId': templateAssetId,
                'sectionId': sectionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * List UserTemplates
     * @returns V3UserTemplates listUserTemplates 200 response
     * @throws ApiError
     */
    public listUserTemplates(): CancelablePromise<V3UserTemplates> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/play-templates',
        });
    }

    /**
     * Get Model Details
     * @returns ModelSchema getModelDetails 200 response
     * @throws ApiError
     */
    public getModelDetails(): CancelablePromise<Array<ModelSchema>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/play-templates/model-details',
        });
    }

    /**
     * Update PlayTemplate Section
     * Updates a prompt for a play section
     * @param sectionId The ID of the section to update
     * @param requestBody The updated prompt
     * @returns V3PlayTemplateSectionDetails Success
     * @throws ApiError
     */
    public updatePlayTemplateSection(
        sectionId: string,
        requestBody: V3PlayTemplateSectionDetails,
    ): CancelablePromise<V3PlayTemplateSectionDetails> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v3/play-templates/sections/{sectionId}/',
            path: {
                'sectionId': sectionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get Shared Templates
     * @returns V3PlayTemplateAccess getSharedTemplates 200 response
     * @throws ApiError
     */
    public getSharedTemplates(): CancelablePromise<Array<V3PlayTemplateAccess>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/play-templates/shared',
        });
    }

    /**
     * Convert to Template
     * Clones a published version of an artifact
     * @param playId
     * @param tenant
     * @returns V3PlayTemplateInfo Success
     * @throws ApiError
     */
    public convertToTemplate(
        playId: string,
        tenant: string,
    ): CancelablePromise<V3PlayTemplateInfo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/play-templates/{playId}/convert/{tenant}',
            path: {
                'playId': playId,
                'tenant': tenant,
            },
        });
    }

    /**
     * Push Checklist
     * Pushes new checklist elements
     * @param playTemplateId
     * @param requestBody
     * @returns V3PlayChecklistTemplate Success
     * @throws ApiError
     */
    public pushChecklist(
        playTemplateId: string,
        requestBody: PushPlayTemplateChecklistRequest,
    ): CancelablePromise<V3PlayChecklistTemplate> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v3/play-templates/{playTemplateId}/checklistTemplate',
            path: {
                'playTemplateId': playTemplateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Clone
     * Clones a published version of an artifact
     * @param playTemplateId
     * @param requestBody
     * @returns V3PlayTemplate Success
     * @throws ApiError
     */
    public clone(
        playTemplateId: string,
        requestBody: ExtendPlayTemplate,
    ): CancelablePromise<V3PlayTemplate> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/play-templates/{playTemplateId}/clones',
            path: {
                'playTemplateId': playTemplateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update PlayTemplateSequence
     * Updates content template
     * @param playTemplateId
     * @param contentTemplateId
     * @param sequenceId
     * @param requestBody
     * @returns V3PlayTemplateSequence Success
     * @throws ApiError
     */
    public updatePlayTemplateSequence(
        playTemplateId: string,
        contentTemplateId: string,
        sequenceId: string,
        requestBody: V3UpdatePlayTemplateSequenceRequest,
    ): CancelablePromise<V3PlayTemplateSequence> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v3/play-templates/{playTemplateId}/contentTemplate/{contentTemplateId}/sequences/{sequenceId}',
            path: {
                'playTemplateId': playTemplateId,
                'contentTemplateId': contentTemplateId,
                'sequenceId': sequenceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Publish Version
     * Publishes a new version
     * @param playTemplateId
     * @param requestBody
     * @returns string Success
     * @throws ApiError
     */
    public publishVersion(
        playTemplateId: string,
        requestBody?: PublishVersionRequest,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/play-templates/{playTemplateId}/versions',
            path: {
                'playTemplateId': playTemplateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get PlayTemplate
     * @param templateId
     * @returns V3PlayTemplate getPlayTemplate 200 response
     * @throws ApiError
     */
    public getPlayTemplate(
        templateId: string,
    ): CancelablePromise<V3PlayTemplate> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/play-templates/{templateId}',
            path: {
                'templateId': templateId,
            },
        });
    }

    /**
     * Update PlayTemplate Details
     * Updates content for a single play
     * @param templateId the ID of the template
     * @param requestBody The updates to be made
     * @returns V3PlayTemplateInfo Success
     * @throws ApiError
     */
    public updatePlayTemplateDetails(
        templateId: string,
        requestBody: UpdatePlayTemplateRequest,
    ): CancelablePromise<V3PlayTemplateInfo> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v3/play-templates/{templateId}',
            path: {
                'templateId': templateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete PlayTemplate
     * Deletes a Play Template record
     * @param templateId The ID of the Play to delete
     * @returns any Success
     * @throws ApiError
     */
    public deletePlayTemplate(
        templateId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v3/play-templates/{templateId}',
            path: {
                'templateId': templateId,
            },
        });
    }

    /**
     * Add PlayTemplateAsset
     * Adds asset to a play template
     * @param templateId the ID of the template
     * @param requestBody The new asset details
     * @returns V3PlayTemplateAsset Success
     * @throws ApiError
     */
    public addPlayTemplateAsset(
        templateId: string,
        requestBody: V3CreatePlayTemplateAssetInput,
    ): CancelablePromise<V3PlayTemplateAsset> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/play-templates/{templateId}/assets',
            path: {
                'templateId': templateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Resort PlayTemplateAssets
     * Changes the sort order for items in this checklist template
     * @param templateId The ID of the Play containing the ChecklistItem being updated
     * @param requestBody The sorted list of Asset ids
     * @returns number Success
     * @throws ApiError
     */
    public resortPlayTemplateAssets(
        templateId: string,
        requestBody: Array<string>,
    ): CancelablePromise<Record<string, number>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/play-templates/{templateId}/assets/sortOrders',
            path: {
                'templateId': templateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update PlayTemplateAsset
     * Updates an asset
     * @param templateId the ID of the template
     * @param assetId the ID of the asset
     * @param requestBody The updates to be made
     * @returns V3PlayTemplateAsset Success
     * @throws ApiError
     */
    public updatePlayTemplateAsset(
        templateId: string,
        assetId: string,
        requestBody: V3UpdatePlayTemplateAssetRequest,
    ): CancelablePromise<V3PlayTemplateAsset> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v3/play-templates/{templateId}/assets/{assetId}',
            path: {
                'templateId': templateId,
                'assetId': assetId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete PlayTemplateAsset
     * Deletes a play template asset
     * @param templateId the ID of the template
     * @param assetId the ID of the asset
     * @returns any Success
     * @throws ApiError
     */
    public deletePlayTemplateAsset(
        templateId: string,
        assetId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v3/play-templates/{templateId}/assets/{assetId}',
            path: {
                'templateId': templateId,
                'assetId': assetId,
            },
        });
    }

    /**
     * Create Checklist Item Template
     * Add an item to the checklist for this play
     * @param templateId The ID of the Play containing the ChecklistItem being updated
     * @param requestBody The details used to update this ChecklistItem
     * @returns CreateChecklistItemTemplateResponse Success
     * @throws ApiError
     */
    public createChecklistItemTemplate(
        templateId: string,
        requestBody: CreateAdhocChecklistItemTemplateRequest,
    ): CancelablePromise<CreateChecklistItemTemplateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/play-templates/{templateId}/checklistTemplate/items',
            path: {
                'templateId': templateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update Checklist Item Template
     * Updates a single ChecklistItem record
     * @param templateId The ID of the Play containing the ChecklistItem being updated
     * @param itemId The ID of the ChecklistItem being updated
     * @param requestBody The details used to update this ChecklistItem
     * @returns UpdateChecklistTemplateResponse Success
     * @throws ApiError
     */
    public updateChecklistItemTemplate(
        templateId: string,
        itemId: string,
        requestBody: UpdateChecklistItemTemplate,
    ): CancelablePromise<UpdateChecklistTemplateResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v3/play-templates/{templateId}/checklistTemplate/items/{itemId}',
            path: {
                'templateId': templateId,
                'itemId': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Checklist Item Template
     * Deletes a single ChecklistItem record
     * @param templateId The ID of the Play containing the ChecklistItem being deleted
     * @param itemId The ID of the ChecklistItem being deleted
     * @returns UpdateChecklistTemplateResponse Success
     * @throws ApiError
     */
    public deleteChecklistItemTemplate(
        templateId: string,
        itemId: string,
    ): CancelablePromise<UpdateChecklistTemplateResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v3/play-templates/{templateId}/checklistTemplate/items/{itemId}',
            path: {
                'templateId': templateId,
                'itemId': itemId,
            },
        });
    }

    /**
     * Resort Checklist Item Templates
     * Changes the sort order for items in this checklist template
     * @param templateId The ID of the Play containing the ChecklistItem being updated
     * @param requestBody The sorted list of ChecklistItem ids
     * @returns number Success
     * @throws ApiError
     */
    public resortChecklistItemTemplates(
        templateId: string,
        requestBody: Array<string>,
    ): CancelablePromise<Record<string, number>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/play-templates/{templateId}/checklistTemplate/sortOrders',
            path: {
                'templateId': templateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Add PlayTemplateSequence
     * Adds sequence to a play template
     * @param templateId the ID of the template
     * @param requestBody The new asset details
     * @returns V3PlayTemplateSequence Success
     * @throws ApiError
     */
    public addPlayTemplateSequence(
        templateId: string,
        requestBody: V3CreatePlayTemplateSequenceInput,
    ): CancelablePromise<V3PlayTemplateSequence> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/play-templates/{templateId}/sequences',
            path: {
                'templateId': templateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete PlayTemplateSequence
     * Adds sequence to a play template
     * @param templateId the ID of the template
     * @param sequenceId the ID of the sequence to delete
     * @returns any Success
     * @throws ApiError
     */
    public deletePlayTemplateSequence(
        templateId: string,
        sequenceId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v3/play-templates/{templateId}/sequences/{sequenceId}',
            path: {
                'templateId': templateId,
                'sequenceId': sequenceId,
            },
        });
    }

    /**
     * Add Sequence Step New Asset
     * Adds asset to a play template
     * @param templateId the ID of the template
     * @param sequenceId the ID of the sequence
     * @param requestBody The new asset details
     * @returns V3CreateSequenceAssetResponse Success
     * @throws ApiError
     */
    public addSequenceStepNewAsset(
        templateId: string,
        sequenceId: string,
        requestBody: V3CreatePlayTemplateAssetInput,
    ): CancelablePromise<V3CreateSequenceAssetResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/play-templates/{templateId}/sequences/{sequenceId}/asset-steps',
            path: {
                'templateId': templateId,
                'sequenceId': sequenceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Add SequenceStart
     * Adds a start to a play template
     * @param templateId the ID of the template
     * @param sequenceId the ID of the sequence
     * @param requestBody The new asset details
     * @returns V3SequenceStart Success
     * @throws ApiError
     */
    public addSequenceStart(
        templateId: string,
        sequenceId: string,
        requestBody: V3SequenceStart,
    ): CancelablePromise<V3SequenceStart> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/play-templates/{templateId}/sequences/{sequenceId}/starts',
            path: {
                'templateId': templateId,
                'sequenceId': sequenceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Add Sequence Step Timer
     * Adds asset to a play template
     * @param sequenceId the ID of the sequence
     * @param requestBody The new asset details
     * @returns V3TimerStep Success
     * @throws ApiError
     */
    public addSequenceStepTimer(
        sequenceId: string,
        requestBody: V3TimerStep,
    ): CancelablePromise<V3TimerStep> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/play-templates/{templateId}/sequences/{sequenceId}/timer-steps',
            path: {
                'sequenceId': sequenceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * List PlayTemplate Tags
     * Returns the categories and tags for all PlayTemplates
     * @returns V3PlayTags Success
     * @throws ApiError
     */
    public listPlayTemplateTags(): CancelablePromise<V3PlayTags> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/public/play-categories',
        });
    }

    /**
     * List PlayTemplates Public
     * Lists all available play templates
     * @returns V3PlayTemplateInfo Success
     * @throws ApiError
     */
    public listPlayTemplatesPublic(): CancelablePromise<Array<V3PlayTemplateInfo>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/public/play-templates',
        });
    }

    /**
     * Get PlayTemplate 1
     * Retrieves a single PlayTemplate by ID, without any merging
     * @param id
     * @returns V3PlayTemplate Success
     * @throws ApiError
     */
    public getPlayTemplate1(
        id: string,
    ): CancelablePromise<V3PlayTemplate> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/public/play-templates/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update Share
     * @param tenantId
     * @param requestBody
     * @returns V3PlayTemplateShare updateShare 200 response
     * @throws ApiError
     */
    public updateShare(
        tenantId: string,
        requestBody: V3PlayTemplateShare,
    ): CancelablePromise<V3PlayTemplateShare> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v3/tenant/{tenantId}/share',
            path: {
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Create Share
     * @param tenantId
     * @param requestBody
     * @returns V3PlayTemplateShare createShare 200 response
     * @throws ApiError
     */
    public createShare(
        tenantId: string,
        requestBody: V3CreateShareRequest,
    ): CancelablePromise<V3PlayTemplateShare> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/tenant/{tenantId}/share',
            path: {
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Access Link
     * @param accessId
     * @param tenantId
     * @returns boolean deleteAccessLink 200 response
     * @throws ApiError
     */
    public deleteAccessLink(
        accessId: string,
        tenantId: string,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v3/tenant/{tenantId}/share/access/{accessId}',
            path: {
                'accessId': accessId,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * List Shares for Template
     * @param templateId
     * @param tenantId
     * @returns V3PlayTemplateShare listSharesForTemplate 200 response
     * @throws ApiError
     */
    public listSharesForTemplate(
        templateId: string,
        tenantId: string,
    ): CancelablePromise<Array<V3PlayTemplateShare>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/tenant/{tenantId}/share/template/{templateId}',
            path: {
                'templateId': templateId,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * Click Access Link
     * @param accessLinkId
     * @param tenantId
     * @returns V3PlayTemplateAccess clickAccessLink 200 response
     * @throws ApiError
     */
    public clickAccessLink(
        accessLinkId: string,
        tenantId: string,
    ): CancelablePromise<V3PlayTemplateAccess> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/tenant/{tenantId}/share/{accessLinkId}',
            path: {
                'accessLinkId': accessLinkId,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * Delete Share
     * @param tenantId
     * @param shareId
     * @returns any deleteShare 200 response
     * @throws ApiError
     */
    public deleteShare(
        tenantId: string,
        shareId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v3/tenant/{tenantId}/share/{shareId}',
            path: {
                'tenantId': tenantId,
                'shareId': shareId,
            },
        });
    }

    /**
     * Create PlayTemplate
     * Add a blank play template
     * @param tenant The tenant calling the endpoint
     * @param requestBody The details used to create this PlayTemplate
     * @returns V3PlayTemplateInfo Success
     * @throws ApiError
     */
    public createPlayTemplate(
        tenant: string,
        requestBody: V3CreateBlankPlayTemplateRequest,
    ): CancelablePromise<V3PlayTemplateInfo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/tenant/{tenant}/play-templates',
            path: {
                'tenant': tenant,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Merge Content Blocks
     * Merge one or more content sections
     * @param tenant The tenant ID
     * @param requestBody Merge request data
     * @returns V3MergeResponse Success
     * @throws ApiError
     */
    public mergeContentBlocks(
        tenant: string,
        requestBody: V3MergeRequest,
    ): CancelablePromise<Record<string, V3MergeResponse>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/tenant/{tenant}/play-templates/merge/execute',
            path: {
                'tenant': tenant,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Merge Test
     * Merge a single content block
     * @param tenant The tenant ID
     * @param requestBody Merge payload
     * @returns V3MergeResponse Success
     * @throws ApiError
     */
    public mergeTest(
        tenant: string,
        requestBody: any,
    ): CancelablePromise<V3MergeResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/tenant/{tenant}/play-templates/merge/test',
            path: {
                'tenant': tenant,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get PlayTemplate Merged
     * Retrieves a single PlayTemplate by ID, merging any dynamic values
     * @param tenant The tenant calling the endpoint
     * @param id The primary key for the template
     * @param requestBody The answers used to merge the result
     * @returns V3PlayTemplate A PlayTemplate, merged with answers
     * @throws ApiError
     */
    public getPlayTemplateMerged(
        tenant: string,
        id: string,
        requestBody?: any,
    ): CancelablePromise<V3PlayTemplate> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/tenant/{tenant}/play-templates/{id}',
            path: {
                'tenant': tenant,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `The PlayTemplate was not found`,
            },
        });
    }

    /**
     * Generate Suggestions for PlaySection
     * Generates suggestion for a single PlaySection content block
     * @param tenant The tenant calling the endpoint
     * @param playTemplateId the ID of the play being generated for
     * @param assetKey the asset key containing the section we are generating for
     * @param sectionKey the sectionKey for the section to generate suggestions for
     * @param requestBody
     * @returns AiResponse Success
     * @throws ApiError
     */
    public generateSuggestionsForPlaySection(
        tenant: string,
        playTemplateId: string,
        assetKey: string,
        sectionKey: string,
        requestBody: GenerateSuggestionsRequest,
    ): CancelablePromise<AiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/tenant/{tenant}/play-templates/{playTemplateId}/assets/{assetKey}/sections/{sectionKey}/suggestions',
            path: {
                'tenant': tenant,
                'playTemplateId': playTemplateId,
                'assetKey': assetKey,
                'sectionKey': sectionKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Create Play From Template
     * Create Play from template
     * @param tenant The tenant calling the endpoint
     * @param playTemplateId The PlayTemplate used to clone the new Play
     * @param requestBody Answers to Play questions
     * @returns any Success
     * @throws ApiError
     */
    public createPlayFromTemplate(
        tenant: string,
        playTemplateId: string,
        requestBody?: V2CreatePlayRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/tenant/{tenant}/play-templates/{playTemplateId}/plays',
            path: {
                'tenant': tenant,
                'playTemplateId': playTemplateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Create Play Preview
     * Creates a play, using data from another tenant
     * @param tenant The tenant calling the endpoint
     * @param playTemplateId The PlayTemplate used to clone the new Play
     * @param requestBody Details for creating the Play
     * @returns any Success
     * @throws ApiError
     */
    public createPlayPreview(
        tenant: string,
        playTemplateId: string,
        requestBody: V3CreatePreview,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/tenant/{tenant}/play-templates/{playTemplateId}/previews',
            path: {
                'tenant': tenant,
                'playTemplateId': playTemplateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Generates defaultValue
     * Generates the defaultValue for a single Play property
     * @param tenant The tenant calling the endpoint
     * @param playTemplateId the ID of the play being generated for
     * @param questionId the question being generated
     * @param requestBody Parameters for generating the default value
     * @returns AiResponse The default values
     * @throws ApiError
     */
    public generateDefaultValueForPlay(
        tenant: string,
        playTemplateId: string,
        questionId: string,
        requestBody: GenerateSuggestionsRequest,
    ): CancelablePromise<AiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/tenant/{tenant}/play-templates/{playTemplateId}/properties/{questionId}/defaultValue',
            path: {
                'tenant': tenant,
                'playTemplateId': playTemplateId,
                'questionId': questionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Generate Suggestions for Play
     * Generates suggestions for a single Play question.
     * @param tenant The tenant calling the endpoint
     * @param playTemplateId the ID of the play being generated for
     * @param questionId the propertyId being generated
     * @param requestBody Details of the request
     * @returns AiResponse Success
     * @throws ApiError
     */
    public generateSuggestionsForPlay(
        tenant: string,
        playTemplateId: string,
        questionId: string,
        requestBody: GenerateSuggestionsRequest,
    ): CancelablePromise<AiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/tenant/{tenant}/play-templates/{playTemplateId}/properties/{questionId}/suggestions',
            path: {
                'tenant': tenant,
                'playTemplateId': playTemplateId,
                'questionId': questionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Validate Play Question
     * Validates a single play question
     * @param tenant The tenant calling the endpoint
     * @param playTemplateId the ID of the play being generated for
     * @param requestBody the answers to use for validation
     * @returns ValidationResult Success
     * @throws ApiError
     */
    public validatePlayQuestion(
        tenant: string,
        playTemplateId: string,
        requestBody: any,
    ): CancelablePromise<ValidationResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/tenant/{tenant}/play-templates/{playTemplateId}/validate',
            path: {
                'tenant': tenant,
                'playTemplateId': playTemplateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
