<template>
    <AccountSwitcher v-if="!embedded" :is-open="bool(accountSwitcher.state.isOpen)" :show-close="null">
        <div>
            <div class="welcome-wrapper">
                <div class="welcome-message">
                    <span class="title" data-qa="title">
                        {{ t('title') }}
                    </span>

                    <span class="subtitle">
                        {{ t('subtitle') }}
                    </span>
                    <DsInlineAlert type="critical" class="access-denied-notice">
                        <i18nt keypath="accessDenied.body" tag="p">
                            <template #link>
                                <a href="/logout" rel="noopener">
                                    {{ t('accessDenied.link') }}
                                </a>
                            </template>
                        </i18nt>
                    </DsInlineAlert>
                    <div class="current-user">
                        {{ t('accessDenied.current', {email: store.state.auth.user.username}) }}
                    </div>
                    <DsButton v-if="!accountSwitcher.state.isOpen" @click="accountSwitcher.open(false)">
                        {{ t('accessDenied.selectAccount') }}
                    </DsButton>
                </div>
            </div>
        </div>
    </AccountSwitcher>
    <NotLoggedInEmbed v-else />
</template>

<script lang="ts">export default { name: 'AccessDeniedPage', inheritAttrs: false }; </script>
<script lang="ts" setup>
import { isEmbedded } from '@/play-editor/frame';
import NotLoggedInEmbed from '@/shared/components/pages/NotLoggedInEmbed.vue';
import AccountSwitcher from '@/shared/components/AccountSwitcher.vue';
import { useCore } from '@/shared/shared-providers';
import { injectAccountSwitcher } from '@/shared/components/AccountSwitchProvider';
import { Translation } from 'vue-i18n';

const accountSwitcher = injectAccountSwitcher();
const {store, t} = useCore();
const embedded = isEmbedded();
const i18nt = defineComponent(Translation);

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .access-denied-notice {
        padding: $spacing-100;
        width: calc(500rem / 16);
    }

    .welcome-wrapper {
        display: flex;
        align-items: center;
        margin-right: calc(400rem / 16);
    }

    .welcome-message {
        flex: 4;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: $spacing-600;
    }

    .title {
        font-family: $font-family-secondary-title;
        font-weight: $font-weight-bold;
        font-size: calc(32rem / 16);
        margin-bottom: $spacing-200;

        @media ($extra-small) {
            font-size: calc(28rem / 16);
        }
    }

    .subtitle {
        color: $color-text-subtle;
        font-size: $font-size-md;
        margin-bottom: $spacing-200;
    }

    .current-user {
        margin: $spacing-200 0;
        font-style: italic;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Account access denied",
        "subtitle": "Uh oh, we have a small problem...",
        "showAccount": "Show account list"
    }
}
</i18n>
