/* eslint-disable import/no-webpack-loader-syntax */
import axios from 'axios';
import { store } from '@/store';
import { distinct } from '@/play-editor/play-utils';
import { addAcceptInteraction } from '@/client/play-client-ext';
import { get } from '@vueuse/core';
import { env } from '@/env';
import { useAppId } from '@/play-editor/provider/provide-app-id';
import { playClientTs } from '@/client/play-client';

export function PlayService(appIdProvider) {
    const PlayApi = {
        get baseUrl() {
            return env.VUE_APP_CONTENT_API_URL || 'https://keap-copy-generator-service.herokuapp.com';
        },

        get appId() {
            const appId = get(appIdProvider);
            const coreAppId = appId || store.state.auth.session.coreAppId;

            if (!coreAppId) {
                throw new Error('No appId!!');
            }

            return coreAppId;
        },
        url(path) {
            return `${PlayApi.baseUrl}/v2/tenant/${PlayApi.appId}${path}`;
        },

        publicUrl(path) {
            return `${PlayApi.baseUrl}/v2${path}`;
        },
    };

    async function createPlayFromTemplate(playTemplate, title = undefined, appId = undefined) {
        // const { data } = await axios.post(PlayApi.url(`/play-templates/${playTemplateId}/plays`), {});
        //
        // return data;
        return playClientTs.playTemplate.createPlayFromTemplate(appId || PlayApi.appId, playTemplate.id, {
            contentTrackingId: playTemplate.contentTrackingId,
            title,
        });
    }

    async function createPreviewFromTemplate({ playTemplateId, previewDataTenant }) {
        const { data } = await axios.post(PlayApi.url(`/play-templates/${playTemplateId}/previews`), {
            previewDataTenant,
        });

        return data;
    }

    async function generateDefaultValue({
        playTemplateId, questionId, initialResults = [], answers = {},
    }) {
        const { data: { responseData } } = await axios.post(PlayApi.url(`/play-templates/${playTemplateId}/properties/${questionId}/defaultValue`), {
            answers,
            initialResults,
        });

        return responseData;
    }

    async function generateSuggestedValue({
        playTemplateId, questionId, initialResults = [], answers = {},
        // eslint-disable-next-line no-unused-vars
        temperatureAdjustment = 0, previousSuggestions = [],
    }) {
        temperatureAdjustment ??= 0;
        initialResults ??= [];

        // Disabling for now because previous suggestions was creating runaway weirdness in teh results
        // initialResults.push(...previousSuggestions);
        initialResults = distinct(initialResults);
        const {
            data: {
                responseData, success, errorMessage, resultId,
            },
        } = await axios.post(PlayApi.url(`/play-templates/${playTemplateId}/properties/${questionId}/suggestion`), {
            answers,
            initialResults,
            adjustments: {
                // Snake case is because these are passed through the to the python service
                temperature_adjust: temperatureAdjustment,
            },
        });

        if (!success) {
            throw new Error(`Error executing suggestions: ${errorMessage}`);
        }

        return [responseData, resultId];
    }

    async function getUserInfo({ search, cursor }) {
        const { data } = await axios.get(PlayApi.publicUrl('/user'), { params: { search, cursor } });

        return data;
    }

    async function aiRephrase({ text }) {
        const { data: { responseData, resultId } } = await axios.post(PlayApi.url('/aiop/v2/rephraser/versions/v1'), {
            userInputs: {
                'Original Sentence': text,
            },
            resultVariables: {},
            adjustments: {},
        });

        if (Array.isArray(responseData)) {
            return responseData.join(', ');
        }

        return [responseData || '', resultId];
    }

    async function generateSectionSuggestedValue({
        playTemplateId,
        assetKey,
        sectionKey,
        answers = {},
        initialResults = [],
    }) {
        const { data: { responseData } } = await axios.post(
            PlayApi.url(`/play-templates/${playTemplateId}/assets/${assetKey}/sections/${sectionKey}/suggestions`),
            {
                answers,
                initialResults,
            },
        );

        return responseData;
    }

    async function savePlay({
        playId, title, answers, state,
    }) {
        const { data } = await axios.put(PlayApi.url(`/plays/${playId}`), {
            title,
            answers,
            state,
        });

        return data;
    }

    async function loadExpandedAnswers({
        playId, answers = {},
    }) {
        const { data } = await axios.post(PlayApi.url(`/plays/${playId}/expanded`), answers);

        return data;
    }

    async function getLaunchLinks({ playId }) {
        const { data: { data } } = await axios.get(PlayApi.url(`/plays/${playId}/launchLinks`));

        return data;
    }

    async function updateLaunchLinks({
        playId, launchLinks = [], deletedSourceIds = [],
    }) {
        const { data: { data } } = await axios.put(PlayApi.url(`/plays/${playId}/launchLinks`), {
            links: launchLinks,
            deletedSourceIds,
        });

        return data;
    }

    async function validatePlay({ playTemplateId, answers }) {
        const { data: { errors = [] } } = await axios.post(PlayApi.url(`/play-templates/${playTemplateId}/validate`), answers);

        // noinspection UnnecessaryLocalVariableJS
        const flattened = errors.reduce((prev, error) => {
            if (!prev[error.questionId]) {
                prev[error.questionId] = [];
            }
            prev[error.questionId].push(error);

            return prev;
        }, {});

        return flattened;
    }

    async function deletePlay({ playId }) {
        const { data } = await axios.delete(PlayApi.url(`/plays/${playId}`));

        return data;
    }

    async function testMerge({ template, params }) {
        const { data } = await axios.post(PlayApi.url('/play-templates/merge/test'), {
            template, data: params,
        });

        return data;
    }

    async function executeMergeMulti({
        templates, params, playId, modelId, refType,
    }) {
        const { data } = await axios.post(PlayApi.url('/play-templates/merge/execute'), {
            templates,
            data: params,
            playId,
            modelId,
            refType,
            expand: true,
        });

        return data;
    }

    async function executeMerge({
        template, params, playId, modelId = null, refType = null,
    }) {
        const { single } = await executeMergeMulti({
            templates: {
                single: template,
            },
            params,
            playId,
            modelId,
            refType,
        });

        return single;
    }

    async function listPlays() {
        const { data: { plays = [] } } = await axios.get(PlayApi.url('/plays'));

        // for now, hide plays with no content
        return plays.filter((p) => p.contentMode !== 'NONE');
    }

    async function listPlayTemplates() {
        const { data } = await axios.get(PlayApi.url('/play-templates'));

        // for now, hide play templates with no content
        return data.filter((pt) => pt.assets != null);
    }

    async function listSampleData() {
        const { data } = await axios.get(PlayApi.publicUrl('/sample-data-sets'));

        return data;
    }

    async function listCategories() {
        const { data } = await axios.get(PlayApi.url('/play-categories'));

        return data;
    }

    async function loadPlayTemplate({ playTemplateId, answers = {} }) {
        const { data } = await axios.post(PlayApi.url(`/play-templates/${playTemplateId}`), answers);

        return data;
    }

    async function loadPlay({ playId }) {
        const { data } = await axios.get(PlayApi.url(`/plays/${playId}`));

        return data;
    }

    async function loadThemes() {
        const { data: { themes = {} } } = await axios.get(PlayApi.publicUrl('/elements/meta'));

        return themes;
    }

    async function loadTagMeta() {
        const { data: { tags = {} } } = await axios.get(PlayApi.publicUrl('/elements/meta'));

        return tags;
    }

    async function loadAssetDetails({ assetId, playId }) {
        const { data } = await axios.get(PlayApi.url(`/plays/${playId}/assets/${assetId}`));

        return data;
    }

    async function saveAsset({
        assetId, playId, sections, assetData, themeId,
    }) {
        const { title, description, sortOrder } = assetData;
        const assetPut = {
            title,
            description,
            sortOrder,
            sections,
            themeId,
        };
        const { data } = await axios.put(PlayApi.url(`/plays/${playId}/assets/${assetId}`), assetPut);

        return data;
    }

    return {
        withAppId(newAppIdProvider) {
            return PlayService(newAppIdProvider);
        },
        saveAsset,
        loadAssetDetails,
        savePlay,
        updateLaunchLinks,
        getLaunchLinks,
        createPreviewFromTemplate,
        deletePlay,
        loadPlay,
        loadThemes,
        generateSuggestedValue,
        loadTagMeta,
        addAcceptInteraction: (resultId, acceptedItem, change) => addAcceptInteraction(PlayApi.appId, resultId, acceptedItem, change),
        aiRephrase,
        generateSectionSuggestedValue,
        generateDefaultValue,
        getUserInfo,
        createPlayFromTemplate,
        listPlays,
        loadExpandedAnswers,
        listPlayTemplates,
        loadPlayTemplate,
        testMerge,
        executeMergeMulti,
        executeMerge,
        validatePlay,
        listSampleData,
        listCategories,
    };
}

export const playServiceDefault = PlayService();

export function injectPlayService(appId = null) {
    return PlayService(appId ?? useAppId());
}
