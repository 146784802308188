<template>
    <div>
        <BodyClickHandler :enabled="isOpenInternal" @click="onBodyClick">
            <div class="tooltip-container">
                <div
                    ref="reference"
                    class="tooltip-reference"
                    @mouseover="onMouseover"
                    @mouseleave="onMouseleave"
                    @click="onClick"
                >
                    <slot name="reference" />
                </div>

                <section
                    ref="tooltip"
                    class="tooltip"
                    :class="[{ open: isOpenInternal, disabled, wrap }]"
                >
                    <div data-popper-arrow class="arrow" />
                    <slot />
                </section>
            </div>
        </BodyClickHandler>
    </div>
</template>

<script>
import { createPopper } from '@popperjs/core';

export default {
    name: 'PlayTooltip',
    props: {
        isOpen: {
            type: Boolean,
            default: null,
        },

        /**
         * Position of the tooltip relative to its reference
         */
        position: {
            type: String,
            default: 'bottom',
            options: ['top', 'right', 'bottom', 'left'],
        },

        /**
         * Boolean to be able to disable a tooltip from being able to open
         */
        disabled: Boolean,

        /**
         * Boolean to force tooltips to wrap with max width
         */
        wrap: Boolean,
    },
    emits: ['close'],

    data() {
        return {
            isOpenInternal: false,
            popperJs: null,
        };
    },

    computed: {
        isControlled() {
            return this.isOpen === true || this.isOpen === false;
        },
    },

    watch: {
        isOpen: {
            handler(isOpen) {
                if (isOpen) {
                    this.open();
                } else {
                    this.close();
                }
            },
            immediate: true,
        },
    },

    mounted() {
        this.popperJs = createPopper(this.$refs.reference, this.$refs.tooltip, {
            placement: this.position,
            modifiers: [
                {
                    name: 'eventListeners',
                    options: {
                        scroll: true,
                        resize: true,
                    },
                },
                {
                    name: 'preventOverflow',
                    options: {
                        tether: true,
                        boundary: 'viewport',
                    },
                },
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
            ],
        });
    },

    beforeUnmount() {
        if (this.popperJs && typeof this.popperJs.destroy === 'function') {
            this.popperJs.destroy();
        }

        this.popperJs = null;
    },

    methods: {
        open() {
            this.isOpenInternal = true;

            if (!this.popperJs) {
                return;
            }

            this.popperJs.update();
        },

        close() {
            this.isOpenInternal = false;

            if (!this.popperJs) {
                return;
            }

            this.popperJs.update();
        },

        onMouseover() {
            if (!this.isControlled) {
                this.open();
            }
        },

        onMouseleave() {
            if (!this.isControlled) {
                this.close();
            }
        },

        onClick() {
            if (!this.isControlled) {
                this.open();
            }
        },

        onBodyClick() {
            if (this.isControlled) {
                this.$emit('close');
            } else {
                this.close();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .tooltip-container {
        display: inline-block;
        position: relative;
    }

    .tooltip-reference {
        display: inline-block;
    }

    .tooltip {
        @include popover($tooltip-background-color);

        color: $tooltip-color;
        font-weight: $font-weight-semibold;
        text-align: center;
        white-space: nowrap;
        padding: $spacing-200;

        @media ($small) {
            border-radius: $border-radius;
        }

        &.wrap {
            width: var(--tooltip-width, #{$tooltip-width});
            white-space: normal;
        }

        &.disabled {
            visibility: hidden;
        }
    }
</style>
