import { loadServerElementDefinitions } from '@/shared/custom-element-definitions';
import { Play, playClientTs } from '@/client/play-client';
import {
    assetTypeMeta,
    ExportDestinationKey,
    PlayEventTypes,
    SectionTypes,
} from '@/play-editor/play.constants';
import { generateExportContent } from '@/shared/clipboard';
import { exportDestinations } from '@/play-editor/play.exporters';
import { amplitude } from '@/analytics/amplitude';
import { LaunchPlayMeta, PlayLaunchInput, PlayLaunchResult } from '@/integration/capabilities/capability-types';
import { PlayHost } from '@/integration/host-system-api';
import { KeyValues } from '@/types/core-types';
import asserts from '@/shared/asserts';
import { Capability } from '@/integration/capabilities/capability-keys';

export async function launchPlayHelper(host: PlayHost, play: Play, playTemplateId: string, startAnswers: KeyValues): Promise<PlayLaunchResult> {
    const { exportRules = {} } = host.capabilities.info<LaunchPlayMeta>(Capability.launchPlay);
    const { log } = host;
    const { id: playId, assets = [] } = play;

    asserts(play.tenantId != null, 'Play must have a tenantId');
    const appId = play.tenantId;
    const expandedAnswers = await playClientTs.play.expandPlayAnswers(appId, playId, startAnswers);
    const playTemplate = await playClientTs.playTemplateV2.getPlayTemplateV2(appId, playTemplateId, expandedAnswers);

    // / This helps us process content;
    await loadServerElementDefinitions();

    log.info('Expanded answers', expandedAnswers);
    log.info('Play template', playTemplate);
    vset(play, 'launchLinks', (await playClientTs.playContent.getPlayLaunchLinks(appId, playId)).data);
    // Get all section content for assets;

    const loadedAssets = await Promise.all(assets.map((asset) => playClientTs.playContent.loadPlayAssetDetails(play.tenantId, playId, asset.id)));

    for (const loadedAsset of loadedAssets) {
        const assetTypeExport = exportRules[loadedAsset.assetType];
        const assetHandler = assetTypeMeta[loadedAsset.assetType];

        asserts(assetHandler, `Missing asset handler for type ${loadedAsset.assetType}`);
        const reducedSections = assetHandler.sectionReducer(loadedAsset.sections);

        reducedSections.forEach((section) => {
            const { sectionType, content } = section;
            let updatedContent;

            switch (sectionType) {
            case SectionTypes.text:
            case SectionTypes.textBody:
                updatedContent = generateExportContent(content, exportDestinations[ExportDestinationKey.text]);
                break;
            case SectionTypes.richTextBody:
                updatedContent = generateExportContent(content, exportDestinations[assetTypeExport || ExportDestinationKey.html]);
                break;
            default:
                updatedContent = generateExportContent(content, exportDestinations[ExportDestinationKey.html]);
                break;
            }

            section.content = updatedContent;
        });
        loadedAsset.sections = reducedSections;
    }

    vset(play, 'assets', loadedAssets);

    log.info('SOURCE PLAY before sending:', play);

    await amplitude.logEvent(PlayEventTypes.PLAY_AUTOMATION_LAUNCHED, {
        'Play Template ID': playTemplateId,
        'Play Template Name': playTemplate.title,
    });

    const success = await host.executeCapability<PlayLaunchInput, PlayLaunchResult>(Capability.launchPlay, {
        play,
        playTemplate,
        answers: expandedAnswers,
    });

    log.info('Launched play!', success);
    const { launchLinks } = success;

    const { playContent } = playClientTs;
    const updatedLaunchLinks = await playContent.updatePlayLaunchLinks(appId, playId, { links: launchLinks, deletedSourceIds: [] });

    vset(play, 'launchLinks', updatedLaunchLinks.data ?? []);

    log.info('Updated launchLinks!', updatedLaunchLinks);

    return success;
}
