import { HostSystem } from '@/generated/play-api';
import { PlayHost } from '@/integration/host-system-api';

import { MaxClassicHost } from '@/integration/hosts/max-classic';
import { PlayWebGenericHost } from '@/integration/hosts/play-web-generic';
import { KeapWebHost } from '@/integration/hosts/keap-web';

export const HostSystems: Record<string, PlayHost> = {
    [HostSystem.GENERAL]: PlayWebGenericHost,
    [HostSystem.KEAP_WEB]: KeapWebHost,
    [HostSystem.CLASSIC]: MaxClassicHost,
    keap: KeapWebHost,
    KEAP: KeapWebHost,
    'max-classic': MaxClassicHost,
};
