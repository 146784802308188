/* eslint-disable import/no-unresolved,@typescript-eslint/no-explicit-any */

import Avatar from '@infusionsoft/design-system/src/components/Avatar/Avatar.vue';
import Button from '@infusionsoft/design-system/src/components/Button/Button.vue';
import ButtonToggle from '@infusionsoft/design-system/src/components/Button/ButtonToggle.vue';
import ButtonToggleOption from '@infusionsoft/design-system/src/components/Button/ButtonToggleOption.vue';
import FilledButton from '@infusionsoft/design-system/src/components/Button/FilledButton.vue';
import IconButton from '@infusionsoft/design-system/src/components/Button/IconButton.vue';
import OutlineButton from '@infusionsoft/design-system/src/components/Button/OutlineButton.vue';
import TextButton from '@infusionsoft/design-system/src/components/Button/TextButton.vue';
import Card from '@infusionsoft/design-system/src/components/Card/Card.vue';
import CardBody from '@infusionsoft/design-system/src/components/Card/CardBody.vue';
import CardFooter from '@infusionsoft/design-system/src/components/Card/CardFooter.vue';
import CardHeader from '@infusionsoft/design-system/src/components/Card/CardHeader.vue';
import Checkbox from '@infusionsoft/design-system/src/components/Checkbox/Checkbox.vue';
import CheckboxGroup from '@infusionsoft/design-system/src/components/Checkbox/CheckboxGroup.vue';
import Chip from '@infusionsoft/design-system/src/components/Chip/Chip.vue';
import Collapsible from '@infusionsoft/design-system/src/components/Collapsible/Collapsible.vue';
import ColorField from '@infusionsoft/design-system/src/components/Color/ColorField.vue';
import ColorPicker from '@infusionsoft/design-system/src/components/Color/ColorPicker.vue';
import Saturation from '@infusionsoft/design-system/src/components/Color/Saturation.vue';
import DatePicker from '@infusionsoft/design-system/src/components/DateTime/DatePicker.vue';
import BodyClickHandler from '@infusionsoft/design-system/src/components/Dom/BodyClickHandler.vue';
import MediaQuery from '@infusionsoft/design-system/src/components/Dom/MediaQuery.vue';
import Dropdown from '@infusionsoft/design-system/src/components/Dropdown/Dropdown.vue';
import DropdownMenu from '@infusionsoft/design-system/src/components/Dropdown/DropdownMenu.vue';
import TouchOverlay from '@infusionsoft/design-system/src/components/Dropdown/TouchOverlay.vue';
import Editable from '@infusionsoft/design-system/src/components/Editable/Editable.vue';
import EditableText from '@infusionsoft/design-system/src/components/Editable/EditableText.vue';
import ErrorBanner from '@infusionsoft/design-system/src/components/ErrorBanner/ErrorBanner.vue';
import ErrorBannerContainer from '@infusionsoft/design-system/src/components/ErrorBanner/ErrorBannerContainer.vue';
import Guide from '@infusionsoft/design-system/src/components/Guide/Guide.vue';
import HtmlPreview from '@infusionsoft/design-system/src/components/HtmlPreview/HtmlPreview.vue';
import Icon from '@infusionsoft/design-system/src/components/Icon/Icon.vue';
import SvgIcon from '@infusionsoft/design-system/src/components/Icon/SvgIcon.vue';
import Illustration from '@infusionsoft/design-system/src/components/Illustration/Illustration.vue';
import InfiniteScroll from '@infusionsoft/design-system/src/components/InfiniteScroll/InfiniteScroll.vue';
import InlineAlert from '@infusionsoft/design-system/src/components/InlineAlert/InlineAlert.vue';
import InlineAlertFooterLink from '@infusionsoft/design-system/src/components/InlineAlert/InlineAlertFooterLink.vue';
import InputField from '@infusionsoft/design-system/src/components/InputField/InputField.vue';
import Link from '@infusionsoft/design-system/src/components/Link/Link.vue';
import ListItem from '@infusionsoft/design-system/src/components/ListItem/ListItem.vue';
import Logo from '@infusionsoft/design-system/src/components/Logo/Logo.vue';
import MenuItemSubtext from '@infusionsoft/design-system/src/components/Menu/MenuItemSubtext.vue';
import ConfirmDialog from '@infusionsoft/design-system/src/components/Modal/ConfirmDialog.vue';
import ConfirmDialogContainer from '@infusionsoft/design-system/src/components/Modal/ConfirmDialogContainer.vue';
import Modal from '@infusionsoft/design-system/src/components/Modal/Modal.vue';
import ModalHeader from '@infusionsoft/design-system/src/components/Modal/ModalHeader.vue';
import ModalHeaderTitle from '@infusionsoft/design-system/src/components/Modal/ModalHeaderTitle.vue';
import MultiSelectMenuItem from '@infusionsoft/design-system/src/components/Multiselect/MultiSelectMenuItem.vue';
import Multiselect from '@infusionsoft/design-system/src/components/Multiselect/Multiselect.vue';
import MultiselectMenu from '@infusionsoft/design-system/src/components/Multiselect/MultiselectMenu.vue';
import FocusOnKeyboardOnly from '@infusionsoft/design-system/src/components/Page/FocusOnKeyboardOnly.vue';
import PhoneInput from '@infusionsoft/design-system/src/components/PhoneInput/PhoneInput.vue';
import Placeholder from '@infusionsoft/design-system/src/components/Placeholder/Placeholder.vue';
import Popover from '@infusionsoft/design-system/src/components/Popover/Popover.vue';
import ProgressBar from '@infusionsoft/design-system/src/components/ProgressBar/ProgressBar.vue';
import PulseGraphic from '@infusionsoft/design-system/src/components/PulseGraphic/PulseGraphic.vue';
import Radio from '@infusionsoft/design-system/src/components/Radio/Radio.vue';
import RadioGroup from '@infusionsoft/design-system/src/components/Radio/RadioGroup.vue';
import SelectField from '@infusionsoft/design-system/src/components/SelectField/SelectField.vue';
import Spinner from '@infusionsoft/design-system/src/components/Spinner/Spinner.vue';
import Status from '@infusionsoft/design-system/src/components/Status/Status.vue';
import TableGrid from '@infusionsoft/design-system/src/components/TableGrid/TableGrid.vue';
import TableGridCell from '@infusionsoft/design-system/src/components/TableGrid/TableGridCell.vue';
import TableGridColumn from '@infusionsoft/design-system/src/components/TableGrid/TableGridColumn.vue';
import TableGridHeader from '@infusionsoft/design-system/src/components/TableGrid/TableGridHeader.vue';
import TableGridTrowser from '@infusionsoft/design-system/src/components/TableGrid/TableGridTrowser.vue';
import Tab from '@infusionsoft/design-system/src/components/Tabs/Tab.vue';
import TabHeader from '@infusionsoft/design-system/src/components/Tabs/TabHeader.vue';
import TabHeaderItem from '@infusionsoft/design-system/src/components/Tabs/TabHeaderItem.vue';
import TabHeaderLink from '@infusionsoft/design-system/src/components/Tabs/TabHeaderLink.vue';
import TabLinks from '@infusionsoft/design-system/src/components/Tabs/TabLinks.vue';
import Tabset from '@infusionsoft/design-system/src/components/Tabs/Tabset.vue';
import TextHighlighter from '@infusionsoft/design-system/src/components/Text/TextHighlighter.vue';
import TextAreaField from '@infusionsoft/design-system/src/components/TextAreaField/TextAreaField.vue';
import Toast from '@infusionsoft/design-system/src/components/Toast/Toast.vue';
import ToastContainer from '@infusionsoft/design-system/src/components/Toast/ToastContainer.vue';
import ToastGroup from '@infusionsoft/design-system/src/components/Toast/ToastGroup.vue';
import ToggleSwitch from '@infusionsoft/design-system/src/components/ToggleSwitch/ToggleSwitch.vue';
import Tooltip from '@infusionsoft/design-system/src/components/Tooltip/Tooltip.vue';
import Trowser from '@infusionsoft/design-system/src/components/Trowser/Trowser.vue';
import FileUploadInput from '@infusionsoft/design-system/src/components/Uploads/FileUploadInput.vue';

export const DsAvatar = Avatar;
export const DsButton = Button;
export const DsButtonToggle = ButtonToggle;
export const DsButtonToggleOption = ButtonToggleOption;
export const DsFilledButton = FilledButton;
export const DsIconButton = IconButton;
export const DsOutlineButton = OutlineButton;
export const DsTextButton = TextButton;
export const DsCard = Card;
export const DsCardBody = CardBody;
export const DsCardFooter = CardFooter;
export const DsCardHeader = CardHeader;
export const DsCheckbox = Checkbox;
export const DsCheckboxGroup = CheckboxGroup;
export const DsChip = Chip;
export const DsCollapsible = Collapsible;
export const DsColorField = ColorField;
export const DsColorPicker = ColorPicker;
export const DsSaturation = Saturation;
export const DsDatePicker = DatePicker;
export const DsBodyClickHandler = BodyClickHandler;
export const DsMediaQuery = MediaQuery;
export const DsDropdown = Dropdown;
export const DsDropdownMenu = DropdownMenu;
export const DsTouchOverlay = TouchOverlay;
export const DsEditable = Editable;
export const DsEditableText = EditableText;
export const DsErrorBanner = ErrorBanner;
export const DsErrorBannerContainer = ErrorBannerContainer;
export const DsGuide = Guide;
export const DsHtmlPreview = HtmlPreview;
export const DsIcon = Icon;
export const DsSvgIcon = SvgIcon;
export const DsIllustration = Illustration;
export const DsInfiniteScroll = InfiniteScroll;
export const DsInlineAlert = InlineAlert;
export const DsInlineAlertFooterLink = InlineAlertFooterLink;
export const DsInputField = InputField;
export const DsLink = Link;
export const DsListItem = ListItem;
export const DsLogo = Logo;
export const DsMenuItemSubtext = MenuItemSubtext;
export const DsConfirmDialog = ConfirmDialog;
export const DsConfirmDialogContainer = ConfirmDialogContainer;
export const DsModal = Modal;
export const DsModalHeader = ModalHeader;
export const DsModalHeaderTitle = ModalHeaderTitle;
export const DsMultiSelectMenuItem = MultiSelectMenuItem;
export const DsMultiselect = Multiselect;
export const DsMultiselectMenu = MultiselectMenu;
export const DsFocusOnKeyboardOnly = FocusOnKeyboardOnly;
export const DsPhoneInput = PhoneInput;
export const DsPlaceholder = Placeholder;
export const DsPopover = Popover;
export const DsProgressBar = ProgressBar;
export const DsPulseGraphic = PulseGraphic;
export const DsRadio = Radio;
export const DsRadioGroup = RadioGroup;
export const DsSelectField = SelectField;
export const DsSpinner = Spinner;
export const DsStatus = Status;
export const DsTableGrid = TableGrid;
export const DsTableGridCell = TableGridCell;
export const DsTableGridColumn = TableGridColumn;
export const DsTableGridHeader = TableGridHeader;
export const DsTableGridTrowser = TableGridTrowser;
export const DsTab = Tab;
export const DsTabHeader = TabHeader;
export const DsTabHeaderItem = TabHeaderItem;
export const DsTabHeaderLink = TabHeaderLink;
export const DsTabLinks = TabLinks;
export const DsTabset = Tabset;
export const DsTextHighlighter = TextHighlighter;
export const DsTextAreaField = TextAreaField;
export const DsToast = Toast;
export const DsToastContainer = ToastContainer;
export const DsToastGroup = ToastGroup;
export const DsToggleSwitch = ToggleSwitch;
export const DsTooltip = Tooltip;
export const DsTrowser = Trowser;
export const DsFileUploadInput = FileUploadInput;

export default {
    DsAvatar: Avatar,
    DsButton: Button,
    DsButtonToggle: ButtonToggle,
    DsButtonToggleOption: ButtonToggleOption,
    DsFilledButton: FilledButton,
    DsIconButton: IconButton,
    DsOutlineButton: OutlineButton,
    DsTextButton: TextButton,
    DsCard: Card,
    DsCardBody: CardBody,
    DsCardFooter: CardFooter,
    DsCardHeader: CardHeader,
    DsCheckbox: Checkbox,
    DsCheckboxGroup: CheckboxGroup,
    DsChip: Chip,
    DsCollapsible: Collapsible,
    DsColorField: ColorField,
    DsColorPicker: ColorPicker,
    DsSaturation: Saturation,
    DsDatePicker: DatePicker,
    DsBodyClickHandler: BodyClickHandler,
    DsMediaQuery: MediaQuery,
    DsDropdown: Dropdown,
    DsDropdownMenu: DropdownMenu,
    DsTouchOverlay: TouchOverlay,
    DsEditable: Editable,
    DsEditableText: EditableText,
    DsErrorBanner: ErrorBanner,
    DsErrorBannerContainer: ErrorBannerContainer,
    DsGuide: Guide,
    DsHtmlPreview: HtmlPreview,
    DsIcon: Icon,
    DsSvgIcon: SvgIcon,
    DsIllustration: Illustration,
    DsInfiniteScroll: InfiniteScroll,
    DsInlineAlert: InlineAlert,
    DsInlineAlertFooterLink: InlineAlertFooterLink,
    DsInputField: InputField,
    DsLink: Link,
    DsListItem: ListItem,
    DsLogo: Logo,
    DsMenuItemSubtext: MenuItemSubtext,
    DsConfirmDialog: ConfirmDialog,
    DsConfirmDialogContainer: ConfirmDialogContainer,
    DsModal: Modal,
    DsModalHeader: ModalHeader,
    DsModalHeaderTitle: ModalHeaderTitle,
    DsMultiSelectMenuItem: MultiSelectMenuItem,
    DsMultiselect: Multiselect,
    DsMultiselectMenu: MultiselectMenu,
    DsFocusOnKeyboardOnly: FocusOnKeyboardOnly,
    DsPhoneInput: PhoneInput,
    DsPlaceholder: Placeholder,
    DsPopover: Popover,
    DsProgressBar: ProgressBar,
    DsPulseGraphic: PulseGraphic,
    DsRadio: Radio,
    DsRadioGroup: RadioGroup,
    DsSelectField: SelectField,
    DsSpinner: Spinner,
    DsStatus: Status,
    DsTableGrid: TableGrid,
    DsTableGridCell: TableGridCell,
    DsTableGridColumn: TableGridColumn,
    DsTableGridHeader: TableGridHeader,
    DsTableGridTrowser: TableGridTrowser,
    DsTab: Tab,
    DsTabHeader: TabHeader,
    DsTabHeaderItem: TabHeaderItem,
    DsTabHeaderLink: TabHeaderLink,
    DsTabLinks: TabLinks,
    DsTabset: Tabset,
    DsTextHighlighter: TextHighlighter,
    DsTextAreaField: TextAreaField,
    DsToast: Toast,
    DsToastContainer: ToastContainer,
    DsToastGroup: ToastGroup,
    DsToggleSwitch: ToggleSwitch,
    DsTooltip: Tooltip,
    DsTrowser: Trowser,
    DsFileUploadInput: FileUploadInput,
};
import { KeapIcon } from '@/generated/play-api';
import '@/assets/wand.svg';

export const components = [
    Avatar,
    Button,
    ButtonToggle,
    ButtonToggleOption,
    FilledButton,
    IconButton,
    OutlineButton,
    TextButton,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Checkbox,
    CheckboxGroup,
    Chip,
    Collapsible,
    ColorField,
    ColorPicker,
    Saturation,
    DatePicker,
    BodyClickHandler,
    MediaQuery,
    Dropdown,
    DropdownMenu,
    TouchOverlay,
    Editable,
    EditableText,
    ErrorBanner,
    ErrorBannerContainer,
    Guide,
    HtmlPreview,
    Icon,
    SvgIcon,
    Illustration,
    InfiniteScroll,
    InlineAlert,
    InlineAlertFooterLink,
    InputField,
    Link,
    ListItem,
    Logo,
    MenuItemSubtext,
    ConfirmDialog,
    ConfirmDialogContainer,
    Modal,
    ModalHeader,
    ModalHeaderTitle,
    MultiSelectMenuItem,
    Multiselect,
    MultiselectMenu,
    FocusOnKeyboardOnly,
    PhoneInput,
    Placeholder,
    Popover,
    ProgressBar,
    PulseGraphic,
    Radio,
    RadioGroup,
    SelectField,
    Spinner,
    Status,
    TableGrid,
    TableGridCell,
    TableGridColumn,
    TableGridHeader,
    TableGridTrowser,
    Tab,
    TabHeader,
    TabHeaderItem,
    TabHeaderLink,
    TabLinks,
    Tabset,
    TextHighlighter,
    TextAreaField,
    Toast,
    ToastContainer,
    ToastGroup,
    ToggleSwitch,
    Tooltip,
    Trowser,
    FileUploadInput,
];

export const icons = ['upload-cloud', ...Object.values(KeapIcon).filter((e) => e !== KeapIcon.WAND)];

export const illustrations = [
    'activity-color',
    'activity-gray',
    'bank-color',
    'bank-gray',
    'calendar-color',
    'calendar-gray',
    'call-forwarding-color',
    'call-forwarding-gray',
    'campaign-report-color',
    'campaign-report-gray',
    'campaigns-color',
    'campaigns-gray',
    'checkboxes-color',
    'checkboxes-gray',
    'combine-contact-sources-color',
    'combine-contact-sources-gray',
    'completed-tasks-color',
    'completed-tasks-gray',
    'contacts-color',
    'contacts-gray',
    'custom-campaign-color',
    'custom-campaign-gray',
    'custom-fields-color',
    'custom-fields-gray',
    'download-app-color',
    'download-app-gray',
    'download-contacts-color',
    'download-contacts-gray',
    'download-contacts-success-color',
    'download-contacts-success-gray',
    'email-broadcasts-color',
    'email-broadcasts-gray',
    'export-color',
    'export-gray',
    'fast-followup-color',
    'fast-followup-gray',
    'followup-color',
    'followup-gray',
    'form-appointment-color',
    'form-appointment-gray',
    'form-checkout-color',
    'form-checkout-gray',
    'form-internal-color',
    'form-internal-gray',
    'form-public-gray',
    'form-public-color',
    'happy-browser-color',
    'happy-browser-gray',
    'import-contacts-color',
    'import-contacts-gray',
    'leads-color',
    'leads-gray',
    'location-lookup-color',
    'location-lookup-gray',
    'messenger-color',
    'messenger-gray',
    'money-color',
    'money-gray',
    'new-contact-color',
    'new-contact-gray',
    'notes-color',
    'notes-gray',
    'oops-color',
    'oops-gray',
    'phone-number-ready-color',
    'phone-number-ready-gray',
    'pipeline-color',
    'pipeline-gray',
    'promote-color',
    'promote-gray',
    'sad-calendar-color',
    'sad-calendar-gray',
    'scan-contact-color',
    'scan-contact-gray',
    'segment-color',
    'segment-gray',
    'setup-messenger-color',
    'setup-messenger-gray',
    'success-confetti-color',
    'success-confetti-gray',
    'success-high-five-color',
    'success-high-five-gray',
    'success-sent-color',
    'success-sent-gray',
    'success-thumbs-up-color',
    'success-thumbs-up-gray',
    'successful-payment-color',
    'successful-payment-gray',
    'tasks-color',
    'tasks-gray',
    'three-emails-color',
    'three-emails-gray',
    'untranscribable-image-color',
    'untranscribable-image-gray',
    'upload-color',
    'upload-gray',
    'voice-messenger-color',
    'voice-messenger-gray',
];

export { DesignSystem } from '@infusionsoft/design-system';
