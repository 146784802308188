import {
    Struct,
    KeyValues,
    PropertyKey,
    Dict,
    Predicate,
    InstanceOrIdentity,
    IdentityOrUpdate
} from "@/types/core-types";
import {groupBy} from "lodash";
import {remapEntries} from "@/shared/shared.utils";
import _get from "lodash/get";


export type Accessor<T, V> = keyof T | ((source: T) => V);
export type Getter<T, V> = ((source: T) => V);


export function toGetter<T, V>(accessor: Accessor<T, V>): Getter<T, V> {
    if (typeof accessor === 'string') {
        return (t: T) => (t as KeyValues)[accessor as string] as V;
    } else {
        return accessor as Getter<T, V>;
    }
}


declare global {
    interface String {
        truncate(amount: number, onTruncate?: string): string;
        clipMiddle(amount: number): string;
    }

    interface Array<T> {
        distinct(using?: Accessor<T, unknown>): Array<T>;

        without(toRemove: T[] | T | Predicate<T>): Array<T>;

        groupBy<V extends PropertyKey>(accessor: Accessor<T, V>): Dict<T[]>;

        keyed<K extends PropertyKey>(mapper: Accessor<T, K>): Struct<K, T>;

        associateKeys<K extends PropertyKey, V>(mapper: Accessor<T, V>): Struct<K, V>;

        replaceWhere(filter: Predicate<T>, replacement: InstanceOrIdentity<T>): Array<T>;

        reduceMap<R>(reduceFn: ((prev: R, next: T, index?: number) => R | undefined| void), initial: R): R;

        random(): T | null;

        max(by?: (a: T, b: T) => number): T | null;

        min(by?: (a: T, b: T) => number): T | null;

        lastIndex: number;

        /**
         * Returns a copy of this array, with null/undefined values removed
         */
        filterNotNull(): T[];
    }
}

if(!String.prototype['truncate']) {
    Object.defineProperties(String.prototype, {
        truncate: {
            writable: false,
            value: function (amount: number, onTruncate?: string) {
                if (this.length > amount) {
                    return `${this.substring(0, amount)}${onTruncate ?? ''}`;
                } else {
                    return this;
                }
            }

        }
    });


    Object.defineProperties(String.prototype, {
        clipMiddle: {
            writable: false,
            value: function (this:string, amount: number) {
                if (this.length > amount + 3) {
                    const trimAmt = (amount - 3) / 2;
                    return `${this.substring(0, trimAmt)}...${this.substring(this.length - trimAmt)}`;
                } else {
                    return this;
                }
            }

        }
    });


    const ignorePromise = {
        ignore: {
            value: function <T>(this: Promise<T>): void {
                this.then(() => {
                });
            },
            writable: false,
        }
    };

// noinspection JSIgnoredPromiseFromCall
    Object.defineProperties(Promise.prototype, ignorePromise);

    Object.defineProperties(Array.prototype, {

        lastIndex: {
            get<T>(this: T[]) {
                return this.length - 1;
            },
        },

        filterNotNull: {
            writable: false,

            value: function <T>(this: T[]): T[] {
                return this.filter((t) => t != null);
            },
        },

        groupBy: {
            writable: false,

            value: function <T, V extends string | symbol>(this: T[], accessor: Accessor<T, V>): Dict<T[]> {
                return groupBy(this, toGetter(accessor)) as Dict<T[]>;
            },
        },

        random: {
            writable: false,
            value: function <T>(this: T[]): T | null {
                switch (this.length) {
                    case 0:
                        return null;
                    case 1:
                        return this[0];
                    default: {
                        const idx = Math.floor(Math.random() * this.length);
                        return this[idx];
                    }
                }
            }
        },

        min: {
            writable: false,
            value: function <T>(this: T[], by?: (a: T, b: T) => number): T | null {
                by ??= (a, b) => {
                    return a < b ? 1 : a > b ? -1 : 0;
                }
                return this.sort(by)[0];
            }
        },

        max: {
            writable: false,
            value: function <T>(this: T[], by?: (a: T, b: T) => number): T | null {
                by ??= (a, b) => {
                    return a < b ? 1 : a > b ? -1 : 0;
                }
                return this.sort((a, b) => -by(a, b))[0];
            }
        },

        keyed: {
            writable: false,
            value: function <K extends string | number | symbol, T extends KeyValues>(this: T[], key?: Accessor<T, K>): Record<K, T> {
                const getter = toGetter(key);

                return (this ?? []).reduce((prev, next) => {
                    const key = getter(next);
                    prev[key] = next;

                    return prev;
                }, <Record<K, T>>{});
            }
        },

        distinct: {
            value: function <T extends KeyValues>(this: T[], using?: Accessor<T, any>) {

                if (!this) return [];

                const filterFn = using ? (value: any, index: number, self: any[]) => {
                    const getter = toGetter(using);
                    const valueUsing = getter(value);

                    return self.findIndex((item) => getter(item) === valueUsing) === index;
                } : distinctFilter;

                return this.filter(filterFn);
            },
            writable: false,
        },

        without: {
            writable: false,
            value: function <T>(this: T[], toRemove: T[] | T | Predicate<T>): T[] {
                if (!toRemove) {
                    return this;
                }

                return this.filter((item: T) => {
                    if (Array.isArray(toRemove)) {
                        return !toRemove.includes(item);
                    } else if (typeof toRemove === 'function') {
                        return (toRemove as Predicate<T>)(item);
                    }

                    return item !== toRemove;
                });
            }
        },
    })

    Object.defineProperties(Object.prototype, {

        filterEntries: {
            value: function (this, predicate: (key: unknown, value: unknown) => boolean) {
                const copy = {...this};
                for (const [k, v] of Object.entries(this)) {
                    if (!predicate(k, v)) {
                        delete copy[k];
                    }
                }
                return copy;
            },

            writable: false,
        },
        valueSet: {
            value: function () {
                return Object.values(this);
            },
            writable: false
        },

        getByPath: {
            value: function <T>(this: any, paths: string | string[], defaultValue?: T): T | null {
                if (Array.isArray(paths)) {
                    paths = paths.join('.');
                }

                return _get(this, paths, defaultValue);
            },
            writable: false
        },

        mapValues: {
            value: function <K extends PropertyKey, V, T>(this: Struct<K, V>, map: (source: V) => T): Struct<K, T> {
                return remapEntries<K, V, T>(this, (k, v) => [k, map(v)]);
            },
            writable: false
        },

        mapEntries: {
            value: function <K extends PropertyKey, V, T>(this: Struct<K, V>, map: (key: K, source: V) => [K, T]): Struct<K, T> {
                return remapEntries<K, V, T>(this, map);
            },
            writable: false
        },

        keySet: {
            value: function () {
                return Object.keys(this);
            },
            writable: false
        },

        associateKeys: {
            value: function <K extends string | symbol, T>(this: K[], accessor: Accessor<K, T>): Record<K, T> {
                const getter = toGetter(accessor);
                return this.reduce((prev, next) => {
                    prev[next] = getter(next);

                    return prev;
                }, <Record<K, T>>{});
            },
            writable: false
        },

        replaceWhere: {
            value: function<T>(this: T[], predicate: Predicate<T>, replacement: InstanceOrIdentity<T>): Array<T> {
                let idx = 0;
                for (const item of this) {
                    if(predicate(item)) {
                        if(typeof replacement === 'function') {
                            const replaceValue = (replacement as IdentityOrUpdate<T>)(item);
                            if (replaceValue !== undefined) {
                                this[idx]=replaceValue;
                            }
                        } else {
                            this[idx]=replacement;
                        }
                    }
                    idx++;
                }
                return this;
            },
            writable: false
        },

        reduceMap: {
            value: function <T, R>(this: T[], reduceFn: ((prev: R, next: T, index: number) => R | undefined), initial: R): R {
                return this.reduce((acc, next, index) => {
                    const returned = reduceFn(acc, next, index);

                    return returned === undefined ? acc : returned;
                }, initial);
            },
            writable: false
        },

        entrySet: {
            value: function () {
                return Object.entries(this);
            },
            writable: false
        },
    });
}

const distinctFilter = <T>(value: T, index: number, self: T[]) => {
    return self.indexOf(value) === index;
};

export default function(){};
