<script lang="ts">export default { name: 'PlayQuestionSummary' }; </script>
<template>
    <div>
        <div class="question-title">
            <h3 data-qa="question-page-page-title">
                {{ $t('pageTitle') }}
            </h3>

            <p>
                {{ $t('pageDescription') }}
            </p>
        </div>

        <div v-if="refs.length" class="questions">
            <div class="summary-header">
                {{ $t('refsHeader') }}
            </div>
            <div
                v-for="property in refProps"
                :key="property.name"
                class="question"
            >
                <PropertyFormField :prop-form="property" />
            </div>
        </div>

        <div v-if="groups.length" class="summary-groups">
            <div class="summary-header">
                {{ $t('groupsHeader') }}
            </div>
            <div
                v-for="(group, pageIndex) in groups"
                :key="group.title"
                class="question"
            >
                <PropGroupListTile
                    :group="group"
                    :invalid="!validPages[pageIndex]"
                    @select="emit('focus-page', pageIndex)"
                />
            </div>
        </div>
        <div :class="['question-button-row']">
            <div class="question-progress-container" />
            <div class="questions-actions">
                <div class="next">
                    <DsButton
                        dense
                        :loading="closing"
                        data-qa="question-done-button"
                        :disabled="!isModelValid"
                        @click="emit(needsPop ? 'close' : 'generate-play')"
                    >
                        {{ needsPop ? $t('done') : props.finishButtonText || $t('generatePlay') }}
                    </DsButton>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>

import {
    computed, ref, toRefs, watch,
} from 'vue';
import PropGroupListTile from '@/play-editor/propGroups/PropGroupListTile.vue';
import { ModelProperty, ModelPropertyGroup } from '@/generated/play-api';
import { injectFormProvider } from '@/model/form/inject-form';
import { Dict } from '@/types/core-types';
import PropertyFormField from '@/model/form/PropertyFormField.vue';
import cloneDeep from 'lodash/cloneDeep';
import { PageModel } from '@/play-editor/play/PlayModel';

const props = defineProps<
    {
        /**
         * Whether this play has already generated content
         */
        needsPop?: boolean,
        pageModel: PageModel,
        validPages: Dict<boolean>,
        closing?: boolean,
        finishButtonText?: string,
    }>();

const emit = defineEmits(['generate-play', 'close', 'focus-page']);
const { pageModel } = toRefs(props);

const { state: formState, ...form } = injectFormProvider();

const refs = computed<ModelProperty[]>(() => cloneDeep(pageModel.value?.refs)
    // a bit of a hack since we don't have modelProperty sortOrder implemented. We need company and person to be set up first in order to get AI operations to work right.
    .sort((a) => (a.name === 'company' ? -1 : a.name === 'persona' ? 1 : 0)) ?? []);

/// Why doesn't this work normally?
const isModelValid = ref(false);

watch(() => cloneDeep(formState), () => {
    isModelValid.value = formState.isModelValid;
}, { deep: true, immediate: true, flush: 'sync' });

const refProps = computed(() => form.getAll(sortRefs(refs.value)));
const groups = computed<ModelPropertyGroup[]>(() => pageModel.value?.groups ?? []);

function sortRefs(refs: ModelProperty[]) {
    const desiredOrder = ['company', 'persona', 'leadMagnet', 'consult'];

    // Sort the cloned array based on the desired order
    return cloneDeep(refs).sort((a, b) => {
        const indexA = desiredOrder.indexOf(a.name);
        const indexB = desiredOrder.indexOf(b.name);

        if (indexA === -1 && indexB === -1) return 0;
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;

        return indexA - indexB;
    });
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "./properties/play-question";

    .summary-groups {
        margin-top: $spacing-600;
    }

    .summary-header {
        font-size: $font-size-md;
        font-weight: $font-weight-bold;
    }

    .question-progress-container {
        flex-grow: 3;
        margin-right: auto;
        flex-basis: 2rem;
        align-content: start;
    }

    .question-progress {
        width: 12rem;
        margin-right: auto;
    }

    .summary-progress-bar {
        margin-bottom: $spacing-050;
    }

    .question-button-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: $spacing-200;
        padding-bottom: $spacing-400;
        position: relative;
        z-index: 100;

        @media (width <= 1440px) {
            justify-content: flex-start;
        }

        &.show-progress {
            border-top: 1px solid $color-gray-200;
        }
    }

    .questions-actions {
        display: flex;
        flex-direction: row;

        .previous {
            margin-right: 1rem;
        }
    }

    .progress-wrapper {
        font-size: $font-size-xs;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: calc(1rem / 2) 0;
    }

    .question-title {
        margin: auto;
        text-align: center;

        h3 {
            font-size: $font-size-jumbo;
            margin-bottom: $spacing-050;
        }

        p {
            margin-bottom: calc(1rem * 2);
            font-size: $font-size-sm;
        }
    }

    .questions-page-container {
        position: relative;
        margin: calc(1rem * 2) auto auto auto;
        max-width: var(--play-questions-width, 50rem);
        background: white;
    }

    .play-questions {
        margin-bottom: 2rem;
    }

    .questions {
        margin-top: 1rem;
    }
</style>
<i18n>
{
    "en-us": {
        "done": "Done with questions",
        "generatePlay": "Generate play",
        "pageTitle": "Customize your play",
        "pageDescription": "Fill out each section below to generate customized content for your play",
        "groupsHeader": "Complete each of these sections:",
        "refsHeader": "Confirm your selections below:"
    }
}
</i18n>
