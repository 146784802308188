import {
    computed, ComputedRef, inject, InjectionKey, provide, reactive, unref,
} from 'vue';
import {
    AiSuggestion,
    AssetType,
    ContentType,
    KeapIcon,
    ModelSchema,
    SortOrderLocation,
    V3CreatePlayTemplateSectionInput,
    NavigateDestination, V1PlaybookCategory,
    V3NavigateDestination,
    V3PlayTag,
    V3PlayTemplate,
    V3PlayTemplateAccess,
    V3PlayTemplateAsset,
    V3UserTemplates,
    V3PlayTemplateInfo, V3TagMatrix, V3TagName, V3DestinationSettings,
} from '@/generated/play-api';
import { watchCalculated } from '@/play-editor/mixins/v3/computedReactive';
import {
    MaybeRef, reactify, until, useAsyncState,
} from '@vueuse/core';
import { playClientTs } from '@/client/play-client';
import { Struct } from '../../../@types/object';
import {
    localizeCategories,
    LocalizedPlayCategory,
    LocalizedPlayCategoryInfo,
    LocalizedPlayTag,
} from '@/play-editor/templates/play-category-helper';
import { logger } from '@/shared/logging';
import { Completer } from '@/shared/Completer';
import {
    array, Dict, dict, DropdownOption, struct,
} from '@/types/core-types';
import { notNull, RECOMMENDATION_CATEGORY, SectionTypes } from '@/play-editor/play.constants';
import { useAsyncAuthState } from '@/shared/use-async-user';
import { CoreProvide } from '@/shared/core-provide.types';
import { AsyncState } from '@/play-editor/model/TenantModelService';
import { useLogOnce } from '@/shared/shared-providers';
import cloneDeep from 'lodash/cloneDeep';
import { randomString } from '@/shared/shared.utils';
import debounce from 'lodash/debounce';

export type MultiSelectOptionGrouped<T> = {
    label: string;
    options: MultiSelectOptionSingle<T>[];
}

export type MultiSelectOptionSingle<T> = {
    label: string;
    value: T;
    help?: string;
    icon?: KeapIcon;
    settings: Record<string, V3DestinationSettings>;
}

export type PlayTemplateId = string;
export type TagDetails = {
    tagId: string,
    tagName: string,
    tagNameLocalized: string,
    tagTenantId: string | null,
    categoryId: string,
    categoryName: string,
    categoryTenantId: string | null,
    categoryVisible: boolean,
}

export enum PlayTagFilterType {
    any = 'any', all = 'all'
}
export type PlayTemplateState = {
    /**
     * An array of all templates
     */
    tagFilter: DropdownOption[];
    tagFilterType: PlayTagFilterType;
    templates: V3PlayTemplateInfo[];
    shared: V3PlayTemplateAccess[];

    /**
     * Will complete when the user selects a template
     */
    selecting: Completer<V3PlayTemplateInfo>;
    /**
     * The templateId or slug that's currently being previewed
     */
    previewTemplateId: string;

    /**
     * An array of all top-level template categories, localized
     */
    categories: LocalizedPlayCategory[];

    playbookCategories: V1PlaybookCategory[];

    /**
     * A mapping of {@link PlayTemplateId} to the default {@link LocalizedPlayTag}.  This information can
     * be used to display a default category info when displaying "All Plays"
     */
    readonly defaultCategoryInfo: Struct<PlayTemplateId, LocalizedPlayTag>;

    /**
     * An array of recommended play templates
     */
    recommendedPlays: V3PlayTemplateInfo[];

    navigationDestinationsById: Struct<NavigateDestination, V3NavigateDestination>;
    readonly groupedDestinations: MultiSelectOptionGrouped<NavigateDestination>[];

    /**
     * All {@link V3PlayTemplateInfo} templates by id/slug
     */
    modelSchemaDetails: Struct<string, ModelSchema>;
    templateInfoById: Struct<string, V3PlayTemplateInfo>;
    tagsByTemplateIdRaw: Struct<string, TagDetails[]>;
    modifiedAssets?: Dict<boolean>;
    modified: Dict<Dict<string>>;
    isReady: boolean;
}

export interface PlayTemplateProvider {
    state: PlayTemplateState;

    /**
     * Returns the {@link LocalizedPlayTag} info for a particular PlayTemplate and category.
     *
     * If no category is provided, then the default category is used.
     */
    getPlayCategoryInfo(playId: MaybeRef<string>, category?: MaybeRef<null>): ComputedRef<LocalizedPlayCategoryInfo>;

    /**
     * Returns {@link LocalizedPlayCategory} info based on category.
     *
     * If no category is provided, then the default is used.
     * @param currentCategoryId
     */
    getCategory(currentCategoryId?: MaybeRef<string | undefined>): ComputedRef<LocalizedPlayCategory>;

    /**
     * Initiates the process of choosing a play, which will pop up the "Choose Template" modal window.
     *
     * - When the user makes a selection, the returned promise will resolve.
     * - If the user cancels the process, then the Promise will resolve with `null`
     */
    choosePlayTemplate(): Promise<V3PlayTemplateInfo | null>;

    previewPlayTemplate(info: V3PlayTemplateInfo): AsyncState<V3PlayTemplate>;

    /**
     * Retrieves {@link V3PlayTemplate} details based on a template ID or slug.  An internal cache is used, so
     * the returned Promise may already be resolved.
     *
     * @param templateId
     * @param forceReload Re-loads the template from the server before mutating
     * @param updateState Updates the necessary provider state properties to keep things in sync
     */
    loadTemplateDetails(templateId: string, forceReload?: boolean, updateState?: boolean): AsyncState<V3PlayTemplate>;

    /**
     * Retrieves the name of a PlayTemplate.
     */
    getPlayTemplateName(templateId: string): string;

    /**
     * Initializes (or re-initializes) the templates and categories.
     */
    initialize(): AsyncState<PlayTemplateState>;

    lookupMatrix(tagA: V3TagName, tagB: V3TagName): string[] | undefined;

    updateTemplateDetailState(templateId: MaybeRef<string>, title: string, description: string): void;

    updateChecklistDetails(templateId: MaybeRef<string>, title: string, description: string): Promise<void>

    mutatePlayTemplateDetailsDebounced(templateId: MaybeRef<string>,
                                       update: (play: V3PlayTemplate) => void,
                                       forceReloadBefore?: boolean,
                                       updatePlayProviderState?: boolean,): Promise<V3PlayTemplate> | V3PlayTemplate

    mutatePlayTemplateDetails(templateId: MaybeRef<string>,
                              update: (playTemplate: V3PlayTemplate) => void,
                              forceReloadBefore?: boolean,
                              updatePlayProviderState?: boolean,): Promise<V3PlayTemplate> | V3PlayTemplate

    addPlayTagForTenant(tenantId: string, tagName: string): Promise<V3PlayTag>;

    applyTags(tenantId: string, templateId: string, tagList: DropdownOption[], slug?: string): Promise<void>;

    tagsByTemplateId: ComputedRef<Struct<string, DropdownOption[]>>;

    updateTemplateSectionContent(params: { assetId?: string, sectionId?: string, data?: string }): Promise<void>;

    // saveAsset(param: { assetId: string; changes?: KeyValues, themeId?: string }, force?: boolean): Promise<unknown>;

    addBlankAsset(templateId: string, assetType: AssetType, assetName: string, sortOrderLocation?: SortOrderLocation): Promise<V3PlayTemplateAsset>;

    getSectionsByAssetType(assetType: AssetType): V3CreatePlayTemplateSectionInput[];

    unloadTemplateDetails(templateId: string): void;
}

export const PlayTemplateProviderKey: InjectionKey<PlayTemplateProvider> = Symbol('playTemplateProvider');

const log = logger('playTemplateProvider');

export function providePlayTemplates(core: CoreProvide, doProvide = true): PlayTemplateProvider {
    const logOnce = useLogOnce({ log, persist: true });
    const { store, toast } = core;
    const state = reactive(<PlayTemplateState>{
        templateInfoById: dict(),
        modelSchemaDetails: dict(),
        tagsByTemplateIdRaw: dict(),
        modified: dict(),
        tagFilter: [],
        tagFilterType: PlayTagFilterType.all,
        tags: dict(),
        templates: [],
        shared: [],
        categories: [],
        recommendedPlays: [],
        playbookCategories: [],
        defaultCategoryInfo: {},
        selecting: null as Completer<V3PlayTemplateInfo>,
        previewTemplateId: null as string,
        navigationDestinationsById: struct<NavigateDestination, V3NavigateDestination>(),
        groupedDestinations: array(),
        isReady: false,
    });

    function matrixKey(a: V3TagName, b: V3TagName) {
        const ax = [`${a.category}:${a.name}`, `${b.category}:${b.name}`].sort((a1, b1) => a1.localeCompare(b1));

        return ax.join('->');
    }

    let matricesByKey: Dict<V3TagMatrix>;

    const detailsLoadQueue = <Struct<string, AsyncState<V3PlayTemplate>>>{};

    const updateProviderStateDebounced = debounce(updateProviderState, 2000);

    function updateProviderState(templateDetails: V3PlayTemplate) {
        const index = state.templates.findIndex((t) => t.id === templateDetails.id);

        if (index > -1) {
            const playTemplateInfo = {
                ...templateDetails,
                permission: state.templates[index].permission,
                tenantId: state.templates[index].tenantId,
            };

            state.templates[index] = playTemplateInfo;
            state.templateInfoById[templateDetails.id as string] = playTemplateInfo;
        }
    }

    function localizeTagName(category: string | null, tag: string): string {
        if (category === null) return tag;

        const key = `categories.${category}.${tag}.title`;
        const resolved = core.i18n.t(key);

        return resolved === key ? tag : resolved.toString();
    }

    async function internalLoadAll(): Promise<PlayTemplateState> {
        try {
            //
            // 1. Load all User templates
            //
            const { templates, categories } = await (store.state.auth.user?.id != null
                ? playClientTs.playTemplate.listUserTemplates()
                : Promise.resolve({ templates: [], categories: [] } as V3UserTemplates));

            //
            // 2. Load all shared templates
            //
            state.shared = await playClientTs.playTemplate.getSharedTemplates();
            state.templates = templates.sort((t1, t2) => (Date.parse(t1.updateTime) > Date.parse(t2.updateTime) ? 1 : -1));

            //
            // 3. Load templates into state by ID/Slug
            //
            state.templateInfoById = state.templates.reduce((acc, template) => {
                let sortedTemplate;

                if (template?.checklistTemplate?.items) {
                    const itemsSorted = template.checklistTemplate.items.sort((a, b) => a.sortOrder - b.sortOrder);

                    sortedTemplate = {
                        ...template,
                        checklistTemplate: { ...template.checklistTemplate, items: itemsSorted },
                    };
                } else {
                    sortedTemplate = template;
                }

                acc[template.id] = sortedTemplate;
                acc[template.slug] = sortedTemplate;

                return acc;
            }, struct<string, V3PlayTemplateInfo>());

            state.categories = localizeCategories(state.templates, categories, (key, fallback) => {
                const resolved = core.i18n.t(key);

                return resolved === key ? fallback : resolved;
            });

            matricesByKey = [].keyed((m) => matrixKey(m.tagA, m.tagB));
            playClientTs.lookups.listPlaybookCategories().then(({ data }) => {
                state.playbookCategories.length = 0;
                state.playbookCategories.push(...data);
            }).catch((err) => {
                log.error(err);
            });

            state.tagsByTemplateIdRaw = categories.filter((cat) => cat.visible === true).reduce((tagsById, category) => {
                category.tags.forEach((tag) => {
                    tag.templates.forEach((template) => {
                        const { templateId } = template;

                        if (!tagsById[templateId]) {
                            tagsById[templateId] = [];
                        }

                        tagsById[templateId].push({
                            tagId: tag.id,
                            tagName: tag.name,
                            tagNameLocalized: localizeTagName(category.name, tag.name),
                            tagTenantId: tag.tenantId,
                            categoryId: category.id,
                            categoryName: category.name,
                            categoryTenantId: category.tenantId,
                            categoryVisible: category.visible,
                        });
                    });
                });

                return tagsById;
            }, dict());

            const recommendedSlugs = categories.find(({ name }) => name === RECOMMENDATION_CATEGORY)
                ?.tags[0]?.templates?.map(({ slug }) => slug) ?? [];

            state.recommendedPlays = recommendedSlugs.map((slug) => state.templateInfoById[slug]).filter(notNull);

            log.info('Categories built:', state.categories);

            // Get and reformat the NavigateDestinations
            const destinations = await playClientTs.lookups.listNavigationDestinations();

            state.navigationDestinationsById = destinations.data.keyed((e) => e.name);

            // Get and reformat the model schema details
            const modelSchemas = await playClientTs.playTemplate.getModelDetails();

            state.modelSchemaDetails = modelSchemas.reduce((result: Struct<string, ModelSchema>, schema: ModelSchema) => {
                const key = `${schema.modelType.category}:${schema.modelType.name}`;

                result[key] = schema;

                return result;
            }, dict());
        } catch (e) {
            log.severe(`Categories error: ${e}`, e);
            state.categories ??= [];
            throw e;
        } finally {
            state.isReady = true;
        }

        return state as PlayTemplateState;
    }

    /**
     * Holds {@link LocalizedPlayTag} info for each play, grouped by categoryId.  The purpose is to assist
     * in finding the tag info for a particular {@link V3PlayTemplate}
     *
     * see {@link PlayTemplateProvider#getPlayCategoryInfo}
     * ```js
     * {
     *     categoryId: {
     *         playTemplateId/playTemplateSlug: {
     *             ...playTag
     *         }
     *     }
     * }```
     */
    const templateCategoryInfo = computed(() => {
        const templates = state.templateInfoById;
        const lookups = dict<Struct<string, LocalizedPlayTag>>();

        for (const category of state.categories) {
            lookups[category.id] ??= dict();

            const templateLookup = lookups[category.id];

            for (const tag of category.tags) {
                for (const { slug } of tag.plays) {
                    if (slug) {
                        const { id } = templates[slug] ?? {};

                        if (id) {
                            templateLookup[slug] = tag;
                            templateLookup[id] = tag;
                        } else {
                            logOnce.warn(`Missing template: ${slug}`);
                        }
                    }
                }
            }
        }

        return lookups;
    });

    const tags = computed(() => state.categories.flatMap((e) => e.tags).keyed(({ tagId }) => tagId));
    // todo:merge Delete?
    // const subcategories = computed(() => state.categories.flatMap((e) => e.subcategories.map((s) => {
    //     return ({
    //         ...s,
    //         id: camelCase(s.id),
    //     });
    // })).keyed(({ subcategoryId }) => subcategoryId));

    watchCalculated(state, {
        defaultCategoryInfo: () => templateCategoryInfo.value.valueSet()[0],
        modifiedAssets(): Dict<boolean> {
            const modified = state.modified.mapEntries((assetId, updatedSections) => [assetId, Object.keys(updatedSections ?? {}).length > 0]);

            return modified;
        },
        groupedDestinations() {
            return Array.from(state.navigationDestinationsById.valueSet().reduce(
                (entryMap, e) => entryMap.set(e.category, [...entryMap.get(e.category) || [], e]),
                new Map<string, V3NavigateDestination[]>(),
            ), ([k, v]) => {
                return {
                    label: k,
                    options: v.map((i: V3NavigateDestination) => {
                        return {
                            // value: Object.entries(NavigateDestination).find(([key, value]) => value === i.name),
                            value: i.name,
                            label: i.fallbackLabel,
                            icon: i.icon,
                            settings: i.settings,
                        };
                    }),
                };
            });
        },
    });

    let attempt = 0;

    const initializer = useAsyncAuthState(store, internalLoadAll, null, {
        immediate: false,
        onError(e) {
            log.severe('Error initializing templates and categories', e);

            if (attempt < 50) {
                void initializer.execute(++attempt * 100);
            }
        },
    });

    const mutatePlayTemplateDetailsDebounced = debounce(mutatePlayTemplateDetails, 1000);

    function mutatePlayTemplateDetails(
        templateId: MaybeRef<string>,
        mutate: (playTemplate: V3PlayTemplate) => void,
        forceReloadBefore?: boolean,
        updatePlayProviderState?: boolean,
    ): Promise<V3PlayTemplate> | V3PlayTemplate {
        templateId = unref(templateId);
        const details = provider.loadTemplateDetails(templateId, forceReloadBefore);

        const performUpdate = () => {
            mutate(details.state.value);

            if (updatePlayProviderState) {
                updateProviderStateDebounced(details.state.value);
            }

            return details.state.value;
        };

        if (details.isReady.value) {
            return performUpdate();
        }

        return until(details.isReady).toBe(true).then(() => {
            return performUpdate();
        });
    }

    // check appId and load user templates if necessary
    watch([core.appId, () => store.state.auth.user?.id], () => initializer.execute());

    function getAiUserPrompt(content: string): AiSuggestion {
        return {
            prompt: [{ content, role: 'user' }],
            operation: null,
            maxRuns: null,
            params: null,
            dependentFields: null,
            numResults: null,
            resultVariables: null,

        };
    }

    function getBlankSectionsByAssetType(assetType: AssetType): V3CreatePlayTemplateSectionInput[] {
        switch (assetType) {
        case AssetType.EMAIL:
            return [
                {
                    label: 'Subject',
                    description: 'Subject line',
                    sectionType: SectionTypes.text,
                    contentType: ContentType.TEXT,
                    sectionKey: randomString(),
                    suggestionGenerator: getAiUserPrompt(
                        'Write a subject line (and nothing else) for an email. \n'
                            + '\n\n###'
                            + 'Email purpose:\n'
                            + 'The goal of the email is to deepen the relationship between the company (below) and their customer.\n'
                            + '\n\n###'
                            + 'Company information\n'
                            + 'Name: {{ company.companyName }}\n'
                            + 'Brand promise: {{ company.brandPromise }} \n'
                            + '\n\n###'
                            + 'Target customer: \n'
                            + 'Target group: {{ persona.personaTarget }}\n'
                            + 'Benefits: {{ persona.personaBenefits }}\n'
                            + 'Typical customer problems:\n'
                            + '{% for problem in persona.personaProblems -%}\n'
                            + '- {{ problem }}\n'
                            + '{% endfor -%}  \n'
                            + '\n\n###'
                            + 'Format Instructions:\n'
                            + '- The subject line should be no more than 35 characters. \n'
                            + '- Do not provide the preview text, email body, or any other content or comments before or after the subject line.\n'
                            + '- Use "[[ contact.first_name ]]" if you are including the name of the recipient in the subject line.',
                    ),
                    content: null,
                    sortOrder: 100,
                    filterExpression: null,
                },
                {
                    label: 'Preview',
                    description: 'Preview text',
                    sectionType: SectionTypes.text,
                    contentType: ContentType.TEXT,
                    sectionKey: randomString(),
                    suggestionGenerator: getAiUserPrompt(
                        'Write the preview text line (and nothing else) for an email. \n'
                            + '\n\n###'
                            + 'Email purpose:\n'
                            + 'The goal of the email is to deepen the relationship between the customer (recipient) and the company (the sender).\n'
                            + '\n\n###'
                            + 'Company information\n'
                            + 'Name: {{ company.companyName }}\n'
                            + 'Brand promise: {{ company.brandPromise }} \n'
                            + '\n\n###'
                            + 'Target customer: \n'
                            + 'Target group: {{ persona.personaTarget }}\n'
                            + 'Benefits: {{ persona.personaBenefits }}\n'
                            + 'Typical customer problems:\n'
                            + '{% for problem in persona.personaProblems -%}\n'
                            + '- {{ problem }}\n'
                            + '{% endfor -%}  \n'
                            + '\n\n###'
                            + 'Format Instructions:\n'
                            + '- The preview text should be no more than 25 characters. \n'
                            + '- Do not provide a subject line or the email body (just the preview text), and do not put any comments before or after the preview text. \n'
                            + '- Use "[[ contact.first_name ]]" if you are including the name of the recipient in the subject line.',
                    ),
                    content: null,
                    sortOrder: 200,
                    filterExpression: null,
                },
                {
                    label: 'Body',
                    description: 'Email body',
                    sectionType: SectionTypes.richTextBody,
                    contentType: ContentType.HTML,
                    sectionKey: randomString(),
                    suggestionGenerator: getAiUserPrompt(
                        'Write the body (and nothing else) for an email to a customer who recently purchased. The goal is to deepen the relationship with new customer has with the company.\n'
                        + '\n'
                        + 'Please include a 1-sentence intro paragraph (a warm welcome), instructions for new customer success, a warm closing, and an ending salutation.\n'
                        + '\n\n###'
                        + 'Company information\n'
                        + 'Name: {{ company.companyName }}\n'
                        + 'Brand promise: {{ company.brandPromise }} \n'
                        + '\n\n###'
                        + 'Target customer: \n'
                        + 'Target group: {{ persona.personaTarget }}\n'
                        + 'Benefits: {{ persona.personaBenefits }}\n'
                        + 'Typical customer problems:\n'
                        + '{% for problem in persona.personaProblems -%}\n'
                        + '- {{ problem }}\n'
                        + '{% endfor -%}   \n'
                        + '\n\n###'
                        + 'Format Instructions:\n'
                        + '- Format your response as simple, valid HTML (do not just respond with plain text). use these HTML tags: div, p, br, (and b and i tags for emphasis).\n'
                        + '- Do not include the subject line, preview text, or any other comments before or after the email body. Only provide the email body copy.'
                        + '- Use "[[ contact.first_name ]]" for the recipient, and "{{ company.companyEmailSignature }}" for the ending salutation.',
                    ),
                    content: null,
                    sortOrder: 300,
                    filterExpression: null,
                },
            ];
        case AssetType.LANDING_PAGE:
            return [
                {
                    label: 'Body',
                    description: 'Landing page body',
                    sectionType: SectionTypes.richTextBody,
                    contentType: ContentType.HTML,
                    sectionKey: randomString(),
                    suggestionGenerator: getAiUserPrompt(
                        'Write the content for a landing page using the information about the company and target customer below.\n'
                            + '\n'
                            + 'Please include a compelling headline (h1) and subheader (h2), content and bullets and a clear call to action.\n'
                            + '\n\n###'
                            + 'Company information\n'
                            + 'Name: {{ company.companyName }}\n'
                            + 'Brand promise: {{ company.brandPromise }} \n'
                            + '\n\n###'
                            + 'Target customer: \n'
                            + 'Target group: {{ persona.personaTarget }}\n'
                            + 'Benefits: {{ persona.personaBenefits }}\n'
                            + 'Typical customer problems:\n'
                            + '{% for problem in persona.personaProblems -%}\n'
                            + '- {{ problem }}\n'
                            + '{% endfor -%}   \n'
                            + '\n\n###'
                            + 'Format Instructions:\n'
                            + '- Format your response as simple, valid HTML (do not just respond with plain text). use these HTML tags: div, p, br, (and b and i tags for emphasis).',
                    ),
                    content: null,
                    sortOrder: 100,
                    filterExpression: null,
                },
            ];
        case AssetType.HTML_PAGE:
            break;
        case AssetType.SNIPPET_PAGE:
            break;
        case AssetType.SOCIAL_SNIPPETS:
            break;
        case AssetType.EMAIL_PS:
            break;
        case AssetType.SMS:
            return [
                {
                    label: 'Body',
                    description: 'Text message body',
                    sectionType: SectionTypes.text,
                    contentType: ContentType.TEXT,
                    sectionKey: randomString(),
                    suggestionGenerator: getAiUserPrompt(
                        'Your responsibility is to write copy for a text message. \n'
                        + '\n\n###'
                        + 'Text message purpose:\n'
                        + 'The goal of the text message is to deepen the relationship between the company (below) and their customer.\n'
                        + '\n\n###'
                        + 'Company information\n'
                        + 'Name: {{ company.companyName }}\n'
                        + 'Brand promise: {{ company.brandPromise }} \n'
                        + '\n\n###'
                        + 'Target customer: \n'
                        + 'Target group: {{ persona.personaTarget }}\n'
                        + 'Benefits: {{ persona.personaBenefits }}\n'
                        + 'Typical customer problems:\n'
                        + '{% for problem in persona.personaProblems -%}\n'
                        + '- {{ problem }}\n'
                        + '{% endfor -%}  \n'
                        + '\n\n###'
                        + 'Format Instructions:\n'
                        + '- The text message should be no more than 165 characters. \n'
                        + '- Do not provide any other content or comments before or after the text message content.\n'
                        + '- Use "[[ contact.first_name ]]" if you are including the name of the recipient in the text message.',
                    ),
                    content: null,
                    sortOrder: 100,
                    filterExpression: null,
                },
            ];
        case AssetType.INSTRUCTIONS:
            break;
        case AssetType.CONTENT:
            break;
        default:
            break;
        }

        return [];
    }

    const provider: PlayTemplateProvider = {
        state: state as PlayTemplateState,
        mutatePlayTemplateDetails,
        mutatePlayTemplateDetailsDebounced,
        lookupMatrix(tagA: V3TagName, tagB: V3TagName): string[] {
            const k = matrixKey(tagA, tagB);

            return matricesByKey[k]?.slugs;
        },
        unloadTemplateDetails(templateId: string) {
            delete detailsLoadQueue[templateId];
        },
        getSectionsByAssetType: getBlankSectionsByAssetType,
        async addBlankAsset(templateId: string, assetType: AssetType, assetName: string, sortOrderLocation?: SortOrderLocation): Promise<V3PlayTemplateAsset> {
            const newAsset = await playClientTs.playTemplate.addPlayTemplateAsset(templateId, {
                title: assetName,
                assetType,
                sortLocation: sortOrderLocation || SortOrderLocation.END,
                assetKey: randomString(),
                sections: getBlankSectionsByAssetType(assetType),
            });

            return newAsset;
        },
        async updateTemplateSectionContent(params: {
            assetId?: string,
            sectionId?: string,
            data?: string
        } = {}): Promise<void> {
            const { assetId, sectionId, data } = params;
            let assetChanges = state.modified[assetId];

            if (!assetChanges) {
                state.modified[assetId] = dict();
                assetChanges = state.modified[assetId];
            }

            assetChanges[sectionId] = data;

            return Promise.resolve();
        },
        tagsByTemplateId: computed(() => {
            const computedValue = {} as Struct<string, DropdownOption[]>;

            for (const [templateId, tagDetailsArray] of state.tagsByTemplateIdRaw.entrySet()) {
                computedValue[templateId] = tagDetailsArray.map((tagDetails) => ({
                    value: tagDetails.tagId,
                    label: tagDetails.tagNameLocalized,
                    helpText: tagDetails.categoryTenantId === null ? tagDetails.categoryName : tagDetails.tagTenantId,
                }));
            }

            return computedValue;
        }),
        async applyTags(tenantId: string, templateId: string, tagList: DropdownOption[], slug?: string): Promise<void> {
            await playClientTs.playTag.applyTemplateTags(
                tenantId,
                templateId,
                tagList.map((t) => t.value),
            );

            tagList.forEach((tagOptionToAdd) => {
                const index = state.categories.findIndex((c) => c.tags.some((tag) => tag.id === tagOptionToAdd.value));

                if (index > -1) {
                    const changed = cloneDeep(state.categories[index]);
                    const tagIndex = changed.tags.findIndex((t) => t.id === tagOptionToAdd.value);

                    changed.tags[tagIndex].plays.push({
                        slug: slug || templateId,
                        templateId,
                        top: true,
                    });

                    vset(state.categories, index, changed);

                    if (!state.tagsByTemplateIdRaw[templateId]) {
                        vset(state.tagsByTemplateIdRaw, templateId, [
                            {
                                tagId: tagOptionToAdd.value,
                                tagName: tagOptionToAdd.label,
                                tagNameLocalized: tagOptionToAdd.label,
                                tagTenantId: tenantId,
                                categoryTenantId: tenantId,
                            },
                        ]);
                    } else {
                        vset(state.tagsByTemplateIdRaw, templateId, [
                            ...state.tagsByTemplateIdRaw[templateId],
                            {
                                tagId: tagOptionToAdd.value,
                                tagName: tagOptionToAdd.label,
                                tagNameLocalized: tagOptionToAdd.label,
                                tagTenantId: tenantId,
                                categoryTenantId: tenantId,
                            },
                        ]);
                    }
                } else {
                    log.warn(`Unable to apply tag ${tagOptionToAdd}. Could not find it in the current categories/tags`);
                }
            });
        },

        async addPlayTagForTenant(tenantId: string, tagName: string): Promise<V3PlayTag> {
            const newTag = await playClientTs.playTag.addPlayTag(tenantId, {
                name: tagName,
                categoryId: null,
            });

            const newTagLocalized = {
                id: newTag.id,
                tagId: null,
                title: newTag.name,
                description: newTag.name,
                illustration: null,
                plays: [],
                tenantId: newTag.tenantId,
            } as LocalizedPlayTag;

            const index = state.categories.findIndex((c) => c.tenantId === tenantId);

            if (index > -1) {
                const changed = cloneDeep(state.categories[index]);

                changed.tags.push(newTagLocalized);
                vset(state.categories, index, changed);
            } else {
                state.categories.push(
                    {
                        id: null,
                        title: null,
                        description: null,
                        illustration: null,
                        hasTags: true,
                        tags: [newTagLocalized],
                        tenantId,
                    },
                );
            }

            return newTag;
        },

        getPlayTemplateName(templateId: string): string {
            return state.templateInfoById[templateId]?.title || '';
        },

        previewPlayTemplate(info: V3PlayTemplateInfo): AsyncState<V3PlayTemplate> {
            state.previewTemplateId = info.id;

            return provider.loadTemplateDetails(info.id);
        },

        getCategory: reactify((category: string): LocalizedPlayCategory => {
            return state.categories.find(({ id }) => id === category) ?? state.categories[0];
        }),

        getPlayCategoryInfo: reactify((playId: string, category?: string): LocalizedPlayTag => {
            const infos = templateCategoryInfo.value;

            if (!category) {
                const [first] = infos.keySet();

                category = first;
            }
            const cat = infos[category] ?? dict();
            const subcatId = cat[playId];

            return tags.value[subcatId];
        }),

        choosePlayTemplate(): Promise<V3PlayTemplateInfo | null> {
            state.selecting = Completer.create<V3PlayTemplateInfo>();

            return state.selecting.done;
        },

        initialize() {
            if (!initializer.isLoading.value) {
                void initializer.execute();
            }

            return initializer;
        },

        loadTemplateDetails(templateId: string, forceReload?: boolean, updateState?: boolean): AsyncState<V3PlayTemplate> {
            if (templateId && (forceReload || !Object.hasOwn(detailsLoadQueue, templateId))) {
                detailsLoadQueue[templateId] = useAsyncState(playClientTs.playTemplate.getPlayTemplate(templateId)
                    .then((templateDetails) => {
                        if (templateDetails?.checklistTemplate?.items) {
                            const sortedItems = templateDetails.checklistTemplate.items.sort((a, b) => a.sortOrder - b.sortOrder);

                            return {
                                ...templateDetails,
                                checklistTemplate: { ...templateDetails.checklistTemplate, items: sortedItems },
                            };
                        }

                        if (updateState) {
                            updateProviderState(templateDetails);
                        }

                        return templateDetails;
                    }), null, {
                    immediate: true,
                });
            }

            return detailsLoadQueue[templateId];
        },

        updateTemplateDetailState(templateId: MaybeRef<string>, title: string, description: string) {
            templateId = unref(templateId);
            state.templateInfoById[templateId].title = title;
            state.templateInfoById[templateId].description = description;
            state.templateInfoById[templateId].updateTime = new Date().toISOString();

            const index = state.templates.findIndex((t) => t.id === templateId);

            if (index > -1) {
                const changed = cloneDeep(state.templates[index]);

                changed.title = title;
                changed.description = description;
                changed.updateTime = new Date().toISOString();
                vset(state.templates, index, changed);
            }
        },

        // async saveAsset(params: { assetId?: string, changes?: KeyValues } = {}, track = true): Promise<void> {
        //     const { assetId } = params;
        //     let { changes } = params;
        //
        //     changes ??= state.modified[assetId] ?? {};
        //
        //     const template = state.templateInfoById[]
        //
        //     const asset = state.play.assets.find(({ id }) => assetId === id);
        //
        //     asserts(asset != null, `No asset with id=${assetId}`);
        //     const { title, assetType, sortOrder } = asset;
        //
        //     const updateRequest: UpdateAssetRequest = {
        //         title,
        //         assetType,
        //         sortOrder,
        //         themeId,
        //         sections: Object.entries(changes).map(([sectionId, content]) => {
        //             return {
        //                 id: sectionId,
        //                 content,
        //             } as UpdatePlaySectionRequest;
        //         }),
        //     };
        //
        //     // Gather sections to be updated
        //
        //     log.info('Updating asset: ', assetId, updateRequest);
        //
        //     if (track) {
        //         amplitude.logEvent(PlayEventTypes.PLAY_TEMPLATE_EDITED, {
        //             'Play Template ID': state.previewTemplateId,
        //             'Play Template Name': state.templateInfoById[state.previewTemplateId],
        //             'Save Method': 'Save individual template asset',
        //         });
        //     }
        //
        //     try {
        //         const updatedAsset = await playClientTs.playContent.updatePlayAsset(
        //             appId,
        //             state.play.id,
        //             assetId,
        //             updateRequest,
        //         );
        //
        //         // Now, trigger reactive changes
        //         // Update the list of play assets
        //         const assetIndex = state.play.assets.findIndex((a) => a.id === assetId);
        //
        //         set(state.play.assets, assetIndex, updatedAsset);
        //
        //         if (state.focusedAsset?.id === updatedAsset.id) {
        //             state.focusedAsset = updatedAsset;
        //         }
        //
        //         // Clear out modifications
        //         set(state.modified, assetId, {});
        //     } catch (e) {
        //         log.severe(` - Error saving asset ${assetId}`, e);
        //     }
        // },

        async updateChecklistDetails(templateId: MaybeRef<string>, newTitle: string, newDescription: string) {
            await provider.mutatePlayTemplateDetails(templateId, (template) => {
                playClientTs.playTemplate.updatePlayTemplateDetails(template.id, {
                    description: newDescription,
                    title: newTitle,
                }).then(() => {
                    template.title = newTitle;
                    template.description = newDescription;
                }).catch((reason) => {
                    toast({ message: 'Error updating template details' });

                    if (reason?.body?._embedded?.errors) {
                        log.warn(reason.body._embedded.errors);
                    }
                });
            }, false, true);
        },
    };

    if (doProvide) provide(PlayTemplateProviderKey, provider);

    return provider;
}

export function usePlayTemplates(): PlayTemplateProvider {
    return inject(PlayTemplateProviderKey);
}
