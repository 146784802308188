<script lang="ts">
import { VNode } from 'vue';

export default defineComponent({
    name: 'SlotRenderer',

    props: {
        collapse: {
            type: Boolean,
            default: true,
        },
        slotValue: [Function, Array],
        as: String,
    },

    setup(props) {
        return () => {
            let slotResult:VNode[];

            if (typeof props.slotValue === 'function') {
                slotResult = props.slotValue();
            } else if (Array.isArray(props.slotValue)) {
                slotResult = props.slotValue as VNode[];
            } else {
                slotResult = [props.slotValue as VNode];
            }

            if (slotResult.length === 1 && props.collapse) {
                return slotResult[0];
            }

            return h(props.as ?? 'div', slotResult);
        };
    },

});
</script>
