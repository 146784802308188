import axios from 'axios';
import { store } from '@/store';
import { sendAuthRedirect } from '@/router/utils/router.util';

export default {
    setup(router) {
        axios.interceptors.request.use((config) => {
            const { jwt } = store.state.auth.session;
            const hasToken = Boolean(jwt);

            if (hasToken) {
                config.headers.Authorization = `Bearer ${jwt}`;
            }

            if (!config.data) {
                config.data = {};
            }

            return config;
        });

        axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            const { response } = error;

            if (response) {
                // eslint-disable-next-line default-case
                switch (response.status) {
                case 401:
                    return sendAuthRedirect(router, '/logout');

                case 403:
                    if (store?.state?.auth?.user) {
                        return sendAuthRedirect(router, '/access-denied');
                    }
                }
            }

            return Promise.reject(error);
        });
    },
};
