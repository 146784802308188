<script lang="ts">
import { injectAccountSwitcher } from '@/shared/components/AccountSwitchProvider';

export default defineComponent({
    name: 'AccountSwitcher',
    props: {
        isOpen: Boolean,
        showClose: { type: Boolean, default: true },
    },
    setup(props, { slots }) {
        const { open, close, toggle } = injectAccountSwitcher();

        onMounted(() => {
            if (props.isOpen) {
                open(props.showClose);
            }
        });

        return () => {
            const slot = slots.default({
                open, close, toggle,
            });

            return Array.isArray(slot) && slot.length > 1 ? h('div', {}, slot) : slot;
        };
    },

});
</script>
