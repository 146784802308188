import firebase from 'firebase';
import { env } from '@/env';

export function setupFirebase() {
    if (env.VUE_APP_FIREBASE_API_KEY) {
        const config = {
            apiKey: env.VUE_APP_FIREBASE_API_KEY,
            projectId: env.VUE_APP_FIREBASE_PROJECT_ID,
            databaseURL: `https://${env.VUE_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
            authDomain: `${env.VUE_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
        };

        firebase.initializeApp(config);
    }
}
