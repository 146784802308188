<template>
    <div class="offset picker-field">
        <!--            <label v-if="label" :for="name">{{ label }}</label>-->
        <div class="offset-options">
            <InputField
                v-model="amount"
                data-qa="timing-count"
                type="text"
                class="offset-count"
            />

            <SelectField
                v-model="unit"
                data-qa="timing-unit"
                :class="['offset-select']"
                :options="timingUnits"
                bind-value-only
            />
        </div>

        <span v-if="showError" class="error-text">
            <slot name="error" />
        </span>

        <span v-if="$slots.help" class="assistive-text">
            <slot name="help" />
        </span>

        <div v-if="$slots.leading" class="leading">
            <!-- @slot Leading icon -->
            <slot name="leading" />
        </div>

        <div v-if="hasTrailing" class="trailing">
            <!-- @slot Trailing icon -->
            <slot name="trailing" />
        </div>
    </div>
</template>

<script>

// import inputMixin from '@infusionsoft/design-system/src/mixins/input';
import moment from 'moment-timezone';
import { MomentUnit } from '@/shared/date.utils';

// noinspection JSUnusedGlobalSymbols
export default {
    name: 'DurationPicker',
    // mixins: [inputMixin],
    props: {
        modelValue: String,
        allowedUnits: {
            type: Array,
            defaultValue: () => Object.keys(MomentUnit),
        },
    },
    emits: ['update:model-value'],
    data() {
        return {
            loading: true,
            error: null,
        };
    },

    computed: {
        showError() {
            return Boolean(this.$slots.error && this.submitted);
        },

        autocompleteValue() {
            if (typeof this.autocomplete === 'boolean') {
                if (this.autocomplete) {
                    return 'on';
                }

                return 'off';
            }

            return this.autocomplete;
        },

        hasTrailing() {
            return this.$slots.trailing;
        },

        timingUnits() {
            const base = this.allowedUnits?.length > 0 ? this.allowedUnits : Object.values(MomentUnit);

            return base.map((unit) => ({
                label: unit,
                value: unit,
            }));
        },

        durationObj() {
            const duration = (!this.modelValue ? moment.duration() : moment.duration(this.modelValue));

            const unit = Object.keys(MomentUnit).find((momentUnit) => duration[momentUnit]() > 0) ?? MomentUnit.minutes;

            return { unit, amount: duration[unit]() };
        },

        amount: {
            get() {
                return this.durationObj.amount;
            },
            set(value) {
                this.handleUpdate(this.unit, value);
            },
        },
        unit: {
            get() {
                return this.durationObj.unit;
            },
            set(value) {
                this.handleUpdate(value, this.amount);
            },
        },
    },
    methods: {
        handleUpdate(unit, amount) {
            const isoDuration = moment.duration(amount, unit).toJSON();

            this.$emit('update:model-value', isoDuration);
        },
    },

};
</script>
<style lang="scss" scoped>
    @import "~@infusionsoft/design-system/src/styles/common";

    .picker-field {
        padding-bottom: 1rem;

        label {
            &.active {
                @include pill-label;
            }
        }

        input {
            @include input-text;
        }

        input.has-leading {
            @include padding-start(calc(1rem * 2.5));
        }

        input.has-trailing {
            @include padding-end(calc(1rem * 2.5));
        }

        input.has-leading:not(:focus, .dirty) + label {
            @include position-start(calc(1rem * 2.5));
        }
    }

    .trailing {
        @include position-end(calc(1rem / 4));

        padding: calc(1rem / 2);
        position: absolute;
    }

    .leading {
        @include position-start(calc(1rem / 4));

        padding: calc(1rem / 2);
        position: absolute;
    }

    .toggle-password {
        --icon-color: #{$color-ink-600};

        cursor: pointer;

        &:hover {
            --icon-color: #{$color-gray-900};
        }
    }

    .step-name {
        display: inline-flex;
    }

    .timing-step {
        margin: calc(1rem * 3);
    }

    .timing-step-delay {
        border-radius: $border-radius;
        background-color: $color-gray-050;
        padding: calc(1rem * 2);
        margin: calc(1rem * 3);
    }

    .delay-options {
        max-width: calc(390rem / 16);
    }

    .offset-label {
        font-size: $font-size-sm;
        margin-bottom: 1rem;
    }

    .offset-event-label {
        font-size: $font-size-sm;
        margin-top: 1rem;
        margin-bottom: 0;
    }

    .offset {
        font-size: $font-size-md;

        --input-margin-bottom: 0;

        .offset-options {
            align-items: center;
            display: flex;

            .offset-count {
                flex: 1;

                @include margin-end(1rem);
            }

            .offset-select {
                flex: 1;

                &.event-type {
                    @include margin-end(1rem);

                    flex: 2;
                }
            }

            .offset-toggle {
                flex: 2;
            }
        }
    }

    .event-type-input {
        --input-margin-bottom: 0;
    }

    .time-options-container {
        --checkbox-padding: 0;

        margin-top: calc(1rem * 2);

        .checkbox-container {
            display: inline-flex;
        }
    }

    .range-toggle {
        margin: calc(1rem * 2) 0 calc(1rem * 3);
    }

    .range-options {
        --input-margin-bottom: 0;

        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: calc(1rem * 2);
    }

    .range-and {
        margin: 0 calc(1rem * 2);
    }

    .start-time,
    .end-time {
        flex: 1;
        max-width: calc(150rem / 16);
    }

    .between-help-text {
        margin-top: calc(1rem * 2);
    }

    .info-alert {
        max-width: calc(390rem / 16);
    }
</style>
<i18n>
{
    "en-us": {
    }
}
</i18n>
