import { DataStoreBuilder } from '@/integration/datastore/data-store-builder';
import { PreparedModel } from '@/integration/datastore/base-types';
import { ModelKeyOrName, ModelKeys } from '@/integration/datastore/model-keys';
import {
    HostSystem,
    Model, playClientTs, PropertyValidation,
} from '@/client/play-client';
import { storeLog } from '@/integration/datastore/data-store-api';
import { verifyModel } from '@/play-editor/model/model.utils';

const log = storeLog.child('generic', true);

export function PlayModelDataStore(appId:string, key: ModelKeyOrName, storeHostSystem: HostSystem = HostSystem.GENERAL): DataStoreBuilder {
    const storeModelType = ModelKeys.of(key);

    function prepareModel(model:Model, errors?:PropertyValidation[]): PreparedModel {
        log.debug('Preparing', model, errors);

        const {
            data, modelType, id, valid, hostSystem, sourceId, updateTime, createTime,
        } = model;
        const {
            title, __title, description, __description,
        } = data;

        const prepared = <PreparedModel>{
            data: data ?? {},
            id,
            valid,
            hostSystem,
            sourceId,
            updateTime,
            createTime,
            title: title ?? __title,
            description: description ?? __description,
            errors,
            type: ModelKeys.of(modelType),
        };

        return prepared;
    }

    return DataStoreBuilder.of(appId, storeModelType, storeHostSystem, {
        async list() {
            const data = await playClientTs.model.listModels(appId, storeModelType.category, storeModelType.name, null);

            return {
                data: data?.map((model: Model) => prepareModel(model)) ?? [],
            };
        },

        async get(id) {
            const {
                model,
                errors,
            } = await playClientTs.model.getModel(appId, storeModelType.category, storeModelType.name, id);

            return prepareModel(model, errors);
        },

        async create(post) {
            verifyModel(post);
            const {
                model,
                errors,
            } = await playClientTs.model.createModel(appId, storeModelType.category, storeModelType.name, post);

            return prepareModel(model, errors);
        },

        async update(id, saveModel) {
            verifyModel(saveModel);
            const { data } = saveModel;
            const {
                model,
                errors,
            } = await playClientTs.model.updateModel(appId, storeModelType.category, storeModelType.name, id, {
                data,
                updatedFields: Object.keys(data),
            });

            return prepareModel(model, errors);
        },

        remove(id: string) {
            return playClientTs.model.deleteModel(appId, storeModelType.category, storeModelType.name, id);
        },
    });
}
