<template>
    <div v-if="!modelSchema">
        <Spinner />
    </div>
    <div v-else class="play-question-list">
        <div class="question-description">
            {{ questionDescription ? questionDescription : questionLabel }}<span class="required">{{ required(question.validators) ? t('shared.requiredSymbol') : '' }}</span>
        </div>
        <div v-if="questionAdditionalDescription" class="question-additional-description">
            {{ questionAdditionalDescription }}
        </div>

        <Vuedraggable
            v-bind="cardDraggableOptions"
            ref="fields"
            :model-value="modelItems"
            item-key="id"
            class="fields"
            @update:model-value="updateModelItems"
            @start="moveStart"
            @end="moveEnd"
        >
            <template #item="{ element: item }">
                <PlayQuestionSortableRefItem
                    :question="question"
                    @remove="removeItem(item.id)"
                    @click="startEditing(item.id)"
                >
                    <template #value>
                        <RefCard
                            :model-value="item.id"
                            :card-options="cardOptions"
                            selected
                            :schema="modelSchema"
                            :loaded-model="item"
                            defer-loading
                            class="ref-card"
                            @remove="removeItem(item.id)"
                            @refresh-model="emit('refresh-model')"
                            @delete="deleteItem(item.id)"
                            @edit="editItem(item.id)"
                        >
                            <template #leading>
                                <div class="drag-icon-container">
                                    <Icon name="draggable" class="drag-icon drag-target" />
                                </div>
                            </template>
                        </RefCard>
                    </template>
                    <template #trailing>
                        <!-- TODO: FIX VERTICAL SCROLLBAR ISSUE-->
                        <DsTooltip>
                            <template #reference>
                                <IconButton
                                    class="remove-icon"
                                    :name="KeapIcon.X_CIRCLE"
                                    @click="removeItem(item.id)"
                                />
                            </template>
                            <div class="tooltip-text">
                                {{ $t('removeItem') }}
                            </div>
                        </DsTooltip>
                    </template>
                </PlayQuestionSortableRefItem>
            </template>
            <template #footer>
                <PlayQuestionSortableRefItem
                    v-if="addingItem"
                    key="adding-new"
                    :question="question"
                >
                    <template #value>
                        <RefCard
                            class="inline-input"
                            force-dropdown
                            :placeholder="$t('selectOne', { modelTitle: schemaI18n.title })"
                            :data-qa="`ref-list-dropdown-${question.name}`"
                            :loaded-model="null"
                            :schema="modelSchema"
                            :without="model"
                            :defer-loading="null"
                            @input="addItemToList"
                            @add="onAdd"
                        />
                    </template>
                    <template #trailing>
                        <IconButton :name="KeapIcon.X_CIRCLE" class="remove-icon" @click="addItemToList(null)" />
                    </template>
                </PlayQuestionSortableRefItem>
            </template>
        </Vuedraggable>

        <div v-if="validationError && validationError.length > 0" class="field-error">
            <div v-for="error in validationError" :key="error.code">
                {{ fullErrorMessage(error) }}
            </div>
        </div>

        <DsTextButton
            v-show="!addingItem"
            dense
            :leading-icon="KeapIcon.ADD"
            class="add-button"
            @click="addingItem = true"
        >
            {{ t(modelOrDefault.length === 0 ? 'addFirstItem' : 'addAnotherItem', { modelTitle: schemaI18n.title }) }}
        </DsTextButton>
    </div>
</template>

<script lang="ts">export default { name: 'PlayQuestionRefList' }; </script>
<script lang="ts" setup>
/* eslint-disable @typescript-eslint/no-explicit-any */

import { CardDisplayOptions, ModelProperty } from '@/generated/play-api';
import {
    computed, getCurrentInstance, nextTick, reactive, Ref, toRef, toRefs, watch,
} from 'vue';
import { PlayQuestionCoreEvents, questionMixin } from '@/play-editor/mixins/v3/questionMixin';
import { modelMixin } from '@/play-editor/mixins/v3/modelMixin';
import { injectDataScope } from '@/model/form/DataScope';
import ModelEditPage from '@/play-editor/model/ModelEditPage.vue';
import RefCard from '@/play-editor/controls/ref/RefCard.vue';
import { useCore } from '@/shared/shared-providers';
import PlayQuestionSortableRefItem from '@/play-editor/properties/PlayQuestionSortableRefItem.vue';
import { PreparedModel } from '@/integration/datastore/base-types';
import { schemaI18n as schemaI18nFn } from '@/play-editor/mixins/v3/schema-i18n';
import Vuedraggable from 'vuedraggable';
import { QuestionPropsType } from '@/shared/proptypes/QuestionPropsType';
import { required } from '../question-validation';

const props = defineProps<QuestionPropsType<any[]>>();

const {
    log, t, confirm, appId,
} = useCore();
const emit = defineEmits([...PlayQuestionCoreEvents, 'change', 'refresh-model']);
const questionHelper = questionMixin<any[]>(props, emit, []);
const question: Ref<ModelProperty> = toRef(props, 'question');
const refType = computed(() => question.value.type.array.listType.ref.refType);
const modelTypeService = modelMixin(appId.value, refType.value, ModelEditPage);
const { modelsById, modelSchemaState } = modelTypeService;
const modelSchema = modelSchemaState.state;
const dataScope = injectDataScope();

const state = reactive({
    addingItem: false,
    modelToAdd: null,
    selectedItemText: '',
    selectedIndex: -1,
    cardError: null,
    mouseOver: false,
    openedFieldKey: null,
    addCustomFieldModalOpen: false,
    mdKeyIndex: 0,
    dragging: false,
    cardDraggableOptions: {
        animation: 0,
        fallbackClass: 'card-dragging',
        fallbackTolerance: 1,
        forceFallback: true,
        ghostClass: 'card-placeholder',
        group: 'fields',
        handle: '.drag-target',
    },
});

watch(() => state.modelToAdd, (newValue) => {
    if (newValue) {
        model.value = [
            ...modelOrDefault.value,
            newValue,
        ].distinct();

        state.mdKeyIndex += 1;
        nextTick(() => {
            state.modelToAdd = null;
        });
    }
});

const {
    model, modelOrDefault, questionDescription, questionLabel, questionAdditionalDescription,
    prop, fullErrorMessage,
} = questionHelper;
const instance = getCurrentInstance();

const schemaI18n = computed(() => {
    return schemaI18nFn(instance.proxy, modelTypeService.modelSchemaState?.state?.value, t);
});

async function onAdd() {
    const composed = await modelTypeService.composeModel({
        dataScope,
    });

    if (composed?.id) {
        state.modelToAdd = composed?.id;
    }
}

function addItemToList(id:string) {
    state.modelToAdd = id;
    state.addingItem = false;
}

async function deleteItem(id: string) {
    const modelTitle = modelsById.value[id]?.title || t('deleteConfirmDefaultModelTitle')?.toString();

    if (await confirm({
        message: t('deleteConfirmMessage', { modelTitle }),
        title: t('deleteConfirmTitle'),
    })) {
        await modelTypeService.deleteModel(id);
        emit('refresh-model');
    }
}

async function editItem(id:string) {
    await modelTypeService.editModel(id, { dataScope });
    emit('refresh-model');
}

function removeItem(item: any) {
    log.info('Removing ', item);
    model.value = [
        ...modelOrDefault.value.without(item),
    ];
    state.selectedIndex = -1;
    state.selectedItemText = '';
}

function startEditing(item: any) {
    state.selectedIndex = props.modelValue.indexOf(item);
    state.selectedItemText = item;
}

function moveStart() {
    document.documentElement.style.cursor = 'grabbing';
    state.dragging = true;
}

function moveEnd() {
    document.documentElement.style.cursor = '';
    state.dragging = false;
}

watch(model, async (list) => {
    if (!list) return;
    const failedLoad = (await Promise.all(list.map(async (id) => {
        try {
            await modelTypeService.loadModel(id);

            return null;
        } catch (e) {
            return id;
        }
    }))).filterNotNull();

    if (failedLoad.length > 0) {
        log.warn('Unable to load these IDs:', failedLoad);
        model.value = modelOrDefault.value.without(failedLoad);
    }
}, { immediate: true, deep: false });

function updateModelItems(items:PreparedModel[]) {
    model.value = items.map((i) => i.id);
}
const modelItems = computed<PreparedModel[]>(() => modelOrDefault.value.map((id) => modelsById.value[id]).filterNotNull());

const { addingItem, cardDraggableOptions, dragging } = toRefs(state);

const cardOptions = computed((): CardDisplayOptions => {
    return (prop.value?.displayOptions?.array.itemOptions?.ref ?? modelSchema.value?.defaultDisplayOptions?.array?.itemOptions?.ref)?.cardOptions;
});

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@/shared/transitions/transitions";
    @import "play-question";
    @import "@/coach/coach";

    .ref-card {
        margin-top: $spacing-050;
        margin-bottom: $spacing-050;
    }

    .no-suggestions {
        color: $color-text-disabled;
    }

    .drag-icon-container {
        cursor: grab;
    }

    .suggestions-header {
        margin-top: 1rem;
        margin-bottom: calc(1rem / 2);
    }

    .results-container {
        margin: auto;
        align-content: center;
        text-align: center;
    }

    .field-error {
        color: $color-red;
        margin-top: calc(1rem / 2);
    }

    .play-question-list {
        margin-bottom: 1rem;
    }

    .input-form {
        margin-top: 1rem;
    }

    .inline-input {
        margin-top: calc(1rem);
        margin-bottom: calc(1rem / 4);
        padding-bottom: calc(1rem / 4);
    }

    .header {
        font-size: large;
    }

    .add-button {
        margin-top: $spacing-200;
    }

    .remove-icon {
        --icon-color: #{$color-gray-600};
    }
</style>
<i18n>
{
    "en-us": {
        "createNewField": "Add Item",
        "noSuggestions": "No suggestions to display.",
        "refreshNow": "Refresh now",
        "selectOne": "Select {modelTitle} to add",
        "numberSelected": "Selected {modelTitle} records: ",
        "addAnotherItem": "Add another {modelTitle}",
        "removeItem": "Remove item (does not delete)",
        "addFirstItem": "Add your first {modelTitle}"
    }
}
</i18n>
