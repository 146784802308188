/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateModelRequest } from '../models/CreateModelRequest';
import type { HostSystem } from '../models/HostSystem';
import type { Model } from '../models/Model';
import type { ModelSaveRequest } from '../models/ModelSaveRequest';
import type { ModelSaveResult } from '../models/ModelSaveResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ModelService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Models
     * @param tenant
     * @param category
     * @param name
     * @param hostSystem
     * @returns Model listModels 200 response
     * @throws ApiError
     */
    public listModels(
        tenant: string,
        category: string,
        name: string,
        hostSystem?: HostSystem | null,
    ): CancelablePromise<Array<Model>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/tenant/{tenant}/models/{category}/{name}',
            path: {
                'tenant': tenant,
                'category': category,
                'name': name,
            },
            query: {
                'hostSystem': hostSystem,
            },
        });
    }

    /**
     * Create Model
     * Creates a new model
     * @param tenant
     * @param category
     * @param name
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public createModel(
        tenant: string,
        category: string,
        name: string,
        requestBody: CreateModelRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/models/{category}/{name}',
            path: {
                'tenant': tenant,
                'category': category,
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get Model
     * Retrieves a single Model record
     * @param tenant
     * @param category
     * @param name
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public getModel(
        tenant: string,
        category: string,
        name: string,
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/tenant/{tenant}/models/{category}/{name}/{id}',
            path: {
                'tenant': tenant,
                'category': category,
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * Update Model
     * Updates a single model
     * @param tenant
     * @param category
     * @param name
     * @param id
     * @param requestBody
     * @returns ModelSaveResult Success
     * @throws ApiError
     */
    public updateModel(
        tenant: string,
        category: string,
        name: string,
        id: string,
        requestBody: ModelSaveRequest,
    ): CancelablePromise<ModelSaveResult> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/tenant/{tenant}/models/{category}/{name}/{id}',
            path: {
                'tenant': tenant,
                'category': category,
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Model
     * Deletes a Model record
     * @param tenant
     * @param category
     * @param name
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public deleteModel(
        tenant: string,
        category: string,
        name: string,
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/tenant/{tenant}/models/{category}/{name}/{id}',
            path: {
                'tenant': tenant,
                'category': category,
                'name': name,
                'id': id,
            },
        });
    }

}
