import { Router, RouteLocationRaw } from 'vue-router';
import { illegalState } from '@/shared/shared.utils';
import { ignore } from '@/shared/type.utils';

type RoutePush<T> = ()=>T;
export type RawLocation = RouteLocationRaw;

/**
 * A route navigator is a lower-level component that is responsible for the mechanics of
 * pushing and popping routes to the stack, but is not responsible for managing completing
 * the routes, or sending a response back after a push
 */
export interface RouteNavigator<T> {
    // eslint-disable-next-line no-unused-vars
    push(route: RoutePush<T>): void;

    forward(): void;

    back(): void;

    readonly canGoBack: boolean;
    readonly canGoForward: boolean;

}

export function RouterNavigator(router: Router): RouteNavigator<RawLocation> {
    return {
        back(): void {
            router.back();
        },

        forward(): void {
            return illegalState('Cannot go forward');
        },

        push(route: RoutePush<RawLocation>): void {
            ignore(router.push(route()));
        },

        get canGoBack(): boolean {
            return true;
        },

        get canGoForward(): boolean {
            return false;
        },
    };
}
