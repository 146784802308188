import {
    inject, InjectionKey, provide, reactive,
} from 'vue';
import { ContentMeta, playClientTs } from '@/client/play-client';
import { ignore } from '@/shared/type.utils';

export type CustomElementDefinitions = {
    serverDefinitions: ContentMeta;
    isReady: boolean;
}

const CustomElementKey:InjectionKey<CustomElementDefinitions> = Symbol('customElementDefinitionsKey');

export function provideCustomElementDefinitions(): CustomElementDefinitions {
    const state = reactive({
        serverDefinitions: null as ContentMeta,
        isReady: false,
    });

    provide(CustomElementKey, state);

    ignore(playClientTs.elementMetadata.getCustomElementMeta().then((meta) => {
        state.serverDefinitions = meta;
        state.isReady = true;
    }));

    return state;
}

export function injectCustomElementDefinitions(): CustomElementDefinitions {
    return inject(CustomElementKey);
}
