/* eslint-disable @typescript-eslint/no-explicit-any */
import { ModelProperty } from '@/generated/play-api';

import {
    inject, Ref, getCurrentInstance, toRefs, toRef,
} from 'vue';
import { logger } from '@/shared/logging';
import { SuggestionProviderKey, SuggestionsProvider } from '@/model/form/form-provider-types';
import { notImplemented } from '@/shared/shared.utils';
import { SuggestionItem } from '@/model/form/SuggestionItem';
import { useVModel2 } from '@/play-editor/mixins/v3/v-model';
import { useComputed } from '@/play-editor/mixins/v3/computedReactive';
import { maxSize, minSize } from '@/play-editor/question-validation';

export type OptionItem = {
    label: string;
    id: string | number;
}

export type TypedOptionItem<T> = {
    value?: T;
    label: string;
    helpText?: string;
}

export type PropInfo<T> = {
    model: Ref<T>;
    prop: ModelProperty;
}

export function injectSuggestionsProvider() : SuggestionsProvider {
    return inject(SuggestionProviderKey, FallbackSuggestionsProvide);
}

export const FallbackSuggestionsProvide = <SuggestionsProvider>{
    fallback: true,
    data: {
        generatesSuggestions: false,
        suggestions: [],
        minimumResults: 0,
        expandedAnswers: {},
        missingFields: [],
        generating: false,
        errors: [],
        numAttempts: 0,
        visibleSuggestions: [],
        error: null,
        replacing: false,
        expectedResultCount: 6,
    },
    acceptSuggestion: notImplemented,
    generateSuggestions: notImplemented,
    rejectSuggestion: notImplemented,
    removeSuggestion: notImplemented,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log = logger('questionMixin');

// export default defineComponent({
//     inject: {
//         suggestionsProvider: {
//             from: ProviderName.suggestionsProvider,
//             default: FallbackSuggestionsProvide,
//         },
//     },
//     props: {
//         propMeta: {
//             type: Object,
//             required: true,
//         },
//         labelMerged: String,
//         descriptionMerged: String,
//         focused: Boolean,
//         question: Object as PropType<ModelProperty>,
//         validationError: Array as PropType<PropertyValidation[]>,
//         name: String,
//     },
//
//     mounted() {
//         const self = this as KeyValues;
//
//         if (self.onQuestionPageChange) {
//             // Registers for any page change events, and checks that there's not some
//             // outstanding state within this widget
//             self.$register(PlayLifecycleEvent.beforeChangePage, self.onQuestionPageChange);
//         }
//     },
//
//     computed: {
//         model: {
//             get() {
//                 return (this as any).value;
//             },
//            vset(value:unknown) {
//                 this.$emit('update', value);
//             },
//         },
//         suggestionsProvide(): SuggestionsProvider {
//             const self = this as KeyValues;
//
//             return self.suggestionsProvider;
//         },
//
//         questionValidators(): Dict<any> {
//             return this.question?.validators ?? {};
//         },
//
//         propInfo(): PropInfo<unknown> {
//             const { model, question: prop } = this;
//
//             return {
//                 model, prop,
//             };
//         },
//
//         questionLabel(): string {
//             return this.labelMerged || this.question.label;
//         },
//
//         questionDescription(): string {
//             return this.descriptionMerged || this.question.description;
//         },
//
//         questionAdditionalDescription() {
//             return this.question.additionalDescription;
//         },
//
//         hasFieldDescription(): boolean {
//             return this.questionLabel && this.questionLabel !== this.questionDescription;
//         },
//
//         minSize(): number {
//             return minSize(this.question.validators, 1);
//         },
//
//         maxSize(): number {
//             return maxSize(this.question.validators);
//         },
//
//         generatesSuggestions(): boolean {
//             return this.suggestionsProvide.data.generatesSuggestions;
//         },
//
//         missingFields(): string[] | null {
//             return this.suggestionsProvide.data.missingFields;
//         },
//
//         hasValue(): boolean {
//             return this.model != null;
//         },
//
//         suggestions(): SuggestionItem[] {
//             return this.suggestionsProvide.data.suggestions;
//         },
//
//         options(): OptionItem[] {
//             if (this.model && !this.suggestions
//                 .find((s) => s.item === (this as KeyValues).value)) {
//                 return [
//                     this.currentValueOption,
//                     ...this.suggestions.map(({ item }) => ({
//                         id: item,
//                         label: item,
//                     })),
//                 ];
//             }
//
//             return this.suggestions.map(({ item }) => ({
//                 id: item,
//                 label: item,
//             }));
//         },
//
//         suggestionsMeta(): KeyValues {
//             return this.getMetaArray(metaKeys.suggestions);
//         },
//
//         currentValueOption: {
//             get(): OptionItem {
//                 return {
//                     id: this.model,
//                     label: this.model,
//                 };
//             },
//
//            vset(value: OptionItem) {
//                 this.model = value?.id;
//             },
//         },
//
//         /**
//          * Checks if the validation errors are actually errors, and not just warnings
//          * @return {boolean}
//          */
//         isValidationSevere() {
//             for (const item of (this.validationError || [])) {
//                 if (item.error) {
//                     return true;
//                 }
//             }
//
//             return false;
//         },
//
//         hasValidationError(): boolean {
//             return this.hasValue && this.validationError?.length > 0;
//         },
//     },
//
//     methods: {
//         emitBlur(): void {
//             this.$emit('blur-question', this.question);
//         },
//
//         emitFocus(): void {
//             this.$emit('focus-question', this.question);
//         },
//
//         getMeta(key: string, defaultValue?: any): any {
//             return (this.propMeta)[key] ?? defaultValue;
//         },
//
//         getMetaArray(key: string) {
//             const meta = this.getMeta(key, []);
//
//             if (!Array.isArray(meta)) {
//                 log.warn(`Found a non array for ${key}`, meta);
//
//                 return [];
//             }
//
//             return meta;
//         },
//
//         fullErrorMessage(errorObj: KeyValues) {
//             if (errorObj.additionalHelp) {
//                 return `${errorObj.message}. ${errorObj.additionalHelp}`;
//             }
//
//             return errorObj.message;
//         },
//     },
// });
