/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CoachingClientDetail } from '../models/CoachingClientDetail';
import type { CoachingClientResults } from '../models/CoachingClientResults';
import type { DefaultPlaybook } from '../models/DefaultPlaybook';
import type { HostSystem } from '../models/HostSystem';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CoachingService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Coaching Clients
     * @returns CoachingClientResults getCoachingClients 200 response
     * @throws ApiError
     */
    public getCoachingClients(): CancelablePromise<CoachingClientResults> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/coach/clients',
        });
    }

    /**
     * Get Coaching Client
     * @param clientId
     * @param defaultPlaybook
     * @param hostSystem
     * @returns CoachingClientDetail getCoachingClient 200 response
     * @throws ApiError
     */
    public getCoachingClient(
        clientId: string,
        defaultPlaybook?: DefaultPlaybook | null,
        hostSystem?: HostSystem | null,
    ): CancelablePromise<CoachingClientDetail> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/coach/clients/{clientId}',
            path: {
                'clientId': clientId,
            },
            query: {
                'defaultPlaybook': defaultPlaybook,
                'hostSystem': hostSystem,
            },
        });
    }

}
