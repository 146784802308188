import {
    APPOINTMENT_SCHEDULED,
    BACKEND_DATE_FORMAT,
    LANDING_PAGE_FORM_SUBMITTED,
    SEND_EMAIL,
    SEND_SMS,
    SMS_DEFAULT_PHONE_TYPES,
    TAG_APPLIED,
    TimerType,
} from './automation.constants';
import { EventType, OffsetType } from './automations.timer.constants';
import moment from 'moment';
import {
    CreateAutomation,
    createAutomation,
    getAutomationNames,
} from '@/integration/hosts/keap-web/launch-play/create-automation';

import { ManualNavigateAction, PlayLaunchInput, PlayLaunchResult } from '@/integration/capabilities/capability-types';
import {
    ChecklistActionPriority,
    DateExpressionType,
    HostSystem,
    NavigateDestination,
    Play,
    PlayAssetDetails,
    PlayLaunchLink,
    PlaySequence,
    PlaySequenceStep,
    SequenceStart,
    TimerStep,
} from '@/generated/play-api';
import {
    EasyAutomationStepDef,
    EasyAutomationTimerConfig,
    EmailStepConfig,
    LaunchedSequence,
    LaunchResult,
    LinkStatus,
    SmsStepConfig,
} from '@/integration/hosts/keap-web/launch-play/launch-play-types';
import { logger } from '@/shared/logging';
import {
    LaunchLinkType, PlayAssetType, PlayStartType, PlayStepType,
} from '@/play-editor/play.constants';
import { KeyValues } from '@/types/core-types';
import { convertEmailContentToQuill } from '@/play-editor/capabilities/mappers';
import { createTag } from '@/integration/hosts/keap-web/api';
import { adjustDate } from '@/shared/date.utils';
import { convertToAutomationDuration } from '@/integration/hosts/keap-web/launch-play/duration-converter';
import { createGraphClient } from '@/shared/graphql.util';

const log = logger('launch_play');

const extraAssetTypes = [
    PlayAssetType.snippetPage,
    PlayAssetType.socialSnippets,
    PlayAssetType.htmlPage,
];

/**
 * Executes the launchPlay capability within keap-web.
 */
export function launchPlayKeapWebFn(appId: string) {
    return (input: PlayLaunchInput) => {
        return launchPlayKeapWeb(appId, input);
    };
}

async function launchPlayKeapWeb(appId:string, input: PlayLaunchInput): Promise<PlayLaunchResult> {
    const { play, playTemplate, answers } = input;

    if (!play || !playTemplate) {
        throw new Error('Missing inputs.  Expected play, playTemplate');
    }
    const { sequences = [] } = playTemplate;
    const launchResult = new LaunchResult(appId, HostSystem.KEAP_WEB, play, answers);

    const automationStartKeys = sequences.flatMap((s) => Object.values(s.starts)).flatMap((a) => a.assetKey);

    log.info('Automation Start Keys', automationStartKeys);
    const nonPublishAssets = playTemplate.assets.filter((asset) => extraAssetTypes.includes(asset.assetType) && !automationStartKeys.includes(asset.assetKey));

    log.info('Non-published Assets', nonPublishAssets);

    let automationNames:string[];

    try {
        automationNames = await getAutomationNames(appId);
    } catch (e) {
        log.warn('Unable to fetch existing automation names: ', e);
        automationNames = [];
    }

    for (const sequenceToCreate of sequences) {
        try {
            // eslint-disable-next-line no-await-in-loop
            const launched = await launchSingleSequence(appId, sequenceToCreate, launchResult, play, automationNames);
            const { status, title: sourceLabel, sequenceId: sourceId } = launched;

            launchResult.setLinkKey({
                hostSystem: HostSystem.KEAP_WEB,
                playLinkKey: sequenceToCreate.key,
                sourceId,
                linkType: LaunchLinkType.sequence,
                sourceLabel,
                status,
            });
        } catch (e) {
            log.error('Error creating sequence', e);
            throw new Error(`Error launching sequence: ${sequenceToCreate.key}`);
        }
    }

    // Hiding this for now
    // if (nonPublishAssets.length > 0) {
    //     launchResult.addManualStep({
    //         notification: true,
    //         title: 'Don\'t forget the extra assets',
    //         description: `Some assets are not included in the automation. You can still reference ${nonPublishAssets.slice(0, 5).map(({ title }) => `_${title}_`).join(', ')}, etc. .`,
    //     });
    // }

    return launchResult.build();
}

async function launchSingleSequence(
    appId:string,
    sequence: PlaySequence,
    launchResult: LaunchResult,
    play: Play,
    automationNames: string[],
)
    : Promise<LaunchedSequence> {
    const client = createGraphClient(appId);

    const {
        label, starts = [], steps = [],

    } = sequence;
    const { title } = play;

    log.info('launchSingleSequence: Initial data!', starts, steps);
    const stepsWithMetaData = steps.map((step, index) => mapStep(play, launchResult, index, step, sequence));
    const triggers = await Promise.all(starts.map((start, index) => mapStart(index, launchResult, start, sequence)));
    const mappedSteps = stepsWithMetaData.map(({ sequenceStep }) => sequenceStep);

    const baseAutomationName = `${title} - ${label || 'Automation'}`;
    let automationName = baseAutomationName;
    let i = 0;

    while (automationNames.includes(automationName) && i++ < 1000) {
        automationName = `${baseAutomationName} ${i}`;
    }

    if (automationNames.includes(automationName)) {
        automationName = `${baseAutomationName} ${Date.now()}`;
    }

    const automationData = <CreateAutomation>{
        name: automationName,
        description: `Sequence for AI Automation Assistant play ${automationName}`,
        triggers,
        steps: mappedSteps,
    };

    log.info('All automation data!', automationData.name);
    log.info('  triggers:', automationData.triggers);
    log.info('  steps:', automationData.steps);

    const createdAutomation = await createAutomation(client, automationData);

    if (!createdAutomation) {
        throw new Error('Something went wrong with creating automation!');
    }

    const { id: sequenceId } = createdAutomation;

    launchResult.resolveActionPlaceholders(LaunchLinkType.sequence, sequence.key, sequenceId);

    stepsWithMetaData.forEach(({ linkKey, asset, sequenceStep }) => {
        const launchLink = <PlayLaunchLink>{
            sourceId: `${sequenceId}.${sequenceStep.id}`,
            linkType: 'automation.step',
            status: 'ready',
            sourceLabel: title,
            playLinkKey: linkKey,
        };

        launchResult.setLinkKey(launchLink);

        if (asset) {
            launchLink.playLinkKey = asset.assetKey;
            launchResult.setLinkKey(launchLink);
        }
    });

    log.info('  FINAL:', createdAutomation);

    return {
        title: automationData.name,
        status: LinkStatus.inactive,
        sequenceId: createdAutomation.id,
    };
}

const notNull = Boolean;

function mapStepType(stepType: string): string {
    switch (stepType) {
    case PlayAssetType.email:
        return SEND_EMAIL;
    case PlayAssetType.sms:
        return SEND_SMS;
    default:
        throw Error(`Invalid stepType: ${stepType}`);
    }
}

function mapAssetConfigJson(type: string, asset: PlayAssetDetails): EmailStepConfig|SmsStepConfig {
    switch (type) {
    case SEND_EMAIL:
        return mapEmailStepConfigJson(asset);
    case SEND_SMS:
        return mapSmsStepConfigJson(asset);
    default:
        log.warn(' No config json mapping for ', type);

        return null;
    }
}

function mapEmailStepConfigJson(emailAsset: PlayAssetDetails): EmailStepConfig {
    const { sections = [], title } = emailAsset;
    const subject = sections.find(({ sectionKey }) => sectionKey === 'subject')?.content;
    const body = sections.find(({ sectionKey }) => sectionKey === 'body')?.content;

    log.info('  mapping email step from: ', emailAsset);

    return {
        fromContactOwner: true,
        fromUser: 0,
        body: convertEmailContentToQuill(body),
        subject,
        signatureEnabled: true,
        email: {
            title,
        },
    };
}

function mapSmsStepConfigJson(smsAsset: PlayAssetDetails): SmsStepConfig {
    log.info('  mapping sms step from: ', smsAsset);
    const { sections = [], title } = smsAsset;
    const message = sections.find(({ sectionKey }) => sectionKey === 'body')?.content;

    // We need to doctor the content a bit - specifically removing all HTML tags. This is the point
    // of no return ... sniff

    return {
        toFieldName: 'PHONE1',
        title,
        message,
        phoneTypes: [...SMS_DEFAULT_PHONE_TYPES],
    };
}

function mapStep(play: Play, launchResult: LaunchResult, index: number, step: PlaySequenceStep, sequence: PlaySequence): EasyAutomationStepDef {
    const { key: sequenceKey } = sequence;

    log.info('Mapping step', step, index, sequence);

    if (step.type === PlayStepType.asset) {
        const { assetKey } = step.asset;
        const asset = play.assets.find((a) => a.assetKey === assetKey) as PlayAssetDetails;

        if (!asset) {
            log.error(`Missing asset for assetKey: ${assetKey}`, step);
            throw Error(`Missing asset for assetKey: ${assetKey} for step: ${step}`);
        }
        const {
            assetType, title,
        } = asset;
        const type = mapStepType(assetType);
        const id = `${sequenceKey}-step-${step.type}-${index}`;

        return {
            asset,
            linkKey: id,
            sequenceStep: {
                id,
                type,
                name: [title].filter(notNull).join(' '),
                configJson: mapAssetConfigJson(type, asset),
            },
        };
    }

    if (step.type === PlayStepType.timer) {
        const id = `${sequenceKey}-timer-${index}`;

        const timerConfig = mapTimerConfigJson(step.timer);

        return {
            linkKey: id,
            sequenceStep: {
                id,
                name: 'timer',
                ...timerConfig,
            },
        };
    }

    return null;
}

const DEFAULT_START_TIME = '0800';

function mapTimerConfigJson(step: TimerStep): EasyAutomationTimerConfig {
    const {
        resolvedDateExpression, dateExpression, expressionType, duration = moment.duration(), durationType,
    } = step;

    const dateExpr = resolvedDateExpression || dateExpression;

    const delayConfig = convertToAutomationDuration(duration);

    switch (expressionType) {
    case DateExpressionType.APPOINTMENT:
        return {
            type: TimerType.relativeTimer,
            configJson: {
                eventType: EventType.appointment,
                offsetType: durationType,
                ...delayConfig,
            },
        };
    case DateExpressionType.DELAY:
        return {
            type: TimerType.delayTimer,
            configJson: {
                delayType: OffsetType.after,
                ...delayConfig,
            },
        };
    case DateExpressionType.EXACT:
        return {
            type: TimerType.relativeTimer,
            configJson: {
                ...delayConfig,
                eventType: EventType.date,
                exactDate: adjustDate(moment(dateExpr), durationType, duration).format(BACKEND_DATE_FORMAT),
                startTime: DEFAULT_START_TIME,
            },
        };
    default:
        throw new Error(`Cant process timer:${expressionType}`);
    }
}

async function mapStart(index: number, launchResult: LaunchResult, {
    type, label, assetKey,
}: SequenceStart, sequence: PlaySequence) {
    switch (type) {
    case PlayStartType.appointment: {
        const sourceAssetAnswers = (!assetKey ? null : launchResult.answers[assetKey] as KeyValues) ?? ({} as KeyValues);
        let { sourceId } = sourceAssetAnswers;
        const { hostSystem } = sourceAssetAnswers;

        if (sourceId && hostSystem === launchResult.hostSystem) {
            sourceId = null;
            log.info('  - FOUND A LINKED RECORD! for appointment: ', sourceId);
        } else if (assetKey) {
            launchResult.addManualStep({
                title: 'Important: Appointment missing',
                description: 'The "When" for this play still needs to be configured, or this automation will run for every type of appointment that is booked_. \n\nYou will need to select the correct appointment for the automation "When" before publishing it.',
                actions: [ManualNavigateAction({
                    label: 'Edit Automation',
                    priority: ChecklistActionPriority.TERTIARY,
                    destination: NavigateDestination.EASY_AUTOMATION,
                    hostSystem: launchResult.hostSystem,
                    placeholderId: sequence.key,
                    navigateParams: {
                        editMode: true,
                    },
                })],
                data: {
                    assetKey,
                },
            });
            log.warn('  - FOUND an assetKey but it did not resolve', assetKey, launchResult.answers);
        }

        return {
            id: `start${index}`,
            type: APPOINTMENT_SCHEDULED,
            name: label || 'Appointment scheduled',
            configJson: {},
            sourceId,
        };
    }

    case PlayStartType.landingPage: {
        launchResult.addManualStep({
            title: 'Create and link the Landing Page',

            description: 'Important: the landing page for this automation was NOT created. You will need to create and link the landing page before publishing this automation',
            data: { assetKey },
            actions: [
                ManualNavigateAction({
                    label: 'Create Landing Page',
                    priority: ChecklistActionPriority.TERTIARY,
                    destination: NavigateDestination.LANDING_PAGE,
                    hostSystem: launchResult.hostSystem,
                    navigateParams: {},
                }),
                ManualNavigateAction({
                    label: 'Edit Automation',
                    priority: ChecklistActionPriority.TERTIARY,
                    destination: NavigateDestination.EASY_AUTOMATION,
                    hostSystem: launchResult.hostSystem,
                    /// We don't have the sequenceId yet, so we use this placeholder and it's resolved later
                    placeholderId: sequence.key,
                    navigateParams: {
                        editMode: true,
                    },
                })],
        });

        return {
            id: `start${index}`,
            type: LANDING_PAGE_FORM_SUBMITTED,
            name: label || 'Form submitted',
            configJson: {},
            sourceId: null,
        };
    }

    case PlayStartType.tag: {
        let tag;

        try {
            tag = await createTag(launchResult.appId, {
                name: label || assetKey,
                description: 'Automatically generated for play',
                categoryId: null,
            });
        } catch (e) {
            tag = await createTag(launchResult.appId, {
                name: `${label || assetKey} ${Math.floor(Date.now() / 1000)}`,
                description: 'Automatically generated for play',
                categoryId: null,
            });
        }

        log.info('  > Created tag', tag);

        return {
            action: 'ADD',
            id: `start${index}`,
            type: TAG_APPLIED,
            name: label || 'Tag submitted',
            configJson: {},
            sourceId: parseInt(tag.id, 10),
        };
    }

    default:
        return null;
    }
}

// Create all sequences
