import gql from 'graphql-tag';
import { AppointmentsIntegration, GetAllIntegrationStatusesQuery, Tag } from '@/generated/keap-web-graphql';

import {
    AppointmentType, AppointmentTypeList, CreateTag, playClientTs,
} from '@/client/play-client';
import asserts from '@/shared/asserts';
import { createGraphClient } from '@/shared/graphql.util';

let integrationCache: AppointmentsIntegration[];

export async function listAppointmentTypes(appId: string): Promise<AppointmentType[]> {
    if (!integrationCache) {
        const graphClient = createGraphClient(appId);

        const { data } = await graphClient.query<GetAllIntegrationStatusesQuery>({
            query: gql`
                query GetAllIntegrationStatuses {
                    integrations {
                        appointment {
                            id
                            calendarProvider
                            calendarProviderAccountId
                            status
                            userId
                        }
                    }
                }`,
        });

        integrationCache = data.integrations.appointment ?? [];
    }

    const allTypes: AppointmentTypeList[] = await Promise.all(integrationCache.map((provider) => {
        const providerName = provider?.id ?? null;

        if (!providerName) {
            return Promise.resolve(<AppointmentTypeList>{});
        }

        return playClientTs.proxy.listApptTypes(appId, providerName);
    }));

    return allTypes.flatMap((e) => e.appointmentTypes ?? []);
}

export function getAppointmentType(appId:string, bookingLink:string): Promise<AppointmentType> {
    return playClientTs.proxy.listApptTypeByLink(appId, bookingLink);
}

export async function createTag(appId:string, tagData:CreateTag):Promise<Tag> {
    tagData.name = tagData.name.replace(/,/g, '');

    const tag = await playClientTs.proxy.createTag(appId, tagData);

    asserts(tag.id, 'Created tag must have an ID');

    return tag;
}
