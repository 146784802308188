import { rangeOf } from '@/shared/shared.utils';

export type PlaceholderRowDefinition = {
    height?: string;
    boxes?: number[];
};

export type PlaceholderDefinition =PlaceholderRowDefinition[];

export type RowDefinition = {
  height?:string;
  heightRem?:number;
  boxCount?: number;
  boxes?: number[];
};
declare interface PlaceholderBuilder extends PlaceholderDefinition{
    row(definition:RowDefinition): PlaceholderBuilder;
}

export function placeholderBuilder():PlaceholderBuilder {
    const placeholder = <PlaceholderBuilder>[];

    placeholder.row = ({
        height, heightRem, boxes, boxCount,
    }: RowDefinition) => {
        height ??= (heightRem ? `${heightRem}rem` : '3rem');
        boxes ??= !boxCount ? [1] : rangeOf(0, boxCount).map(() => 1);
        placeholder.push({ height, boxes });

        return placeholder;
    };

    return placeholder;
}
