import { FlagsState, SetFlagOptions } from '@/store/flags/types';

export default {
    SET_FLAG(state:FlagsState, { flag, value }:SetFlagOptions) {
        state[flag] = value;
    },

    SET_LEGACY_QUESTIONS(state:FlagsState, value:boolean) {
        state.legacyQuestions = value;
    },
};
