import { nextTick } from 'vue';
import { beforeResolveRouteAuth } from '@/router/utils/router.util';
import interceptor from '@/router/interceptor';
import { RouteLocationNormalized, Router } from 'vue-router';
import { State } from '@/store/play-root-state';
import { Store } from 'vuex';
import asserts from '@/shared/asserts';
import { RouteName } from '@/router/route-names';
import { resolveCode } from '@/play-editor/mixins/v3/schema-i18n';
import { setUserVars } from '@fullstory/browser';
import { I18nType } from '@/shared/compat';

export type VueRouter = Router;

export function setupVueRouter(router: VueRouter, store: Store<State>, i18n: typeof I18nType) {
    asserts(router);
    asserts(store);
    asserts(i18n);

    router.beforeEach((to, from, next) => {
        try {
            const { appId, coachId } = to.params;

            Object.entries(to.query).forEach(([key, queryValue]) => {
                if (key.startsWith('flag.')) {
                    const flag = key.substring(5);

                    const value = Boolean(queryValue === 'true');

                    store.commit('flags/SET_FLAG', { flag, value });
                }
            });

            if (appId) {
                store.commit('auth/SET_APP_ID', appId);
                setUserVars({
                    appName: appId,
                });
            }

            if (coachId) {
                store.commit('auth/SET_COACH_ID', coachId);
            }
        } catch (e) {
            return next({ name: RouteName.error });
        }

        return next();
    });

    router.beforeEach((to, _, next) => {
        setPageTitleForRoute(i18n, to);
        next();
    });

    router.beforeResolve((to, from, next) => {
        return beforeResolveRouteAuth(to, from, next, store, i18n, router);
    });

    interceptor.setup(router);
}

const DEFAULT_TITLE = 'Keap AI Automation Assistant';
const setPageTitleForRoute = (i18n: I18nType, to: RouteLocationNormalized) => {
    const { meta: { title }, name } = to;

    const routeTitle = title?.toString() || `routes.${name.toString() || 'default'}`;

    // noinspection JSIgnoredPromiseFromCall
    void nextTick(() => {
        document.title = resolveCode(i18n.t.bind(i18n), routeTitle, DEFAULT_TITLE);
    });
};
