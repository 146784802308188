/**
 * This abstraction represents an external provider for some data model.  For example, keap-web appointmentTypes may be
 * used in plays, but we want to fetch those options directly from the API.  This class holds all the necessary information
 * for us to do that.
 */
import { DataStore } from '@/integration/datastore/data-store-api';
import { DataStoreOperations } from '@/integration/datastore/base-types';
import { ModelKeyOrName } from '@/integration/datastore/model-keys';
import { HostSystem } from '@/generated/play-api';

/**
 * A {@link DataStore} that is constructed by passing individual operations.
 */
export class DataStoreBuilder extends DataStore {
    static of(appId: string, type: ModelKeyOrName, hostSystem: HostSystem, operations: DataStoreOperations): DataStoreBuilder {
        return new DataStoreBuilder(appId, type, hostSystem, operations);
    }
}
