<script lang="ts">export default { name: 'RefDisplay' }; </script>

<template>
    <div>
        <div v-if="error" class="error">
            {{ error }}
        </div>
        <FullPageSpinner v-else-if="loading" />
        <div v-else-if="cardOptions">
            <div v-for="(prop, i) in cardOptions.cardProperties" :key="i" :class="cardClasses(prop)">
                {{ getPropertyValue(refModel || modelsById[refId], prop) }}
            </div>
            <div v-if="$slots.error" class="error">
                <slot name="error" />
            </div>
        </div>
        <div v-else>
            <slot :ref-model="refModel || modelsById[refId]" />
        </div>
    </div>
</template>
<script lang="ts" setup>
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ModelKey } from '@/integration/datastore/model-keys';

import {
    computed,
    onMounted,
    ref,
} from 'vue';
import { modelMixin } from '@/play-editor/mixins/v3/modelMixin';
import { CardDisplayOptions, CardProperty, RefType } from '@/generated/play-api';
import { PreparedModel } from '@/integration/datastore/base-types';
import { KeyValues } from '@/types/core-types';
import { useCore } from '@/shared/shared-providers';
import ModelEditPage from '@/play-editor/model/ModelEditPage.vue';
import FullPageSpinner from '@/shared/components/FullPageSpinner.vue';

const props = defineProps<{
    cardOptions?: CardDisplayOptions,
    refId: string;
    refModel: PreparedModel;
    refType: ModelKey|RefType;
}>();
const emit = defineEmits(['ref-missing']);

const { log, appId } = useCore();
const {
    isReady, modelsById, ...modelProvider
} = modelMixin(appId.value, props.refType, ModelEditPage);

const loading = ref(true);
const error = ref(null);

onMounted(async () => {
    try {
        await modelProvider.loadModel(props.refId);
        loading.value = false;
    } catch (e) {
        log.severe('ERROR', e);
        const k = e as KeyValues;

        if (k.response) {
            const { status } = k.response as KeyValues;

            if (status === 404) {
                error.value = 'Problem loading record';
                emit('ref-missing', null);
            }
        } else if ((e as KeyValues).status === 404 || e.toString().toLowerCase().includes('not found')) {
            error.value = 'Problem loading record';
            emit('ref-missing', null);
        }
    }
});

const cardOptions = computed(() => props.cardOptions as CardDisplayOptions);

function getPropertyValue(model: PreparedModel, prop: CardProperty): any {
    if (!model?.data) return null;

    return model.data[prop.propertyName] ?? (model as KeyValues)[prop.propertyName];
}

function cardClasses(prop: CardProperty) {
    return [
        'card-property',
        ...(prop.styles ?? []).map((style) => `cp-${style.replaceAll(/_/g, '-').toLowerCase()}`),
    ];
}
</script>

<style lang="scss" scoped>
    .cp-large {
        font-size: $font-size-lg;
        line-height: $line-height-lg;
    }

    .cp-medium {
        font-size: $font-size-md;
        line-height: $line-height-md;
    }

    .cp-small {
        font-size: $font-size-sm;
        line-height: $line-height-sm;
    }

    .cp-xs {
        font-size: $font-size-xs;
        line-height: $line-height-sm;
    }

    .cp-align-end {
        text-align: end;
    }

    .cp-hyphenated::before {
        content: "—";
    }

    .cp-center {
        text-align: center;
    }

    .cp-quote {
        font-size: $font-size-md;
        line-height: $line-height-md;
        quotes: "\201C" "\201D" "\2018" "\2019";
        color: $color-text-subtle;
        margin-top: calc(1rem / 2);
        margin-bottom: calc(1rem / 2);

        &::before {
            font-size: $font-size-xl;
            line-height: $font-size-lg;
            content: open-quote;
        }

        &::after {
            font-size: $font-size-xl;
            line-height: $font-size-lg;
            content: close-quote;
        }
    }

</style>
<i18n>
{
    "en-us": {
    }
}
</i18n>
