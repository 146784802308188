import {
    CreateChecklistRequest,
    ModelPropertyGroup,
    Play,
    PlayAssetDetails,
    PlayChecklist, PlayChecklistItem,
    PlayContentState as PlayStateEnum,
    PlayLaunchLink,
    V2PlayTemplate,
    V2PlayTemplateAssetInfo,
} from '@/generated/play-api';
import { PageModel } from '@/play-editor/play/PlayModel';
import { Dict, KeyValues, VoidCallback } from '@/types/core-types';
import { PlayLaunchManualStep, PlayLaunchResult } from '@/integration/capabilities/capability-types';
import { FrameService } from '@/integration/host-system-api';
import { Ref } from 'vue';
import { HostProvide } from '@/play-editor/provider/provider-types';
import { CoreProvide } from '@/shared/core-provide.types';
import { Logger } from '@/shared/logging';

export interface PlayState {

    /**
     * Whether this provider is a fallback provider
     */
    fallback?: boolean;
    lastSave: Date;
    play: Play;
    focusedAsset: PlayAssetDetails;
    playTemplate: V2PlayTemplate;
    isError: boolean;
    isSaving: boolean;
    isPreparing: boolean;
    isLaunchingPlay: boolean;
    isEditingQuestionsOverride: boolean,
    checklists: PlayChecklist[];
    modified: Dict<Dict<string>>;
    previewData: KeyValues;
    justLaunched: boolean;
    launchResult: PlayLaunchResult;
    highlightFields: false,
    originalAnswers: KeyValues;
    currentSave?: {
        reason?: string;
        readonly promise?: Promise<unknown>;
    };
    isOpen: boolean;

    // Computed
    readonly activeStep: number;
    readonly pageModel: PageModel;
    readonly isEditingQuestions: boolean;
    readonly launchLinks?: PlayLaunchLink[];

    /**
     * By assetId: whether the asset has unsaved changes
     */
    readonly modifiedAssets?: Dict<boolean>;
    readonly frameProvide?: FrameService;
    readonly isControlled?: boolean;
    readonly sequences?: ExtendedLaunchLink[];
    readonly launchAdditionalSteps?: PlayLaunchManualStep[] | null;
    readonly canLaunchPlay?: boolean;
    readonly hasAutomations?: boolean;
    readonly launchPlayLabel?: string;
    readonly hasLaunchItems?: boolean;
    readonly playId?: string;
    readonly answers?: KeyValues;
    readonly propertyGroups?: ModelPropertyGroup[];
    readonly assets?: Dict<V2PlayTemplateAssetInfo>;
    readonly hasAnyModifications?: boolean;
    readonly createdFromColumn?: boolean;
    readonly isReady?: boolean;
}

export type PlayStageName = {
    label: string;
    showError: boolean;
    status: string;
    complete: boolean;
}

export type SaveParams = Partial<Play> & {
    reason?: string;
    state?: PlayStateEnum;
    contentState?: PlayStateEnum;
    meta?: KeyValues;
    answers?: KeyValues;
};

export interface PlayProvider {
    appId: string;

    state?: PlayState;

    updatePlayDetails(editNameValue: string): Promise<void>;

    saveAnswers(answers: KeyValues, meta?: KeyValues): Promise<void>;

    doneWithQuestions(): Promise<void>;

    addChecklist(create: CreateChecklistRequest): Promise<PlayChecklist>;

    scheduleItemTemplateUpdate(checklist: PlayChecklist, item: PlayChecklistItem, isChecked: boolean): Promise<void>;

    loadTemplateForPlay(play: Play): Promise<V2PlayTemplate>;

    launchPlay(play: Play, playTemplateId: string, answers: KeyValues): Promise<PlayLaunchResult>;

    waitForPriorSave(number?: number): Promise<void>;

    /**
     * Saves the current state of each asset
     */
    saveAllAssets(): Promise<void>,

    updateSectionContent(params?: { assetId?: string, sectionId?: string, data?: string }): Promise<void>;

    /**
     * Saves play data to the backend.
     *
     */
    savePlay(param: SaveParams): Promise<unknown>;

    saveAsset(param: { assetId: string; changes?: KeyValues, themeId?: string }, force?: boolean): Promise<unknown>;

    loadPlayAndTemplate(playId: string): Promise<unknown>;

    persistPlay(reason: string): Promise<Play>;

    previewEmbedded(): Promise<unknown>;

    /**
     * Checks to see if there are any content changes.  If so, it displays a confirmation message and returns the
     * result.  False indicates that the user wants to stay on the page
     */
    checkChanges(): Promise<CheckChangesResult>;
}
export enum CheckChangesResult {
    noChanges, changesIgnore, changesStay
}

export type PlayProviderParams = {
    focusedPlayId?: Ref<string>;
    onClose: VoidCallback;
    hostProvider?: HostProvide;
    coreProvider?: CoreProvide;
    appId?: string;
    log?: Logger;
};

export type ExtendedLaunchLink = PlayLaunchLink & {
    linkTypeName: string;
    updateTime: string;
    capabilityKey: string;
};
