import asyncBus from '@/shared/async-bus';
import { onUnmounted } from 'vue';
import mitt from 'mitt';
import { logger } from '@/shared/logging';
import { Play } from '@/generated/play-api';

export enum PlayEvent {
    navigateBack = 'navigateBack',
    savePlay = 'savePlay',
    test = 'MY_EVENT'
}

export interface PlayEventTypes extends Record<string|symbol, unknown>{
    [PlayEvent.navigateBack]: void;
    [PlayEvent.savePlay]: Play;
}

let bus = mitt<PlayEventTypes>();

const log = logger('events');

export type EventHandler<T> = ((payload?: T) => void);

export function listenForEvents<K extends PlayEvent>(eventName: K, handler: EventHandler<PlayEventTypes[K]>, name?:string) {
    const eventLogger = log.child(eventName, true);
    const eventListenerLogger = eventLogger.child(name ?? 'listener', true);

    eventLogger.info(`registered: ${name}`);

    const handleIt = (value: PlayEventTypes[K]) => {
        eventListenerLogger.debug('handled', value);
        handler(value);
    };

    bus.on(eventName, handleIt);

    onUnmounted(() => {
        eventLogger.info(`unregistered: ${name}`);
        bus.off(eventName, handleIt);
    });
}

export function resetEvents() {
    bus = mitt();
}

export function fireEvent<K extends keyof PlayEventTypes>(eventName: K, payload?: PlayEventTypes[K]) {
    log.child(eventName as string, true).debug('fire', payload);

    bus.emit(eventName, payload);
}

function getBus() {
    return bus;
}

export { asyncBus, getBus };
export default {};
