<template>
    <div :class="`prop-field ${questionType}`">
        <div :class="`question-control ${questionType}`">
            <component
                :is="editComponent"
                v-model="value"
                :prop-meta="propMeta"
                :has-value="hasValue"
                :focused="focused"
                :name="property.name"
                :label-merged="labelMerged"
                :description-merged="descriptionMerged"
                :question="property"
                :validation-error="validationErrors"
                v-on="$attrs"
                @update:model-value="updateAnswer"
                @focus="focusProp"
                @blur="blurProp"
                @submit="$emit('submit')"
                @refresh-model="refreshRefs"
                @change-item="propForm.syncSuggestionsMeta"
            >
                <template #helperText>
                    <MarkdownDisplay
                        v-if="helperTextMerged != null"
                        class="helper-text"
                        data-qa="helperText"
                        :markdown="helperTextMerged"
                    />
                    <span v-else>&nbsp;</span>
                </template>
            </component>

            <div v-if="generatingDefaultValueMessage">
                <InlineAlert>
                    {{ generatingDefaultValueMessage }}
                </InlineAlert>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {
    computed, defineComponent, PropType, toRef, toRefs, watch,
} from 'vue';
import { PropertyProvide } from '@/model/form/form-provider-types';
import { PropertyPrimitiveType } from '@/generated/play-api';
import PlayQuestionRefList from '@/play-editor/properties/PlayQuestionRefList.vue';
import PlayQuestionArray from '@/play-editor/properties/PlayQuestionArray.vue';
import PlayQuestionLongText from '@/play-editor/properties/PlayQuestionLongText.vue';
import PlayQuestionText from '@/play-editor/properties/PlayQuestionText.vue';
import PlayQuestionDateTime from '@/play-editor/properties/PlayQuestionDateTime.vue';
import PlayQuestionDuration from '@/play-editor/properties/PlayQuestionDuration.vue';
import PlayQuestionRef from '@/play-editor/properties/PlayQuestionRef.vue';
import MarkdownDisplay from '@/play-editor/properties/MarkdownDisplay.vue';
import PlayQuestionUnknown from '@/play-editor/properties/PlayQuestionUnknown.vue';
import { useCore } from '@/shared/shared-providers';
import PlayQuestionCheckbox from '@/play-editor/properties/PlayQuestionCheckbox.vue';

export default defineComponent({
    name: 'PropertyFormField',
    components: {
        MarkdownDisplay,
        PlayQuestionRefList,
        PlayQuestionText,
        PlayQuestionLongText,
        PlayQuestionUnknown,
        PlayQuestionRef,
        PlayQuestionArray,
    },

    props: {
        propForm: {
            type: Object as PropType<PropertyProvide<unknown>>,
            required: true,
        },
    },

    emits: ['submit', 'update:model-value'],

    setup(props) {
        // eslint-disable-next-line vue/no-setup-props-destructure
        const { propForm } = props;
        const { log: clog } = useCore();
        const log = clog.child(`PropFormField:${propForm.name}`);
        const {
            form,
            property,
            state,
            helperMerge,
            updateAnswer,
            focus: focusProp,
            blur: blurProp,
        } = propForm;

        const {
            hasValue, metaData, validationErrors, generatingDefaultValueMessage,
            focused,
            value,
        } = toRefs(state);

        watch(value, (newValue, oldValue) => {
            log.info('GOT PROPERTY UPDATE', { newValue, oldValue });
        });

        const {
            update: updateAllMergeProps,
            values: { helperText, label, description },
        } = helperMerge(['helperText', 'label', 'description']);

        // Watch for this field to be focused, and merge props
        watch(() => state.focused, (newValue, oldValue) => {
            if (!oldValue && newValue) {
                updateAllMergeProps();
            }
        });

        // Watch all form answers, and re-merge help text
        watch(toRef(form.state, 'answers'), updateAllMergeProps);

        const questionType = computed<PropertyPrimitiveType>(() => {
            return property.type?.base;
        });

        return {
            value,
            focused,
            hasValue,
            generatingDefaultValueMessage,
            updateAnswer,
            focusProp,
            blurProp,
            refreshRefs: form.refreshRefs,
            property,
            propMeta: metaData,
            validationErrors,
            helperTextMerged: helperText,
            labelMerged: label,
            descriptionMerged: description,
            questionType,
            editComponent: computed(() => {
                // eslint-disable-next-line default-case
                switch (questionType.value) {
                case PropertyPrimitiveType.ARRAY:
                    if (property.type.array?.listType?.base === PropertyPrimitiveType.REF) {
                        return PlayQuestionRefList;
                    }

                    return PlayQuestionArray;
                case PropertyPrimitiveType.LONG_TEXT:
                    return PlayQuestionLongText;
                case PropertyPrimitiveType.TEXT:
                    return PlayQuestionText;
                case PropertyPrimitiveType.DATETIME:
                    return PlayQuestionDateTime;
                case PropertyPrimitiveType.DURATION:
                    return PlayQuestionDuration;
                case PropertyPrimitiveType.REF:
                    return PlayQuestionRef;
                case PropertyPrimitiveType.BOOLEAN:
                    return PlayQuestionCheckbox;
                }

                return PlayQuestionUnknown;
            }),
        };
    },

});

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .prop-field {
        display: grid;
        grid-template-columns: 1fr 0fr;

        > * {
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    .unknown-field {
        color: $color-red;
    }

    .helper-text {
        font-size: $font-size-xs;
        color: $color-ink-800;
    }
</style>
