<template>
    <div>
        <div v-if="questionDescription || questionLabel" class="question-description">
            {{ questionDescription ? questionDescription : questionLabel }}<span
                class="required"
            >{{ (validators.required) ? t('shared.requiredSymbol') : '' }}</span>
        </div>
        <div v-if="questionAdditionalDescription" class="question-additional-description">
            {{ questionAdditionalDescription }}
        </div>
        <div class="question-text">
            <PlayPopover
                :position="PopoverPosition.rightStart"
                ignore-clicks
                position-fixed
                block
                :arrow-offset="{
                    top: '-15px',
                }"
                :disabled="!hasFieldDescription"
                :is-open="bool(focused)"
                class="pqt-popover-container"
            >
                <template #default>
                    <slot name="popover">
                        <div class="help-popover">
                            {{ questionDescription }}
                        </div>
                    </slot>
                </template>
                <template #reference>
                    <AbsolutePlacement>
                        <template #reference>
                            <DsDatePicker
                                :model-value="model"
                                :name="question.name"
                                time
                                static
                                :force-invalid="hasValidationError"
                                :label="questionLabel"
                                :data-qa="`date-picker-${question.name}`"
                                class="question-input"
                                :class="{
                                    warning: !isValidationSevere && hasValidationError,
                                    invalid: hasValidationError,
                                }"
                                :required="questionValidatorSummary.required"
                                :submitted="hasValue"
                                @update:model-value="emit('update:model-value', $event ? $event.toISOString() : null)"
                                @blur="onBlur"
                                @focus="onFocus"
                            >
                                <!-- Displays error messages from the server -->
                                <template v-if="hasValidationError" #error>
                                    <div
                                        v-for="error in validationError"
                                        :key="error.message"
                                        :class="{ 'validation-error': true, warning: !isValidationSevere && hasValidationError }"
                                    >
                                        {{ fullErrorMessage(error) }}
                                        <!-- Also show the help message to guide them further, since it is hidden while errors are displayed -->
                                        <slot name="helperText" />
                                    </div>
                                </template>

                                <!-- Displays helperText with current value merged in -->
                                <template #help>
                                    <slot name="helperText" />
                                </template>

                                <!-- Suggestions trailing icon and menu-->
                                <template #trailing />
                            </DsDatePicker>
                        </template>
                        <template #default>
                            <PlaySuggestions
                                v-if="generatesSuggestions"
                                :disabled="missingFields.length > 0"
                                :excludes="model ? model.toISOString() : null"
                                :allow-multiple="null"
                                :question="question"
                                @accept="suggestion = $event"
                            />
                        </template>
                    </AbsolutePlacement>
                </template>
            </PlayPopover>
        </div>
    </div>
</template>
<script lang="ts">export default { name: 'PlayQuestionDateTime' }; </script>
<script lang="ts" setup>

import PlaySuggestions from '@/play-editor/PlaySuggestions.vue';
import { metaKeys } from '@/play-editor/play.constants';
import PlayPopover from '@/play-editor/PlayPopover.vue';
import AbsolutePlacement from '@/shared/components/AbsolutePlacement.vue';
import { ModelProperty } from '@/generated/play-api';
import { PlayService } from '@/play-editor/play-service';
import { useAppId } from '@/play-editor/provider/provide-app-id';
import { useCore } from '@/shared/shared-providers';
import { computed, ref } from 'vue';
import { PlayQuestionCoreEvents, questionMixin } from '@/play-editor/mixins/v3/questionMixin';
import { PopoverPosition } from '@/play-editor/PopoverPosition';
import { DEFAULT_EVENT_NAME } from '@/play-editor/mixins/v3/v-model';
import { QuestionPropsType } from '@/shared/proptypes/QuestionPropsType';
import { propertyValidatorHelper } from '@/generated-ext/property-validator-helpers';

const props = defineProps<QuestionPropsType<Date>>();
const emit = defineEmits([...PlayQuestionCoreEvents, DEFAULT_EVENT_NAME]);
const playService = PlayService(useAppId(useCore()));
const {
    questionDescription, questionAdditionalDescription,
    questionLabel, model, getMetaArray,
    missingFields, hasValidationError,
    isValidationSevere,
    fullErrorMessage, generatesSuggestions, hasFieldDescription, questionValidators,
    questionValidatorSummary,
} = questionMixin<Date>(props, emit, null);

const question = computed(() => props.question as ModelProperty);
const validators = computed(() => propertyValidatorHelper(question.value.validators));

const suggestion = ref(null);

function onFocus() {
    emit('focus');
}

function onBlur() {
    const [suggestion] = getMetaArray(metaKeys.suggestions);

    if (suggestion) {
        const { resultId, item } = suggestion;

        if (item !== props.modelValue) {
            playService.addAcceptInteraction(resultId, item, props.modelValue);
        }
    }
    emit('blur');
}

</script>

<style lang="scss" rel="stylesheet/scss">
    // These unscoped styles are for replacing the typical red with orange
    .input-field.warning input.submitted:invalid,
    .input-field.warning input.submitted:invalid:focus {
        border: 1px solid $color-orange-600;
        border-color: $color-orange-600;
    }

    /* stylelint-disable */
    .date-picker-container input.numInput {
        border: 0;
    }

    /* stylelint-enable */

</style>
<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@/styles/main";
    @import "../../play-editor/properties/play-question";

    .help-popover {
        min-height: 130px;
        padding: 1rem;
    }

    .validation-error.warning {
        color: $color-orange-600;
    }

    .dropdown-menu-group {
        color: $color-text-subtle;
        font-size: $font-size-xs;
        padding: 0 1rem;
    }

    .input-trailing-suggest {
        cursor: pointer;
    }

    .dropdown-menu-list {
        width: calc(500rem / 16);
    }

    .no-suggestions {
        color: $color-text-disabled;
    }

    .suggestions-header {
        margin-top: 1rem;
        margin-bottom: calc(1rem / 2);
    }

    .results-container {
        margin: auto;
        align-content: center;
        text-align: center;
    }

    .field-error {
        color: $color-red;
        margin-top: calc(1rem / 2);
    }

    .play-question-list {
        margin-bottom: 1rem;
    }

    .input-form {
        margin-top: 1rem;
    }

    .inline-input {
        margin-bottom: calc(1rem / 4);
        padding-bottom: calc(1rem / 4);
    }

    .pqt-popover-container {
        width: 100%;
    }

</style>
<i18n>
{
    "en-us": {
    }
}
</i18n>
