<template>
    <div class="suggestion-container">
        <SuggestionsResults v-if="noButton" :question="question" @close-suggestion="emit('close-suggestion')" />
        <TooltipWithDelay v-else-if="disabled">
            <template #reference>
                <TextButton
                    :class="['input-trailing-suggest', { dense, disabled }]"
                    :name="icon"
                    :leading-icon="icon"
                >
                    {{ $t(label) }}
                </TextButton>
            </template>
            <template #default>
                <div>{{ t('disabledSuggestions') }}</div>
            </template>
        </TooltipWithDelay>
        <PlayPopover
            v-else
            ref="popper"
            hide-arrow
            :keep-visible="null"
            :position="PopoverPosition.bottomStart"
            class="suggestion-menu suggestion-popover"
            :is-open="bool(isMenuOpen)"
            @close="hideSuggestions"
            @popover-active="onMenuChange"
        >
            <template #reference>
                <div @click.stop="openSuggestions">
                    <TextButton
                        :class="['input-trailing-suggest', { dense, disabled }]"
                        :name="icon"
                        :leading-icon="icon"
                    >
                        {{ $t(label) }}
                    </TextButton>
                </div>
            </template>

            <template #default>
                <SuggestionsResults :question="question" @close-suggestion="isMenuOpen = allowMultiple" />
            </template>
        </PlayPopover>
    </div>
</template>

<script lang="ts">export default { name: 'PlaySuggestions' }; </script>
<script lang="ts" setup>
import SuggestionsResults from '@/play-editor/SuggestionsResults.vue';
import {
    isReactive, onMounted, reactive, ref, toRefs, watch,
} from 'vue';
import { injectSuggestionsProvider } from '@/play-editor/mixins/questionMixin';
import { useLog } from '@/shared/shared-providers';
import PlayPopover from '@/play-editor/PlayPopover.vue';
import asserts from '@/shared/asserts';
import cloneDeep from 'lodash/cloneDeep';
import { PopoverPosition } from '@/play-editor/PopoverPosition';
import TooltipWithDelay from '@/shared/components/TooltipWithDelay.vue';
import { KeapIcon, ModelProperty } from '@/generated/play-api';
import { KeyValues } from '@/types/core-types';

const props = withDefaults(defineProps<{
        label?: string;
        icon?: KeapIcon;
        question?:ModelProperty,
        disabled?: boolean,
        noButton?: boolean,
        excludes?: string[]|string;
        maxDisplay?: number,
        allowMultiple?: boolean;
        dense?: boolean,
        tooltipPosition?:string;
    }
    >(), {
    label: 'getSuggestions',
    icon: KeapIcon.WAND,
    allowMultiple: false,
    tooltipPosition: PopoverPosition.right,
});

const emit = defineEmits(['suggestions-menu', 'close-suggestion']);

const log = useLog();
const data = reactive({
    isMenuOpen: false,
});
const { data: suggData, ...suggestionsProvider } = injectSuggestionsProvider();

onMounted(() => {
    if (props.noButton === true && !props.disabled) {
        suggestionsProvider.generateSuggestions(false, 'Auto generate (no button)');
    }
});

const popper = ref();

asserts(isReactive(suggData), 'Suggestion data must be reactive');

watch(() => cloneDeep({
    visibleSuggestions: suggData.visibleSuggestions,
    suggestions: suggData.suggestions,
    error: suggData.error,
    generating: suggData.generating,
}), () => {
    /// If suggestions change, tell the popover to resize itself
    asserts(popper.value, 'Should hold a ref');
    const popperValue = popper.value as KeyValues;

    popperValue.update();
});

const { isMenuOpen } = toRefs(data);

function openSuggestions() {
    if (!props.disabled) {
        log.debug('Opened suggestions');
        data.isMenuOpen = true;
    }
}

function hideSuggestions() {
    log.debug('Closed suggestions');
    data.isMenuOpen = false;
}

function onMenuChange(isOpen: boolean) {
    if (!props.disabled) {
        log.debug('Menu change', isOpen);
        emit('suggestions-menu', isOpen);

        if (isOpen && suggData.suggestions.length === 0) {
            suggestionsProvider.generateSuggestions(false, 'Initial suggestions request');
        }

        return isOpen;
    }

    return false;
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@/styles/main";

    $suggestion-width: 25rem;

    .suggestion-container {
        position: relative;
    }

    .results-container {
        width: $suggestion-width;
        margin: auto;
        align-content: center;
        text-align: center;
    }

    .dropdown-menu-list {
        @include dropdown-list;
    }

    .dropdown-menu-item {
        @include dropdown-list-item;

        padding-top: calc(1rem / 4);
        padding-bottom: calc(1rem / 4);
    }

    .dropdown-menu-group {
        color: $color-text-subtle;
        font-size: $font-size-xs;
        padding: 0 1rem;
    }

    .dropdown-menu-group.suggestion-label {
        min-height: calc(1rem * 3);
    }

    .input-trailing-suggest {
        &.disabled {
            color: $color-text-disabled;
        }
    }

    .input-trailing-suggest.dense {
        padding: calc(1rem / 4);
        margin: auto;
    }

    .no-suggestions {
        color: $color-text-disabled;
    }

    .suggestions-header {
        margin-top: 1rem;
        margin-bottom: calc(1rem / 2);
    }

    .field-error {
        color: $color-red;
        margin-top: calc(1rem / 2);
    }

    .play-question-list {
        margin-bottom: 1rem;
    }

    .input-form {
        margin-top: 1rem;
    }

    .inline-input {
        margin-bottom: calc(1rem / 4);
        padding-bottom: calc(1rem / 4);
    }

    .suggestion-popover {
        cursor: pointer;

        --popover-width: $suggestion-width;
        --popover-min-width: $suggestion-width;
    }

</style>
<i18n>
{
    "en-us": {
        "getSuggestions": "Get suggestions",
        "suggestions": "Suggestions",
        "noSuggestions": "Suggestions appear here",
        "suggestionsAvailable": "Suggestions available. Click to see",
        "generateMore": "Generate more suggestions",
        "errorGenerating": "There was a problem generating suggestions",
        "disabledSuggestions": "Suggestions are disabled because you are missing some required fields."
    }
}
</i18n>
