import {
    computed, inject, provide, Ref,
} from 'vue';
import { ProviderName } from '@/play-editor/play.constants';
import { PlayStore } from '@/store/play-root-state';
import { dict, KeyValues } from '@/types/core-types';
import { VueRouter } from '@/shared/compat';
import { fromArray } from '@/shared/type.utils';

export const AppIdProviderKey = 'appIdProvider';

export type AppIdProvider = Ref<string | null>;

function useCore() {
    return inject<KeyValues>(ProviderName.coreProvider);
}

export function provideRouterAppId({
    router,
    store,
}: { router?: VueRouter, store?: PlayStore } = {}, doProvide = true): AppIdProvider {
    if (router == null || store == null) {
        const core = useCore();

        router ??= core.router;
        store ??= core.store;
    }

    return provideAppId({ store }, computed(() => {
        const { appId, focusedClientId } = router.currentRoute.value?.params ?? dict<string>();

        return fromArray(appId || focusedClientId);
    }), doProvide);
}

// eslint-disable-next-line default-param-last
export function provideAppId({ store }: { store?: PlayStore } = {}, appId?: Ref<string | null>, doProvide = true) {
    store ??= useCore().store;

    const parentAppId = useAppId({ store });
    const calculatedAppId = computed(() => {
        return appId?.value || parentAppId?.value;
    });

    if (doProvide) {
        provide(AppIdProviderKey, calculatedAppId);
    }

    return calculatedAppId;
}

let vuexFallback: AppIdProvider;

function buildVuexFallback(store: PlayStore): AppIdProvider {
    return computed(() => store.state.auth.session?.coreAppId);
}

export function useAppId({ store }: { store?: PlayStore } = {}): Ref<string | null> {
    store ??= inject<KeyValues>(ProviderName.coreProvider)?.store;

    return inject(AppIdProviderKey, getVuexFallback(store));
}

export function getVuexFallback(store: PlayStore) {
    vuexFallback ??= buildVuexFallback(store);

    return vuexFallback;
}
