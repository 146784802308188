<script lang="ts">export default { name: 'PlayQuestionArray' }; </script>
<template>
    <div class="play-question-list">
        <div v-if="questionDescription || questionLabel" class="question-description">
            {{ questionDescription ? questionDescription : questionLabel }}<span class="required">{{ (validators.required) ? t('shared.requiredSymbol') : '' }}</span>
        </div>
        <div v-if="questionAdditionalDescription" class="question-additional-description">
            {{ questionAdditionalDescription }}
        </div>
        <AbsolutePlacement>
            <template #reference>
                <form class="input-form" @submit.stop.prevent="addItem">
                    <DsInputField
                        v-model="selectedItemText"
                        :readonly="maxSize != null && maxSize > 0 && model.length >= maxSize"
                        :data-qa="`array-input-${question.name}`"
                        class="inline-input"
                        :placeholder="t('itemInputPlaceholder', { label: questionLabel.toLowerCase() })"
                    >
                        <template #help>
                            <slot name="helperText" />
                        </template>
                    </DsInputField>
                    <div class="save-icon-container">
                        <DsButton
                            type="submit"
                            :disabled="!hasChanges"
                            :data-qa="`array-button-${question.name}`"
                            :class="['save-icon', { enabled: hasChanges }]"
                            name="check"
                        >
                            {{ t('add') }}
                        </DsButton>
                    </div>
                </form>
            </template>
            <template #default>
                <PlaySuggestions
                    v-if="generatesSuggestions"
                    :excludes="model"
                    :disabled="missingFields.length > 0"
                    allow-multiple
                    :question="question"
                />
            </template>
        </AbsolutePlacement>
        <div v-if="model && model.length > 0" class="question-array-label">
            {{ questionLabel }}{{ t('shared.colon') }}
        </div>
        <Draggable
            v-bind="cardDraggableOptions"
            ref="fields"
            v-model="model"
            class="fields"
            :item-key="(i:KeyValues)=> i?.toString() ?? randomString()"
            @change="onChange"
            @start="moveStart"
            @end="moveEnd"
        >
            <template #item="{ element: item, index: index }">
                <PlayQuestionSortableListItem
                    :model-value="item"
                    :is-suggestion="bool(hasSuggestion(index))"
                    :question="question"
                    @remove="removeItem(item, index)"
                    @change="(newVal) => updateItem(newVal, index)"
                />
            </template>
        </Draggable>
        <div v-if="validationError && validationError.length > 0" class="field-error">
            <div v-for="error in validationError" :key="error.code">
                {{ fullErrorMessage(error) }}
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
/* eslint-disable @typescript-eslint/no-explicit-any */
import PlaySuggestions from '@/play-editor/PlaySuggestions.vue';
import { ArrayEvent } from '@/play-editor/play.constants';
import AbsolutePlacement from '@/shared/components/AbsolutePlacement.vue';
import { KeyValues } from '@/types/core-types';
import { ModelProperty } from '@/generated/play-api';
import { PlayService } from '@/play-editor/play-service';
import { useAppId } from '@/play-editor/provider/provide-app-id';
import { useCore } from '@/shared/shared-providers';
import { computed, toRefs } from 'vue';
import { PlayQuestionCoreEvents, questionMixin } from '@/play-editor/mixins/v3/questionMixin';
import PlayQuestionSortableListItem from './PlayQuestionArrayItem.vue';
import Draggable from 'vuedraggable';
import { distinct } from '@/play-editor/play-utils';
import asserts from '@/shared/asserts';
import { QuestionPropsType } from '@/shared/proptypes/QuestionPropsType';
import { propertyValidatorHelper } from '@/generated-ext/property-validator-helpers';
import { randomString } from '@/shared/shared.utils';

const { log, t } = useCore();
const props = defineProps<QuestionPropsType<any[]>>();

const emit = defineEmits([...PlayQuestionCoreEvents, ArrayEvent.changeItem]);
const playService = PlayService(useAppId(useCore()));
const {
    questionDescription, questionAdditionalDescription,
    questionLabel, model,
    missingFields,
    suggestionsMeta,
    maxSize,
    fullErrorMessage, generatesSuggestions,
} = questionMixin<any[]>(props, emit, []);

const question = computed(() => props.question as ModelProperty);
const validators = computed(() => propertyValidatorHelper(question.value.validators));

const data = reactive({
    selectedItemText: '',
    selectedIndex: -1,
    cardError: null,
    mouseOver: false,
    openedFieldKey: null,
    addCustomFieldModalOpen: false,
    cardDraggableOptions: {
        animation: 0,
        fallbackClass: 'card-dragging',
        fallbackTolerance: 1,
        forceFallback: true,
        ghostClass: 'card-placeholder',
        group: 'fields',
        handle: '.drag-target',
    },
});

const hasChanges = computed(() => data.selectedItemText);

function removeItem(item:any, index:number) {
    emitArrayEvent(ArrayEvent.remove, item, index);

    model.value = [
        ...model.value.filter((x, idx) => idx !== index),
    ];

    data.selectedIndex = -1;
    data.selectedItemText = '';
}

function hasSuggestion(idx:number) {
    return Boolean(suggestionsMeta.value?.find((e:KeyValues) => e.index === idx));
}

function getSuggestion(idx:number) {
    return suggestionsMeta.value?.find((e:KeyValues) => e.index === idx);
}

function updateItem(item:any, index:number) {
    emitArrayEvent(ArrayEvent.update, item, index);
    const newValue = [...model.value];

    newValue[index] = item;

    const suggestion = getSuggestion(data.selectedIndex);

    if (suggestion) {
        const { resultId, item: acceptedItem } = suggestion;

        asserts(resultId && item, 'Must have resultId and item');

        // Emit an accept/edit interaction
        playService.addAcceptInteraction({
            resultId,
            acceptedItem,
            change: item,
        });
    }
    model.value = distinct(newValue);
}

function addItem() {
    emitArrayEvent(ArrayEvent.add, data.selectedItemText, model.value?.length ?? 0);
    model.value = [
        data.selectedItemText,
        ...(model.value ?? []),
    ].distinct();
    data.selectedItemText = '';
}

function moveStart() {
    document.documentElement.style.cursor = 'grabbing';
}

function moveEnd() {
    document.documentElement.style.cursor = '';
}

/**
 * Emit an array mutation event, so listeners can respond to the specifics of the change
 */
function emitArrayEvent(type:unknown, item:any, index:number, other = {}) {
    emit(ArrayEvent.changeItem, type, {
        item,
        index,
        ...other,
    });
}

function onChange({ moved: { element, oldIndex, newIndex } }:{ moved: { element: unknown, oldIndex: number, newIndex: number }}) {
    log.info('drag-change', element, oldIndex, newIndex);

    if (element) {
        emitArrayEvent(ArrayEvent.move, element, newIndex, { oldIndex });
    }
}

const {
    selectedItemText,

    cardDraggableOptions,
} = toRefs(data);
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
@import "play-question";

.required {
    color: $color-red;
}

.no-suggestions {
    color: $color-text-disabled;
}

.suggestions-header {
    margin-top: 1rem;
    margin-bottom: calc(1rem / 2);
}

.results-container {
    margin: auto;
    align-content: center;
    text-align: center;
}

.field-error {
    color: $color-red;
    margin-top: calc(1rem / 2);
}

.play-question-list {
    margin-bottom: $spacing-400;
}

.input-form {
    display: flex;
    flex-direction: row;
}

.inline-input.input-field {
    margin-bottom: $spacing-150;
}

.header {
    font-size: large;
}

.save-icon-container {
    margin-left: calc(1rem / 2);
}

.question-array-label {
    margin-bottom: $spacing-150;
    font-size: $font-size-sm;
    font-weight: $font-weight-semibold;
}
</style>
<i18n>
{
    "en-us": {
        "add": "Add",
        "createNewField": "Add Item",
        "itemInputPlaceholder": "Add {label} (one at a time)",
        "noSuggestions": "No suggestions to display.",
        "refreshNow": "Refresh now",
        "warning": "Warning",
        "arrayEditWarning": "You have unsaved changes.  Would you like to save these changes before continuing?"
    }
}
</i18n>
