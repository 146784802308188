import { KeyValues } from '@/types/core-types';
import isObject from 'lodash/isObject';
import { logger } from '@/shared/logging';
import { PreparedModel, RemoteModelStatus } from '@/integration/datastore/base-types';
import { HostSystem } from '@/generated/play-api';

const log = logger('model.util');

export function verifyModelRawData(sourceData: KeyValues) {
    const { data } = sourceData;

    if (data && isObject(data)) {
        log.severe('Invalid source data. Contains nested data field: ', sourceData);
    }
}

export function isRemoteModel(preparedModel: PreparedModel) {
    return preparedModel.hostSystem !== HostSystem.GENERAL && preparedModel.id === preparedModel.sourceId;
}

export function updateRemoteStatus(preparedModel: PreparedModel, fromPlayDb: boolean) {
    const isRemote = isRemoteModel(preparedModel);

    preparedModel.remoteStatus = isRemote ? fromPlayDb ? RemoteModelStatus.synced : RemoteModelStatus.notSynced : RemoteModelStatus.none;
}

export function verifyModel(preparedModel: KeyValues) {
    const { data } = preparedModel;

    if (!data) {
        log.severe('No data property found - maybe we are passing the raw data', preparedModel);

        return;
    }
    verifyModelRawData(data);
}
