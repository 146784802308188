<template>
    <component
        :is="as"
        class="list-item2"
        :class="[`border-${borderStyle}`,
                 { clickable: isClickable },
                 { dense: dense },
                 { comfortable: !dense },
                 {'item-selected': isSelected}
        ]"
        @click.stop="maybeClick"
    >
        <div v-if="$slots.leadingSlot" class="leading-slot">
            <!-- @slot Icon or avatar to display before the list item text. -->
            <slot name="leadingSlot" />
        </div>

        <div :class="{ 'list-item-info': true, border }">
            <div :class="{ 'item-text': truncateText, 'tile-content': true }">
                <div v-if="title || $slots.title" :class="{ title, 'one-line': truncateText }">
                    <slot name="title">
                        {{ title }}
                    </slot>
                </div>

                <div
                    v-if="description || $slots.description"
                    class="description"
                    :class="[{ 'one-line': hasShortDescription }]"
                >
                    <slot name="description">
                        {{ description }}
                    </slot>
                </div>

                <div v-if="hasShortDescription" class="description one-line">
                    <slot name="shortDescription">
                        {{ shortDescription }}
                    </slot>
                </div>
                <div v-if="$slots.bottom" class="bottom">
                    <slot name="bottom" />
                </div>
            </div>
            <div v-if="$slots.trailingSlot" class="trailing-slot">
                <!-- @slot Checkbox or other to display after list item text. -->
                <slot name="trailingSlot" />
            </div>
        </div>
    </component>
</template>

<script>

export default {
    name: 'ListItem2',

    props: {
        /**
         * Root element type
         */
        as: {
            type: String,
            default: 'div',
        },

        border: Boolean,
        truncateText: {
            type: Boolean,
            default: true,
        },
        /**
         * Main text to show on the list item
         */
        title: {
            type: String,
            default: '',
        },

        /**
         * Description text to display. This will truncate after two lines (or one line if shortDescription is used).
         */
        description: {
            type: String,
            default: '',
        },

        /**
         * Another line of description text. This will truncate to one line.
         */
        shortDescription: {
            type: String,
            default: '',
        },

        /**
         * The style in which to display the border below the list item.
         */
        borderStyle: {
            type: String,
            default: 'partial',
            options: ['none', 'partial', 'full'],
        },

        /**
         * Whether the list item is selected or not.
         */
        isSelected: {
            type: Boolean,
            default: false,
        },

        dense: Boolean,

        /**
         * Show interactive hover state
         */
        clickable: Boolean,
    },
    emits: ['click'],

    computed: {
        isClickable() {
            return this.clickable || this.as === 'a';
        },

        hasShortDescription() {
            return !!this.shortDescription || !!this.$slots.shortDescription;
        },
    },

    methods: {
        maybeClick(event) {
            if (event?.target?.tagName?.toLowerCase() !== 'svg') {
                this.info('Clicked', event?.target);
                this.$emit('click', event);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "@/styles/common";

    .list-item-info {
        display: flex;
        flex-flow: row;
        padding: var(--list-item-info-padding, 1rem);

        > .tile-content {
            flex-grow: 1;
        }

        .trailing-slot {
            margin-left: 1rem;
            align-items: center;
            display: flex;
        }

        flex: 3;
        min-width: 0;
        justify-content: space-between;
        align-items: center;
    }

    .list-item2 {
        --checkbox-padding: 0;
        --checkbox-align-items: flex-start;
        --icon-color: #{$color-ink};

        display: flex;
        flex-direction: row;
        align-items: stretch;
        padding: var(--list-item-padding, 0);
        width: 100%;
        background-color: transparent;

        /* stylelint-disable */
        border-top-left-radius: var(--border-radius-top, 0);
        border-top-right-radius: var(--border-radius-top, 0);
        border-bottom-left-radius: var(--border-radius-bottom, 0);
        border-bottom-right-radius: var(--border-radius-bottom, 0);
        /* stylelint-enable */

        &.clickable {
            cursor: pointer;
            text-decoration: none;

            &:hover {
                background-color: var(--hover-background-color, $color-gray-050);
            }
        }

        &.item-selected {
            background-color: var(--hover-background-color, $color-gray-100) !important;
        }
    }

    .leading-spacer {
        padding-left: $spacing-200;
    }

    .leading-slot {
        // @include padding-end(1rem);
    }

    .item-text {
        overflow: hidden;
    }

    .border-full {
        border-bottom: solid 1px $color-ink-200;
    }

    .border-none {
        --list-border: 0;

        .list-item-info {
            padding: var(--list-item-info-padding, 1rem);
        }
    }

    .border-partial {
        @include padding-end(0, true);

        padding-bottom: 0;

        .leading-slot {
            padding-top: var(--list-item-padding, 1rem);
            padding-bottom: var(--list-item-padding, 1rem);
        }

        .list-item-info {
            padding: var(--list-item-info-padding, 1rem);

            &.border {
                border-bottom: solid 1px $color-ink-200;
            }
        }
    }

    .title {
        color: var(--list-item-title-color, #{$color-ink});

        &:not(:only-child) {
            margin-bottom: calc(1rem / 4);
        }
    }

    .dense {
        .description {
            font-size: $font-size-xs;
            line-height: 1.2;
        }

        .title {
            font-size: $font-size-sm;
        }

        .leading-slot {
            min-width: calc(42rem / 16);
        }
    }

    .comfortable {
        .description {
            line-height: 1.5;
            font-size: $font-size-sm;
        }

        .title {
            font-size: $font-size-md;
        }

        .leading-slot {
            min-width: $spacing-100;
        }
    }

    .description {
        @include ellipsis-multiline(2);

        color: var(--list-item-description-color, #{$color-gray});
        margin: 0;
    }

    .one-line {
        @include ellipsis;

        display: block;
    }

    .trailing-slot {
        @include margin-start(1rem);

        height: 100%;
    }
</style>
