/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormProvider, KeyedMetaMutations, SaveForm } from '@/model/form/form-provider-types';
import { ModelProperty, PropertyPrimitiveType } from '@/client/play-client';
import { toArray } from '@/shared/type.utils';
import { formLogger } from '@/model/form/provide-form-logger';

const log = formLogger.child('save', true);

export function setupFormProviderSaveValues(self: FormProvider, saveForm: SaveForm) {
    const { state } = self;

    self.answerAsArray = (question: ModelProperty) => {
        const qAnswer = state.answers[question.name];

        return toArray(qAnswer);
    };

    self.isArray = (question: ModelProperty): boolean => {
        return question.type.base === PropertyPrimitiveType.ARRAY;
    };

    self.saveAnswers = async () => {
        return saveForm(state.answers, state.meta);
    };

    self.updateAnswer = (question: ModelProperty, answer: any, metaMutation?: KeyedMetaMutations) => {
        log.info(`FORM: ${question.name}`, answer);
        vset(state.answers, question.name, answer);

        if (metaMutation != null) {
            metaMutation.entrySet().forEach(([key, mutation]) => {
                self.mutateMeta(question, key as string, mutation);
            });
        }
        self.sanitize();

        return self.saveAnswers().then(() => {
            // eslint-disable-next-line no-void
            return self.validate();
        });
    };
}
