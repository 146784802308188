/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AdminService } from './services/AdminService';
import { AiOperationService } from './services/AiOperationService';
import { CoachingService } from './services/CoachingService';
import { CoachingV2Service } from './services/CoachingV2Service';
import { ElementMetadataService } from './services/ElementMetadataService';
import { GoalService } from './services/GoalService';
import { ImagesService } from './services/ImagesService';
import { LookupsService } from './services/LookupsService';
import { ModelService } from './services/ModelService';
import { ModelDefinitionService } from './services/ModelDefinitionService';
import { PlayService } from './services/PlayService';
import { PlaybookService } from './services/PlaybookService';
import { PlayContentService } from './services/PlayContentService';
import { PlayTagService } from './services/PlayTagService';
import { PlayTemplateService } from './services/PlayTemplateService';
import { PlayTemplateAdminService } from './services/PlayTemplateAdminService';
import { PlayTemplateV2Service } from './services/PlayTemplateV2Service';
import { ProxyService } from './services/ProxyService';
import { UserService } from './services/UserService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class PlayClient {

    public readonly admin: AdminService;
    public readonly aiOperation: AiOperationService;
    public readonly coaching: CoachingService;
    public readonly coachingV2: CoachingV2Service;
    public readonly elementMetadata: ElementMetadataService;
    public readonly goal: GoalService;
    public readonly images: ImagesService;
    public readonly lookups: LookupsService;
    public readonly model: ModelService;
    public readonly modelDefinition: ModelDefinitionService;
    public readonly play: PlayService;
    public readonly playbook: PlaybookService;
    public readonly playContent: PlayContentService;
    public readonly playTag: PlayTagService;
    public readonly playTemplate: PlayTemplateService;
    public readonly playTemplateAdmin: PlayTemplateAdminService;
    public readonly playTemplateV2: PlayTemplateV2Service;
    public readonly proxy: ProxyService;
    public readonly user: UserService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'http://localhost:8080',
            VERSION: config?.VERSION ?? '2',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.admin = new AdminService(this.request);
        this.aiOperation = new AiOperationService(this.request);
        this.coaching = new CoachingService(this.request);
        this.coachingV2 = new CoachingV2Service(this.request);
        this.elementMetadata = new ElementMetadataService(this.request);
        this.goal = new GoalService(this.request);
        this.images = new ImagesService(this.request);
        this.lookups = new LookupsService(this.request);
        this.model = new ModelService(this.request);
        this.modelDefinition = new ModelDefinitionService(this.request);
        this.play = new PlayService(this.request);
        this.playbook = new PlaybookService(this.request);
        this.playContent = new PlayContentService(this.request);
        this.playTag = new PlayTagService(this.request);
        this.playTemplate = new PlayTemplateService(this.request);
        this.playTemplateAdmin = new PlayTemplateAdminService(this.request);
        this.playTemplateV2 = new PlayTemplateV2Service(this.request);
        this.proxy = new ProxyService(this.request);
        this.user = new UserService(this.request);
    }
}

