import asserts from '@/shared/asserts';
import isNull from 'lodash/isNull';
import { AssetType, NavigateDestination, PlaySection } from '@/generated/play-api';
import { Dict, KeyValues, Struct } from '@/types/core-types';

export const BLANK_PLAY_ID = 'blank';

export const DEFAULT_SUGGESTION_COUNT = 6;

export enum MimeTypes {
    html = 'text/html',
    text = 'text/plain',
}

export type TransitionDefinition = {
    next: string;
    prev: string;
}

export enum TransitionType {
    next = 'next',
    prev = 'prev',
}

export const RECOMMENDATION_CATEGORY = 'recommended';

export enum ExportDestinationKey {
    unlayer = 'unlayer',
    unlayerJson = 'unlayerJson',
    html = 'html',
    text = 'text',
    simpleHtml = 'simpleHtml',
}

export enum TimingUnit {
    MINUTES = 'MINUTES',
    HOURS = 'HOURS',
    DAYS = 'DAYS',
    WEEKS = 'WEEKS',
    MONTHS = 'MONTHS',
}

export enum PlayEventTypes {
    PLAY_CATEGORY_CHANGED = 'Play Category Changed',
    PLAY_TEMPLATE_LIST_VIEWED = 'Play Template List Viewed',
    PLAY_TEMPLATE_PREVIEWED = 'Play Template Previewed',
    PLAY_QUESTIONS_STARTED = 'Play Questions Started',
    PLAY_SUGGESTION_REQUESTED = 'Play Suggestion Requested',
    PLAY_SUGGESTION_ACCEPTED = 'Play Suggestion Accept',
    PLAY_SUGGESTION_REJECTED = 'Play Suggestion Reject',
    PLAY_QUESTIONS_FINISHED = 'Play Questions Finished',
    PLAY_QUESTIONS_UPDATED = 'Play Questions Updated',
    PLAY_CONTENT_EDITED = 'Play Content Edited',
    PLAY_TEMPLATE_EDITED = 'Play Template Edited',
    PLAY_CONTENT_COPIED = 'Play Content Copied',
    PLAY_AUTOMATION_LAUNCHED = 'Play Automation Launched', // there,but commented
    PLAYS_VIEWED = 'My Plays Viewed',
    PLAY_OPENED = 'Completed Play Opened',
    PLAY_DELETED = 'Play Deleted',
    COACH_PLAY_TEMPLATE_TESTED = 'Coach Play Template Tested',
    COACH_PLAY_ADDED = 'Coach Added Play to Playbook',
}

export const DEFAULT_THEME_ID = 'kopy-default';

export enum SectionTypes {
    headline = 'headline',
    subheader = 'subheader',
    text = 'text',
    textBody = 'textBody',
    richTextBody = 'richTextBody',
}

export const dateExpressionNow = 'now';

export enum LaunchLinkType {
    landingPage = 'landingPage',
    sequence = 'automation',
    trigger = 'trigger'
}

export const LaunchLinkToNavDestination = <Dict<string>>{
    [LaunchLinkType.landingPage]: NavigateDestination.LANDING_PAGE,
    [LaunchLinkType.sequence]: NavigateDestination.EASY_AUTOMATION,
};

export const LaunchLinkTypeName = <Dict<string>>{
    sequence: 'Easy Automation',
    trigger: 'Trigger',
    landingPage: 'Landing Page',
};

export enum PlayStartType {
    appointment = 'appointment',
    landingPage = 'landingPage',
    form = 'form',
    stageMove = 'stageMove',
    tag = 'tag',
    productPurchased = 'productPurchased',
}

export const PlayStepType = {
    asset: 'asset',
    timer: 'timer',
};

export const PlayAssetType = {
    email: 'email',
    landingPage: 'landingPage',
    htmlPage: 'htmlPage',
    snippetPage: 'snippetPage',
    socialSnippets: 'socialSnippets',
    emailPs: 'emailPs',
    sms: 'sms',
    instructions: 'instructions',
    content: 'content',
};

export const PLAY_STAGE_STATUS = {
    SETUP: 'setup',
    CONTENT: 'content',
    AUTOMATION_CREATED: 'automation_created',
};

/**
 * Describes how we should treat each content type:
 *
 * - html: Does it support html?
 * - multiLine: does it support multiple lines?
 */
export const sectionTypeMeta = {
    [SectionTypes.headline]: {
        destinations: [ExportDestinationKey.text],
        multiLine: false,
    },

    [SectionTypes.text]: {
        destinations: [ExportDestinationKey.text],
        multiLine: false,
    },

    [SectionTypes.subheader]: {
        destinations: [ExportDestinationKey.text],
        multiLine: false,
    },

    [SectionTypes.textBody]: {
        destinations: [ExportDestinationKey.text],
        multiLine: true,
    },

    [SectionTypes.richTextBody]: {
        destinations: [
            ExportDestinationKey.text,
            ExportDestinationKey.html,
            ExportDestinationKey.unlayer,
        ],
        multiLine: true,
    },
};

function bodySectionReducer(sections: PlaySection[]): PlaySection[] {
    if (sections?.length < 1) {
        return sections;
    }
    const [firstSection] = sections;
    const combinedContent = sections.map((sec) => sec.content).filter(notNull).join('\n');
    const targetSection = sections.find(({ sectionKey }) => sectionKey === 'body') ?? firstSection;

    targetSection.content = combinedContent;
    targetSection.sectionKey = 'body';

    return [targetSection];
}

export type BodySectionReducer = ((sections: PlaySection[]) => PlaySection[]);
export type AssetTypeMeta = {
    preferredExport: ExportDestinationKey;
    sectionReducer: BodySectionReducer;
    destinations: ExportDestinationKey[];
}

const echoSectionReducer: BodySectionReducer = ((sections: PlaySection[]) => sections);

function _AssetTypeMeta(source: {
    preferredExport: ExportDestinationKey,
    sectionReducer?: BodySectionReducer,
    destinations?: ExportDestinationKey[],
}): AssetTypeMeta {
    const { destinations = [], preferredExport, sectionReducer = echoSectionReducer } = source;

    asserts(preferredExport, 'Must provide default export');

    return <AssetTypeMeta>{
        destinations,
        preferredExport,
        sectionReducer,
    };
}

export const assetTypeMeta = <Struct<AssetType, AssetTypeMeta>>{
    [AssetType.LANDING_PAGE]: _AssetTypeMeta({
        preferredExport: ExportDestinationKey.simpleHtml,
        destinations: [ExportDestinationKey.text, ExportDestinationKey.simpleHtml, ExportDestinationKey.unlayer],
        sectionReducer: bodySectionReducer,
    }),

    [AssetType.HTML_PAGE]: _AssetTypeMeta({
        preferredExport: ExportDestinationKey.simpleHtml,
        destinations: [ExportDestinationKey.simpleHtml, ExportDestinationKey.text, ExportDestinationKey.unlayer],
        sectionReducer: bodySectionReducer,
    }),

    [AssetType.SMS]: _AssetTypeMeta({
        preferredExport: ExportDestinationKey.text,
    }),

    [AssetType.SNIPPET_PAGE]: _AssetTypeMeta({
        preferredExport: ExportDestinationKey.simpleHtml,
        destinations: [ExportDestinationKey.simpleHtml],
    }),

    [AssetType.SOCIAL_SNIPPETS]: _AssetTypeMeta({
        preferredExport: ExportDestinationKey.text,
        destinations: [ExportDestinationKey.simpleHtml],
    }),

    [AssetType.EMAIL]: _AssetTypeMeta({
        preferredExport: ExportDestinationKey.simpleHtml,
    }),

    [AssetType.EMAIL_PS]: _AssetTypeMeta({
        preferredExport: ExportDestinationKey.text,
    }),

    [AssetType.CONTENT]: _AssetTypeMeta({
        preferredExport: ExportDestinationKey.simpleHtml,
    }),

    [AssetType.INSTRUCTIONS]: _AssetTypeMeta({
        preferredExport: ExportDestinationKey.simpleHtml,
    }),
};

export const metaKeys = {
    suggestions: 'suggestions',
};

asserts.containsKeys(assetTypeMeta, Object.values(AssetType), 'assetTypeMeta');

export enum PlayLifecycleEvent {
    close = 'close',
    doneWithQuestions = 'done-with-questions',
    saveAnswers = 'save-answers',
    beforeChangePage = 'before-change-page',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const notNull = (x: any) => !isNull(x);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const notFalsy = (x: any) => Boolean(x);

export function byName(nameLiteral: string) {
    return (x?: KeyValues | null) => x?.name === nameLiteral;
}

export const alwaysTrue = () => true;

export enum PlayQuestionMode {
    inline = 'INLINE',
    normal = 'NORMAL',
}

export enum ArrayEvent {
    remove = 'remove',
    move = 'move', // { item: element, oldIndex, newIndex }
    update = 'update',
    add = 'add',
    changeItem = 'change-item',
}

export type ArrayEventPayload = KeyValues & {
    index: number;
    oldIndex: number;
}

export const SEARCHING_DEBOUNCE_DELAY = 1000;

export const VALUE_ALT_REPLACE_PATTERN = '__VALUE__';
export const VALUE_LIQUID_REPLACE_PATTERN = '{{ value }}';
export const MAX_AI_ATTEMPTS = 5;

export enum ProviderName {
    suggestionsProvider = 'suggestionsProvider',
    playProvider = 'playProvider',
    hostProvider = 'hostProvider',
    accountSwitcherProvider = 'accountSwitcherProvider',
    questionProvider = 'questionProvider',
    playServiceProvider = 'playServiceProvider',
    modelProvider = 'modelProvider',
    modelEditProvider = 'modelEditProvider',
    propInfoProvider = 'propInfoProvider',
    coreProvider = 'coreProvider',
    feedbackProvider = 'feedbackProvider',
}

export enum PlayStates {
    questions = 'QUESTIONS',
    content = 'CONTENT',
}

export const playCategories = [
    {
        category: 'By LCM Phase',
        columns:
            [
                {
                    column: 'Collect Leads',
                    columnDescription: 'Capture information to turn visitors, fans and contacts into leads',
                    columnIllustration: 'leads-color',
                    topPlays: [
                        'Lead Magnet Download',
                        'Lead Magnet Offer Broadcast',
                        'Schedule a Free Consult',
                        'Free Consult Invites',
                    ],
                    otherPlays: [
                        'Checklist Generator',
                        'Free Guide Builder',
                        'All Questions',
                        'Eric Test Template',

                    ],
                },
                {
                    column: 'Convert Clients',
                    columnDescription: 'Move leads through your sales proces and convert them to clients',
                    columnIllustration: 'successful-payment-color',
                    topPlays: [
                        'Lead Magnet Follow Up (To Consult)',
                        'Lead Magnet Follow Up (Sales Offer)',
                        'Post-Consult Follow-Up + Offer',

                    ],
                    otherPlays: [
                        '',
                    ],
                },
                {
                    column: 'Create Fans',
                    columnDescription: 'Wow new customers and turn them into fans who buy more & refer',
                    columnIllustration: 'success-high-five-color',
                    topPlays: [
                        'New Customer Welcome (Services)',
                        'Testimonial Request',
                        'Sales Offer (Made-Up Holiday)',
                        'Sales Offer (Generic)',
                        'Sales Offer (Generic v2)',
                    ],
                    otherPlays: [
                        '',
                    ],
                },
            ],
    },
    {
        category: 'By Play Type',
        columns:
            [
                {
                    column: 'Lead Magnets',
                    columnDescription: 'Plays that leverage various Lead Magnets',
                    topPlays: [
                        'Lead Magnet Download',
                        'Lead Magnet Offer Broadcast',
                        'Lead Magnet Follow Up (To Consult)',
                        'Lead Magnet Follow Up (Sales Offer)',
                        'Checklist Generator',
                        'Free Guide Builder',
                        '',
                    ],
                    otherPlays: [
                        '',
                    ],
                },
                {
                    column: 'Free Consultation',
                    columnDescription: 'Plays that get the consult scheduled and follow through to a sale',
                    topPlays: [
                        'Schedule a Free Consult',
                        'Free Consult Invites',
                        'Consult Reminders + Pre-suasion',
                        'Post-Consult Follow-Up + Offer',

                    ],
                    otherPlays: [
                        '',
                    ],
                },
                {
                    column: 'Sales Offers',
                    columnDescription: 'Plays that wow your new customers and turn them into fans',
                    topPlays: [
                        'Sales Offer (Made-Up Holiday)',
                        'Sales Offer (Generic)',
                        'Sales Offer (Generic v2)',
                    ],
                    otherPlays: [
                        '',
                    ],
                },
            ],
    },
];

export const CAPABILITY_PLAY_LAUNCH = 'play.launch';
export const CAPABILITY_USER_LOG_EVENT = 'user.logEvent';

export const capabilityKeys = {
    launchPlay: CAPABILITY_PLAY_LAUNCH,
    userLogEvent: CAPABILITY_USER_LOG_EVENT,
};
