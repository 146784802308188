import { ModelProperty, ModelPropertyGroup, ModelPropertyTypeDiscriminator } from '@/generated/play-api';

export type PageModelParams = {
    refs: ModelProperty[];
    groups: ModelPropertyGroup[];
}

export class PageModel {
    refs: ModelProperty[];

    groups: ModelPropertyGroup[];

    hasRefs: boolean;

    static empty(): PageModel {
        return new PageModel({
            refs: [],
            groups: [],
        });
    }

    static of(allGroups: ModelPropertyGroup[], extractRefs = true): PageModel {
        if (extractRefs) {
            const [firstPage = { properties: [] }, ...groups] = allGroups;

            const refs = firstPage.properties.filter((p: ModelProperty) => {
                return p.type.discriminator === ModelPropertyTypeDiscriminator.REF;
            });

            return new PageModel({
                refs: refs.length === 0 ? [] : refs,
                groups: refs.length === 0 ? allGroups : groups,
            });
        }

        return new PageModel({
            refs: [],
            groups: allGroups,
        });
    }

    constructor({ refs, groups }: PageModelParams) {
        this.refs = refs;
        this.groups = groups.sort((a, b) => a.sortOrder - b.sortOrder);
        this.hasRefs = refs.length > 0;
    }
}
