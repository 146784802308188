/* eslint-disable @typescript-eslint/no-explicit-any */

const buildEnv: any = {
    isDev: undefined,
    isProd: undefined,
};

export type Env = {
    readonly isDev: boolean;
    readonly isProd: boolean;
    NODE_ENV: string;
    PROD:boolean;
    DEV:boolean;
    MODE: string;
    VUE_APP_FIREBASE_API_KEY: string;
    VUE_APP_APP_ID: string;
    VUE_APP_DISABLE_AUTH: string;
    VUE_APP_ENV: string;
    VUE_APP_FIREBASE_PROJECT_ID: string;
    VUE_APP_CONTENT_API_URL: string;
    VUE_APP_WC_URL: string;
    VUE_APP_TINY_BASE_URL: string;
    VUE_APP_TINY_API_KEY: string;
    VUE_APP_ENABLE_EXPERIMENTAL_MODE: string;
    VUE_APP_PUBLIC_URL: string;
    VUE_APP_CAS_URL: string;
    VUE_APP_EMBED_ALLOW_LIST: string;
    VUE_APP_AMPLITUDE_V2_KEY: string;
    VUE_APP_KEAP_WEB_URL: string;
    VUE_APP_AMPLITUDE_V2_INSTANCE_NAME: string;
    VUE_APP_THIRD_PARTY_LOGGING: string;
    VUE_APP_PLAY_BFF_URL: string;
    VUE_APP_APPTS_API_URL: string;
    VUE_APP_BOOKING_BASE_URL: string;
}

Object.assign(buildEnv, {
    NODE_ENV: process.env.NODE_ENV,
    MODE: import.meta.env.MODE,
    PROD: import.meta.env.PROD,
    DEV: import.meta.env.DEV,
    VUE_APP_APP_ID: process.env.VUE_APP_APP_ID,
    VUE_APP_DISABLE_AUTH: false,
    VUE_APP_FIREBASE_API_KEY: process.env.VUE_APP_FIREBASE_API_KEY,
    VUE_APP_ENV: process.env.VUE_APP_ENV,
    VUE_APP_FRAME_USER_ID: process.env.VUE_APP_FRAME_USER_ID,
    VUE_APP_FRAME_TICKET: process.env.VUE_APP_FRAME_TICKET,
    VUE_APP_FRAME_APP_ID: process.env.VUE_APP_FRAME_APP_ID,
    VUE_APP_FIREBASE_PROJECT_ID: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    VUE_APP_CONTENT_API_URL: process.env.VUE_APP_CONTENT_API_URL,
    VUE_APP_WC_URL: process.env.VUE_APP_WC_URL,
    VUE_APP_TINY_BASE_URL: process.env.VUE_APP_TINY_BASE_URL,
    VUE_APP_TINY_API_KEY: process.env.VUE_APP_TINY_API_KEY,
    VUE_APP_ENABLE_EXPERIMENTAL_MODE: process.env.VUE_APP_ENABLE_EXPERIMENTAL_MODE,
    VUE_APP_PUBLIC_URL: process.env.VUE_APP_PUBLIC_URL,
    VUE_APP_CAS_URL: process.env.VUE_APP_CAS_URL,
    VUE_APP_EMBED_ALLOW_LIST: process.env.VUE_APP_EMBED_ALLOW_LIST,
    VUE_APP_AMPLITUDE_V2_KEY: process.env.VUE_APP_AMPLITUDE_V2_KEY,
    VUE_APP_KEAP_WEB_URL: process.env.VUE_APP_KEAP_WEB_URL,
    VUE_APP_AMPLITUDE_V2_INSTANCE_NAME: process.env.VUE_APP_AMPLITUDE_V2_INSTANCE_NAME,
    VUE_APP_THIRD_PARTY_LOGGING: process.env.VUE_APP_THIRD_PARTY_LOGGING,
    VUE_APP_PLAY_BFF_URL: process.env.VUE_APP_PLAY_BFF_URL,
    VUE_APP_APPTS_API_URL: process.env.VUE_APP_APPTS_API_URL,
    VUE_APP_BOOKING_BASE_URL: process.env.VUE_APP_BOOKING_BASE_URL,
});

Object.assign(buildEnv, {
    get isDev() {
        return buildEnv.MODE !== 'production' || buildEnv.MODE !== 'prod';
    },
    get isProd() {
        return buildEnv.MODE === 'production' || buildEnv.MODE === 'prod';
    },
});

export const env: Env = buildEnv;
