import capitalize from 'lodash/capitalize';
import { AssetType, KeapIcon, SequenceStartType } from '@/generated/play-api';

export function calculateIconForAsset(asset) {
    if (asset?.customIcon) {
        return asset.customIcon;
    }

    switch (asset?.assetType) {
    case AssetType.EMAIL:
        return 'email';
    case AssetType.SMS:
        return 'message';
    case AssetType.LANDING_PAGE:
        return 'form';
    case AssetType.SNIPPET_PAGE:
        return 'fire';
    case AssetType.CONTENT:
        return 'note';
    case AssetType.HTML_PAGE:
        return 'form';
    default:
        return asset.assetType ?? 'unknown';
    }
}

export function calculateIconForStartType(startType) {
    switch (startType) {
    case SequenceStartType.APPOINTMENT:
        return KeapIcon.CALENDAR;
    case SequenceStartType.FORM:
    case SequenceStartType.LANDING_PAGE:
        return KeapIcon.FORM;
    case SequenceStartType.STAGE_MOVE:
        return KeapIcon.PIPELINE;
    case SequenceStartType.TAG:
        return KeapIcon.TAG;
    case SequenceStartType.PRODUCT_PURCHASED:
        return KeapIcon.SALES;
    default:
        return KeapIcon.CODE;
    }
}

export function splitWords(text) {
    return text?.split(/(?=[A-Z])/).join(' ');
}

export function properCase(text) {
    return text?.split(/(?=[A-Z])/).map(capitalize).join(' ');
}

export function calculateAssetTypeCode(asset) {
    switch (asset?.assetType) {
    case 'email':
    case 'text':
    case 'landingPage':
    case 'snippetPage':
    case 'content':
        return asset.assetType;
    default:
        return 'unknown';
    }
}
