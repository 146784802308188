import { NavigateInput } from '@/integration/capabilities/capability-types';
import { logger } from '@/shared/logging';
import { NavigateDestination } from '@/generated/play-api';
import { env } from '@/env';

const log = logger('navigate');

export const navigateKeapWeb = (appId: string) => ((input: NavigateInput) => {
    const { recordType, recordId, navigateParams = {} } = input;

    const parts = recordId?.split('\\.') ?? [];

    let relativeUrl;

    // todo: Get rid of the hard-coded values below

    switch (recordType) {
    case 'automation':
    case NavigateDestination.EASY_AUTOMATION:
        relativeUrl = navigateParams.editMode ? `/automations/build/${parts[0]}` : `/automations/easy/${parts[0]}/quickview`;
        log.info('Navigate to ', relativeUrl, input);

        return navigateKeapWebUrl(appId, relativeUrl);
    case 'landingPage':
    case NavigateDestination.LANDING_PAGE:
        relativeUrl = navigateParams.recordId ? `/marketing-sites/${parts[0]}/edit` : '/marketing-sites/create';
        log.info('Navigate to ', relativeUrl, input);

        return navigateKeapWebUrl(appId, relativeUrl);
    default:
        return Promise.reject(new Error(`Unable to navigate to ${recordType}`));
    }
});

function navigateKeapWebUrl(appId: string, relativeUrl: string) {
    const baseUrl = env.VUE_APP_KEAP_WEB_URL;

    window.open(`${baseUrl}${relativeUrl}?app_id=${appId}`);

    return Promise.resolve();
}
