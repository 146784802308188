<script lang="ts">export default { name: 'RefSelectorInput' }; </script>
<template>
    <div>
        <ListItem2
            :truncate-text="null"
            class="asset-item"
        >
            <template #leadingSlot>
                <div class="icon-box">
                    <DsIcon
                        :class="['ref-badge-icon', { invalid: model && !isValid }]"
                        :name="iconName"
                        :show-badge="model && !isValid"
                    />
                </div>
            </template>
            <template #title>
                <div class="ref-title-box flex flex-align-center">
                    <div class="question-label">
                        {{ question.label }}
                    </div>
                    <PlayTooltip v-if="question.additionalDescription" wrap>
                        <template #default>
                            <span>{{ question.additionalDescription }}</span>
                        </template>
                        <template #reference>
                            <Icon :name="KeapIcon.INFO_CIRCLE" class="small-icon" dense />
                        </template>
                    </PlayTooltip>
                </div>
            </template>
            <template #description>
                <div>
                    {{ question.description }}
                </div>
            </template>
            <template v-if="hasModels" #bottom>
                <div class="ref-card">
                    <RefCard
                        v-if="isReady"
                        :model-value="model"
                        :loaded-model="loadedModel"
                        :schema="modelSchema"
                        v-bind="$attrs"
                        @update:model-value="emit('update:model-value', $event)"
                    />
                </div>
            </template>
            <template v-else-if="isLoadingList" #bottom>
                <DsPlaceholder :rows="placeholderBuilder().row({ heightRem: 3.7, boxes: [6, 1] })" />
            </template>
            <template v-if="hasNoModels" #trailingSlot>
                <div v-if="isLoading">
                    <DsButton
                        class="setup-button"
                        trailing-icon="arrow-right"
                        :data-qa="`set-up-ref-${question.name}`"
                        dense
                        loading
                    >
                        {{ $t('setUpNow') }}
                    </DsButton>
                </div>
                <div v-else>
                    <DsButton
                        class="setup-button"
                        trailing-icon="arrow-right"
                        :data-qa="`set-up-ref-${question.name}`"
                        dense
                        @click="composeAndUpdateModel"
                    >
                        {{ $t('setUpNow') }}
                    </DsButton>
                </div>
            </template>
        </ListItem2>
    </div>
</template>

<script lang="ts" setup>
/* eslint-disable @typescript-eslint/no-explicit-any */

import ModelEditPage from '@/play-editor/model/ModelEditPage.vue';
import PlayTooltip from '@/shared/components/PlayTooltip.vue';
import RefCard from '@/play-editor/controls/ref/RefCard.vue';
import ListItem2 from '@/shared/components/ListItem2.vue';
import {
    computed, Ref, ref, toRef, watch,
} from 'vue';
import { modelMixin } from '@/play-editor/mixins/v3/modelMixin';
import { ModelProperty } from '@/generated/play-api';
import { PreparedModel } from '@/integration/datastore/base-types';

import { useVModel2 } from '@/play-editor/mixins/v3/v-model';
import { RefValidState } from '@/play-editor/controls/ref/ref-constants';
import { useCore } from '@/shared/shared-providers';
import { injectDataScope } from '@/model/form/DataScope';

import cloneDeep from 'lodash/cloneDeep';

import { waitAsync } from '@/play-editor/model/TenantModelService';
import { placeholderBuilder } from '@/shared/placeholder-boxes';

const props = withDefaults(defineProps<{
    question: ModelProperty;
    modelValue?: string | null;
    isModelValid?: RefValidState;
}>(), {
    isModelValid: RefValidState.unknown,
});

const emit = defineEmits(['update:model-value']);

const { log, appId, t } = useCore();
const question: Ref<ModelProperty> = toRef(props, 'question');
const valueRef: Ref<string> = toRef(props, 'modelValue');
const refType = computed(() => question.value.type.ref.refType);
const isRefValid = ref<RefValidState>(RefValidState.unknown); // unknown
const modelTypeService = modelMixin(appId.value, refType.value, ModelEditPage);

const dataScope = injectDataScope();

const {
    modelsById,
    modelListState,
    isReady,
    modelSchemaState,
} = modelTypeService;

const modelSchema = modelSchemaState.state;

function updateModelId(id?: string) {
    emit('update:model-value', id);
}

const loadedModel = computed({
    get: () => {
        return modelsById.value[valueRef.value];
    },
    set: (model: PreparedModel) => {
        updateModelId(model?.id);
    },
});

/// Watch ID changes and trigger load, looking for missing refs
watch(valueRef, async (value) => {
    if (value) {
        /// Trigger a load for this model
        try {
            await modelTypeService.loadModel(value);
        } catch (e: any) {
            if (e.response) {
                const { status } = e.response;

                if (status === 404) {
                    log.info(`${refType.value} not found with id`, value);
                    updateModelId(null);
                    isRefValid.value = RefValidState.valid;
                }
            }
        }
    }
}, { immediate: true });

/// Watch the loaded ref and update validation state
watch(() => cloneDeep(modelTypeService.modelsById.value[valueRef.value]), async (result) => {
    if (result) {
        log.debug('[onchange] Loaded selected ref', valueRef.value, result);
        isRefValid.value = result.valid ? RefValidState.valid : RefValidState.invalid;
    }
}, { deep: true, immediate: true });

const model = useVModel2(props);

/// If there is only one model, select that model now
waitAsync(modelListState).then((list) => {
    if (!model.value && list.length === 1) {
        updateModelId(list[0].id);
    }
});

function emptyStateOf(name: string, defaultValue?: any): string {
    const emptyState = [`empty.${(question.value as any).name}.${name}`];

    if (!emptyState) {
        return defaultValue;
    }

    for (const code of emptyState) {
        const resolved = t(code);

        if (resolved && resolved !== code) {
            return resolved?.toString();
        }
    }

    return defaultValue;
}

const hasModels = computed(() => modelListState.isReady.value && modelListState.state.value?.length > 0 && modelSchemaState.isReady.value);
const hasNoModels = computed(() => modelListState.isReady.value && modelListState.state.value?.length <= 0);

const isLoading = computed((): boolean => {
    return !model.value && modelListState.isReady.value !== true;
});

const isLoadingList = computed((): boolean => {
    return modelListState.isReady.value !== true;
});

const isValid = computed((): boolean => {
    return isRefValid.value !== RefValidState.invalid;
});

const iconName = computed((): string => {
    return emptyStateOf('icon', 'link');
});

async function composeAndUpdateModel() {
    const { id } = (await modelTypeService.composeModel({ dataScope })) ?? {};

    if (id) {
        // this.propInfo.model = id;
        updateModelId(id);
    }
}
</script>

<style lang="scss" scoped>
    @import "~@/styles/common";
    @import "../../play-editor/properties/play-question";

    .ref-card {
        margin-top: $spacing-100;
    }

    .error {
        color: $color-red;
        font-size: $font-size-xs;
    }

    .setup-button {
        --icon-color: white;

        color: white;
    }

    .edit-button {
        --icon-color: blue;

        color: blue;
    }

    .icon-box {
        height: $spacing-300;
        width: $spacing-300;

        --icon-size: $spacing-200;
    }

    .header {
        padding: 1rem;
        width: calc(170rem / 16);
        height: 100%;
    }

    .icon {
        margin: auto;
        text-align: center;
    }

    .ref-title {
        font-size: $font-size-md;
        white-space: nowrap;
        overflow-y: clip;
    }

    .source-type-label {
        @include copy-button;

        font-size: $font-size-xs;
        background-color: $color-gray-20;

        &.keap-web {
            background-color: $color-green;
            color: white;
        }
    }

    .ref-title-box {
        display: flex;
        flex-direction: row;

        > div {
            // display: inline-block;
            margin: auto calc(1rem / 2) auto 0;
        }
    }

    .small-icon {
        --icon-size: 1rem;
        --icon-color: $color-accent-text;
    }

</style>
<i18n>
{
    "en-us": {
        "edit": "Edit {name}...",
        "createNew": "Add new {refType}...",
        "setUpNow": "Set up now",
        "chooseItem": "Choose",
        "changeItem": "Edit",
        "empty": {
            "consult": {
                "icon": "conversation",
                "title": "Consult",
                "description": "Provide information about the consult"
            },
            "leadMagnet": {
                "icon": "lightbulb",
                "title": "Lead Magnet",
                "description": "Set up the lead magnet you will be using"
            },
            "checklist": {
                "icon": "check-square",
                "title": "Checklist Content",
                "description": "Create the checklist content"
            },
            "persona": {
                "icon": "contacts-users",
                "title": "Customer Persona",
                "description": "Help us understand your customers"
            },
            "company": {
                "icon": "company",
                "title": "Company",
                "description": "Enter some basic company information"
            },
            "cta": {
                "icon": "click",
                "title": "Call to Action",
                "description": "Enter the details for your call to action"
            },
            "product": {
                "icon": "box",
                "title": "Product",
                "description": "Enter the product information"
            },
            "upsellProduct": {
                "icon": "box",
                "title": "Upsell Product",
                "description": "Enter the product information"
            },
            "webinar": {
                "icon": "view-desktop",
                "title": "Webinar",
                "description": "Enter the webinar information"
            },
            "crossSellProduct": {
                "icon": "box",
                "title": "Cross-Sell Product",
                "description": "Enter the product information"
            },
            "refField": {
                "icon": "check-square",
                "title": "Test Ref",
                "description": "Test Ref Description"
            },
            "refLevel1RefField": {
                "icon": "check-square",
                "title": "Test Sub-Ref",
                "description": "Test Sub-Ref Description"
            }
        }
    }
}
</i18n>
