/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FileUploadRequest } from '../models/FileUploadRequest';
import type { UploadResult } from '../models/UploadResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ImagesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Upload File
     * Uploads a file
     * @param tenantId tenantId
     * @param requestBody Upload request path
     * @returns UploadResult Success
     * @throws ApiError
     */
    public uploadFile(
        tenantId: string,
        requestBody: FileUploadRequest,
    ): CancelablePromise<UploadResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenantId}/images',
            path: {
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Files
     * Deletes an image
     * @param tenantId tenantId
     * @param requestBody
     * @returns boolean Success
     * @throws ApiError
     */
    public deleteFiles(
        tenantId: string,
        requestBody: Array<string>,
    ): CancelablePromise<Array<boolean>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/tenant/{tenantId}/images',
            path: {
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
