<template>
    <ModalScaffold
        :key="`page-${propertyPageIndex}`"
        :title="pageTitle"
        :show-back="!isFirstPage || needsPop"
        emit-close
        @close="closePage"
    >
        <div class="questions-outer">
            <div class="questions-layout">
                <div class="questions-panel">
                    <div class="question-title">
                        <h3 :data-qa="`question-page-${propertyGroup.title}`">
                            {{ propertyGroup.title }}
                        </h3>

                        <p v-if="propertyGroup.description">
                            {{ propertyGroup.description }}
                        </p>
                    </div>
                    <div class="questions">
                        <PropertyProvider
                            v-for="(property, idx) in propertyGroup.properties"
                            :key="property.name"
                            v-slot="{ propForm }"
                            :index="idx"
                            :prop="property"
                            :as="inlineMode(propertyGroup) ? 'span' : 'div'"
                            class="question"
                        >
                            <div v-if="!inlineMode(propertyGroup)">
                                <div v-if="propForm.state.generatingDefaultValue">
                                    <Spinner />
                                </div>
                                <PropertyFormField
                                    v-else
                                    :prop-form="propForm"
                                />
                            </div>
                        </PropertyProvider>
                    </div>
                    <div :class="{ 'question-button-row': true, 'show-progress': false }">
                        <div class="question-progress-container">
                            <div class="question-progress" />
                        </div>
                        <div class="questions-actions">
                            <div class="next">
                                <DsButton
                                    dense
                                    :loading="closing"
                                    :leading-icon="isPageValid ? null : KeapIcon.ALERT_TRIANGLE_FILL"
                                    data-qa="question-done-button"
                                    @click="closePage"
                                >
                                    {{ $t('done') }}
                                </DsButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ModalScaffold>
</template>

<script lang="ts">
import { GroupPageMode, ModelPropertyGroup } from '@/generated/play-api';
import ModalScaffold from '@/shared/components/ModalScaffold.vue';
import { injectFormProvider } from '@/model/form/inject-form';
import PropertyProvider from '@/model/form/PropertyProvider.vue';
import PropertyFormField from '@/model/form/PropertyFormField.vue';
import { injectPageStackCompleter } from '@/shared/components/navigation-inject';
import { ignore } from '@/shared/type.utils';
import { logLifecycle } from '@/play-editor/provider/vue3/lifecycle';
import { Emit } from '@/types/core-types';

export default defineComponent({
    name: 'PlayQuestionPage',

    components: {
        PropertyFormField,
        PropertyProvider,
        ModalScaffold,
    },

    props: {
        needsPop: Boolean,
        propertyPageIndex: Number,
        closing: Boolean,
        pageTitle: String,
    },

    setup(props, inst) {
        const { confirm, t } = useCore();

        logLifecycle();
        const emit = inst.emit as Emit<'go-to-summary'|'change-page'|'generate-play'|'close'>;
        const formProvider = injectFormProvider();
        const { groups, state } = formProvider;
        const { propertyPageIndex } = toRefs(props);
        const isFirstPage = computed(() => propertyPageIndex.value === -1);
        const nav = injectPageStackCompleter(!isFirstPage.value);

        const isPageValid = computed(() => {
            const errs = state.validationErrorsByPage[propertyPageIndex.value] ?? {};

            return Object.keys(errs).length === 0;
        });

        return {
            emit,
            isPageValid,
            async closePage() {
                if (!isPageValid.value) {
                    if (!await confirm({
                        message: t('models.validationError'),
                        title: t('models.validationTitle'),
                        confirmLabel: t('models.validationContinue'),
                        cancelLabel: t('models.validationCancel'),
                    })) {
                        // Stay on the page
                        return;
                    }
                }

                formProvider.saveAnswers().then(ignore);
                isFirstPage.value ? emit('close') : nav.back(null);
            },
            ...formProvider,
            ...toRefs(state),
            pageCount: computed(() => groups.length),

            inlineMode(propGroup: ModelPropertyGroup) {
                return !state.legacyQuestions && propGroup.mode === GroupPageMode.PARAGRAPH;
            },
            propertyGroup: computed(() => groups[propertyPageIndex.value]),
            isFirstPage,

            canGenerate: computed(() => {
                return Object.entries(state.validationErrorsByPage).length === 0;
            }),
        };
    },

});
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "../../styles/main";
    @import "../properties/play-question";

    .question-button-row {
        margin-bottom: $spacing-400;
    }

    .question {
        position: relative;
    }
</style>

<i18n>
{
    "en-us": {
        "asset": {
            "type": {
                "landingPage": "Landing Page",
                "email": "Email"
            }
        },
        "incompleteItems": "Incomplete items",
        "allItemsComplete": " ",
        "noChecklistItems": "Click here to view",
        "launchError": "There was an error launching this play",
        "launchSuccess": "We just created the Easy Automation for this Play.",
        "additionalStepsRequired": "Before publishing this Automation, please finish the pre-launch checklist items below.",
        "launchItems": "Launch Items",
        "showLaunchLinks": "Show Published Records",
        "goBackToQuestions": "Edit Play questions",
        "editTitle": "Edit Play name",
        "saveWarning": "One or more of your assets has unsaved changes.  Closing will discard these changes.",
        "unsavedChanges": "Unsaved changes",
        "closeAnyway": "Discard changes",
        "dontClose": "Keep editing",

        "saveAll": "Save All",
        "launch": "Use This Play",
        "editPlayName": "Edit Play name",
        "preview": "PREVIEW",
        "saveTitle": "Save",
        "pageTitle": "Choose your items",
        "pageDescription": "Describe things",
        "step": "Step {current} of {stepCount}",
        "previous": "Go back",
        "next": "Next",
        "done": "Done",
        "doneRefs": "Save and go back",
        "validation": {
            "requiredField": "This field is required",
            "tooFewItems": "Please enter at least {minItems} items",
            "tooManyItems": "Please enter at most {maxItems} items"
        },
        "validationTitle": "Invalid input",
        "validationError": "There are some issues with your submission.  Please fix any areas that require attention"
    }
}
</i18n>
