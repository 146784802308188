import {
    ChecklistActionType, HostSystem, Play, PlayAssetDetails, PlayLaunchLink, TimerRelativeType,
} from '@/generated/play-api';
import { KeyValues } from '@/types/core-types';
import { PlayLaunchManualStep, PlayLaunchResult } from '@/integration/capabilities/capability-types';
import { logger } from '@/shared/logging';
import { TimerType } from '@/integration/hosts/keap-web/launch-play/automation.constants';
import { EasyAutomationDuration } from '@/integration/hosts/keap-web/launch-play/duration-converter';
import { EventType, OffsetType } from '@/integration/hosts/keap-web/launch-play/automations.timer.constants';
import { LaunchLinkToNavDestination } from '@/play-editor/play.constants';
import asserts from '@/shared/asserts';

const log = logger('launch_play');

export enum LinkStatus {
    inactive = 'inactive',
    active = 'active',
}

export type LaunchedSequence = {
    title: string;
    status: LinkStatus;
    sequenceId: string;
}

export type DelayTimerConfig = EasyAutomationDuration & {
    delayType: OffsetType.after;
};

export type ExactTimerConfig = EasyAutomationDuration & {
    eventType: EventType.date;
    exactDate: string;
    startTime?: string;
};

export type AppointmentTimerConfig = EasyAutomationDuration & {
    eventType: EventType.appointment;
    offsetType: TimerRelativeType;
}

export type EasyAutomationTimerConfig = {
    id?: string;
    type: TimerType;
    configJson: DelayTimerConfig | ExactTimerConfig | AppointmentTimerConfig
};

export type EmailStepConfig = {
    fromContactOwner: boolean;
    fromUser: number;
    body: string;
    subject: string;
    signatureEnabled: boolean;
    email: {
        title: string;
    };
}

export type SmsStepConfig = {
    toFieldName: string;
    title: string;
    message: string;
    phoneTypes: string[];
}
export type EasyAutomationStepDef = {
    asset?: PlayAssetDetails;
    linkKey: string;
    sequenceStep: EasyAutomationStepConfig | EasyAutomationTimerConfig,
}

export type EasyAutomationStepConfig = {
    id: string;
    type: string;
    name: string;
    configJson: SmsStepConfig | EmailStepConfig | EasyAutomationTimerConfig;
}

export class LaunchResult {
    readonly appId: string;

    readonly links: Record<string, PlayLaunchLink>;

    readonly errors: Record<string, unknown>;

    readonly answers: KeyValues;

    readonly play: Play;

    readonly hostSystem: HostSystem;

    additionalSteps: PlayLaunchManualStep[];

    constructor(appId:string, hostSystem: HostSystem, play: Play, answers: KeyValues) {
        asserts(appId != null, 'Must have an appId');
        this.appId = appId;
        this.hostSystem = hostSystem;
        this.links = {};
        this.errors = {};
        this.play = play;
        this.answers = answers;
        this.additionalSteps = [];

        const { launchLinks = [] } = play;

        launchLinks?.forEach((link) => {
            this.links[link.playLinkKey] = link;
        });
    }

    addManualStep(action: PlayLaunchManualStep) {
        this.additionalSteps.push(action);
    }

    setLinkKey({
        playLinkKey, sourceId, linkType, status, sourceLabel,
    }: PlayLaunchLink): void {
        if (!playLinkKey) throw new Error(`Invalid linkKey: ${playLinkKey}`);

        if (this.links[playLinkKey]) {
            log.warn(`Overwriting existing link for ${playLinkKey}`);
        } else {
            log.warn(`Creating link for ${playLinkKey}`);
        }
        this.links[playLinkKey] = {
            sourceId,
            hostSystem: HostSystem.KEAP_WEB,
            sourceLabel,
            playLinkKey,
            linkType,
            status,
        };
    }

    build(): PlayLaunchResult {
        return {
            launchLinks: Object.values(this.links),
            errors: this.errors,
            success: Object.keys(this.errors).length === 0,
            additionalSteps: this.additionalSteps,
        };
    }

    /**
     * Finds any navigation actions that were created without knowing the ID, and updates those with the
     * correct ID
     * @param recordType The type of record to look for. This will correspond to the ManualStepAction.action property
     * @param placeholder The placeholder id that was used - typically an assetKey or sequenceKey
     * @param actualId The actual ID that was created
     */
    resolveActionPlaceholders(recordType: string, placeholder: string, actualId: string) {
        for (const additionalStep of this.additionalSteps) {
            if (additionalStep.actions?.length > 0) {
                for (const actionItem of additionalStep.actions) {
                    if (actionItem.actionType === ChecklistActionType.NAVIGATE) {
                        const { navigate: { destination, placeholderId } = {} } = actionItem;

                        if ([recordType, LaunchLinkToNavDestination[recordType]].includes(destination) && placeholder === placeholderId) {
                            actionItem.navigate.recordId = actualId;
                        }
                    }
                }
            }
        }
    }
}
