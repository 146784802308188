/* eslint-disable */
import { FormProvider } from '@/model/form/form-provider-types';
import { ModelProperty, PropertyPrimitiveType } from '@/client/play-client';
import { ArrayEvent, ArrayEventPayload, metaKeys } from '@/play-editor/play.constants';
import { IndexedItem, reorderMeta } from '@/play-editor/suggestion.util';
import asserts from '@/shared/asserts';

export function setupFormProviderSuggestions(self: FormProvider) {
    // Runs when a question order or index changes, and allows us to sync up our meta
    self.syncSuggestionsMeta = (question: ModelProperty, change: ArrayEvent, payload: ArrayEventPayload) => {
        self.mutateMeta(question, metaKeys.suggestions, (existing: IndexedItem[]) => {
            return reorderMeta(existing ?? [], change, payload);
        });

        return Promise.resolve();
        // return self.saveAnswers();
    };

    self.acceptSuggestion = (question: ModelProperty, suggestion: any, resultId: string) => {
        asserts(resultId, 'Must have resultId');

        if (question.type.base === PropertyPrimitiveType.ARRAY) {
            const answerArray = self.answerAsArray(question);

            return self.updateAnswer(question, [...answerArray, suggestion], {
                [metaKeys.suggestions]: (sugg: IndexedItem[]) => [
                    ...(sugg ?? []),
                    {
                        index: answerArray.length,
                        item: suggestion,
                        resultId,
                    },
                ],
            });
        }

        return self.updateAnswer(question, suggestion, {
            [metaKeys.suggestions]: () => [{
                index: 0,
                item: suggestion,
                resultId,
            }],
        });
    };

}
