/* eslint-disable @typescript-eslint/no-explicit-any */
import { InjectionKey, reactive, Ref } from 'vue';
import {
    Dict, KeyValues, Struct, VoidCallback,
} from '@/types/core-types';
import {
    AiResponse,
    ModelProperty,
    ModelPropertyGroup,
    ModelSchema,
    PropertyPrimitiveType,
    PropertyValidation,
    FullSuggestion,
} from '@/generated/play-api';
import { ValidationErrors } from '@/play-editor/provider/vue3/provider-types';
import { ArrayEvent, ArrayEventPayload } from '@/play-editor/play.constants';
import { ModelKey } from '@/integration/datastore/model-keys';
import { PageModel } from '@/play-editor/play/PlayModel';
import { BaseProvider } from '@/shared/shared-providers';
import { DataScopeData } from '@/model/form/DataScope';
import { SuggestionItem } from '@/model/form/SuggestionItem';
import { Logger } from '@/shared/logging';

export type SaveForm = ((answers: KeyValues, meta: KeyValues) => void | Promise<void>);
export const FormProviderKey: InjectionKey<FormProvider> = Symbol('modelFormProvider');
export const PropertyFormProviderKey: InjectionKey<PropertyProvide<unknown>> = Symbol('propFormProvider');
export const SuggestionProviderKey: InjectionKey<SuggestionsProvider> = Symbol('suggestionProviderKey');
export type ValidationErrorsByPage = Dict<Dict<PropertyValidation[]>>;

export type SuggestionsData = {
    generatesSuggestions: boolean;
    suggestions: SuggestionItem[];
    generating: boolean;
    replacing: boolean;
    errors: any[];
    numAttempts: number;
    expectedResultCount: number;

    /// Calculated Props
    visibleSuggestions: SuggestionItem[];
    minimumResults: number;
    missingFields: string[];
    error: string;
}

export type ModelFormCreateParameters = {
    schema: ModelSchema;
    modelId?: string;
    mergePlayId?: string;
    initialAnswers?: KeyValues;
    /** Gets called whenever the form values change */
    saveForm: SaveForm;
    appId: string;
}

export interface SuggestionsProvider {
    fallback?: boolean;
    data: SuggestionsData;

    acceptSuggestion(suggestion: SuggestionItem): void;

    removeSuggestion(removeItem: SuggestionItem): void;

    generateSuggestions(replace: boolean, eventSource?: string): Promise<void>;

    rejectSuggestion(suggestion: SuggestionItem): Promise<void>;

}

/**
 * The reactive state for a {@link FormProvider}
 *
 * see {@link FormProvider.formState}
 */
export type FormState = {
    /**
     * Whether or not the form is currently valid
     */
    isModelValid: boolean;

    baseUrl?: string;

    meta: KeyValues;

    /**
     * Whether legacy question mode is enabled
     */
    legacyQuestions: any;

    validationErrors: ValidationErrors;

    focusedQuestion?: ModelProperty | null;

    answers: KeyValues;

    expandedRefs: KeyValues;

    isValidByPage: Dict<boolean>;

    validationErrorsByPage: ValidationErrorsByPage;
}

/**
 * A form provider handles loading, saving, and validating forms based on {@link ModelSchema}.
 *
 * The primary endpoints for this functionality are {@link provideModelRefForm} and {@link providePlayForm}
 */
export interface FormProvider extends BaseProvider {

    /** Logger for this form */

    log: Logger;

    /**
     * Some core properties for this form:
     */
    source: ModelFormSource | PlayFormSource;

    /**
     * What type of form this is: play questions, or model ref
     */
    sourceType: FormSourceType;

    appId: string;

    /**
     * What pages (groups) and top-level refs exist as part of this form.
     *
     * See {@link hasRefs}, {@link refs}, {@link groups}
     */
    pageModel: PageModel;

    /**
     * Whether this form has top-level refs
     */
    hasRefs: boolean;

    /**
     * A list of top-level refs for this form
     */
    refs: Dict<ModelProperty>;

    /**
     * A list of pages for this form
     */
    groups: ModelPropertyGroup[];

    /**
     * A flattened list of all properties for this form
     */
    properties: Dict<ModelProperty>;

    /**
     * A property containing the reactive state for this form
     */
    state: FormState;

    /**
     * Validates this form.  This function is debounced
     */
    validate: (() => Promise<Dict<PropertyValidation[]>>);

    /**
     * Validates this form without debouncing
     */
    performValidate: (() => Promise<Dict<PropertyValidation[]>>);

    /**
     * The parent play for this form, if there is one
     */
    mergePlayId: string;

    /**
     * The data scope for this form.  Contains reactive references to parent forms.
     */
    scope: DataScopeData;

    // createProperty<T>(prop: string | ModelProperty): PropertyProvide<T>;

    /**
     * Gets (or creates) a reactive property state for a single property.
     *
     * Subsequent calls to this method will return the same instance
     *
     * @param name The name or instance of the property
     */
    get<T>(name: string | ModelProperty): PropertyProvide<T>;

    /**
     * Returns the current value of the provided question as an array.
     *
     * @param question
     */
    answerAsArray(question: ModelProperty): any[];

    /**
     * Manual sets/clears validation error for certain properties
     * @param question The question to add the error for
     * @param errors An error or errors.  If this value is empty or null, any error will be cleared
     */
    setValidationError(question: ModelProperty, errors?: PropertyValidation | PropertyValidation[]): void;

    /**
     * Gets the metadata for a single property.
     *
     * see {@link meta}
     *
     * @param propOrName The name or instance of the property
     */
    getPropMeta(propOrName: ModelProperty | string): KeyValues;

    /**
     * A function for mutating the metadata for a property.
     *
     * An example of metadata is tracking whether a selected option in a list came from a suggestion
     */
    mutateMeta<T>(prop: ModelProperty, key: string, mutation: MetaMutation<T>, defaultValue?: any): any;

    /**
     * Saves the answers for this form to the backend
     */
    saveAnswers(): Promise<unknown>;

    /**
     * Updates a single answer, while optionally updating the metadata for that question
     */
    updateAnswer(question: ModelProperty, answer: any, metaMutation?: KeyedMetaMutations): Promise<unknown>;

    /**
     * Retrieves several property providers at once.
     * @param props The list of names, or instances to return
     */
    getAll(props: ModelProperty[] | string[]): PropertyProvide<unknown>[];

    /**
     * Executes code on each property for this form.
     *
     * @param props
     * @param exec
     */
    onEachProperty(props: (ModelProperty | string)[], exec: ((helper: PropertyProvide<unknown>) => void)): void;

    /**
     * Generates all default values for this form.
     */
    generateDefaultValues(): Promise<unknown>

    /**
     * Sanitizes the data for this form, which will coerce data to the correct type
     */
    sanitize(): void;

    /**
     * Syncs all suggestion metadata.
     *
     * Runs when a question order or index changes, and allows us to sync up our meta
     */
    syncSuggestionsMeta(question: ModelProperty, change: ArrayEvent, payload: ArrayEventPayload): Promise<any>;

    /**
     * Accepts a suggestion for a single property
     */
    acceptSuggestion(question: ModelProperty, suggestion: any, resultId: string): Promise<unknown>;

    /**
     * Whether the current answer for a single property is an Array.
     *
     * @param question
     */
    isArray(question: ModelProperty): boolean;

    /**
     * Applies focus to a single question
     *
     */
    focusQuestion(question: ModelProperty): void;

    /**
     * Blurs a single question
     */
    blurQuestion(question: ModelProperty): void;

    /**
     * Refreshes all ref properties
     */
    refreshRefs(): Promise<void>;

    /**
     * Whether or not a single question is currently focused.
     * @param question
     */
    isFocused(question: ModelProperty): boolean;

    /**
     * Retrieves the answer for a single property
     * @param property
     */
    getAnswer<T>(property: ModelProperty): T;
}

export function fallbackFormProvider(): FormProvider {
    // @ts-ignore  It's okay - we're not trying to create a full implementation
    return {
        fallback: true,
        formState: reactive({
            answers: {},
            expandedRefs: {},
        }),
    } as FormProvider;
}

export type PropertyState<T> = {
    generatingDefaultValue: boolean;
    generatingDefaultValueMessage: string;
    validationErrors: PropertyValidation[];
    metaData: KeyValues;
    value: T;
    hasValue: boolean;
    focused: boolean;
}

export type PropertyProvide<T> = {
    property: ModelProperty;
    state: PropertyState<T>;
    form: FormProvider;
    name: string;
    generateSuggestions: GenerateSuggestions;

    /**
     * Returns a merge data helper - these allow us to merge in help descriptions
     */
    helperMerge(name: (keyof ModelProperty)[]): HelperMerger;
    updateAnswer(value: T, meta?: KeyedMetaMutations): Promise<unknown>;
    syncSuggestionsMeta(change: ArrayEvent, payload: ArrayEventPayload): void
    focus(): void;
    blur(): void;
};

export type PropertyMergeData = { merged: Ref<string>, update: VoidCallback };

export type HelperMerger = {
    update: VoidCallback;
    values: Struct<string, Ref<string>>;
}

export type MetaMutation<T> = T | MetaMutationFunction<T>;
// eslint-disable-next-line no-unused-vars
export type MetaMutationFunction<T> = ((previousValue: T, propMeta?: KeyValues) => void | any);

export type KeyedMetaMutations = Dict<MetaMutation<unknown>>;

export type PropMeta = Dict<KeyValues>;

export enum FormSourceType {
    play, modelRef
}

export type ExpandRefs = (answers: KeyValues) => Promise<KeyValues>;

export type FormSource = {
    pageModel: PageModel,
    name: string;
    mergePlayId: string;
    initialAnswers: KeyValues;
    saveForm: SaveForm;
    /**
     * Tells the provider how to expand ref answers for this form.
     * @param answers
     */
    expandRefs: ExpandRefs
}

export type ModelFormSource = FormSource & {
    refType: ModelKey;
}

export type PlayFormSource = FormSource & {
    playTemplateId: string;
}

export type GenerateSuggestionsParams = {
    previousSuggestions?: any[];
    attemptCount?: number;
    skipExtraTokenization?: boolean;
    expectedResultCount?: number;
    initialResults?: any[];
}

export type GenerateSuggestions = (params: GenerateSuggestionsParams) => Promise<AiResponse>;

export function defaultSuggestionGenerator(prop: ModelProperty): FullSuggestion {
    /// We'll use the property
    let numResults: number;

    switch (prop.type.base) {
    case PropertyPrimitiveType.LONG_TEXT:
        numResults = 3;
        break;
    case PropertyPrimitiveType.TEXT:
        numResults = 6;
        break;
    default:
        numResults = 0;
        break;
    }

    return {
        maxRuns: 1,
        dependentFields: [],
        numResults,
        operation: null,
        params: {},
        resultVariables: {},
    };
}
