<script lang="ts">export default { name: 'PlayPopover' }; </script>

<template>
    <div v-if="!disabled" class="popover-container" :class="[{ block }]">
        <BodyClickHandler :enabled="isOpenInternal" :target="$el" @click="onBodyClick" />

        <component
            :is="inline ? 'span' : 'div'"
            ref="reference"
            class="popover-reference"
            :class="{ inline }"
            @click="onReferenceClick"
        >
            <slot name="reference" />
        </component>

        <section
            ref="popover"
            class="popover"
            :class="[{ open: isOpenInternal }]"
        >
            <div
                v-if="!hideArrow"
                data-popper-arrow
                class="arrow"
            />
            <slot />
        </section>
    </div>
    <component :is="inline ? 'span' : 'div'" v-else>
        <slot name="reference" />
    </component>
</template>
<script lang="ts" setup>

import {
    createPopper, Modifier, Placement, VirtualElement,
} from '@popperjs/core';
import {
    onBeforeUnmount, toRef, toRefs, watch,
} from 'vue';
import {
    PopoverArrowOffset,
    PopoverOffsetCalculation, PopoverOffsetLiteral,
    PopoverPosition,
    PopperOffset,
} from '@/play-editor/PopoverPosition';

/**
 * Places a widget in relation to a reference widget.  By default, the widget will be placed to the right of the
 * reference widget
 */
const props = withDefaults(defineProps<{
    disabled?: boolean;
    inline?: boolean;

    /**
     * Controls the open state of the popover.  This makes the popover controlled and will not work with uncontrolled options.
     */
    isOpen?: boolean;

    arrowOffset?: PopoverArrowOffset;

    /**
     * Make popover reference a block instead of default inline-block
     */
    block?: boolean;

    /**
     * Stick popper to viewport top when scrolling past view
     */
    sticky?: boolean;

    /**
     * Fix popover to window - great for getting around overflow: hidden containers
     */
    positionFixed?: boolean;

    /**
     * Whether or not to hide the arrow pointing to the reference
     */
    hideArrow?: boolean;

    keepVisible?: boolean;
    offset?: PopoverOffsetCalculation|PopoverOffsetLiteral,

    /**
     * Position of the popover, top bottom left right top-start top-end right-start right-end bottom-start bottom-end left-start left-end.
     */
    position?: PopoverPosition,
}>(), {
    keepVisible: true,
    position: PopoverPosition.rightStart,
    offset: () => ({ skidding: 0, distance: 8 }),
});

const data = reactive({
    isOpenInternal: false,
    popperJs: null,
});

const {
    disabled, isOpen, position, offset, keepVisible, arrowOffset, hideArrow,
    inline, sticky, positionFixed, block,
} = toRefs(props);

onMounted(() => {
    if (!props.disabled) {
        initializePopper();
    }
});
const emit = defineEmits(['popover-active', 'close']);

onBeforeUnmount(() => {
    if (data.popperJs && typeof data.popperJs.destroy === 'function') {
        data.popperJs.destroy();
    }

    data.popperJs = null;
});

watch(disabled, (newValue) => {
    if (!newValue && !data.popperJs) {
        initializePopper();
    }
});

watch(
    isOpen,
    (isOpen) => {
        if (disabled.value) return;

        if (isOpen) {
            open();
        } else {
            close();
        }
    },
    { immediate: true },
);

watch(position, (value) => {
    if (data.popperJs) {
        data.popperJs.options.placement = value;
    }
});

const isControlled = computed((): boolean => {
    return isOpen.value === true || isOpen.value === false;
});

const popperOffset = computed((): PopperOffset => {
    if (typeof offset.value === 'function') {
        return (input) => {
            const { distance, skidding } = (offset.value as PopoverOffsetCalculation)(input);

            return [skidding, distance];
        };
    }

    return [offset.value?.skidding ?? 0, offset.value?.distance ?? 0];
});

const popover = ref();
const reference = ref();

function initializePopper(): void {
    if (!data.popperJs) {
        const arrowOffset2 = {
            name: 'arrowOffset',
            enabled: true,
            phase: 'beforeWrite',
            requires: ['computeStyles'],
            fn: ({ state }) => {
                const { top, left } = arrowOffset.value ?? {};

                if (top) {
                    state.styles.arrow.top = top;
                }

                if (left) {
                    state.styles.arrow.left = left;
                }
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as Modifier<any, any>;

        data.popperJs = createPopper(reference.value as VirtualElement, popover.value as HTMLElement, {
            placement: position.value as Placement,
            strategy: positionFixed.value ? 'fixed' : 'absolute',
            modifiers: [
                arrowOffset2,
                {
                    name: 'eventListeners',
                    options: {
                        scroll: true,
                        resize: true,
                    },
                },

                {
                    name: 'offset',
                    options: {
                        offset: popperOffset.value,
                    },
                },
                {
                    name: 'preventOverflow',
                    options: keepVisible.value ? {
                        tether: !sticky.value,
                        boundary: sticky.value ? 'viewport' : 'clippingParents',
                    } : { mainAxis: false },
                },
                {
                    name: 'flip',
                    options: keepVisible.value ? {

                    } : {
                        flipVariations: false,
                        fallbackPlacements: [],
                    },
                },
            ],
        });
    }
}

function update(): void {
    if (data.popperJs) {
        data.popperJs.update();
    }
}

function open(): void {
    data.isOpenInternal = true;

    emit('popover-active', true);

    if (data.popperJs) {
        data.popperJs.update();
    }
}

function close(): void {
    if (data.isOpenInternal) {
        data.isOpenInternal = false;

        emit('close');
        emit('popover-active', false);

        if (data.popperJs) {
            data.popperJs.update();
        }
    }
}

function toggle(): void {
    if (data.isOpenInternal) {
        close();
    } else {
        open();
    }
}

function onReferenceClick(): void {
    if (isControlled.value) {
        return;
    }

    toggle();
}

function onBodyClick(event:MouseEvent): void {
    if (isControlled.value && data.isOpenInternal) {
        emit('close', { event });

        return;
    }

    close();
}
const isOpenInternal = toRef(data, 'isOpenInternal');

defineExpose({
    update,
    open,
    close,
    toggle,
});
</script>

<style lang="scss" scoped>
    @import "~@infusionsoft/design-system/src/styles/common";

    $background-color: var(--popover-background-color, #{$popover-background-color});

    .popover-container {
        display: inline-block;
        position: relative;
        z-index: 10001;

        &.block {
            display: block;

            .popover-reference {
                display: block;
            }
        }

        &:not(.block) {
            &.inline {
                display: inline-block;
            }

            &:not(.inline) {
                display: inline-block;
            }
        }
    }

    .popover {
        @include popover($background-color);

        box-shadow: $popover-shadow;
        min-width: var(--popover-min-width, #{$dropdown-minwidth});
        width: var(--popover-width, #{$dropdown-minwidth});
        z-index: 10001;
    }
</style>
