/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmptyValue } from '@/shared/shared.utils';
import {
    FormProvider, FormSourceType, ModelFormSource, PlayFormSource,
} from '@/model/form/form-provider-types';
import { playClientTs, PropertyPrimitiveType } from '@/client/play-client';

export function setupFormProviderDefaultValues(self: FormProvider) {
    self.generateDefaultValues = async () => {
        const { state, source, sourceType } = self;

        const forProperties = self.properties.valueSet().filter((prop) => {
            const helper = self.get(prop);
            const hasGenerator = prop.generatedDefaultValue || prop.emptySuggestionGenerator;

            const existingValue = state.answers[prop.name];

            if (isEmptyValue(existingValue)) {
                return hasGenerator && !helper.state.generatingDefaultValueMessage;
            }

            return false;
        });

        try {
            for (const prop of self.getAll(forProperties)) {
                prop.state.generatingDefaultValue = true;
            }

            await Promise.all(
                forProperties.map(async (prop) => {
                    let defaultValue: any;

                    const helper = self.get(prop);

                    if (sourceType === FormSourceType.play) {
                        const { mergePlayId, playTemplateId } = <PlayFormSource>source;

                        const generatedValue = await playClientTs.playTemplateV2.generateDefaultValueV2(
                            self.appId,
                            playTemplateId,
                            prop.name,
                            {
                                answers: {
                                    ...state.answers,
                                    ...state.expandedRefs,
                                },
                                initialResults: self.answerAsArray(prop),
                                playId: mergePlayId,
                                expectedResultCount: null,
                                skipExtraTokenization: null,
                                attemptCount: null,
                                adjustments: null,
                            },
                        );

                        defaultValue = generatedValue.responseData;
                    } else {
                        const { refType, mergePlayId } = <ModelFormSource>source;
                        const generatedValue = await playClientTs.modelDefinition.generateDefaultValueForModel(
                            self.appId,
                            refType.category,
                            refType.name,
                            prop.name,
                            {
                                answers: {
                                    ...state.answers,
                                    ...state.expandedRefs,
                                },
                                initialResults: self.answerAsArray(prop),
                                playId: mergePlayId,
                                expectedResultCount: null,
                                skipExtraTokenization: null,
                                attemptCount: null,
                                adjustments: null,
                            },
                        );

                        defaultValue = generatedValue.responseData;
                    }

                    if (defaultValue != null) {
                        if (prop.type.base === PropertyPrimitiveType.TEXT && Array.isArray(defaultValue)) {
                            // eslint-disable-next-line prefer-destructuring
                            // Get random value from generated
                            defaultValue = defaultValue.random();
                        }

                        await self.updateAnswer(prop, defaultValue);
                    }

                    if (defaultValue && prop.defaultValueMessage) {
                        helper.state.generatingDefaultValueMessage = prop.defaultValueMessage;
                    }
                    helper.state.generatingDefaultValue = false;
                }),
            );
        } finally {
            for (const prop of self.getAll(forProperties)) {
                prop.state.generatingDefaultValue = false;
            }
        }
    };
}
