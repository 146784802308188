/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChecklistItemStatus } from '../models/ChecklistItemStatus';
import type { CreateAdhocChecklistItemRequest } from '../models/CreateAdhocChecklistItemRequest';
import type { CreateChecklistItemResponse } from '../models/CreateChecklistItemResponse';
import type { CreateChecklistRequest } from '../models/CreateChecklistRequest';
import type { HostSystem } from '../models/HostSystem';
import type { ListPlay } from '../models/ListPlay';
import type { Object } from '../models/Object';
import type { Play } from '../models/Play';
import type { PlayChecklist } from '../models/PlayChecklist';
import type { PlayChecklists } from '../models/PlayChecklists';
import type { UpdateChecklistItem } from '../models/UpdateChecklistItem';
import type { UpdateChecklistResponse } from '../models/UpdateChecklistResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PlayService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get All Checklists
     * Retrieves all checklists
     * @param tenant
     * @param includeHidden
     * @returns PlayChecklists Success
     * @throws ApiError
     */
    public getAllChecklists(
        tenant: string,
        includeHidden: boolean = false,
    ): CancelablePromise<PlayChecklists> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/tenant/{tenant}/checklists',
            path: {
                'tenant': tenant,
            },
            query: {
                'includeHidden': includeHidden,
            },
        });
    }

    /**
     * Resort Checklists
     * Changes the sort order for checklists
     * @param tenant
     * @param requestBody
     * @returns number Success
     * @throws ApiError
     */
    public resortChecklists(
        tenant: string,
        requestBody: Array<string>,
    ): CancelablePromise<Record<string, number>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/checklists/sortOrders',
            path: {
                'tenant': tenant,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * List Plays
     * Lists all plays for a given tenant, optionally filtered by hostSystem
     * @param tenant The tenantId to use
     * @param sourceType deprecated: use hostSystem
     * @param hostSystem show plays for this system as well as "general"
     * @returns ListPlay A list of Plays. These Play records will not have full details loaded.
     * @throws ApiError
     */
    public listPlays(
        tenant: string,
        sourceType?: string | null,
        hostSystem?: HostSystem | null,
    ): CancelablePromise<ListPlay> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/tenant/{tenant}/plays',
            path: {
                'tenant': tenant,
            },
            query: {
                'sourceType': sourceType,
                'hostSystem': hostSystem,
            },
        });
    }

    /**
     * Resort Plays
     * Changes the sort order for all plays
     * @param tenant
     * @param requestBody
     * @returns number Success
     * @throws ApiError
     */
    public resortPlays(
        tenant: string,
        requestBody: Array<string>,
    ): CancelablePromise<Record<string, number>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/tenant/{tenant}/plays',
            path: {
                'tenant': tenant,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get Play
     * Retrieves Play details by ID
     * @param tenant The tenant ID
     * @param playId The id of the Play to load
     * @returns Play Success
     * @throws ApiError
     */
    public getPlay(
        tenant: string,
        playId: string,
    ): CancelablePromise<Play> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/tenant/{tenant}/plays/{playId}',
            path: {
                'tenant': tenant,
                'playId': playId,
            },
        });
    }

    /**
     * Delete Play
     * Deletes a Play based on ID
     * @param tenant The tenant calling the endpoint
     * @param playId the ID of the play to be deleted.
     * @returns any Success
     * @throws ApiError
     */
    public deletePlay(
        tenant: string,
        playId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/tenant/{tenant}/plays/{playId}',
            path: {
                'tenant': tenant,
                'playId': playId,
            },
        });
    }

    /**
     * Get Checklists for Play
     * Retrieves all Checklists associated with a Play
     * @param tenant
     * @param playId
     * @returns PlayChecklists Success
     * @throws ApiError
     */
    public getChecklistsForPlay(
        tenant: string,
        playId: string,
    ): CancelablePromise<PlayChecklists> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/tenant/{tenant}/plays/{playId}/checklists',
            path: {
                'tenant': tenant,
                'playId': playId,
            },
        });
    }

    /**
     * Create Checklist
     * Creates a new ad-hoc Checklist and attaches it to a Play
     * @param tenant
     * @param playId
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public createChecklist(
        tenant: string,
        playId: string,
        requestBody: CreateChecklistRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/plays/{playId}/checklists',
            path: {
                'tenant': tenant,
                'playId': playId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get Checklist
     * Retrieves a Checklist for a Play, or default
     * @param tenant
     * @param playId
     * @param checklistId
     * @returns PlayChecklist The Checklist for this Play
     * @throws ApiError
     */
    public getChecklist(
        tenant: string,
        playId: string,
        checklistId: string,
    ): CancelablePromise<PlayChecklist> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/tenant/{tenant}/plays/{playId}/checklists/{checklistId}',
            path: {
                'tenant': tenant,
                'playId': playId,
                'checklistId': checklistId,
            },
            errors: {
                404: `The Checklist does not exist`,
            },
        });
    }

    /**
     * Create Checklist Item
     * Add an item to the checklist for this play
     * @param tenant
     * @param playId
     * @param checklistId
     * @param requestBody
     * @returns CreateChecklistItemResponse Success
     * @throws ApiError
     */
    public createChecklistItem(
        tenant: string,
        playId: string,
        checklistId: string,
        requestBody: CreateAdhocChecklistItemRequest,
    ): CancelablePromise<CreateChecklistItemResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/plays/{playId}/checklists/{checklistId}/items',
            path: {
                'tenant': tenant,
                'playId': playId,
                'checklistId': checklistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update Checklist Item
     * Updates a single ChecklistItem record
     * @param tenant
     * @param playId
     * @param checklistId
     * @param itemId
     * @param requestBody
     * @returns UpdateChecklistResponse Success
     * @throws ApiError
     */
    public updateChecklistItem(
        tenant: string,
        playId: string,
        checklistId: string,
        itemId: string,
        requestBody: UpdateChecklistItem,
    ): CancelablePromise<UpdateChecklistResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/tenant/{tenant}/plays/{playId}/checklists/{checklistId}/items/{itemId}',
            path: {
                'tenant': tenant,
                'playId': playId,
                'checklistId': checklistId,
                'itemId': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Checklist Item
     * Deletes a single ChecklistItem record
     * @param tenant
     * @param playId
     * @param checklistId
     * @param itemId
     * @returns UpdateChecklistResponse Success
     * @throws ApiError
     */
    public deleteChecklistItem(
        tenant: string,
        playId: string,
        checklistId: string,
        itemId: string,
    ): CancelablePromise<UpdateChecklistResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/tenant/{tenant}/plays/{playId}/checklists/{checklistId}/items/{itemId}',
            path: {
                'tenant': tenant,
                'playId': playId,
                'checklistId': checklistId,
                'itemId': itemId,
            },
        });
    }

    /**
     * Update ChecklistItemStatus
     * Updates a single ChecklistItem record
     * @param tenant
     * @param playId
     * @param checklistId
     * @param itemId
     * @param itemStatus
     * @returns UpdateChecklistResponse Success
     * @throws ApiError
     */
    public updateChecklistItemStatus(
        tenant: string,
        playId: string,
        checklistId: string,
        itemId: string,
        itemStatus: ChecklistItemStatus,
    ): CancelablePromise<UpdateChecklistResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/tenant/{tenant}/plays/{playId}/checklists/{checklistId}/items/{itemId}/itemStatus/{itemStatus}',
            path: {
                'tenant': tenant,
                'playId': playId,
                'checklistId': checklistId,
                'itemId': itemId,
                'itemStatus': itemStatus,
            },
        });
    }

    /**
     * Resort Checklist Items
     * Changes the sort order for items in this checklist
     * @param tenant
     * @param playId
     * @param checklistId
     * @param requestBody
     * @returns number Success
     * @throws ApiError
     */
    public resortChecklistItems(
        tenant: string,
        playId: string,
        checklistId: string,
        requestBody: Array<string>,
    ): CancelablePromise<Record<string, number>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/plays/{playId}/checklists/{checklistId}/sortOrders',
            path: {
                'tenant': tenant,
                'playId': playId,
                'checklistId': checklistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Expand Play Answers
     * Loads expanded answers for a given play
     * @param tenant The tenant calling the endpoint
     * @param playId the ID of the play for which to fetch expanded answers.
     * @param requestBody The current answers to be expanded
     * @returns Object Success
     * @throws ApiError
     */
    public expandPlayAnswers(
        tenant: string,
        playId: string,
        requestBody: any,
    ): CancelablePromise<Object> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/plays/{playId}/expanded',
            path: {
                'tenant': tenant,
                'playId': playId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
