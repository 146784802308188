import {
    ChecklistAction,
    ChecklistActionType,
    ChecklistHrefAction,
    ChecklistNavigateAction, HostSystem,
    Play,
    V2PlayTemplate,
} from '@/generated/play-api';
import { KeyValues } from '@/types/core-types';
import { ExportRules } from '@/integration/host-system-api';

export type PlayLaunchInput = {
    success?: boolean;
    play: Play;
    playTemplate: V2PlayTemplate;
    answers: KeyValues;
}

type LaunchLink = {
    sourceId: string;
    hostSystem: HostSystem;
    sourceLabel: string;
    playLinkKey: string,
    linkType: string;
    status: string;
};

export type PlayLaunchManualStep = {
    title?: string;
    description: string;
    data?: KeyValues;
    actions?: ChecklistAction[];
    notification?: boolean;
};

export function ManualHrefAction(href:ChecklistHrefAction): ChecklistAction {
    return {
        href,
        actionType: ChecklistActionType.HREF,
    };
}

export function ManualNavigateAction(navigate :ChecklistNavigateAction): ChecklistAction {
    return {
        navigate,
        actionType: ChecklistActionType.NAVIGATE,
    };
}
export type PlayLaunchResult = {
    launchLinks: LaunchLink[];
    errors: Record<string, unknown>;
    additionalSteps?: PlayLaunchManualStep[];
    success: boolean;
};

export type NavigateInput = {
    hostSystem: HostSystem;
    recordType: string;
    recordId?: string;
    navigateParams: KeyValues;
};

export enum NavigateType {
    /**
     * Opens within this app
     */
    internal='internal',

    /**
     * Opens an external window
     */
    external='external',

    /**
     * Opens in the parent frame
     */
    parent='parent',
}

export type NavigateMeta = {
    /**
     * Indicates how this link will open
     */
    navType: NavigateType;
}

export type LaunchPlayMeta = {
    /**
     * Meta information about the Play Launch capability
     */
    launchLabel: string;

    /**
     * Indicates how this link will open
     */
    exportRules: ExportRules;
}
