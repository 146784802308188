import { ModelKey } from '@/integration/datastore/model-keys';
import { Dict } from '@/types/core-types';
import { PropMeta } from '@/model/form/form-provider-types';
import { HostSystem } from '@/generated/play-api';

export type ListFunctionParams = {
    limit?: number;
    offset?: number;
    cursor?: string;
};

export type SearchFunctionParams = ListFunctionParams & {
    search?: string;
};

export type ModelData = Dict<unknown>;
export type ModelSpecificData = Dict<unknown>&{
    title?:string;
    __title?:string;
    description?:string;
    __description?:string;
};

export enum RemoteModelStatus {
    none, notSynced, synced
}

/**
 * Within play-web, we use this model.  Note that the [data] property is not at the root.
 */
export type PreparedModel = {
    id? :string;
    data: ModelSpecificData;
    title?: string;
    description?: string;
    type: ModelKey;
    remoteStatus?: RemoteModelStatus;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errors?: any[];
    hostSystem?: HostSystem;
    sourceId?: string;
    createTime?: string;
    updateTime?: string;
    valid: boolean;
    __meta?: PropMeta;
};

export function blankModel(type: ModelKey):PreparedModel {
    return {
        data: {},
        type,
        valid: false,
    };
}

export type ListResults<T> = {
    data: T[];
    totalCount?: number;
    cursor?: string;
}

export type PreparedListResults = ListResults<PreparedModel>;
// type UpdateParameters = {
//     id:string,
//     data: PreparedModel,
// };
export type InitializeStoreFunction = () => Promise<void>;

/**
 * Creates a new model
 */
export type CreateFunction = (input: PreparedModel) => Promise<PreparedModel>;

/**
 * Retrieves a single model by ID
 */
export type GetFunction = (id: string) => Promise<PreparedModel>;

/**
 * Updates a model by ID
 */
export type UpdateFunction = (...params: [string, PreparedModel]) => Promise<PreparedModel>;

/**
 * Deletes a model from the remote system
 */
export type RemoveFunction = (id: string) => Promise<unknown>;

/**
 * Returns a paged view of all records
 */
export type ListFunction = (params?: ListFunctionParams) => Promise<PreparedListResults>;

/**
 * Searches for records
 */
export type SearchFunction = (params: SearchFunctionParams) => Promise<PreparedListResults>;

/**
 * Copies a record from a remote system into the play-service backend
 */
export type SyncFunction = (params: PreparedModel) => Promise<PreparedModel> | PreparedModel;

export type DataStoreOperations = {
    initializeStore?: InitializeStoreFunction;
    get?: GetFunction;
    create?: CreateFunction;
    update?: UpdateFunction;
    remove?: RemoveFunction;
    list?: ListFunction;
    search?: SearchFunction;
    sync?: SyncFunction;
}

export enum Operation {
    GET = 'get',
    CREATE = 'create',
    REMOVE = 'remove',
    LIST = 'list',
    SEARCH = 'search',
    UPDATE = 'update',
    SYNC = 'sync',
}
