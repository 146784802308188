import { RouteRecordRaw } from 'vue-router';
import { RouteName } from '@/router/route-names';
import { authRoutes } from '@/router/routes/auth-routes';
import { coachPortalRoutes } from '@/router/routes/coach-portal-routes';
import { devRoutes } from '@/router/routes/dev-routes';
import { copyGeneratorRoutes } from '@/router/routes/copy-generator-routes';
import { meta, normalizeRoutes } from '@/router/routes/routes-utils';

const routes = normalizeRoutes(<RouteRecordRaw[]>[
    ...authRoutes,
    ...devRoutes,
    {
        path: '/',
        component: () => import('@/UserRoot.vue'),
        meta: { isPublic: true },
        props: true,
        children: [
            {
                path: '', meta: meta(), name: RouteName.app, component: () => import('@/HomePage.vue'),
            },
            {
                path: 'admin',
                meta: { title: 'routes.coachAdmin' },
                name: RouteName.coachAdmin,
                component: () => import('@/coach/CoachAdmin.vue'),
            },
            ...coachPortalRoutes,
            ...copyGeneratorRoutes,
            {
                name: RouteName.compose,
                path: ':coachId/:appId/compose/:id',
                component: () => import('@/play-editor/PlayListPage.vue'),
            },
            {
                path: ':coachId?/:appId/embed',
                name: RouteName.embed,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                redirect: ({ path, ...to }) => {
                    return {
                        ...to,
                        name: RouteName.plays,
                        params: {
                            ...to.params,
                            embed: true,
                        },
                    };
                },
            },
            {
                path: ':appId/playbookEmbed',
                name: RouteName.playBookembed,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                redirect: ({ path, ...to }) => {
                    return {
                        ...to,
                        name: RouteName.playbook,
                        params: {
                            ...to.params,
                            embed: true,
                        },
                    };
                },
            },
            {
                path: ':coachId?/:appId',
                redirect: (to) => {
                    return ({
                        ...to,
                        name: RouteName.plays,
                    });
                },
            },
        ],
    },
    { path: '/:pathMatch(.*)*', name: RouteName.fallback, redirect: { name: RouteName.app } },
]);

export default routes;
