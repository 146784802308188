import { setupExtensions } from '@/setup/setup-extensions';
import { setupVueLoggers } from '@/setup/setup-vue-logging';
import { setupVueRouter } from '@/setup/setup-vue-router';
import { setupKeapDevtools } from '@/setup/setup-devtools';
import { setupVueGlobals } from './setup-vue-globals';
import { setupDesignSystem } from '@/setup/setup-design-system';
import { App } from 'vue';
import { setupFirebase } from '@/setup/setup-firebase';
import ModelPropertiesEditor from '@/play-editor/model/ModelPropertiesEditor.vue';
import PlayQuestionEditor from '@/play-editor/play/PlayQuestionEditor.vue';
import { State } from '@/store/play-root-state';
import { Store } from 'vuex';
import asserts from '@/shared/asserts';
import { env } from '@/env';
import { I18n } from 'vue-i18n';
import { Router } from 'vue-router';
import { setupVuePlugins } from '@/setup/setup-vue-plugins';

export function setupApp(
    app: App<unknown>,
    routerFactory: () => Router,
    i18nFactory: (app:App) => I18n,
    storeFactory: () => Store<State>,
) {
    // This needs to come first
    if (env.MODE !== 'production') {
        app.config.globalProperties.productionTip = false;
    }
    app.config.globalProperties.devtools = true;
    app.config.globalProperties.playDevtools = true;
    app.config.performance = true;

    setupExtensions();
    setupVuePlugins(app);
    const i18n = i18nFactory(app);

    const store = storeFactory();

    app.use(store);

    asserts(store, 'Router must not be null');

    setupVueGlobals(app, i18n, store);
    setupDesignSystem(app, store);
    setupVueLoggers(app);
    setupFirebase();
    const router = routerFactory();

    app.use(router);

    asserts(router, 'Router must not be null');
    setupVueRouter(router, store, i18n.global);
    setupKeapDevtools(store);

    app.component('ModelPropertiesEditor', ModelPropertiesEditor);
    app.component('PlayQuestionEditor', PlayQuestionEditor);

    return { router, i18n, store };
}

// export function setupAppFrom(
//     app: App<unknown>,
//     setupRouter: Router,
//     setupI18n: VueI18n,
//     setupStore: PlayStore,
// ) {
//     setupExtensions();
//
//     setupVuePlugins(app);
//     const i18n = setupI18n;
//
//     const store = setupStore;
//
//     setupVueGlobals(app, i18n, store);
//
//     setupDesignSystem(app, store);
//     setupVueLoggers(app);
//     setupFirebase();
//     setupVueFilters(app);
//     const router = setupRouter;
//
//     setupVueRouter(router, store, i18n);
//     setupKeapDevtools(store);
//
//     app.component('ModelPropertiesEditor', ModelPropertiesEditor);
//     app.component('PlayQuestionEditor', PlayQuestionEditor);
// }
