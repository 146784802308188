import {
    inject, InjectionKey, reactive,
} from 'vue';
import {
    IndustryType, V1GoalMetricType, V1IndustryType, V1PlayProgress,
} from '@/generated/play-api';
import { watchCalculated } from '@/play-editor/mixins/v3/computedReactive';
import { useAsyncState } from '@vueuse/core';
import {
    dict, struct, Dict,
} from '@/types/core-types';
import { playClientTs } from '@/client/play-client';
import { AsyncState } from '@/play-editor/model/TenantModelService';
import { getOrProvide } from '@/shared/shared-providers';

export type OptionItem = {
    label: string;
    name: string;
}

export type LookupState = {
    goalTypes?: Dict<V1GoalMetricType>;
    goalsByLcaPhase?: Dict<V1GoalMetricType[]>;
    industryTypes?: Dict<V1IndustryType>;
    industryTypesOptions?: OptionItem[];
    playProgresses?: Dict<V1PlayProgress>;
    isLoading: boolean;
}

type LookupKeys = 'goalMetrics' | 'industryTypes' | 'playProgresses';
export interface LookupProvider {
    state: LookupState;

    refresh(only?: LookupKeys[]): Promise<unknown>;
}

export const LookupsProviderKey: InjectionKey<LookupProvider> = Symbol('lookupsProvider');

export function provideLookups(doProvide = true): LookupProvider {
    return getOrProvide(LookupsProviderKey, () => lookupsProvider(), doProvide);
}

function lookupsProvider() {
    const goalMetrics = useAsyncState(() => {
        return playClientTs.lookups.listGoalMetricTypes();
    }, null, { shallow: false });

    const industryTypes = useAsyncState(async () => {
        const result = await playClientTs.lookups.listIndustryTypes();

        return result.data;
    }, null, { shallow: false });

    const playProgresses = useAsyncState(() => {
        return playClientTs.lookups.listPlayProgresses();
    }, null, { shallow: false });

    const state = reactive({
        isLoading: true,
    } as LookupState);

    const allStates = struct<LookupKeys, AsyncState<unknown>>({ goalMetrics, industryTypes, playProgresses });

    watchCalculated(state, {
        goalTypes() {
            return goalMetrics.state.value?.data?.keyed((e) => e.name as string) ?? dict();
        },
        industryTypes() {
            return industryTypes.state.value?.keyed((e) => e.name as string) ?? dict();
        },
        playProgresses() {
            return playProgresses.state.value?.data?.keyed((e) => e.name as string) ?? dict();
        },
        goalsByLcaPhase() {
            return goalMetrics.state.value?.data?.groupBy((e) => e.lcaPhase) ?? dict();
        },
        industryTypesOptions() {
            return industryTypes.state.value?.map((value) => ({
                name: value.name,
                label: value.fallbackLabel,
            })) ?? [{
                name: IndustryType.COACH_CONSULTANT,
                label: 'Coach/Consultant',
            }];
        },
    }, { deep: true });

    watchCalculated(state, {
        isLoading() {
            return allStates.valueSet().find((st) => st.isLoading.value) != null;
        },
    });

    const provider: LookupProvider = {
        state,
        refresh(only?: LookupKeys[]): Promise<unknown> {
            return Promise.all(allStates.entrySet().filter(([k]) => only == null || only.includes(k))
                .map(([, s]) => {
                    return s.execute();
                }));
        },
    };

    return provider;
}

export function useLookups(): LookupProvider {
    return inject(LookupsProviderKey);
}
