import '../types/core-ext';
import '@/shared/directives';
import PlayDevToolsPlugin from '@/setup/devtools/PlayDevToolsPlugin';
import { App } from 'vue';

export function setupVuePlugins(app: App<unknown>) {
    // app.use(vClickOutside);

    app.use(PlayDevToolsPlugin);
}
