<script lang="ts">export default { name: 'RefCard' }; </script>
<template>
    <div v-if="forceDropdown || isChoosingModel || !actualLoadedModel" :class="{ 'ref-card-container': true }">
        <DsMultiselect
            ref="refSearchField"
            :model-value="actualLoadedModel"
            label-prop="title"
            data-qa="ref-search-field"
            class="ref-search-field"
            value-prop="id"
            :allow-add="!isEnum"
            :searchable="!isEnum"
            show-search-icon
            help-text
            :add-text="schemaI18nHelper.addLabel"
            :loading="loading"
            :options="filteredSearchResults"
            :placeholder="placeholder || schemaI18nHelper.chooseLabel"
            @update:model-value="updateModel($event?.id, true)"
            @update-search="updateSearch"
            @add="emit('add')"
            @blur="onBlur"
        >
            <template #option="{ isSelected, ...slotProps }">
                <MenuListItem v-bind="vbind(slotProps)" :label="slotProps.option.title" :selected="bool(isSelected || slotProps['is-selected'])">
                    <template #trailing>
                        <div v-if="calculateTrailingIcon(slotProps.option)" :class="{ 'selected-item': (isSelected || slotProps['is-selected']) }">
                            <DsChip v-if="calculateTrailingIcon(slotProps.option)">
                                {{ hostSystemName(slotProps.option) }}
                            </DsChip>
                        </div>
                    </template>
                </MenuListItem>
            </template>
        </DsMultiselect>
    </div>
    <div v-else :class="{ 'ref-card-container': true, 'ref-card': true, 'allow-edit': allowEdit }">
        <slot name="leading" />
        <div class="ref-card-edit" @click="isChoosingModel = allowEdit">
            <div v-if="!cardOptions" data-qa="model-info">
                <div class="model-title">
                    {{ actualLoadedModel.title || t('defaultModelTitle') }}
                </div>
                <div v-if="actualLoadedModel.description || !isModelValid" class="model-description">
                    <span v-if="actualLoadedModel.description">{{ actualLoadedModel.description }} </span><a
                        v-if="actualLoadedModel && !isModelValid"
                        class="error"
                        @click.stop="emit('edit')"
                    >{{ t('models.invalid') }}</a>
                </div>
            </div>
            <RefDisplay
                v-else
                :card-options="cardOptions"
                :ref-id="actualLoadedModel.id"
                :ref-model="actualLoadedModel"
                :ref-type="refTypeObj"
            >
                <template #error>
                    <div v-if="!isModelValid" class="model-description">
                        <a
                            v-if="actualLoadedModel && !isModelValid"
                            class="error"
                            @click.stop="emit('edit')"
                        >{{ t('models.invalid') }}</a>
                    </div>
                </template>
            </RefDisplay>
        </div>
        <div v-if="isModelSelected && !isChoosingModel" class="ref-actions">
            <slot name="trailing" />
            <DsDropdown position="bottom-end" icons>
                <template #default>
                    <DsIconButton
                        name="more-vertical"
                        data-qa="model-actions"
                    />
                </template>

                <template #menu>
                    <MenuList>
                        <MenuListItem
                            class="dropdown-menu-item"
                            data-qa="edit-model"
                            :leading-icon="KeapIcon.PENCIL"
                            :subtitle="actualLoadedModel.title"
                            @select="emit('edit')"
                        >
                            {{ $t('shared.edit') }}
                        </MenuListItem>

                        <MenuListItem
                            v-if="allowEdit"
                            class="dropdown-menu-item"
                            data-qa="replace-model"
                            :leading-icon="KeapIcon.SEARCH"
                            border-style="full"
                            @select="isChoosingModel = true"
                        >
                            {{ $t('shared.replace') }}
                        </MenuListItem>

                        <MenuListItem
                            :class="['dropdown-menu-item', 'delete', { disabled: !allowDelete }]"
                            data-qa="delete-product"
                            :leading-icon="KeapIcon.TRASH_2"
                            :subtitle="actualLoadedModel.title"
                            @select="deleteModel"
                        >
                            {{ $t('shared.delete') }}
                        </MenuListItem>
                    </MenuList>
                </template>
            </DsDropdown>
            <slot name="trailing-end" />
        </div>
    </div>
</template>

<script lang="ts" setup>

import { PreparedModel, RemoteModelStatus } from '@/integration/datastore/base-types';
import {
    computed, getCurrentInstance, nextTick, ref, Ref, toRef, toRefs, watch,
} from 'vue';
import {
    CardDisplayOptions, HostSystem, KeapIcon, ModelSchema,
} from '@/generated/play-api';
import { useCore } from '@/shared/shared-providers';
import { schemaI18n } from '@/play-editor/mixins/v3/schema-i18n';
import { modelMixin } from '@/play-editor/mixins/v3/modelMixin';
import ModelEditPage from '@/play-editor/model/ModelEditPage.vue';
import debounce from 'lodash/debounce';
import { SEARCHING_DEBOUNCE_DELAY } from '@/play-editor/play.constants';
import RefDisplay from '@/play-editor/controls/RefDisplay.vue';
import asserts from '@/shared/asserts';
import { waitAsync } from '@/play-editor/model/TenantModelService';
import { useEventBus, useVModel } from '@vueuse/core';
import { modelEventKey } from '@/play-editor/model/model-bus-events';
import { KeyValues } from '@/types/core-types';
import { DEFAULT_EVENT_NAME, useVModel2 } from '@/play-editor/mixins/v3/v-model';

const props = withDefaults(defineProps<{
        modelValue?: string,
        loadedModel?: PreparedModel,
        /**
         * Whether this widget should take control of loading the full model, or whether the parent widget will
         * include it in the loadedModel property
         */
        deferLoading?: boolean;
        schema: ModelSchema;
        selected?: boolean;
        forceDropdown?: boolean;
        focusImmediately?: boolean;
        allowEdit?: boolean;
        placeholder?: string;
        without?: string|string[];
        cardOptions?: CardDisplayOptions;}>(), {
    focusImmediately: true,
    allowEdit: true,
});

const emit = defineEmits(['blur', 'delete', DEFAULT_EVENT_NAME, 'add', 'edit']);
const { t, log, appId } = useCore();

const model = useVModel2(props);

const isChoosingModel = ref(false);
const schema: Ref<ModelSchema> = toRef(props, 'schema');

asserts(props.schema?.modelType !== undefined, `Invalid type: ${props.schema}`);
const modelService = modelMixin(appId.value, schema.value.modelType, ModelEditPage);

const { refTypeObj } = modelService;

const search = ref('');

function updateSearch(value:string) {
    search.value = value;
}

const searchResults = ref([] as PreparedModel[]);
const loading = ref(false);

const { loadedModel, modelValue, without } = toRefs(props);

const instance = getCurrentInstance();

const schemaI18nHelper = computed(() => schemaI18n(instance.proxy, schema.value));

const refSearchField = ref(null);

/// Sometimes this widget takes care of loading/refreshing the model, and sometimes it's done by the parent
/// widget.  If the parent wants to own loading the model, they should pass deferLoading=true
const actualLoadedModel: Ref<PreparedModel> = (!loadedModel.value || !props.deferLoading) ? computed(() => {
    if (model.value) {
        if (!modelService.modelsById.value[model.value]) {
            modelService.loadModel(model.value);
        }

        return modelService.modelsById.value[model.value];
    }

    return null;
}) : loadedModel;

const allowDelete = computed(() => actualLoadedModel.value.hostSystem === HostSystem.GENERAL);

async function baseSearchRefs(searchTerm = ''): Promise<PreparedModel[]> {
    const result = await (searchTerm
        ? modelService.searchList(searchTerm)
        : waitAsync(modelService.modelListState));

    /// Update every record to ensure it has a title?
    for (const item of result) {
        item.title = item.title || t('defaultModelTitle').toString();
    }
    searchResults.value = result;

    return result;
}

function calculateTrailingIcon(option: KeyValues) {
    switch (option.remoteStatus) {
    case RemoteModelStatus.notSynced:
        return KeapIcon.REFRESH_CW;

    case RemoteModelStatus.synced:
        return KeapIcon.CHECK;

    default:
        return null;
    }
}

function hostSystemName(option: KeyValues) {
    switch (option.hostSystem) {
    case HostSystem.KEAP_WEB:
        return 'Keap';
    case HostSystem.GENERAL:
        return '';
    default:
        return null;
    }
}
const searchRefs = debounce(baseSearchRefs, SEARCHING_DEBOUNCE_DELAY, {
    leading: true,
    trailing: true,
});

useEventBus(modelEventKey).on((evt) => {
    if (evt.modelType.key === refTypeObj.key) {
        log.info('Refreshing search results from event', evt);

        searchRefs(search.value);
    }
});

watch(search, searchRefs, { deep: true, immediate: true });

if (props.focusImmediately) {
    watch(refSearchField, (newValue, oldValue) => {
        if (oldValue == null && newValue != null) {
            // Set a frame callback to activate?
            newValue.activate();
        }
    }, { deep: true, immediate: true });
}

const isEnum = computed(() => schema.value?.enumeration);
const filteredSearchResults = computed(() => {
    return searchResults.value.without(({ id }) => id !== without.value && !without.value?.includes(id));
});

const isModelSelected = computed(() => modelValue.value != null);
const isModelValid = computed(() => actualLoadedModel.value?.valid ?? false);

async function onBlur() {
    /// set a timeout. Blur is fired on selection, and processing
    /// this immediately caused the model update to be lost.
    await nextTick();
    isChoosingModel.value = false;
    emit('blur');
}

function deleteModel() {
    if (allowDelete.value) {
        emit('delete');
    }
}

function updateModel(v?: string | null | undefined, ignoreEmpty = false) {
    if (!v && ignoreEmpty) {
        // Don't update
        return;
    }

    model.value = v;
    isChoosingModel.value = false;
}

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "@/coach/coach";

    .ref-card-container {
        background-color: $card-background-color;
        flex-grow: 1;
        display: flex;
        margin-bottom: 0;
        align-items: center;

        --input-margin-bottom: 0;
        --input-height: 3.5rem;
    }

    .ref-card-edit {
        &.allow-edit {
            cursor: pointer;
        }

        width: 100%;
        min-height: 3.5rem;
        display: flex;
        align-items: center;
        padding: $spacing-100 $spacing-200;
        margin: 0;
    }

    .ref-card {
        padding: 0 $spacing-100 0 0;
        border: 1px solid $color-ink-600;
        border-radius: calc($border-radius / 1.5);
    }

    .model-title {
        font-size: $font-size-md;
    }

    .ref-actions,
    .ref-card {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .table-number {
        margin-right: $spacing-200;
        color: $color-ink-800;
    }

    .delete {
        --icon-color: #{$color-red};
    }

    .spinner {
        margin: $spacing-100;
    }

    .error {
        color: $color-red;
    }

    .selected-item {
        --chip-background-color: $color-white;
        --chip-text-color: $color-primary-dark;

        color: $color-white;
    }
</style>
<i18n>
{
    "en-us": {
    }
}
</i18n>
