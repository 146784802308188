import { inject, InjectionKey } from 'vue';
import { PlayProvider } from '@/play-editor/provider/vue3/play-provider-types';
import { fallbackPlayProvider } from '@/play-editor/provider/vue3/play-provider';

export const PlayProviderName = 'playProvider';
export const PlayProviderKey: InjectionKey<PlayProvider> = Symbol(PlayProviderName);

export function injectPlayStateProvider(allowDefault = false): PlayProvider {
    return inject(PlayProviderKey, allowDefault ? fallbackPlayProvider() : null);
}
