<template>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="markdown-container" v-html="helpTextMarkdown" />
</template>

<script>
import { marked } from 'marked';

export default {
    name: 'MarkdownDisplay',

    props: {
        markdown: String,
    },

    computed: {
        helpTextMarkdown() {
            return marked(this.markdown);
        },
    },
};
</script>
<style lang="scss" scoped>
    .markdown-container {
        p {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
</style>
