/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PlaybookCategory } from '../models/PlaybookCategory';
import type { TaggedPlaysResponse } from '../models/TaggedPlaysResponse';
import type { V3CreatePlayTag } from '../models/V3CreatePlayTag';
import type { V3CreatePlayTagCategory } from '../models/V3CreatePlayTagCategory';
import type { V3PlayTag } from '../models/V3PlayTag';
import type { V3PlayTagCategory } from '../models/V3PlayTagCategory';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PlayTagService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List All PlayTags
     * Gets all linked records for this Page
     * @param tenant
     * @returns TaggedPlaysResponse Success
     * @throws ApiError
     */
    public listAllPlayTags(
        tenant: string,
    ): CancelablePromise<TaggedPlaysResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/tenant/{tenant}/playTags',
            path: {
                'tenant': tenant,
            },
        });
    }

    /**
     * List User Categories
     * @param tenant
     * @returns V3PlayTagCategory listUserCategories 200 response
     * @throws ApiError
     */
    public listUserCategories(
        tenant: string,
    ): CancelablePromise<Array<V3PlayTagCategory>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/tenant/{tenant}/play-tag/categories',
            path: {
                'tenant': tenant,
            },
        });
    }

    /**
     * Add Category
     * Creates play category for a tenant
     * @param tenant to add the category to
     * @param requestBody The category info
     * @returns V3PlayTagCategory Success
     * @throws ApiError
     */
    public addCategory(
        tenant: string,
        requestBody: V3CreatePlayTagCategory,
    ): CancelablePromise<V3PlayTagCategory> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/tenant/{tenant}/play-tag/categories',
            path: {
                'tenant': tenant,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Category
     * Deletes play category for a tenant
     * @param tenant the tenant to remove the category for
     * @param categoryId The categoryIds to apply
     * @returns any Success
     * @throws ApiError
     */
    public deleteCategory(
        tenant: string,
        categoryId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v3/tenant/{tenant}/play-tag/categories/{categoryId}',
            path: {
                'tenant': tenant,
                'categoryId': categoryId,
            },
        });
    }

    /**
     * Apply PlayTags
     * Applies tags to a play
     * @param tenant
     * @param playId the ID of the play
     * @param requestBody The tagIds to apply
     * @returns any Success
     * @throws ApiError
     */
    public applyPlayTags(
        tenant: string,
        playId: string,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/tenant/{tenant}/play-tag/play/{playId}/play-tags',
            path: {
                'tenant': tenant,
                'playId': playId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Un Apply PlayTags
     * Removes tags from a play
     * @param tenant
     * @param playId the ID of the play
     * @param requestBody The tagIds to remove from a template
     * @returns any Success
     * @throws ApiError
     */
    public unApplyPlayTags(
        tenant: string,
        playId: string,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v3/tenant/{tenant}/play-tag/play/{playId}/play-tags',
            path: {
                'tenant': tenant,
                'playId': playId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Set PlaybookCategory
     * Replaces playbook category tag on a play
     * @param tenant
     * @param playId the ID of the play
     * @param playbookCategory The playbook category to set
     * @returns any Success
     * @throws ApiError
     */
    public setPlaybookCategory(
        tenant: string,
        playId: string,
        playbookCategory: PlaybookCategory,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v3/tenant/{tenant}/play-tag/play/{playId}/playbook-category/{playbookCategory}',
            path: {
                'tenant': tenant,
                'playId': playId,
                'playbookCategory': playbookCategory,
            },
        });
    }

    /**
     * Add PlayTag
     * Creates play tag for a tenant
     * @param tenant to add the tag to
     * @param requestBody The tag info
     * @returns V3PlayTag Success
     * @throws ApiError
     */
    public addPlayTag(
        tenant: string,
        requestBody: V3CreatePlayTag,
    ): CancelablePromise<V3PlayTag> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/tenant/{tenant}/play-tag/tags',
            path: {
                'tenant': tenant,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Template Tag
     * Deletes play tag for a tenant
     * @param tenant the tenant to remove the tag for
     * @param tagId The tagIds to apply
     * @returns any Success
     * @throws ApiError
     */
    public deleteTemplateTag(
        tenant: string,
        tagId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v3/tenant/{tenant}/play-tag/tags/{tagId}',
            path: {
                'tenant': tenant,
                'tagId': tagId,
            },
        });
    }

    /**
     * Apply Template Tags
     * Applies tags to a template
     * @param tenant
     * @param templateId the ID of the template
     * @param requestBody The tagIds to apply
     * @returns any Success
     * @throws ApiError
     */
    public applyTemplateTags(
        tenant: string,
        templateId: string,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/tenant/{tenant}/play-tag/template/{templateId}/template-tags',
            path: {
                'tenant': tenant,
                'templateId': templateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Un Apply Template Tags
     * Removes tags from a template
     * @param tenant
     * @param templateId the ID of the template
     * @param requestBody The tagIds to remove from a template
     * @returns any Success
     * @throws ApiError
     */
    public unApplyTemplateTags(
        tenant: string,
        templateId: string,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v3/tenant/{tenant}/play-tag/template/{templateId}/template-tags',
            path: {
                'tenant': tenant,
                'templateId': templateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
