import { RouteName } from '@/router/route-names';
import NewAccount from '@/shared/components/pages/NewAccount.vue';
import NotLoggedIn from '@/shared/components/pages/NotLoggedIn.vue';
import AccessDeniedPage from '@/shared/components/AccessDeniedPage.vue';
import { store } from '@/store';
import noop from 'lodash/noop';
import { env } from '@/env';
import NotLoggedInEmbed from '@/shared/components/pages/NotLoggedInEmbed.vue';

import ErrorPage from '@/shared/components/pages/ErrorPage.vue';
import { RouteRecordRaw } from 'vue-router';
import NestedRouter from '@/router/NestedRouter.vue';

export const authRoutes: RouteRecordRaw[] = [
    { path: '/new', component: NewAccount },
    {
        path: '/loggedout',
        name: RouteName.loggedOut,
        meta: {
            title: 'routes.loggedOut',
            isPublic: true,
        },
        component: NotLoggedIn,
    },
    {
        path: '/error',
        name: RouteName.error,
        meta: {
            title: 'routes.error',
            isPublic: true,
        },
        component: ErrorPage,
    },
    {
        path: '/loggedoutEmbed',
        name: RouteName.loggedOutEmbed,
        meta: {
            isPublic: true,
        },
        component: NotLoggedInEmbed,
    },
    {
        path: '/access-denied',
        name: RouteName.accessDenied,
        component: AccessDeniedPage,
        meta: {
            title: 'routes.accessDenied',
            isPublic: true,
        },
    },
    {
        path: '/logout',
        name: RouteName.logout,
        meta: {
            title: 'routes.logout',
            isPublic: true,
        },
        component: NestedRouter,
        beforeEnter: (to, from) => {
            // noinspection JSIgnoredPromiseFromCall
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            store.dispatch('auth/LOGOUT').then(noop);

            if (to.query?.renew) {
                const redirectUrl = new URL(from.fullPath || '/', window.location.href);

                redirectUrl.searchParams.delete('ticket'); // ensure we don't send the JWT back in a loop

                return window.location.assign(`${env.VUE_APP_CAS_URL}/login?service=${encodeURIComponent(redirectUrl.toString())}&renew=true`);
            }

            return window.location.assign(`${env.VUE_APP_CAS_URL}/logout?service=${encodeURIComponent(window.location.origin)}`);
        },
    },

];
