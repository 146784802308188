<script lang="ts">export default { name: 'PropGroupListTile' }; </script>
<template>
    <ListItem2
        :truncate-text="null"
        class="prop-group-list-item"
        :title="group.title"
        :description="group.description"
    >
        <template #leadingSlot>
            <div class="icon-box">
                <Icon name="form" :show-badge="invalid" />
            </div>
        </template>
        <template #trailingSlot>
            <div class="icon-box">
                <OutlineButton dense trailing-icon="chevron-right" @click="emit('select')">
                    <span class="select-button">{{ $t('setUp') }}</span>
                </OutlineButton>
            </div>
        </template>
    </ListItem2>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { ModelPropertyGroup } from '@/generated/play-api';
import ListItem2 from '@/shared/components/ListItem2.vue';

const emit = defineEmits(['select']);
/**
 * Renders a ListTile that represents a page of properties.  Used on the PlaySummary pages to show the
 * extra pages.
 */
const props = defineProps<{
    group: ModelPropertyGroup;
    invalid?:boolean;
}>();

const group = computed(() => props.group as ModelPropertyGroup);
</script>

<style lang="scss" scoped>
    .select-button {
        white-space: nowrap;
    }

    .icon-box {
        padding: 0;
    }
</style>
<i18n>
{
    "en-us": {
        "setUp": "Set up"
    }
}
</i18n>
