/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePlaybookGoal } from '../models/CreatePlaybookGoal';
import type { GoalStatus } from '../models/GoalStatus';
import type { GoalStatuses } from '../models/GoalStatuses';
import type { UpdatePlaybookGoal } from '../models/UpdatePlaybookGoal';
import type { UpdateStatusResponse } from '../models/UpdateStatusResponse';
import type { V1PlaybookGoal } from '../models/V1PlaybookGoal';
import type { V1PlaybookGoals } from '../models/V1PlaybookGoals';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GoalService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Goals
     * @param tenant
     * @returns V1PlaybookGoals listGoals 200 response
     * @throws ApiError
     */
    public listGoals(
        tenant: string,
    ): CancelablePromise<V1PlaybookGoals> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/tenant/{tenant}/goals',
            path: {
                'tenant': tenant,
            },
        });
    }

    /**
     * Create Goal
     * @param tenant
     * @param requestBody
     * @returns V1PlaybookGoal createGoal 200 response
     * @throws ApiError
     */
    public createGoal(
        tenant: string,
        requestBody: CreatePlaybookGoal,
    ): CancelablePromise<V1PlaybookGoal> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/tenant/{tenant}/goals',
            path: {
                'tenant': tenant,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update Goal
     * @param tenant
     * @param goalId
     * @param requestBody
     * @returns V1PlaybookGoal updateGoal 200 response
     * @throws ApiError
     */
    public updateGoal(
        tenant: string,
        goalId: string,
        requestBody: UpdatePlaybookGoal,
    ): CancelablePromise<V1PlaybookGoal> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/tenant/{tenant}/goals/{goalId}',
            path: {
                'tenant': tenant,
                'goalId': goalId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Goal
     * @param tenant
     * @param goalId
     * @returns boolean deleteGoal 200 response
     * @throws ApiError
     */
    public deleteGoal(
        tenant: string,
        goalId: string,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/tenant/{tenant}/goals/{goalId}',
            path: {
                'tenant': tenant,
                'goalId': goalId,
            },
        });
    }

    /**
     * Get Status Updates
     * @param tenant
     * @param goalId
     * @returns GoalStatuses getStatusUpdates 200 response
     * @throws ApiError
     */
    public getStatusUpdates(
        tenant: string,
        goalId: string,
    ): CancelablePromise<GoalStatuses> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/tenant/{tenant}/goals/{goalId}/statusUpdates',
            path: {
                'tenant': tenant,
                'goalId': goalId,
            },
        });
    }

    /**
     * Add Status Update
     * @param tenant
     * @param goalId
     * @param requestBody
     * @returns UpdateStatusResponse addStatusUpdate 200 response
     * @throws ApiError
     */
    public addStatusUpdate(
        tenant: string,
        goalId: string,
        requestBody: GoalStatus,
    ): CancelablePromise<UpdateStatusResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/tenant/{tenant}/goals/{goalId}/statusUpdates',
            path: {
                'tenant': tenant,
                'goalId': goalId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Status Update
     * @param tenant
     * @param goalId
     * @param statusId
     * @returns boolean deleteStatusUpdate 200 response
     * @throws ApiError
     */
    public deleteStatusUpdate(
        tenant: string,
        goalId: string,
        statusId: string,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/tenant/{tenant}/goals/{goalId}/statusUpdates/{statusId}',
            path: {
                'tenant': tenant,
                'goalId': goalId,
                'statusId': statusId,
            },
        });
    }

}
