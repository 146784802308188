import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import { configureLogging } from '@/shared/logging';
import { ConfigState, PlayConfig, stateLog } from './types';
import { pruneDeep } from '@/shared/type.utils';

const log = stateLog('config', 'mutations');

export default {
    MERGE_CONFIG_OVERRIDE(state:ConfigState, config:PlayConfig) {
        const { localConfig } = state;

        log.info('Setting local override', cloneDeep({ localConfig, newConfig: config }));
        state.localConfig = merge(state.localConfig, config);
        state.values = merge(cloneDeep(state.staticConfig), state.localConfig) as PlayConfig;

        // prune null values

        configureLogging(state.values.logging);
        pruneDeep(state.values);

        log.info('  Result', cloneDeep(state.values));
    },

    CLEAR_LOCAL_CONFIG(state:ConfigState) {
        state.localConfig = {};
        state.values = merge(cloneDeep(state.staticConfig), state.localConfig) as PlayConfig;

        configureLogging(state.values.logging);
        pruneDeep(state.values);
    },

    MERGE_CONFIG(state:ConfigState) {
        state.values = merge(cloneDeep(state.staticConfig), state.localConfig) as PlayConfig;
        pruneDeep(state.values);

        configureLogging(state.values.logging);
        pruneDeep(state.values);
    },
};
