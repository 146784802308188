import NestedRouter from '@/router/NestedRouter.vue';
import { RouteName } from '@/router/route-names';
import { RouteRecordRaw } from 'vue-router';

export const copyGeneratorRoutes: RouteRecordRaw[] = [
    {
        path: ':coachId?/:appId/plays',
        meta: { title: 'pages.copy-generator', public: false },
        component: NestedRouter,
        children: [
            {
                path: '',
                meta: { title: 'routes.copyGenerator' },
                component: () => import('@/play-editor/PlayListPage.vue'),
                name: RouteName.plays,
                props: true,
            },
            {
                path: ':focusedPlayId',
                meta: { title: 'routes.copyGenerator' },
                component: () => import('@/play-editor/play/PlayPage.vue'),
                props: true,
                name: RouteName.play,
            },
        ],
    },
    {
        path: ':appId/playbook',
        meta: { title: 'pages.playbook' },
        component: NestedRouter,
        children: [
            {
                path: '',
                meta: { title: 'routes.playbook' },
                component: () => import('@/playbook/MyPlaybook.vue'),
                name: RouteName.playbook,
                props: (route) => ({
                    appId: route.params.appId,
                }),
            },
        ],
    },
];
