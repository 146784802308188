import asserts from '@/shared/asserts';
import { playClientTs } from '@/client/play-client';
import { logger } from '@/shared/logging';
import { CUSTOM_ELEMENT_DEFINITIONS, CustomElementDefinitions } from '@/shared/custom-elements';
import { KeyValues } from '@/types/core-types';

let hasLoadedServerDefinitions = false;
let customElementDefinitions:CustomElementDefinitions = {};

export function getCustomElementDefinitions() {
    asserts(hasLoadedServerDefinitions, 'Has loaded server definitions');

    return customElementDefinitions;
}

const log = logger('element');

export async function loadServerElementDefinitions() {
    if (hasLoadedServerDefinitions) {
        return;
    }
    const { tags } = await playClientTs.elementMetadata.getCustomElementMeta();
    const fromServer = tags.mapValues((tag: KeyValues) => {
        log.info('K, tag', { tag });

        return {
            attributes: tag.attributes ?? [],
            inline: tag.inline ?? false,
        };
    });

    customElementDefinitions = {
        ...fromServer,
        ...CUSTOM_ELEMENT_DEFINITIONS,
    };
    hasLoadedServerDefinitions = true;
}
