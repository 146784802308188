/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormProvider, MetaMutation, MetaMutationFunction } from '@/model/form/form-provider-types';
import { ModelProperty } from '@/client/play-client';
import { KeyValues } from '@/types/core-types';
import asserts from '@/shared/asserts';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';

export function setupFormProviderMetaData(self: FormProvider) {
    self.getPropMeta = (propOrName: ModelProperty | string): KeyValues => {
        const { state } = self;

        asserts(propOrName, 'Must provide prop or name');
        const prop: string = typeof propOrName === 'string' ? propOrName : (propOrName).name;

        if (state.meta[prop] == null) {
            state.meta[prop] = {};
        }

        return state.meta[prop];
    };

    self.mutateMeta = <T>(prop: ModelProperty, key: string, mutation: MetaMutation<T>, defaultValue?: any) => {
        const propMeta = self.getPropMeta(prop);

        const mutationFunction: MetaMutationFunction<T> = typeof mutation === 'function' ? mutation as MetaMutationFunction<T> : (keyMeta: T) => {
            const merged = merge(cloneDeep(keyMeta), mutation);

            propMeta[key] = merged;
        };

        if (propMeta[key] == null && defaultValue != null) {
            propMeta[key] = defaultValue;
        }

        const keyMeta = propMeta[key];

        const result = mutationFunction(keyMeta, propMeta);

        if (result !== undefined) {
            propMeta[key] = result;
        }

        return keyMeta;
    };
}
