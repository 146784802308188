/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContentMeta } from '../models/ContentMeta';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ElementMetadataService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Custom Element Meta
     * Retrieves information for custom kopy-* tags that are used in editable content
     * @returns ContentMeta getCustomElementMeta 200 response
     * @throws ApiError
     */
    public getCustomElementMeta(): CancelablePromise<ContentMeta> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/elements/meta',
        });
    }

}
