<template>
    <div class="results-container">
        <TransitionGroup tag="ul" class="suggestions-menu dropdown-menu-list" name="list">
            <li key="label" class="dropdown-menu-group suggestion-label">
                <Icon :name="KeapIcon.SEARCH" />
                <div class="label">
                    {{ $t('suggestions') }}
                </div>

                <Tooltip v-if="!generating" position="bottom">
                    <template #reference>
                        <div>
                            <IconButton
                                :tabindex="-1"
                                :data-qa="`suggestion-refresh-${question.name}`"

                                size="sm"
                                :class="{ 'refresh-menu': true }"
                                name="refresh-cw"
                                @click.stop="suggestionProvider.generateSuggestions(true, 'User pressed refresh button')"
                            />
                        </div>
                    </template>
                    <template #default>
                        <div class="tooltip-text">
                            {{ $t('generateMore') }}
                        </div>
                    </template>
                </Tooltip>
                <div v-else class="suggest-spinner">
                    <Spinner :size="24" :thickness="2.5" />
                </div>
            </li>
            <li v-if="visibleSuggestions.length === 0" key="message" class="results-message no-animation">
                {{ $t(generating ? numAttempts > 0 ? 'slowResults' : 'generatingResults' : "noResults") }}
            </li>
            <li
                v-for="({ item, resultId }, i) in visibleSuggestions"
                :key="item || i"
                :data-qa="`suggestion-item-${question.name}`"
                class="dropdown-menu-item suggestion-li"
                @click="acceptSuggestion({ item, resultId })"
            >
                <div class="suggestion-li-item">
                    <div class="suggestion-li-label">
                        {{ item }}
                    </div>
                    <div class="suggestion-li-delete">
                        <Tooltip position="bottom">
                            <template #reference>
                                <IconButton
                                    :name="KeapIcon.X_CIRCLE"
                                    :tabindex="-1"
                                    :data-qa="`suggestion-reject-${question.name}`"
                                    @click.stop="rejectSuggestion({ item, resultId })"
                                />
                            </template>
                            <template #default>
                                <div class="tooltip-text">
                                    {{ $t('removeSuggestion') }}
                                </div>
                            </template>
                        </Tooltip>
                    </div>
                </div>
            </li>
            <li v-if="error" class="dropdown-menu-item error">
                {{ $t('errorGenerating') }}
            </li>
        </TransitionGroup>
    </div>
</template>
<script lang="ts">export default { name: 'SuggestionsResults' }; </script>
<script lang="ts" setup>

import { PlayEventTypes } from './play.constants';
import { amplitude } from '@/analytics/amplitude';
import { ModelProperty } from '@/generated/play-api';
import {
    computed,
    Ref, toRef, toRefs, watch,
} from 'vue';
import { injectSuggestionsProvider } from '@/play-editor/mixins/questionMixin';
import { useLog } from '@/shared/shared-providers';
import cloneDeep from 'lodash/cloneDeep';
import { SuggestionItem } from '@/model/form/SuggestionItem';

const props = defineProps<{
    question?: ModelProperty;
    allowMultiple?: boolean;
}>();
const emit = defineEmits(['close-suggestion']);

const { question: questionRef } = toRefs(props);
const {
    acceptSuggestion: accept, rejectSuggestion: reject, data, ...suggestionProvider
} = injectSuggestionsProvider();

const log = useLog();
const {
    missingFields, numAttempts, replacing, expectedResultCount, minimumResults, visibleSuggestions,
    errors, generating, error, generatesSuggestions, suggestions,
} = toRefs(data);

watch(visibleSuggestions, (newValue) => {
    log.debug('New suggestions', newValue);
}, { immediate: true, deep: true });

watch(() => cloneDeep(data.suggestions), (newValue) => {
    log.debug('New all suggestions', newValue);
}, { immediate: true, deep: true });

const missingFieldsLength = computed(() => missingFields.value.max());

// eslint-disable-next-line vue/no-dupe-keys
const question = toRef(props, 'question') as Ref<ModelProperty>;

function acceptSuggestion(suggestion: SuggestionItem):void {
    const question = questionRef.value as ModelProperty;

    accept(suggestion);
    amplitude.logEvent(PlayEventTypes.PLAY_SUGGESTION_ACCEPTED, {
        'Question Name': question.name,
        'Question Label': question.label,
        'Question Description': question.description,
    });

    if (!props.allowMultiple) {
        emit('close-suggestion');
    }
}

function rejectSuggestion(suggestion: SuggestionItem):void {
    const question = questionRef.value as ModelProperty;

    reject(suggestion);
    amplitude.logEvent(PlayEventTypes.PLAY_SUGGESTION_REJECTED, {
        'Question Name': question.name,
        'Question Label': question.label,
        'Question Description': question.description,
    });
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@/styles/main";
    @import "~@/shared/transitions/transitions";
    @import "../coach/coach";

    $suggestion-width: 22rem;

    .dropdown-menu-list.suggestions-menu {
        padding: 0 0 $spacing-100;
    }

    .results-container {
        width: $suggestion-width;
        margin: auto;
        align-content: center;
        text-align: center;
    }

    .dropdown-menu-list {
        @include dropdown-list;
    }

    .dropdown-menu-item {
        @include dropdown-list-item;

        padding-top: calc(1rem / 4);
        padding-bottom: calc(1rem / 4);
    }

    .error {
        color: $color-red;
    }

    .suggestion-label {
        @include divider-bottom;

        width: 100%;
        line-height: calc(40rem / 16);
        display: flex;
        flex-direction: row;
        align-items: center;

        > .label {
            color: $color-ink-900;
            padding-left: $spacing-100;
            font-size: $font-size-md;
            font-weight: $font-weight-semibold;
            text-align: left;
            flex: 3;
        }
    }

    .refresh-menu {
        --icon-color: #{$color-blue-600};
        --icon-size: 1.5rem;
    }

    .suggest-spinner {
        margin: auto;
        height: calc(40rem / 16);
        line-height: calc(40rem / 16);
        padding: calc(1rem / 2);
        display: inline-block;
        vertical-align: middle;
    }

    .validation-error.warning {
        color: $color-orange-600;
    }

    .dropdown-menu-group {
        color: $color-text-subtle;
        font-size: $font-size-xs;
        padding: 0 1rem;
    }

    .dropdown-menu-group.suggestion-label {
        min-height: calc(1rem * 3);
    }

    .input-trailing-suggest {
        color: $color-orange;
    }

    .input-trailing-suggest.dense {
        padding: calc(1rem / 4);
        margin: auto;
    }

    .no-suggestions {
        color: $color-text-disabled;
    }

    .suggestions-header {
        margin-top: 1rem;
        margin-bottom: calc(1rem / 2);
    }

    .field-error {
        color: $color-red;
        margin-top: calc(1rem / 2);
    }

    .play-question-list {
        margin-bottom: 1rem;
    }

    .input-form {
        margin-top: 1rem;
    }

    .inline-input {
        margin-bottom: calc(1rem / 4);
        padding-bottom: calc(1rem / 4);
    }

    .suggestion-popover {
        cursor: pointer;

        --popover-width: $suggestion-width;
        --popover-min-width: $suggestion-width;
    }

    .suggestion-li-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 $spacing-150;
    }

    .results-message {
        padding: $spacing-200;
    }

    .suggestion-li-label {
        flex-grow: 1;
        text-align: left;
        margin: $spacing-150 0;
    }

    .suggestion-li-delete {
        --icon-color: #{$color-gray-600};

        flex-basis: calc(60rem / 16);
        text-align: right;
    }

    .suggestion-li {
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 0;
    }

</style>
<i18n>
{
    "en-us": {
        "generatingResults": "Generating suggestions...",
        "noResults": "We couldn't generate any suggestions.  Please try again later",
        "suggestions": "Suggestions",
        "noSuggestions": "Suggestions appear here",
        "suggestionsAvailable": "Suggestions available. Click to see",
        "generateMore": "Generate more suggestions",
        "errorGenerating": "There was a problem generating suggestions",
        "removeSuggestion": "Remove this suggestion",
        "slowResults": "Hang in there, we're almost done"
    }
}
</i18n>
