/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FeatureFlag } from '../models/FeatureFlag';
import type { FeatureFlags } from '../models/FeatureFlags';
import type { PlayAccount } from '../models/PlayAccount';
import type { UpdateFlagResponse } from '../models/UpdateFlagResponse';
import type { UpdatePlayAccountRequest } from '../models/UpdatePlayAccountRequest';
import type { UserSummary } from '../models/UserSummary';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get User Info
     * @param search
     * @param cursor
     * @returns UserSummary getUserInfo 200 response
     * @throws ApiError
     */
    public getUserInfo(
        search?: string | null,
        cursor?: string | null,
    ): CancelablePromise<UserSummary> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/user',
            query: {
                'search': search,
                'cursor': cursor,
            },
        });
    }

    /**
     * Add FeatureFlag
     * @param tenantId
     * @param force
     * @param waitForResponse
     * @param requestBody
     * @returns UpdateFlagResponse addFeatureFlag 200 response
     * @throws ApiError
     */
    public addFeatureFlag(
        tenantId: string,
        force?: boolean | null,
        waitForResponse?: boolean | null,
        requestBody?: FeatureFlags,
    ): CancelablePromise<Array<UpdateFlagResponse>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/user/{tenantId}/featureFlag',
            path: {
                'tenantId': tenantId,
            },
            query: {
                'force': force,
                'waitForResponse': waitForResponse,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Remove FeatureFlag
     * @param tenantId
     * @param waitForResponse
     * @param requestBody
     * @returns UpdateFlagResponse removeFeatureFlag 200 response
     * @throws ApiError
     */
    public removeFeatureFlag(
        tenantId: string,
        waitForResponse?: boolean | null,
        requestBody?: FeatureFlags,
    ): CancelablePromise<Array<UpdateFlagResponse>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/user/{tenantId}/featureFlag',
            path: {
                'tenantId': tenantId,
            },
            query: {
                'waitForResponse': waitForResponse,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get FeatureFlag Status
     * @param tenantId
     * @param flag
     * @returns boolean getFeatureFlagStatus 200 response
     * @throws ApiError
     */
    public getFeatureFlagStatus(
        tenantId: string,
        flag: FeatureFlag,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/user/{tenantId}/featureFlag/{flag}',
            path: {
                'tenantId': tenantId,
                'flag': flag,
            },
        });
    }

    /**
     * Get PlayAccount
     * @param tenantId
     * @returns PlayAccount getPlayAccount 200 response
     * @throws ApiError
     */
    public getPlayAccount(
        tenantId: string,
    ): CancelablePromise<PlayAccount> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/user/{tenantId}/playAccount',
            path: {
                'tenantId': tenantId,
            },
        });
    }

    /**
     * Update PlayAccount
     * @param tenantId
     * @param requestBody
     * @returns PlayAccount updatePlayAccount 200 response
     * @throws ApiError
     */
    public updatePlayAccount(
        tenantId: string,
        requestBody: UpdatePlayAccountRequest,
    ): CancelablePromise<PlayAccount> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/user/{tenantId}/playAccount',
            path: {
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
