/* eslint-disable @typescript-eslint/no-explicit-any */
import mergeWith from 'lodash/mergeWith';
import { KeyValues } from '@/types/core-types';
import isObject from 'lodash/isObject';

export type TimerHandle = any;

export type Supplier<T> = (()=>T);

export function supply<T>(supplier?: T | Supplier<T>):T {
    return typeof supplier === 'function' ? (<Supplier<T>>supplier)() : supplier;
}

export function ignore(promise:any):void {
    if (promise?.then) {
        void promise?.then(() => {
            // nothing
        });
    }
}

export function typeNameOf(obj:any):string {
    if (obj == null) {
        return 'null';
    }

    if (obj.constructor) {
        return obj.constructor.name;
    }

    return typeof obj;
}

export function toArray(input:any, { ...rest } = {}):any[] {
    return execByParameterType(input, {
        array: (e) => e,
        fallback: (e) => (e ? [e] : []),
        ...rest,
    });
}

export function fromArray<T>(input:T|T[]):T {
    if (input == null) return input as T;

    return (Array.isArray(input) ? input[0] : input);
}

function dontMergeArrays(objValue:any, srcValue:any):any {
    if (Array.isArray(objValue) || Array.isArray(srcValue)) {
        return srcValue;
    }

    return undefined;
}

export function mergePlayData(obj:any, source:any):any {
    return mergeWith(obj, source, dontMergeArrays);
}

/**
 * Executes a function based on the type of parameter.

 */
export function execByParameterType<O>(obj:any, {
    array, object, string, number, literal, fallback, ...rest
}:FnExecArgs<O>): O {
    return parameterTypeExecutor({
        array, object, string, number, literal, fallback, ...rest,
    })(obj);
}

export function pruneDeep(source:KeyValues): KeyValues {
    for (const [key, value] of Object.entries(source)) {
        if (Array.isArray(value) ? Boolean(value?.length < 1) : !value) {
            delete source[key];
        } else if (isObject(value)) {
            pruneDeep(value);
        }
    }

    return source;
}

/**
 * Merges default values into an object - will not overwrite any non-empty values, and will process recursively.
 */
// export function mergeDefaults(source:KeyValues, defaultValues: KeyValues): KeyValues {
//     return mergeWith({}, source, defaultValues, (a, b) => {
//         if (isObject(a) && isObject(b)) {
//             return mergeDefaults(a, b);
//         }
//
//         return isEmptyValue(a) ? b : a;
//     });
// }

/**
 * Merges default values into an object - will not overwrite any non-empty values, and will process recursively.
 */
export function mergeDefaults<T extends object>(source: Partial<T>, defaultValues: Partial<T>): T {
    return mergeWith({}, source, defaultValues, (a, b) => {
        if (isObject(a) && isObject(b)) {
            return mergeDefaults(a, b);
        }

        return isEmptyValue(a) ? b : a;
    }) as T;
}

export function mergeDefaultsAny(source: KeyValues, defaultValues: KeyValues): KeyValues {
    return mergeDefaults(source, defaultValues);
}

function isEmptyValue(value:unknown) {
    if (value == null) return true;

    if (Array.isArray(value) && value.length === 0) {
        return true;
    }

    if (typeof value === 'string') {
        return !value;
    }

    return false;
}

export type FnExec<I, O> = (input:I, typeName?:string)=>O;
export type FnExecArgs<O> = {
    array?:FnExec<any[], O>,
    object?:FnExec<KeyValues, O>,
    string?:FnExec<string, O>,
    number?:FnExec<number, O>,
    literal?:FnExec<number|string|boolean, O>,
    fallback?:FnExec<any, O>,
}&Record<string, FnExec<any, O>>;

export function parameterTypeExecutor<O>({
    array, object, string, number, literal, fallback, ...rest
}:FnExecArgs<O>): FnExec<any, O> {
    fallback ??= (input:any) => input as O;
    literal ??= fallback;
    number ??= literal;
    string ??= literal;
    object ??= fallback;
    array ??= fallback;

    const restLower = Object.entries(rest).reduce((prev, [key, value]) => {
        prev[key.toLowerCase()] = value;

        return prev;
    }, <Record<string, FnExec<any, O>>>{});

    const withDefaults = <Record<string, FnExec<any, O>>>{
        literal,
        number,
        string,
        object,
        array,
        ...restLower,
    };

    return (obj:any) => {
        const typeName = typeNameOf(obj).toLowerCase();
        const fn:FnExec<any, O> = withDefaults[typeName] ?? fallback;

        return fn(obj, typeName);
    };
}
