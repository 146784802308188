import { isEmbedded } from '@/play-editor/frame';

import { isAuthDisabled } from '@/store/auth/auth-utils';
import { env } from '@/env';
import { RouteName } from '@/router/route-names';
import { logger } from '@/shared/logging';

export function sendAuthRedirect(router, location) {
    if (!router.currentRoute.value.meta?.isPublic) {
        if (isEmbedded()) {
            return router.replace({ name: RouteName.loggedOutEmbed });
        }

        return router.push(location);
    }

    return null;
}

const log = logger('router.intercept');

export const beforeResolveRouteAuth = async (to, _, next, store, i18n, router) => {
    try {
        const inboundTicket = to.query.ticket || to.query.userToken;
        const embedded = isEmbedded();

        if (inboundTicket) {
            // Remove ticket and redirect parameters from request uri.
            const {
                query: {
                    ticket, userToken, redirect, ...query
                }, ...nextRoute
            } = to;
            let jwt;

            if (Array.isArray(inboundTicket)) { // in case of multiple, take the last one
                jwt = inboundTicket[inboundTicket.length - 1];
            } else {
                jwt = inboundTicket;
            }

            await store.dispatch('auth/LOGIN', jwt);
            nextRoute.query = query;
            nextRoute.replace = true;

            if (nextRoute.name === 'logout') {
                nextRoute.name = 'home';
            }

            if (store.state.auth.failed && !embedded) {
                // just let the oops page be shown
                return next();
            }

            return next(nextRoute);
        }

        await store.dispatch('auth/VERIFY_LOGIN');

        const isLoggedIn = store.getters['auth/isLoggedIn'];

        const authDisabled = isAuthDisabled();

        if (to.meta.isPublic || authDisabled) {
            return next();
        }

        if (!isLoggedIn && !embedded) {
            if (store.state.auth.hasEverLoggedIn || to.query?.forceLogout === 'yes') {
                window.location.assign(`${env.VUE_APP_CAS_URL}/login?service=${encodeURIComponent(window.location.href)}`);
            } else {
                await sendAuthRedirect(router, '/loggedout');
            }

            return next(false);
        }

        if (!isLoggedIn && embedded) {
            return next({ name: RouteName.loggedOutEmbed });
        }

        return next();
    } catch (e) {
        log.severe('Error in auth handler', e);

        return next({ name: 'error' });
    }
};
