<script lang="ts">export default { name: 'ModelPropertiesEditor' }; </script>
<template>
    <ModalScaffold
        :title="modelTitle"
        show-close
        is-open
        emit-close
        show-back
        @close="saveAndExit"
    >
        <div class="edit-modal-inner">
            <div class="edit-modal-body">
                <div :class="{ 'questions-outer': true }">
                    <div>
                        <div :key="`page-${propertyPageIndex}`" class="questions-layout">
                            <form action="#" @submit.prevent="submitPage">
                                <div class="questions-panel">
                                    <div class="question-title">
                                        <h3 :data-qa="`question-page-${mainTitle}`">
                                            {{ mainTitle }}
                                        </h3>

                                        <p v-if="mainDescription">
                                            {{ mainDescription }}
                                        </p>
                                    </div>
                                    <div class="questions">
                                        <PropertyProvider
                                            v-for="(property, idx) in propertyGroup.properties"
                                            :key="property.name"
                                            v-slot="{ propForm }"
                                            :index="idx"
                                            :prop="property"
                                            :as="inlineMode(propertyGroup) ? 'span' : 'div'"
                                            class="question"
                                        >
                                            <div v-if="!inlineMode(propertyGroup)">
                                                <div v-if="propForm.generatingDefaultValue">
                                                    <Spinner />
                                                </div>
                                                <PropertyFormField
                                                    v-else
                                                    :prop-form="propForm"
                                                    @submit="submitPage"
                                                />
                                            </div>
                                        </PropertyProvider>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div :class="{ 'question-button-row': true, 'show-progress': pageCount > 1 }">
                            <div class="question-progress-container">
                                <div v-if="pageCount > 1" class="question-progress">
                                    <ProgressBar
                                        :progress="propertyPageIndex + 1"
                                        :max="pageCount"
                                        class="question-progress"
                                    />
                                    <div class="progress-wrapper">
                                        {{ t('step', {current: propertyPageIndex + 1, stepCount: pageCount}) }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="pageModel.hasRefs" class="questions-actions">
                                <div class="next">
                                    <NextControl :invalid-items="invalidPropertyNames">
                                        <DsButton
                                            dense
                                            :loading="closing"
                                            :disabled="!isPageValid"
                                            :leading-icon="leadingIconDone"
                                            data-qa="question-done-button"
                                            @click="saveAndExit"
                                        >
                                            {{ t('doneRefs') }}
                                        </DsButton>
                                    </NextControl>
                                </div>
                            </div>
                            <div v-else class="questions-actions">
                                <div v-if="propertyPageIndex > 0" class="previous">
                                    <TextButton dense :leading-icon="KeapIcon.ARROW_LEFT" @click="goPreviousPage()">
                                        {{ t('previous') }}
                                    </TextButton>
                                </div>
                                <div :key="`page-${propertyPageIndex}`" class="next">
                                    <NextControl v-if="!isLastPage" :invalid-items="invalidPropertyNames">
                                        <DsButton
                                            dense
                                            :disabled="saving"
                                            :trailing-icon="KeapIcon.ARROW_RIGHT"
                                            :leading-icon="leadingIcon"
                                            data-qa="question-next-button"
                                            @click="advancePage(); $event.target.blur();"
                                        >
                                            {{ t('next') }}
                                        </DsButton>
                                    </NextControl>
                                    <NextControl v-else :title="invalidDoneTitle" :invalid-items="invalidDone">
                                        <DsButton
                                            dense
                                            :disabled="saving"
                                            :loading="closing"
                                            :leading-icon="leadingIcon"
                                            data-qa="question-done-button"
                                            @click="saveAndExit"
                                        >
                                            {{ t('done') }}
                                        </DsButton>
                                    </NextControl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ModalScaffold>
</template>

<script lang="ts" setup>
/* eslint-disable no-return-assign */

import {
    GroupPageMode, KeapIcon, ModelPropertyGroup, ModelSchema,
} from '@/generated/play-api';
import {
    computed, onBeforeMount, toRefs, watch,
} from 'vue';
import { provideModelRefForm } from '@/model/form/provide-form';
import { notFalsy, PlayLifecycleEvent, TransitionType } from '@/play-editor/play.constants';
import { KeyValues } from '@/types/core-types';
import { asyncBus } from '@/events';
import asserts from '@/shared/asserts';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { injectPlayStateProvider } from '../provider/vue3/ProviderKeys';
import { useCore } from '@/shared/shared-providers';
import PropertyProvider from '@/model/form/PropertyProvider.vue';
import PropertyFormField from '@/model/form/PropertyFormField.vue';
import { PreparedModel } from '@/integration/datastore/base-types';
import { ModelKeys } from '@/integration/datastore/model-keys';
import ModalScaffold from '@/shared/components/ModalScaffold.vue';
import { modelMixin } from '@/play-editor/mixins/v3/modelMixin';
import { ignore } from '@/shared/type.utils';
import ModelEditPage from '@/play-editor/model/ModelEditPage.vue';
import NextControl from '@/play-editor/model/NextControl.vue';
import { toNumber } from '@/shared/shared.utils';
import { asyncDebounce } from '@/shared/utils/async-debounce';
import { verifyModelRawData } from '@/play-editor/model/model.utils';

export type ModelEditState = {
    modelId?: string;
    active?: true;
    dirty?: boolean;
    model?: PreparedModel;
    propMeta?: KeyValues;
}

/**
 * This component is the main guts of editing RefModel instances.
 */

const props = defineProps<{
    schema: ModelSchema;
    modelId?: string;
    initialAnswers: PreparedModel;
    pageTitle?: string;
    pageDescription?: string;
}>();

const emit = defineEmits(['close', 'save']);
const { t, log, appId } = useCore();

// eslint-disable-next-line vue/no-setup-props-destructure
const { schema, initialAnswers, modelId: startModelId } = props;

const refType = ModelKeys.of(schema.modelType);

asserts(refType, 'Got a valid refType!');

const modelTypeService = modelMixin(appId.value, refType, ModelEditPage);

const closing = ref(false);
const transitionName = ref(TransitionType.next);
const saving = ref(false);
const propertyPageIndex = ref(0);

const editing = reactive({
    active: true,
    model: cloneDeep(initialAnswers),
    modelId: startModelId,
    dirty: false,
} as ModelEditState);

async function persistModel(event: 'save' | 'close' = 'save') {
    try {
        saving.value = true;

        if (event === 'close') {
            closing.value = true;
        }

        const { model, modelId } = editing;
        const savedRecord = cloneDeep(await modelTypeService.saveModel(modelId, model));

        editing.model = savedRecord;
        editing.modelId = savedRecord.id;
        editing.dirty = false;

        // Send the update to the parent page
        emit(event, editing.model);
    } catch (e) {
        emit(event, Error(`Error saving model: ${e}`));
    } finally {
        saving.value = false;
    }
}

const debouncePersist = asyncDebounce(persistModel, 3000, {
    maxWait: 10000,
});

/// This provider does all the heavy lifting.
const { state: formState, ...formProvider } = provideModelRefForm({
    ...props,
    appId: appId.value,
    initialAnswers: initialAnswers?.data,
    schema,
    // This doesn't actually persist any data - it just copies the modified data
    // into our local state - it will be saved at some point in the future
    async saveForm(newAnswers, propMeta) {
        verifyModelRawData(newAnswers);
        const data = cloneDeep(editing.model.data ?? {});
        const dirty = isEqual(data, newAnswers);

        vset(editing.model, 'data', cloneDeep(newAnswers));
        editing.dirty = dirty;
        editing.propMeta = cloneDeep(propMeta);

        ignore(debouncePersist());
    },
    mergePlayId: injectPlayStateProvider()?.state.play?.id,
});

const {
    performValidate,
    pageModel,
    hasRefs,
} = formProvider;

const formStateRefs = toRefs(formState);
const {
    validationErrorsByPage,
    legacyQuestions,
} = formStateRefs;

const propertyGroups = computed(() => pageModel.groups);
const propertyGroup = computed<ModelPropertyGroup>(() => propertyGroups.value[propertyPageIndex.value] ?? { properties: [] });

async function changePage(newPage: number) {
    const minPage = hasRefs ? -1 : 0;

    if (newPage >= minPage && newPage <= propertyGroups.value.length - 1) {
        const isForward = newPage > propertyPageIndex.value;

        transitionName.value = isForward ? TransitionType.next : TransitionType.prev;
        propertyPageIndex.value = newPage;
    } else {
        log.warn(`Invalid page selection: ${newPage}; expected between 0 and ${propertyGroups.value.length - 1}`);
    }
}

/// LIFECYCLE, WATCHES
onBeforeMount(async () => {
    const { __meta = {} } = initialAnswers;

    merge(formState.meta, __meta ?? {});

    formProvider.sanitize();

    try {
        await formProvider.validate();
    } catch (e) {
        log.severe('Validation error', e);
    }
    await formProvider.generateDefaultValues();
});

/// On page change validate, and save
watch(() => propertyPageIndex.value, () => {
    performValidate();
    persistModel();
});

const pageCount = computed(() => propertyGroups.value.length);
const isPageValid = computed(() => {
    const errs = validationErrorsByPage.value[propertyPageIndex.value] ?? {};

    return Object.keys(errs).length === 0;
});
const invalidPageNames = computed(() => {
    return validationErrorsByPage.value.entrySet().filter(([, valid]) => valid)
        .map(([pageNumber]) => {
            const group = propertyGroups.value[toNumber(pageNumber)];

            return group?.title ?? t('models.invalidPage', { pageNumber: toNumber(pageNumber) + 1 });
        });
});
const invalidPropertyNames = computed(() => {
    const errs = validationErrorsByPage.value[propertyPageIndex.value] ?? {};

    return Object.keys(errs ?? {}).map((k) => formProvider.get(k)?.property?.label).filter(notFalsy);
});

const isLastPage = computed(() => !(pageCount.value > (propertyPageIndex.value + 1)));
const leadingIconPage = computed(() => (isPageValid.value ? null : KeapIcon.ALERT_TRIANGLE));
const leadingIconDone = computed(() => (invalidPageNames.value.length > 0 || invalidPropertyNames.value.length > 0 ? KeapIcon.ALERT_TRIANGLE : null));

const invalidDoneTitle = computed(() => {
    if (!isPageValid.value) {
        // return invalid page title, which is null
        return null;
    }

    return t(invalidPageNames.value.length > 1 ? 'models.invalidPages' : 'models.invalidPageSingle');
});
const leadingIcon = computed(() => (isLastPage.value ? leadingIconDone.value : leadingIconPage.value));
const mainTitle = computed(() => (propertyPageIndex.value === 0 && props.pageTitle ? props.pageTitle : propertyGroup.value.title));
const mainDescription = computed(() => (propertyPageIndex.value === 0 && props.pageDescription ? props.pageDescription : propertyGroup.value.description));
/// The currently focused page
const modelTitle = computed(() => {
    const { article, title } = schema;

    if (props.modelId == null) {
        return t('models.createTitle', { article, title }).toString();
    }

    return t('models.updateTitle', { title }).toString();
});
const invalidDone = computed(() => {
    if (!isPageValid.value) {
        return invalidPropertyNames.value;
    }

    return invalidPageNames.value;
});

function saveAndExit() { return persistModel('close'); }

function goPreviousPage () { return changePage(propertyPageIndex.value - 1); }

async function advancePage() {
    const results = await asyncBus.check(PlayLifecycleEvent.beforeChangePage);

    if (!results) {
        log.info('Next was cancelled');
    } else {
        await changePage(propertyPageIndex.value + 1);
    }
}

async function submitPage() {
    if (isLastPage.value) {
        return saveAndExit();
    }

    return advancePage();
}

function inlineMode(propGroup: ModelPropertyGroup) {
    return !legacyQuestions.value && propGroup.mode === GroupPageMode.PARAGRAPH;
}

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "../../styles/main";
    @import "../properties/play-question";

    .edit-modal-inner {
        height: 100%;
    }

    .edit-modal-body {
        padding: 1rem 0;
        height: 100%;
    }

    .section {
        display: flex;
        flex-direction: row;
        flex-basis: available;
        width: 100%;
    }

    .question-button-row {
        position: relative;
        z-index: 100;

        @media (width <= 1440px) {
            margin-left: $spacing-400;
            margin-right: auto;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "incompleteItems": "Incomplete items",
        "allItemsComplete": " ",
        "noChecklistItems": "Click here to view",
        "launchError": "There was an error launching this play",
        "launchSuccess": "We just created the Easy Automation for this Play.",
        "additionalStepsRequired": "Before publishing this Automation, please finish the pre-launch checklist items below.",
        "launchItems": "Launch Items",
        "showLaunchLinks": "Show Published Records",
        "goBackToQuestions": "Edit play questions",
        "editTitle": "Edit play name",
        "saveWarning": "One or more of your assets has unsaved changes.  Closing will discard these changes.",
        "unsavedChanges": "Unsaved changes",
        "closeAnyway": "Discard changes",
        "dontClose": "Keep editing",

        "saveAll": "Save All",
        "launch": "Use This Play",
        "editPlayName": "Edit play name",
        "preview": "PREVIEW",
        "saveTitle": "Save",
        "asset": {
            "type": {
                "landingPage": "Landing Page",
                "email": "Email"
            }
        },
        "pageTitle": "Choose your items",
        "pageDescription": "Describe things",
        "step": "Step {current} of {stepCount}",
        "previous": "Go back",
        "next": "Next",
        "done": "Done",
        "doneRefs": "Save and go back",
        "validation": {
            "requiredField": "This field is required",
            "tooFewItems": "Please enter at least {minItems} items",
            "tooManyItems": "Please enter at most {maxItems} items"
        }
    }
}
</i18n>
