/* eslint-disable */
import {
    computed, ComputedRef, inject, InjectionKey, provide, ref, Ref,
} from 'vue';
import {Dict, KeyValues, Predicate} from '@/types/core-types';
import cloneDeep from 'lodash/cloneDeep';
import {isEmptyValue, remapEntries} from '@/shared/shared.utils';
import {logger} from "@/shared/logging";

const DataScopeProviderKey: InjectionKey<DataScopeData> = Symbol('dataScopeProvider');

/**
 * The idea behind data scope is that you have collapsed/expanded refs, and also data from related entities (such as a
 * play), all of which may be used to merge content, to validate, or to execute suggestions.  The DataScope preserves
 * a hierarchy of lookup tables that can be consulted to find a deeply nested value.
 *
 * To keep things simpler, these values are mounted to the root providers that expose them.
 */
export interface DataScopeData {
    scopeKey: string;
    data: Dict<KeyValues>;
    parent?: DataScopeData;

    /**
     * Finds a value within this data scope by key
     * @param path
     * @param isEmpty
     */
    get<T>(path: string, isEmpty?: Predicate<T>): T | null;

    flatten(): KeyValues;
}

const log = logger('DataScope');

export function DataScope(scopeKey: string, scope?: Dict<KeyValues>, parentScope?: DataScopeData): DataScopeData {
    if (!scope && !parentScope) {
        return {
            scopeKey,
            data: {},
            parent: null,
            get: () => null,
            flatten:()=>({}),
        };
    }

    function getFrom<T>(name:string, scope: Dict<KeyValues>, isEmpty: Predicate<T> = isEmptyValue){
        for (const scopeName of scope!.keySet()) {
            const tree = scope[scopeName];
            if (tree) {
                const found = (tree as object).getByPath(name);

                if (found == null) continue;

                if (!isEmpty(found as T)) {
                    log.debug(`Found ${name} in ${scopeName.toString()}`);
                    return found as T;
                }
            }
        }
        return null;
    }
    function get<T>(name:string, isEmpty: Predicate<T> = isEmptyValue): T|null {
        const result = getFrom<T>(name, scope, isEmpty) ?? parentScope?.get(name, isEmpty);


        return result;
    }

    return {
        scopeKey,
        data: scope,
        parent:parentScope,
        get,
        flatten(): KeyValues {
            const result = parentScope?.flatten() ?? {};

           return cloneDeep(scope.valueSet().reverse().reduce((prev,next)=> {
               Object.assign(prev, next);
               return prev;
           }, result));
        },
    };
}

/**
 * Provides scoped data down the tree, and also returns scoped data that combines any data from up the tree with
 * the data provided by this component.
 */
export function provideDataScope(scopeKey:string, scope: Dict<KeyValues>, parentScope?: DataScopeData): DataScopeData {
    parentScope ??= injectDataScope();

    const selfScope = DataScope(scopeKey, scope, parentScope);

    provide(DataScopeProviderKey, selfScope);

    return selfScope;
}

export function injectDataScope(): DataScopeData {
    return inject(DataScopeProviderKey, DataScope('root'));
}
