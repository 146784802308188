/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiResponse } from '../models/AiResponse';
import type { GenerateContentRequest } from '../models/GenerateContentRequest';
import type { Play } from '../models/Play';
import type { PlayAssetDetails } from '../models/PlayAssetDetails';
import type { PlayLaunchLinks } from '../models/PlayLaunchLinks';
import type { UpdateAssetRequest } from '../models/UpdateAssetRequest';
import type { UpdatePlayLinks } from '../models/UpdatePlayLinks';
import type { UpdatePlayRequest } from '../models/UpdatePlayRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PlayContentService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Update Play Details
     * Updates content for a single play
     * @param tenant The tenant calling the endpoint
     * @param playId the ID of the play being updated
     * @param requestBody The updates to be made
     * @returns Play The updated play
     * @throws ApiError
     */
    public updatePlayDetails(
        tenant: string,
        playId: string,
        requestBody: UpdatePlayRequest,
    ): CancelablePromise<Play> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/tenant/{tenant}/plays/{playId}',
            path: {
                'tenant': tenant,
                'playId': playId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Load PlayAssetDetails
     * Loads all details for a single asset
     * @param tenant The tenant calling the endpoint
     * @param playId the ID of the play to be deleted.
     * @param assetId The primary key of the PlayAsset record
     * @returns PlayAssetDetails Play asset details
     * @throws ApiError
     */
    public loadPlayAssetDetails(
        tenant: string,
        playId: string,
        assetId: string,
    ): CancelablePromise<PlayAssetDetails> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/tenant/{tenant}/plays/{playId}/assets/{assetId}',
            path: {
                'tenant': tenant,
                'playId': playId,
                'assetId': assetId,
            },
        });
    }

    /**
     * Update PlayAsset
     * Updates content for a single PlayAsset
     * @param tenant The tenant calling the endpoint
     * @param playId the ID of the play this asset belongs to.
     * @param assetId the ID of the asset we are updating.
     * @param requestBody The updates to be made
     * @returns PlayAssetDetails Success
     * @throws ApiError
     */
    public updatePlayAsset(
        tenant: string,
        playId: string,
        assetId: string,
        requestBody: UpdateAssetRequest,
    ): CancelablePromise<PlayAssetDetails> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/tenant/{tenant}/plays/{playId}/assets/{assetId}',
            path: {
                'tenant': tenant,
                'playId': playId,
                'assetId': assetId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Improve PlaySection Content
     * Improves content for a single play section
     * @param tenant
     * @param playId
     * @param assetId
     * @param sectionId
     * @param requestBody
     * @returns AiResponse Success
     * @throws ApiError
     */
    public improvePlaySectionContent(
        tenant: string,
        playId: string,
        assetId: string,
        sectionId: string,
        requestBody: GenerateContentRequest,
    ): CancelablePromise<AiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/plays/{playId}/assets/{assetId}/sections/{sectionId}/improve',
            path: {
                'tenant': tenant,
                'playId': playId,
                'assetId': assetId,
                'sectionId': sectionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Generate PlaySection Content
     * Gets a suggestion for a PlaySection
     * @param tenant The tenant calling the endpoint
     * @param playId the ID of the play this asset belongs to.
     * @param assetId the ID of the asset we are updating.
     * @param sectionId the ID of the section we are generating for
     * @returns AiResponse Success
     * @throws ApiError
     */
    public generatePlaySectionContent(
        tenant: string,
        playId: string,
        assetId: string,
        sectionId: string,
    ): CancelablePromise<AiResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/plays/{playId}/assets/{assetId}/sections/{sectionId}/suggestion',
            path: {
                'tenant': tenant,
                'playId': playId,
                'assetId': assetId,
                'sectionId': sectionId,
            },
        });
    }

    /**
     * Get PlayLaunchLinks
     * Gets all linked records for this Page
     * @param tenant The tenant calling the endpoint
     * @param playId the ID of the play being updated
     * @returns PlayLaunchLinks Success
     * @throws ApiError
     */
    public getPlayLaunchLinks(
        tenant: string,
        playId: string,
    ): CancelablePromise<PlayLaunchLinks> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/tenant/{tenant}/plays/{playId}/launchLinks',
            path: {
                'tenant': tenant,
                'playId': playId,
            },
        });
    }

    /**
     * Update PlayLaunchLinks
     * Updates the linked records for a Play
     * @param tenant The tenant calling the endpoint
     * @param playId the ID of the Play being updated
     * @param requestBody The updates to be made
     * @returns PlayLaunchLinks Success
     * @throws ApiError
     */
    public updatePlayLaunchLinks(
        tenant: string,
        playId: string,
        requestBody: UpdatePlayLinks,
    ): CancelablePromise<PlayLaunchLinks> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/tenant/{tenant}/plays/{playId}/launchLinks',
            path: {
                'tenant': tenant,
                'playId': playId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
