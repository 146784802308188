import { KeyValues } from '@/types/core-types';
import {
    ChecklistItemStatus,
    ChecklistStatus,
    CreateChecklistRequest,
    Play,
    PlayChecklist,
    PlayChecklistItem,
    PlayContentState as PlayStateEnum, UpdateAssetRequest,
    UpdatePlaySectionRequest,
    V2PlayTemplate,
} from '@/generated/play-api';

import { PlayEventTypes } from '@/play-editor/play.constants';
import { PlayLaunchResult } from '@/integration/capabilities/capability-types';
import {
    CheckChangesResult,
    PlayProvider,
    PlayProviderParams,
    PlayState,
    SaveParams,
} from '@/play-editor/provider/vue3/play-provider-types';
import asserts from '@/shared/asserts';
import { launchPlayHelper } from '@/play-editor/mixins/launchPlayHelper';
import { amplitude } from '@/analytics/amplitude';
import cloneDeep from 'lodash/cloneDeep';
import { playClientTs } from '@/client/play-client';
import { mergePlayAndTemplate, repeatString } from '@/play-editor/play-utils';
import { mergePlayData } from '@/shared/type.utils';
import { nextTick } from 'vue';
import { fireEvent, PlayEvent } from '@/events';
import moment from 'moment';
import { timeout } from '@/shared/promise.utils';
import { Completer } from '@/shared/Completer';
import { asyncDebounce } from '@/shared/utils/async-debounce';

export function configurePlayProviderMethods(playState: PlayState, {
    log, hostProvider, coreProvider, appId,
}: PlayProviderParams): PlayProvider {
    const {
        confirm, t, error,
    } = coreProvider;

    asserts(appId != null, 'Must provide appId');

    const self = <PlayProvider>{
        state: playState,
        appId,
    };

    /**
     * Use {@link PlayProvider#savePlay}
     */
    async function internalPersistPlay(reason = 'None') {
        playState.isSaving = true;
        await self.waitForPriorSave();
        asserts(playState.currentSave == null, "Shouldn't have a saving promise");
        const { title, state: currentState, answers } = playState.play;
        const state = playState.isPreparing ? PlayStateEnum.CONTENT : currentState;

        const completer = Completer.create<unknown>(async () => {
            const play = await playClientTs.playContent.updatePlayDetails(appId, playState.play.id, {
                answers,
                state,
                title,
                sortedAssetIds: undefined,
                playProgress: undefined,
            });

            playState.playTemplate = await self.loadTemplateForPlay(play);
            playState.play = play;
            /// We use events to sync up the list page
            playState.lastSave = new Date();
            fireEvent(PlayEvent.savePlay, playState.play);
            log.info(` - done[${reason}]`);
        }, {
            finalizer() {
                playState.isSaving = false;
                playState.currentSave = null;
                log.info(` - finally[${reason}]`);
            },
        });

        playState.currentSave = {
            reason,
            promise: completer.done,
        };

        return completer.done;
    }

    const debouncedPersistPlay = asyncDebounce(internalPersistPlay, 1000, {
        maxWait: 10000,
    });

    Object.assign(
        self,
        <Partial<PlayProvider>>{
            /**
             * Launches this play.
             * @param {Play} play
             * @param {string} playTemplateId
             * @param {KeyValues} answers
             * @returns {Promise<PlayLaunchResult>}
             */
            async launchPlay(play: Play, playTemplateId: string, answers: KeyValues): Promise<PlayLaunchResult> {
                asserts(play);
                asserts(playTemplateId);
                asserts(answers);
                asserts(hostProvider?.host, 'Must have a valid host');

                playState.isLaunchingPlay = true;

                try {
                    await timeout(hostProvider.host.ready, 5000, 'Timed out waiting for host provider to be ready');
                    asserts(hostProvider?.initialized, 'Must have an initialized host');
                    const result = await launchPlayHelper(
                        hostProvider.host,
                        play,
                        playTemplateId,
                        answers ?? {},
                    );

                    /// Fire event for saved play, so the list page can refresh itself
                    fireEvent(PlayEvent.savePlay, playState.play);

                    if (result.additionalSteps?.length > 0) {
                        await self.addChecklist({
                            checklistKey: 'launch',
                            status: ChecklistStatus.ACTIVE,
                            title: 'Pre-launch checklist',
                            description: 'Complete these tasks before publishing your play',
                            items: result.additionalSteps.map((item) => ({
                                actions: item.actions,
                                title: item.title,
                                description: item.description,
                                itemKey: null,
                                itemState: {
                                    ...(item.data ?? {}),
                                },
                                setupTime: moment.duration(15, 'minutes').toISOString(),
                                itemStatus: ChecklistItemStatus.ACTIVE,
                                itemType: 'task',
                            })),
                        });
                    }

                    playState.isLaunchingPlay = false;
                    playState.launchResult = result;
                } catch (e) {
                    log.severe('Error launching', e);
                    playState.launchResult = {
                        success: false,
                        errors: {
                            main: e,
                        },
                        launchLinks: [],
                    };
                } finally {
                    playState.isLaunchingPlay = false;
                }

                return playState.launchResult;
            },

            closePreview() {
                playState.isEditingQuestionsOverride = true;
                playState.previewData = null;
            },

            async savePlay({
                meta, answers: updateAnswers, reason = 'None',
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                state, contentState, ...updates
            }: SaveParams): Promise<unknown> {
                // Merges incoming data with the previously saved answers.  The merge
                // will overwrite arrays, but otherwise will attempt to merge the two
                // trees gracefully
                updateAnswers = mergePlayData(updateAnswers ?? {}, { __meta: meta ?? {} });
                asserts(playState.play != null, 'Must have a valid play');

                for (const [key, value] of updates.entrySet()) {
                    vset(playState.play, key as string, value);
                }

                vset(playState.play, 'answers', mergePlayData(cloneDeep(playState.play.answers), updateAnswers));
                const result = await self.persistPlay(reason);

                log.debug('AFTER PERSIST', result);

                return result;
            },

            async previewEmbedded(): Promise<void> { // Fetch primary asset
                const [{ id: assetId }] = playState.play.assets;

                const asset = await playClientTs.playContent.loadPlayAssetDetails(appId, playState.play.id, assetId);

                playState.previewData = {
                    asset,
                };

                log.info('Notify parent', asset, playState.play);
            },

            async saveAnswers(answers, meta) {
                await self.savePlay({ answers, meta, reason: 'saveAnswers' });
            },

            async doneWithQuestions() {
                playState.isPreparing = true;
                log.debug('doneWithQuestions: start');

                try {
                    const result = await self.savePlay({
                        contentState: PlayStateEnum.CONTENT,
                        reason: 'doneWithQuestions',
                    });

                    log.debug('doneWithQuestions: savePlayDone', { result, state: playState.play });

                    playState.isEditingQuestionsOverride = false;

                    if (playState.isControlled) {
                        await self.previewEmbedded();
                    } else {
                        amplitude.logEvent(PlayEventTypes.PLAY_QUESTIONS_FINISHED, {
                            'Play Template ID': playState.playTemplate.id,
                            'Play Template Name': playState.playTemplate.title,
                        });
                    }
                    /// Arbitrary wait
                    log.info('DONE PREPARE');
                    playState.isPreparing = false;
                } catch (e) {
                    log.info(e);
                    playState.isPreparing = false;
                }
            },

            async updatePlayDetails(newTitle: string) {
                if (newTitle !== playState.play?.title) {
                    playState.play = {
                        ...cloneDeep(playState.play ?? {}) as Play,
                        title: newTitle || '',
                    };

                    await self.savePlay({ reason: 'playTitle' });
                } else {
                    //
                }
            },

            async checkChanges(): Promise<CheckChangesResult> {
                if (playState.hasAnyModifications) {
                    if (!await confirm({
                        message: t('asset.unsavedChanges'),
                    })) {
                        return CheckChangesResult.changesIgnore;
                    }

                    return CheckChangesResult.changesStay;
                }

                return CheckChangesResult.noChanges;
            },

            async loadTemplateForPlay(play: Play): Promise<V2PlayTemplate> {
                if (play?.templateId != null) {
                    playState.playTemplate = await playClientTs.playTemplateV2.getPlayTemplateV2(
                        appId,
                        play.templateId,
                        play.answers,
                    );
                    log.debug('Fetched template', playState.playTemplate);
                }

                return playState.playTemplate;
            },

            async loadPlayAndTemplate(playId: string) {
                const play = await playClientTs.play.getPlay(appId, playId);

                await self.loadTemplateForPlay(play);

                playState.play = mergePlayAndTemplate(play);
            },

            /**
             * Waits for any prior save operation to complete before continuing.  Will only wait for 10 iterations
             * before giving up with an error
             */
            async waitForPriorSave(i = 0) {
                const { reason } = playState.currentSave ?? {};
                let promise = playState.currentSave?.promise;

                while (promise != null && i < 10) {
                    const indent = repeatString('  ', i);

                    log.info(`${indent} - ${reason}: wait`);
                    // eslint-disable-next-line no-await-in-loop
                    await promise;
                    log.info(`${indent} - ${reason}: done`);

                    // eslint-disable-next-line no-await-in-loop
                    await nextTick();

                    promise = playState.currentSave?.promise;
                    i++;
                }

                if (i >= 10) {
                    log.severe("Couldn't finish saving?  Something's off!");
                    asserts(false, "Couldn't finish saving?  Something's off!");
                }
            },

            persistPlay: debouncedPersistPlay,

            async saveAsset(params: { assetId?: string, themeId?: string, changes?: KeyValues } = {}, track = true): Promise<void> {
                const { assetId, themeId } = params;
                let { changes } = params;

                changes ??= playState.modified[assetId] ?? {};

                const asset = playState.play.assets.find(({ id }) => assetId === id);

                asserts(asset != null, `No asset with id=${assetId}`);
                const { title, assetType, sortOrder } = asset;

                const updateRequest: UpdateAssetRequest = {
                    title,
                    assetType,
                    sortOrder,
                    themeId,
                    sections: Object.entries(changes).map(([sectionId, content]) => {
                        return {
                            id: sectionId,
                            content,
                        } as UpdatePlaySectionRequest;
                    }),
                };

                // Gather sections to be updated

                log.info('Updating asset: ', assetId, updateRequest);

                if (track) {
                    amplitude.logEvent(PlayEventTypes.PLAY_CONTENT_EDITED, {
                        'Play Template ID': playState.playTemplate.id,
                        'Play Template Name': playState.playTemplate.title,
                        'Save Method': 'Save individual asset',
                    });
                }

                try {
                    const updatedAsset = await playClientTs.playContent.updatePlayAsset(
                        appId,
                        playState.play.id,
                        assetId,
                        updateRequest,
                    );

                    // Now, trigger reactive changes
                    // Update the list of play assets
                    const assetIndex = playState.play.assets.findIndex((a) => a.id === assetId);

                    vset(playState.play.assets, assetIndex, updatedAsset);

                    if (playState.focusedAsset?.id === updatedAsset.id) {
                        playState.focusedAsset = updatedAsset;
                    }

                    // Clear out modifications
                    vset(playState.modified, assetId, {});
                } catch (e) {
                    log.severe(` - Error saving asset ${assetId}`, e);
                }
            },

            /**
             * @param {CreateChecklistRequest} checklist
             * @returns {Promise<PlayChecklist>}
             */
            async addChecklist(checklist: CreateChecklistRequest) {
                const saved = await playClientTs.play.createChecklist(appId, playState.playId, checklist);

                playState.checklists = cloneDeep([saved, ...(playState.checklists ?? []).filter((c) => c.checklistKey !== checklist.checklistKey)]);

                return saved;
            },

            /**
             * Updates the status of a single checklist item
             */
            async scheduleItemTemplateUpdate(checklist: PlayChecklist, item: PlayChecklistItem, isChecked: boolean): Promise<void> {
                const newItemStatus = isChecked ? ChecklistItemStatus.COMPLETE : ChecklistItemStatus.ACTIVE;

                asserts(checklist?.id, 'Must have focused checklist');
                asserts(item?.id, 'Item must have valid id');
                const foundItem = checklist.items?.find(({ id }) => id === item.id);

                asserts(foundItem, `Should have item with id===${item.id}`);
                /// Triggers reactive update for the checkbox status

                foundItem.itemStatus = newItemStatus;

                const {
                    title, description, actions, setupTime, itemStatus, itemState,
                } = item;

                const { status } = await playClientTs.play.updateChecklistItem(appId, playState.playId, checklist.id, item.id, {
                    title,
                    description,
                    actions,
                    setupTime,
                    itemStatus,
                    itemState,
                    dontSyncStatus: true,
                });

                if (status !== checklist.status) {
                    checklist.status = status;
                }
            },

            updateSectionContent(params: { assetId?: string, sectionId?: string, data?: string } = {}): Promise<void> {
                const { assetId, sectionId, data } = params;
                let assetChanges = playState.modified[assetId];

                if (!assetChanges) {
                    vset(playState.modified, assetId, {});
                    assetChanges = playState.modified[assetId];
                }

                vset(assetChanges, sectionId, data);

                return Promise.resolve();
            },

            async saveAllAssets() {
                playState.isSaving = true;
                amplitude.logEvent(PlayEventTypes.PLAY_CONTENT_EDITED, {
                    'Play Template ID': playState.playTemplate.id,
                    'Play Template Name': playState.playTemplate.title,
                    'Save Method': 'Save All',
                });

                try {
                    const allSaves = Object.entries(playState.modified).map(([assetId, sectionChanges]) => {
                        return self.saveAsset({
                            assetId,
                            changes: sectionChanges,
                        }, false);
                    });

                    await Promise.all(allSaves);
                    playState.lastSave = new Date();
                } catch (e) {
                    log.severe('Error saving', e);
                    error({ message: 'Unable to save' });
                } finally {
                    playState.isSaving = false;
                }
            },
        },
    );

    return self;
}
