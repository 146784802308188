/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PropertyPrimitiveType {
    LONG_TEXT = 'LONG_TEXT',
    TEXT = 'TEXT',
    NUMBER = 'NUMBER',
    INTEGER = 'INTEGER',
    DURATION = 'DURATION',
    BOOLEAN = 'BOOLEAN',
    OBJECT = 'OBJECT',
    ARRAY = 'ARRAY',
    REF = 'REF',
    DATETIME = 'DATETIME',
    DATE = 'DATE',
}
