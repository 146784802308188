import {
    inject, InjectionKey, provide, reactive, toRef, watch,
} from 'vue';
import { TenantAccess, UserAccount, UserSummary } from '@/generated/play-api';
import { RouteName } from '@/router/route-names';
import { distinct } from '@/play-editor/play-utils';
import { playClientTs } from '@/client/play-client';
import { appendCalculated } from '@/play-editor/mixins/v3/computedReactive';
import { ProviderName } from '@/play-editor/play.constants';
import { LoadStatus } from '@/shared/shared.utils';
import cloneDeep from 'lodash/cloneDeep';
import { ignore } from '@/shared/type.utils';
import { useDebounce } from '@vueuse/core';
import { logger } from '@/shared/logging';
import { RawLocation } from '@/shared/navigation';

export type AccountSwitcherOptions = {
    debounceTime?: number;
    showClose?: boolean;
}

type UserAccountItem = {
    id: string;
    name: string;
    legacyId?: string;
    route?: RawLocation;
}
export type AccountSwitcherState = {
    accounts: UserAccountItem[];
    accountsCount: number;
    isOpen: boolean;
    hasCount: boolean;
    isError: boolean;
    noMoreData: boolean;
    isLoading: boolean;
    showEmptyState: boolean;
    searchText: string;
    showCloseButton: boolean;
}

export type AccountSwitcherProvider = {
    state: AccountSwitcherState;
    open(showCloseButton?: boolean): void;
    close(): void;
    reset(): void;
    toggle(): void;
    fetchMore(force: boolean): Promise<unknown>;
    reloadAccounts():Promise<unknown>;
}

const log = logger('provider.AccountSwitcher');

export function provideAccountSwitcher(options?: AccountSwitcherOptions) {
    const { debounceTime, showClose } = options ?? {};

    const crmApps = reactive({
        accountsIds: {},
        totalAccounts: null as number,
        accountList: [] as UserAccountItem[],
        status: LoadStatus.idle,
    });

    const extraAccounts = reactive({
        accountList: [] as TenantAccess[],
    });

    const data = reactive({
        isOpen: false,
        cursor: '',
        searchText: '',
        requestError: null,
        showEmptyState: false,
        showCloseButton: showClose ?? true,
        currentSearch: null,
        // Final
        accountsCount: 0,
        accounts: [],
        noMoreData: false,
        isLoading: false,
        isError: false,
        hasCount: false,
    });

    appendCalculated(data, {
        noMoreData() {
            return (crmApps.accountList.length >= crmApps.totalAccounts);
        },

        isLoading() {
            return crmApps.status === LoadStatus.loading;
        },

        isError() {
            return crmApps.status === LoadStatus.error;
        },

        hasCount() {
            return !data.isLoading || crmApps.totalAccounts != null;
        },

        accounts() {
            return [
                ...extraAccounts.accountList,
                ...crmApps.accountList,
            ];
        },

        accountsCount(): number {
            return (crmApps.totalAccounts ?? 0) + extraAccounts.accountList.length;
        },
    }, { deep: true });

    const searchTextRef = toRef(data, 'searchText');
    const debouncedSearchText = debounceTime > 0
        ? useDebounce(searchTextRef, debounceTime)
        : searchTextRef;

    watch(debouncedSearchText, () => {
        log.info('Account-Switcher Root Debounce');
        resetCrmAccounts();

        return fetchAllAccounts();
    });

    function formatAccount(account: UserAccount): UserAccountItem {
        const {
            id,
            account: {
                legacyId,
                companyProfile: { name },
            },
        } = account;

        return {
            id,
            name,
            legacyId,
            route: {
                name: RouteName.plays,
                params: {
                    appId: legacyId,
                },
            },
        };
    }

    function fetchAllAccounts(force = false) {
        if (!data.isOpen && !force) {
            return { shorted: true };
        }

        crmApps.status = LoadStatus.loading;

        return new Promise<UserSummary>((resolve, reject) => {
            if (data.currentSearch && data.currentSearch !== resolve) {
                data.currentSearch();
            }
            data.currentSearch = resolve;

            playClientTs.user.getUserInfo(data.searchText, data.cursor)
                .then((result) => {
                    resolve(result);
                    data.currentSearch = null;
                }).catch((error) => {
                    reject(error);
                    data.currentSearch = null;
                });
        }).then((responseData: UserSummary) => {
            if (responseData == null) {
                return { cancelled: true };
            }

            const accounts = responseData.apps;

            if (responseData.totalCount) {
                crmApps.totalAccounts = responseData.totalCount;
            } else {
                crmApps.totalAccounts = accounts?.length ?? 0;
            }

            extraAccounts.accountList = responseData.extraApps?.map((app) => ({
                id: app.tenantId,
                name: (app.id === app.tenantId) ? 'Default AI Automation Assistant Account' : 'AI Automation Assistant Account',
                legacyId: (app.id === app.tenantId) ? app.userId : app.tenantId,
                route: {
                    name: RouteName.plays,
                    params: {
                        appId: app.tenantId,
                        defaultApp: Boolean(app.id === app.tenantId),
                    },
                },
            })) ?? [];

            data.cursor = responseData.cursor;

            crmApps.accountList = cloneDeep(distinct(
                [
                    ...crmApps.accountList || [],
                    ...(accounts?.map((a) => formatAccount(a)) || [])],
                'legacyId',
            ));

            data.showEmptyState = crmApps.totalAccounts === 0 && !data.searchText && extraAccounts.accountList.length === 0;
            crmApps.status = LoadStatus.idle;

            return { list: crmApps.accountList };
        }).catch((error) => {
            data.requestError = error;
            crmApps.status = LoadStatus.error;

            return { error };
        });
    }

    function reset() {
        resetCrmAccounts();
        extraAccounts.accountList = [];

        data.requestError = null;
        data.searchText = '';
    }

    function resetCrmAccounts() {
        data.cursor = '';
        crmApps.accountList = [];
        crmApps.accountsIds = {};
        crmApps.totalAccounts = 0;
        data.currentSearch?.call();
        data.currentSearch = null;
        data.showEmptyState = false;
    }

    function open(showCloseButton = data.showCloseButton) {
        data.showCloseButton = showCloseButton;
        reset();
        data.isOpen = true;
        ignore(fetchAllAccounts());
    }

    function close() {
        reset();
        data.isOpen = false;
    }

    function toggle() {
        if (data.isOpen) {
            close();
        } else {
            open();
        }
    }

    const provider = <AccountSwitcherProvider>{
        state: data,
        open,
        close,
        reset,
        toggle,
        fetchMore: fetchAllAccounts,
        reloadAccounts() {
            reset();

            return fetchAllAccounts();
        },
    };

    provide(ProviderName.accountSwitcherProvider, provider);
    provide(AccountSwitcherKey, provider);

    return provider;
}

const AccountSwitcherKey: InjectionKey<AccountSwitcherProvider> = Symbol('accountSwitcherProvider');

export function injectAccountSwitcher() {
    return inject(AccountSwitcherKey);
}
