/* eslint-disable no-console */
import merge from 'lodash/merge';
import assert from 'assert';
import appConfig from '@/config';
import { Dict } from '@/types/core-types';
// noinspection ES6UnusedImports
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
    level, LevelSettings, LogFunction, Logger, LogSettings,
} from '@/shared/logging/logging-api';
import noop from 'lodash/noop';
import { noopLogger } from '@/shared/logging/noop-logger';
import { dict } from '@/store/tutorials/types';
import { env } from '@/env';
import cloneDeep from 'lodash/cloneDeep';
import { mergeDefaults, mergeDefaultsAny } from '@/shared/type.utils';

export * from '@/shared/logging/logging-api';

let settings: LogSettings = {
    isDevelopment: !env.isProd,

    levels: {
        level: level.warn,
        style: {

        },
        bg: '#ccc',
        color: '#000',
    },
};

const LEVEL_STYLES = {
    [level.info]: 'color:#CCCCCC;background:black',
    [level.debug]: 'color:#999999;background:black;font-style:italic',
    [level.severe]: 'color:red,background:black;font-weight:bold',
    [level.warn]: 'color:yellow,background:black',
};

const selfLog = { debug: noop, info: noop };

const loggers: Dict<Logger> = dict();

export function allLoggers() {
    return loggers;
}

export function logSettings() {
    return cloneDeep(settings);
}

export function logger(name: string, logLevel?: level): Logger {
    assert(settings.isDevelopment != null, 'logging has not been initialized yet');

    if (!settings.isDevelopment) {
        return noopLogger;
    }

    if (loggers[name]) {
        return loggers[name];
    }

    const logData = <Logger>{
        name,
        ...noopLogger,
    };

    logData.configure = function () {
        let path = settings.levels ?? {};
        let levelSettings: LevelSettings = {
            level: logLevel ?? path.level ?? level.info,
            style: {
                'background-color': path.bg,
                color: path.color,
                ...(path.style ?? {}),
            },
        };
        const traversed = <string[]>[];

        selfLog.info(`${name}: from source: `, path);
        let nextName = name;

        name.toString().split('.').forEach((next) => {
            traversed.push(next);

            const prefix = `${name}: ${traversed.join(' -> ')}: `;

            if (path[next] != null) {
                path = path[next] as LevelSettings;

                selfLog.debug(`${prefix} Found next path: `, next, {
                    bg: path.bg, style: path.style, color: path.color, level: path.level,
                });
                const nextStyleSettings = {
                    color: path.color,
                    'background-color': path.bg,
                    ...(path.style ?? {}),
                };

                selfLog.debug(`${prefix} `, {
                    traversed,
                    path,
                    result: {
                        nodeSettings: nextStyleSettings,
                        levelSettings,
                    },
                });
                levelSettings = mergeDefaultsAny({
                    level: path.level,
                    style: nextStyleSettings,
                }, levelSettings);
            } else {
                selfLog.debug(`${prefix} No data`);
            }

            nextName = next;
        });

        const { level: currLevel, style } = levelSettings;
        const overrideStyle = buildStyle(style);

        selfLog.info(`${name} -> final: `, currLevel, overrideStyle);
        logData.name = name;
        logData.simpleName = nextName;
        logData.settings = levelSettings;
        logData.debug = currLevel > level.debug ? noop : console.log.bind(console.log, `%c [DEBUG] %c ${nextName} `, LEVEL_STYLES[level.debug], overrideStyle) as LogFunction;
        logData.info = currLevel > level.info ? noop : console.log.bind(console.log, `%c [INFO] %c ${nextName} `, LEVEL_STYLES[level.info], overrideStyle) as LogFunction;
        logData.warn = currLevel > level.warn ? noop : console.warn.bind(console.log, `%c [WARN] %c ${nextName} `, LEVEL_STYLES[level.warn], overrideStyle) as LogFunction;
        logData.severe = currLevel > level.severe ? noop : console.error.bind(console.log, `%c [SEVERE] %c ${nextName} `, LEVEL_STYLES[level.severe], overrideStyle) as LogFunction;
        logData.error = currLevel > level.severe ? noop : console.error.bind(console.log, `%c [ERROR] %c ${nextName} `, LEVEL_STYLES[level.severe], overrideStyle) as LogFunction;
    };

    logData.configure();

    logData.child = (childName?:string, appendName?: boolean) => {
        if (appendName) childName = `${logData.simpleName}:${childName}`;

        return logger(`${name}.${childName}`);
    };

    const log = new Proxy<Logger>(logData, {
        /**
         * You can invoke the logger directly too
         */
        apply: (ft, thisArg: Logger, argumentsList) => {
            thisArg.info(...argumentsList);
        }, // (B)
    });

    loggers[name] = log;

    return log;
}

const buildStyle = (props: Record<string, unknown>): string => {
    const styleVal = Object.entries(props).reduce((prev: string[], [k, v]) => {
        k = k === 'bg' ? 'background-color' : k;
        if (v) prev.push(`${k}:${v}`);

        return prev;
    }, []).join(';');

    return `${styleVal};`;
};

export function configureLogging(newSettings: LogSettings = {}) {
    settings = merge(settings, newSettings);
    settings.isDevelopment ??= false;

    if (Object.keys(loggers).length > 0) {
        for (const log of loggers.valueSet()) {
            selfLog.info(`Reconfiguring ${log.name}`);
            log.configure();
        }
    }
}

type HasLoggingConfig = {
    logging?: LogSettings;
}

configureLogging((appConfig as HasLoggingConfig).logging ?? {});
