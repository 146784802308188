<script lang="ts">export default { name: 'ModelEditPage' }; </script>

<template>
    <ModelPropertiesEditor
        v-if="editing.ready"
        :key="stateKey"
        show-progress
        :page-title="pageTitle"
        :schema="schema"
        :page-description="pageDescription"
        :initial-answers="editing.model"
        :model-id="editing.modelId"
        @save="onSaveAnswers"
        @close="onDoneWithModelQuestions"
    />
    <FullPageSpinner v-else />
</template>
<script lang="ts" setup>
import { ModelKeys } from '@/integration/datastore/model-keys';
import asserts from '@/shared/asserts';
import {
    computed, reactive, toRefs,
} from 'vue';
import { blankModel, PreparedModel } from '@/integration/datastore/base-types';
import { DataScopeData, provideDataScope } from '@/model/form/DataScope';
import { injectPageStackCompleter } from '@/shared/components/navigation-inject';
import FullPageSpinner from '@/shared/components/FullPageSpinner.vue';
import { modelMixin } from '@/play-editor/mixins/v3/modelMixin';
import { logLifecycle } from '@/play-editor/provider/vue3/lifecycle';
import { useCore } from '@/shared/shared-providers';
import { waitAsync } from '@/play-editor/model/TenantModelService';
import ModelEditPage from '@/play-editor/model/ModelEditPage.vue';

/**
 * This is the base component for editing models.  This widget is responsible for:
 *  - 1. Loading the schema
 *  - 2. Loading the model (if there is one)
 *  * THEN
 *  - Rendering the ModelPropertiesEditor
 *  - Handling save events and popping the route when it's time
 */
const props = defineProps<{
        modelCategory: string;
        modelName: string;
        modelId?: string,
        /**
         * Passing through a ref because I'm not exactly sure how to rebuild stacked routes otherwise
         */
        parentScope?: DataScopeData;
        pageTitle?: string;
        pageDescription?: string;
    }>();

const { appId } = useCore();
const { modelCategory, modelName } = toRefs(props);

logLifecycle();
asserts(modelCategory.value, 'modelCategory must not be null');
asserts(modelName.value);

/// This ModelEditPage can be invoked from a number of different places.  Instead of using the provider
/// hierarchy for parentScope, the caller can provide it directly.
if (props.parentScope != null) {
    /// Makes this available down the tree.
    provideDataScope(
        props.parentScope.scopeKey,
        props.parentScope.data,
        props.parentScope.parent,
    );
}

const refType = ModelKeys.of(`${modelCategory.value}:${modelName.value}`);

asserts(refType, 'Should have a valid refType');

const navigator = injectPageStackCompleter<PreparedModel>();
const modelTypeService = modelMixin(appId.value, refType, ModelEditPage);
const { modelSchemaState } = modelTypeService;

const schema = computed(() => modelSchemaState?.state?.value);
const editing = reactive({
    modelId: props.modelId,
    ready: false,
    model: null as PreparedModel,

});

waitAsync(modelSchemaState).then(async () => {
    if (props.modelId) {
        // Now we prepare
        editing.model = await modelTypeService.loadModel(props.modelId);
        editing.ready = true;
    } else {
        editing.model = blankModel(refType);
        editing.ready = true;
    }
});

function onSaveAnswers(model: PreparedModel) {
    /// This sends a completion signal back, but does not pop the route.
    navigator.complete(model);
}

function onDoneWithModelQuestions(model: PreparedModel) {
    navigator.back(model);
}

const stateKey = computed(() => {
    return editing.modelId || refType.key;
});

</script>
<i18n>
{
    "en-us": {
    }
}
</i18n>
