/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChecklistItemStatus } from '../models/ChecklistItemStatus';
import type { CreateAdhocChecklistItemRequest } from '../models/CreateAdhocChecklistItemRequest';
import type { CreateChecklistItemResponse } from '../models/CreateChecklistItemResponse';
import type { DefaultPlaybook } from '../models/DefaultPlaybook';
import type { FocusPlaybookRequest } from '../models/FocusPlaybookRequest';
import type { HostSystem } from '../models/HostSystem';
import type { Play } from '../models/Play';
import type { PlaybookSummary } from '../models/PlaybookSummary';
import type { PlayChecklist } from '../models/PlayChecklist';
import type { PlayChecklists } from '../models/PlayChecklists';
import type { UpdateChecklistItem } from '../models/UpdateChecklistItem';
import type { UpdateChecklistResponse } from '../models/UpdateChecklistResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PlaybookService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get All Checklists
     * Retrieves all checklists
     * @param tenant
     * @param includeHidden
     * @returns PlayChecklists Success
     * @throws ApiError
     */
    public getAllChecklists(
        tenant: string,
        includeHidden: boolean = false,
    ): CancelablePromise<PlayChecklists> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/tenant/{tenant}/checklists',
            path: {
                'tenant': tenant,
            },
            query: {
                'includeHidden': includeHidden,
            },
        });
    }

    /**
     * Resort Checklists
     * Changes the sort order for checklists
     * @param tenant
     * @param requestBody
     * @returns number Success
     * @throws ApiError
     */
    public resortChecklists(
        tenant: string,
        requestBody: Array<string>,
    ): CancelablePromise<Record<string, number>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/checklists/sortOrders',
            path: {
                'tenant': tenant,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get Playbook With Default
     * Retrieves a Playbook summary for the current user. Also supports adding a default Playbook if no Playbook exists
     * @param tenant the tenant ID
     * @param defaultPlaybook If provided, will initialize default playbook.
     * @param hostSystem
     * @returns PlaybookSummary The current user's Playbook (summary)
     * @throws ApiError
     */
    public getPlaybookWithDefault(
        tenant: string,
        defaultPlaybook?: DefaultPlaybook | null,
        hostSystem?: HostSystem | null,
    ): CancelablePromise<PlaybookSummary> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/tenant/{tenant}/playbook',
            path: {
                'tenant': tenant,
            },
            query: {
                'defaultPlaybook': defaultPlaybook,
                'hostSystem': hostSystem,
            },
        });
    }

    /**
     * Change Focus
     * Changes which items are focused within the playbook (the Play and ChecklistItem)
     * @param tenant The tenant ID
     * @param requestBody Which items to focus on
     * @returns Play The details for the newly focused Play
     * @throws ApiError
     */
    public changeFocus(
        tenant: string,
        requestBody: FocusPlaybookRequest,
    ): CancelablePromise<Play> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/tenant/{tenant}/playbook',
            path: {
                'tenant': tenant,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get Checklist
     * Retrieves a Checklist for a Play, or default
     * @param tenant
     * @param playId
     * @param checklistId
     * @returns PlayChecklist The Checklist for this Play
     * @throws ApiError
     */
    public getChecklist(
        tenant: string,
        playId: string,
        checklistId: string,
    ): CancelablePromise<PlayChecklist> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/tenant/{tenant}/plays/{playId}/checklists/{checklistId}',
            path: {
                'tenant': tenant,
                'playId': playId,
                'checklistId': checklistId,
            },
            errors: {
                404: `The Checklist does not exist`,
            },
        });
    }

    /**
     * Create Checklist Item
     * Add an item to the checklist for this play
     * @param tenant
     * @param playId
     * @param checklistId
     * @param requestBody
     * @returns CreateChecklistItemResponse Success
     * @throws ApiError
     */
    public createChecklistItem(
        tenant: string,
        playId: string,
        checklistId: string,
        requestBody: CreateAdhocChecklistItemRequest,
    ): CancelablePromise<CreateChecklistItemResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/plays/{playId}/checklists/{checklistId}/items',
            path: {
                'tenant': tenant,
                'playId': playId,
                'checklistId': checklistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update Checklist Item
     * Updates a single ChecklistItem record
     * @param tenant
     * @param playId
     * @param checklistId
     * @param itemId
     * @param requestBody
     * @returns UpdateChecklistResponse Success
     * @throws ApiError
     */
    public updateChecklistItem(
        tenant: string,
        playId: string,
        checklistId: string,
        itemId: string,
        requestBody: UpdateChecklistItem,
    ): CancelablePromise<UpdateChecklistResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/tenant/{tenant}/plays/{playId}/checklists/{checklistId}/items/{itemId}',
            path: {
                'tenant': tenant,
                'playId': playId,
                'checklistId': checklistId,
                'itemId': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Checklist Item
     * Deletes a single ChecklistItem record
     * @param tenant
     * @param playId
     * @param checklistId
     * @param itemId
     * @returns UpdateChecklistResponse Success
     * @throws ApiError
     */
    public deleteChecklistItem(
        tenant: string,
        playId: string,
        checklistId: string,
        itemId: string,
    ): CancelablePromise<UpdateChecklistResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/tenant/{tenant}/plays/{playId}/checklists/{checklistId}/items/{itemId}',
            path: {
                'tenant': tenant,
                'playId': playId,
                'checklistId': checklistId,
                'itemId': itemId,
            },
        });
    }

    /**
     * Update ChecklistItemStatus
     * Updates a single ChecklistItem record
     * @param tenant
     * @param playId
     * @param checklistId
     * @param itemId
     * @param itemStatus
     * @returns UpdateChecklistResponse Success
     * @throws ApiError
     */
    public updateChecklistItemStatus(
        tenant: string,
        playId: string,
        checklistId: string,
        itemId: string,
        itemStatus: ChecklistItemStatus,
    ): CancelablePromise<UpdateChecklistResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/tenant/{tenant}/plays/{playId}/checklists/{checklistId}/items/{itemId}/itemStatus/{itemStatus}',
            path: {
                'tenant': tenant,
                'playId': playId,
                'checklistId': checklistId,
                'itemId': itemId,
                'itemStatus': itemStatus,
            },
        });
    }

    /**
     * Resort Checklist Items
     * Changes the sort order for items in this checklist
     * @param tenant
     * @param playId
     * @param checklistId
     * @param requestBody
     * @returns number Success
     * @throws ApiError
     */
    public resortChecklistItems(
        tenant: string,
        playId: string,
        checklistId: string,
        requestBody: Array<string>,
    ): CancelablePromise<Record<string, number>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tenant/{tenant}/plays/{playId}/checklists/{checklistId}/sortOrders',
            path: {
                'tenant': tenant,
                'playId': playId,
                'checklistId': checklistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
