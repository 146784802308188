<script lang="ts">export default { name: 'PlayApp' }; </script>

<template>
    <AccountSwitcherRoot>
        <RouterView />
        <DsToastContainer />
        <DsConfirmDialogContainer />
    </AccountSwitcherRoot>
</template>

<script setup lang="ts">

import { VueRouterProviderKey } from '@/shared/shared-providers';
import { RouterNavigator } from '@/shared/navigation';
import { RouterNavigatorKey } from '@/shared/components/navigation-inject';
import { componentLogger } from '@/setup/setup-vue-logging';
import { ProviderName, SEARCHING_DEBOUNCE_DELAY } from '@/play-editor/play.constants';
import { ConfirmArgs, MessageArgs } from '@/ConfirmArgs';
import { provideCustomElementDefinitions } from '@/play-editor/provider/vue3/custom-element-definitions';
import { env } from '@/env';
import { CoreProvide } from '@/shared/core-provide.types';
import { provideAppId } from '@/play-editor/provider/provide-app-id';
import { provideAccountSwitcher } from '@/shared/components/AccountSwitchProvider';
import AccountSwitcherRoot from '@/shared/components/AccountSwitcherRoot.vue';
import { provideFrameService } from '@/play-editor/provider/FrameServiceProvider';
import { provideHostProvider } from '@/play-editor/provider/provider-types';
import { providePlayTemplates } from '@/play-editor/provider/PlayTemplatesProvider';
import { provideGraphQL } from '@/shared/graphql.util';
import { provideLookups } from '@/shared/provider/LookupProvider';
import { Composer, useI18n } from 'vue-i18n';
import { watchAnalytics } from '@/analytics/components/AnalyticsHandler';
import { State } from '@/store/play-root-state';
import { Store } from 'vuex';
import { Router } from 'vue-router';

const components = inject<{
    ready: boolean;
    store: Store<State>;
    router: Router;
    i18n: Composer;
}>('appComponents');

asserts(components.ready, 'Components are ready');
const vm = getCurrentInstance();
const { app } = vm.appContext;
const { name } = vm.proxy.$options;
const appId = provideAppId({ store: components.store }, ref(null));
const i18n = useI18n();
const coreProvide: CoreProvide = {
    confirm: (args: ConfirmArgs) => vm.proxy.$confirmation(args),
    toast: (args: MessageArgs) => vm.proxy.$toast(args),
    error: (args: MessageArgs) => vm.proxy.$error(args),
    t: vm.proxy.$t,
    tc: vm.proxy.$t,
    n: vm.proxy.$n,
    log: componentLogger.child(name ?? 'App'),
    store: components.store,
    router: components.router,
    i18n,
    name,
    appId,
};

provideAccountSwitcher({
    debounceTime: SEARCHING_DEBOUNCE_DELAY,
    showClose: true,
});

provideGraphQL(components.store);
provide(VueRouterProviderKey, components.router);
provide(RouterNavigatorKey, RouterNavigator(components.router));
provideCustomElementDefinitions();
provide<CoreProvide>(ProviderName.coreProvider, coreProvide);

const frameProvider = provideFrameService(coreProvide);

provideHostProvider(frameProvider, coreProvide);
providePlayTemplates(coreProvide);
provideLookups();

const playWebWcScriptsId = 'play-web-wc-scripts';

if (!document.getElementById(playWebWcScriptsId)) {
    const tag = document.createElement('script');

    tag.id = playWebWcScriptsId;
    tag.setAttribute('src', env.VUE_APP_WC_URL);
    document.head.appendChild(tag);
}

watchAnalytics(components.router, components.store.state);

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .view-container {
        padding-top: calc(1rem * 1.5);
        margin: 0 auto;
        max-width: 70rem;
        min-height: 20rem;
    }
</style>
