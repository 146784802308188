/* eslint-disable @typescript-eslint/no-explicit-any */

import { provide } from 'vue';
import { Dict } from '@/types/core-types';
import { ModelProperty, ModelPropertyGroup } from '@/generated/play-api';
import asserts from '@/shared/asserts';
import { playClientTs } from '@/client/play-client';
import {
    FormProvider,
    FormProviderKey,
    FormSourceType,
    ModelFormCreateParameters,
    ModelFormSource,
    PlayFormSource,
} from '@/model/form/form-provider-types';
import { PageModel } from '@/play-editor/play/PlayModel';
import { ModelKeys } from '@/integration/datastore/model-keys';
import { setupRefExpansion } from '@/model/form/provide-form-ref-expansion';
import { setupFormProviderDefaultValues } from '@/model/form/provide-form-default-values';
import { setupFormProviderSuggestions } from '@/model/form/provide-form-suggestions';
import { setupFormProviderValidation } from '@/model/form/provide-form-validation';
import { setupFormProviderSaveValues } from '@/model/form/provide-form-save';
import { setupFormProviderMetaData } from '@/model/form/provide-form-metadata';
import { setupFormProviderFocus } from '@/model/form/provide-form-focus';
import { setupFormProviderPropHelpers } from '@/model/form/provide-form-prop-helpers';
import { setupFormProviderState } from '@/model/form/provide-form-state';
import { PlayProvider } from '@/play-editor/provider/vue3/play-provider-types';
import { ignore } from '@/shared/type.utils';
import { formLogger } from '@/model/form/provide-form-logger';
import { verifyModelRawData } from '@/play-editor/model/model.utils';

/**
 * Configures and provides FormProvider for editing questions associated with a play.
 *
 * This assumes that there is an existing PlayProvider in the widget hierarchy.
 *
 */
export function providePlayForm(playProvider: PlayProvider): FormProvider {
    const { state: playState, appId } = playProvider;
    // eslint-disable-next-line vue/no-setup-props-destructure
    const { id: playTemplateId } = playProvider.state.playTemplate;

    asserts(playTemplateId, 'Should have play template');

    const mergePlayId = playState.play.id;

    const questionsProvider = formProvider(
        appId,
        FormSourceType.play,
        {
            playTemplateId,
            pageModel: playState.pageModel,
            mergePlayId,
            name: `play[${playTemplateId}]`,
            initialAnswers: playState.play.answers,

            saveForm(answers, meta) {
                return playProvider.saveAnswers(answers, meta);
            },

            expandRefs(answers) {
                return playClientTs.play.expandPlayAnswers(appId, mergePlayId, answers);
            },
        },
    );

    provide(FormProviderKey, questionsProvider);

    return questionsProvider;
}

/**
 * Sets up all the necessary state to have a form to edit a ref.
 *
 * For play question forms, see {@link providePlayForm}
 *
 */
export function provideModelRefForm({
    schema, modelId, saveForm, mergePlayId, initialAnswers, appId,
}: ModelFormCreateParameters): FormProvider {
    asserts(schema, 'Should have already loaded schema');

    const modelGroups: ModelPropertyGroup[] = schema.groups?.length > 0 ? schema.groups.sort((a, b) => a.sortOrder - b.sortOrder) : [{
        groups: [],
        properties: schema.properties,
        title: schema.title,
        description: schema.description,
    }];

    const refType = ModelKeys.of(schema.modelType);

    const refFormProvider = formProvider(appId, FormSourceType.modelRef, {
        mergePlayId,
        pageModel: PageModel.of(modelGroups, false),
        name: [schema.modelType.name, modelId ?? 'create'].join('.'),
        refType: ModelKeys.of(schema.modelType),
        initialAnswers,
        saveForm,
        expandRefs: (answers) => {
            return playClientTs.modelDefinition.expandModelAnswers(
                appId,
                refType.category,
                refType.name,
                {
                    answers,
                    playId: mergePlayId,
                },
            );
        },
    });

    provide(FormProviderKey, refFormProvider);

    return refFormProvider;
}

/**
 * The base form provider method, called by {@link providePlayForm} and {@link provideModelRefForm}
 */
function formProvider(
    appId: string,
    sourceType: FormSourceType,
    source: PlayFormSource | ModelFormSource,
): FormProvider {
    asserts(appId != null, 'appId cannot be null');

    const {
        pageModel: { groups, refs, hasRefs }, expandRefs, saveForm, initialAnswers,
    } = source;

    /**
     * Verify that the caller is passing us the raw data, and not the complete model instance
     */
    verifyModelRawData(initialAnswers);

    const self = <FormProvider>{};

    self.appId = appId;
    self.log = formLogger.child(source.name);
    /// First, set up properties, groups, and refs
    self.pageModel = source.pageModel;
    self.groups = groups.sort((a, b) => a.sortOrder - b.sortOrder);
    self.hasRefs = hasRefs;
    self.sourceType = sourceType;
    self.mergePlayId = source.mergePlayId;
    self.source = source;
    self.properties = <Dict<ModelProperty>>{};
    self.refs = refs.keyed<string>('name');

    for (const r of self.pageModel.refs) {
        self.properties[r.name] = r;
    }

    for (const group of self.groups) {
        for (const property of group.properties) {
            self.properties[property.name] = property;
        }
    }

    self.mergePlayId = self.source.mergePlayId;

    /// This one needs to go first
    setupFormProviderState(self, initialAnswers);

    /// General setup
    setupFormProviderValidation(self);
    setupFormProviderFocus(self);
    setupFormProviderSaveValues(self, saveForm);
    setupFormProviderMetaData(self);
    setupFormProviderSuggestions(self);
    setupFormProviderDefaultValues(self);

    /// It's important that this come last. We want to ensure that there aren't any propHelper accesses
    /// during the setup of the formProvider
    setupFormProviderPropHelpers(self);

    /// POST-SETUP EXECUTION
    /// Any form initialization can go here, now that it's safe
    setupRefExpansion(expandRefs, self);

    // do initial validation?
    ignore(self.validate());

    return self;
}
