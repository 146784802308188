/* eslint-disable no-return-assign */
import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import jwtDecode from 'jwt-decode';

import { dict, KeyValues } from '@/types/core-types';
import { PlayStore } from '@/store/play-root-state';
import asserts from '@/shared/asserts';
import { env } from '@/env';
import { InjectionKey, provide } from 'vue';

let globalGraphQLFactory: (appId:string)=>ApolloClient<unknown>;

function createGraphQLClientProvider(store: PlayStore) {
    const appClients = dict<ApolloClient<unknown>>();

    globalGraphQLFactory = (appId:string):ApolloClient<unknown> => {
        return appClients[appId] ??= buildApolloClient(store, appId);
    };

    return globalGraphQLFactory;
}

const graphQLClientKey:InjectionKey<typeof globalGraphQLFactory> = Symbol('graphQLFactory');

export function provideGraphQL(store: PlayStore) {
    provide(graphQLClientKey, createGraphQLClientProvider(store));
}

export function createGraphClient(appId:string):ApolloClient<unknown> {
    asserts(globalGraphQLFactory != null, 'Must have a global factory');
    const factory = globalGraphQLFactory(appId);

    return factory;
}

class JwtMissingError extends Error {
    constructor(message?:string) {
        super(message);
        this.name = 'JwtMissingError';
    }
}
class JwtExpiredError extends Error {
    constructor(message?:string) {
        super(message);
        this.name = 'JwtExpiredError';
    }
}

export { JwtMissingError, JwtExpiredError };

function buildApolloClient(vstore: PlayStore, appId:string) {
    const isJwtExpired = (jwt:string) => {
        const { exp } = jwtDecode<KeyValues>(jwt);

        if (exp) {
            return (exp as number) * 1000 < Date.now();
        }

        return true;
    };

    const client = new ApolloClient({
        uri: env.VUE_APP_PLAY_BFF_URL,
        cache: new InMemoryCache({
            addTypename: false,
        }),

        request: async (operation) => {
            const { auth } = vstore.state;
            const { jwt } = auth.session;

            if (jwt == null) {
                throw new JwtMissingError('JWT is not present in the auth session.');
            }

            if (isJwtExpired(jwt)) {
                throw new JwtExpiredError('JWT is expired.');
            }

            asserts(appId, 'AppId must not be null');

            const headers = {
                Authorization: `Bearer ${jwt}`,
                'x-is-app-name': appId,
            };

            operation.setContext({ headers });

            return Promise.resolve();
        },
        onError: ({ networkError }) => {
            if (networkError instanceof JwtExpiredError) {
                // eslint-disable-next-line no-console
                console.log('Expired', networkError);
                // window.location = '/logout?checkCas=true';
            }

            if (networkError instanceof JwtMissingError) {
                // eslint-disable-next-line no-console
                console.log('Missing', networkError);

                // window.location = '/logout';
            }

            const error = <unknown>networkError as KeyValues;

            if (networkError && error.statusCode === 401) {
                // eslint-disable-next-line no-console
                console.log('401', networkError);
                // window.location = '/logout?checkCas=true';
            }
        },
    });

    return client;
}
