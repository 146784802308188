/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ContentType {
    TEXT = 'text',
    HTML = 'html',
    FORM = 'form',
    UNKNOWN = 'unknown',
}
