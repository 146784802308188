/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Statuses for Checklist Items:
 * * WAITING: The item is waiting for another item to be completed
 * * ACTIVE: The item is ready and not yet complete
 * * SKIPPED: The item was skipped by the user
 * * COMPLETE: The item was completed normally
 */
export enum ChecklistItemStatus {
    WAITING = 'WAITING',
    ACTIVE = 'ACTIVE',
    SKIPPED = 'SKIPPED',
    COMPLETE = 'COMPLETE',
}
