import { Placement, Rect } from '@popperjs/core';

export enum PopoverPosition {
    top='top',
    right='right',
    bottom='bottom',
    left='left',
    topStart='top-start',
    topEnd='top-end',
    rightStart='right-start',
    rightEnd='right-end',
    bottomStart='bottom-start',
    bottomEnd='bottom-end',
    leftStart='left-start',
    leftEnd='left-end',
}

export const validPopoverPositions = Object.values(PopoverPosition);

export type PopoverArrowOffset = {
    top?: string,
    left?: string;
}

export type PopoverOffsetLiteral = {
    skidding?: number;
    distance?: number;
}

export type PopoverOffsetCalculation = ((placement:PopoverPlacement)=> PopoverOffsetLiteral);

export type PopoverPlacement = {
    placement?: Placement;
    reference?: Rect;
    popper?: Rect;
};

type PopperLiteral = [number|null|undefined, number|null|undefined];

// eslint-disable-next-line no-unused-vars
export type PopoverOffset = PopoverOffsetLiteral | PopoverOffsetCalculation;
export type PopperOffset = PopperLiteral | ((placement:PopoverPlacement)=> PopperLiteral);
