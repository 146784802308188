import { KeyValues } from '@/types/core-types';
import { RouteLocationRaw, Router } from 'vue-router';
import { Composer } from 'vue-i18n';

export type { VueI18n } from 'vue-i18n';

export function vset(obj:KeyValues, key:string|number, value:unknown):void {
    obj[key] = value;
}

export function vget(obj:KeyValues, key:string|number):unknown {
    return obj[key];
}

export function vdel(obj:KeyValues, key:string|number) {
    delete obj[key];
}

export type VueRouter = Router;
export type I18nType = Composer;
export type RawLocation=RouteLocationRaw;
