/**
 * Allows a timeout to be applied to a Promise.
 *
 * @param promise The original promise.
 * @param durationMs The number of milliseconds to wait before timing out
 * @param msg A message to use when raising a timeout exception
 */
export function timeout<T>(promise: Promise<T>, durationMs: number, msg?: string): Promise<T> {
    return new Promise<T>((res, rej) => {
        const handle = setTimeout(() => rej(msg || (`Promise timed out after ${durationMs}ms`)), durationMs);

        promise.then(res).catch(rej).finally(() => clearTimeout(handle));
    });
}
