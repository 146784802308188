import { amplitude } from '@/analytics/amplitude';
import { PLAY_FIRST_SOURCE, PLAY_SOURCES } from '@/analytics/constants/amplitude-users.constants';

import { KeyValues } from '@/types/core-types';
import { logger } from '@/shared/logging';
import { State } from '@/store/play-root-state';
import { watch } from 'vue';
import { Router } from 'vue-router';

const log = logger('AnalyticsHandler');

export function watchAnalytics(router: Router, state: State) {
    watch([() => router.currentRoute.value, () => state.auth.session?.coreAppId, () => state.auth.user], ([route, appId, user]) => {
        const params:KeyValues = {
            ...route.query,
            ...route.params,
        };

        const { playSource, amplitudeSessionId } = params;

        if (playSource) {
            amplitude.setOnce(PLAY_FIRST_SOURCE, playSource);
            amplitude.postInsert(PLAY_SOURCES, playSource);
        }

        if (amplitudeSessionId) {
            log.info('Changing amplitude sessionID', amplitudeSessionId, appId, user);
            // eslint-disable-next-line radix
            amplitude.setSessionId(parseInt(amplitudeSessionId));
        }
    });
}
