import jwtDecode from 'jwt-decode';
import { isAuthDisabled } from '@/store/auth/auth-utils';
import { playClientTs } from '@/client/play-client';
import { ActionContext, ActionTree } from 'vuex';
import { AuthState, JwtClaims } from '@/store/auth/types';
import { State } from '@/store/play-root-state';
import { stateLog } from '@/store/config/types';

const actions = <ActionTree<AuthState, State>>{
    FETCH_USER_DETAILS(context) {
        return fetchUserDetails(context);
    },

    LOGIN(context, jwt:string) {
        return login(context, jwt);
    },

    VERIFY_LOGIN(context) {
        return verifyLogin(context);
    },

    LOGOUT({ commit }) {
        commit('SESSION_END');
    },
};

const log = stateLog('auth', 'actions');

type AuthContext = ActionContext<AuthState, unknown>;

async function fetchUserDetails({ commit, state }:AuthContext) {
    const { session: { jwt }, accounts, userDetailsFetched } = state;

    if (jwt && !accounts) {
        try {
            playClientTs.request.config.TOKEN = jwt;
            playClientTs.request.config.WITH_CREDENTIALS = true;

            if (!userDetailsFetched) {
                commit('SET_USER_DETAILS_FETCHED', true);
                const accountsInfo = await playClientTs.user.getUserInfo();

                log.info('From API', accountsInfo);
                commit('SET_USER_DETAILS', accountsInfo);
                commit('SET_USER_ROLES', accountsInfo.roles);

                log.info('User details already being fetched');

                return accountsInfo;
            }

            return null;
        } catch (e) {
            log.error('Error fetching', e);
        }
    }

    log.warn('No user details could be fetched');

    return null;
}

const login = ({ commit }:AuthContext, jwt?:string) => {
    if (jwt) {
        try {
            const {
                email, firstName, id, lastName, displayName, exp,
            } = jwtDecode<JwtClaims>(jwt) ?? {};

            if (isJwtExpired(exp)) {
                commit('SESSION_END');
            } else {
                const user = {
                    id,
                    username: email,
                    givenName: firstName,
                    familyName: lastName,
                    fullName: displayName,
                    exp,
                };

                commit('SESSION_START', { jwt, user });
            }
        } catch (e) {
            commit('SESSION_FAIL');
        }
    } else {
        commit('SESSION_END');
    }
};

const verifyLogin = ({ state, commit }:AuthContext) => {
    if (!isAuthDisabled() && isJwtExpired(state.user?.exp)) {
        commit('SESSION_END');
    }
};

const isJwtExpired = (exp:number) => {
    if (exp) {
        return exp * 1000 < Date.now();
    }

    return true;
};

export default actions;
