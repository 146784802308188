/**
 * COPIED FROM keap-web
 */

// Triggers
export const LANDING_PAGE_FORM_SUBMITTED = 'LANDING_PAGE_FORM_SUBMITTED';
export const TAG_APPLIED = 'TAG_APPLIED';
export const APPOINTMENT_SCHEDULED = 'APPOINTMENT_SCHEDULED';

// Steps
export const SEND_EMAIL = 'SEND_EMAIL';
export const RELATIVE_TIMER = 'RELATIVE_TIMER';
export const SEND_SMS = 'SEND_SMS';

export enum TimerType {
    delayTimer='DELAY_TIMER',
    relativeTimer='RELATIVE_TIMER',
}

export const TimingUnit = {
    minutes: 'MINUTES',
    hours: 'HOURS',
    days: 'DAYS',
    weeks: 'WEEKS',
    months: 'MONTHS',
};

// Formats used by BACKEND ONLY, do not use them for display
export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';

export const SMS_DEFAULT_PHONE_TYPES = ['Mobile', 'Work', 'Home', 'Other'];
export const DEFAULT_SEQUENCE_KEY = 'sequence';
