/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DateExpressionType {
    DELAY = 'delay',
    EXACT = 'exact',
    APPOINTMENT = 'appointment',
    INVOICE_DUE = 'invoiceDue',
    ANY = 'any',
}
