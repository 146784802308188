<template>
    <div
        :class="{
            'modal-scaffold-overlay': true, 'modal-transition': transition, open, overlay,
        }"
    >
        <div :class="['modal-scaffold', size]">
            <div v-if="hasHeader" class="modal-header">
                <ModalHeader>
                    <template v-if="showBack || showClose" #leading>
                        <slot name="leading">
                            <IconButton
                                v-if="showBack"
                                id="back"
                                name="arrow-left"
                                class="navigation-button"
                                @click.stop="closeModal"
                            />

                            <IconButton
                                v-else-if="showClose"
                                id="close"
                                name="x"
                                class="navigation-button"
                                @click.stop="closeModal"
                            />
                        </slot>
                    </template>
                    <template v-if="title" #title>
                        <slot name="title">
                            <h4>{{ title }}</h4>
                        </slot>
                    </template>
                    <template v-else-if="slots.title" #title>
                        <slot name="title">
                            <SlotRenderer :slot-value="slots.title" />
                        </slot>
                    </template>

                    <template v-if="slots.trailing || defaultSlots.trailing" #trailing>
                        <slot name="trailing">
                            <SlotRenderer :slot-value="defaultSlots.trailing" />
                        </slot>
                    </template>
                </ModalHeader>
            </div>

            <div ref="modalBody" :class="{ 'modal-scaffold-body': true, 'has-header': hasHeader }">
                <slot :height="height" />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import {
    computed, defineComponent, nextTick, onMounted, provide, ref, toRefs, watch,
} from 'vue';
import SlotRenderer from '@/shared/components/SlotRenderer.vue';
import { useElementSize } from '@vueuse/core';
import {
    injectModalRouter,
    isModalNested,
    ModalTransitionMode,
    NestedModalProviderKey,
} from '@/shared/components/modal-providers';

export default defineComponent({
    name: 'ModalScaffold',
    components: { SlotRenderer },
    props: {
        title: String,
        size: {
            type: String,
            default: 'fullscreen',
        },
        overlay: Boolean,
        showClose: Boolean,
        showBack: Boolean,
        emitClose: Boolean,
        isOpen: Boolean,
        /**
         * Whether this modal should display the expanding/contracting modal overlay.  This will only work on the
         * outermost modal - nested modals should not repeat transition
         */
        transitionMode: String,
    },
    emits: ['close'],
    setup(props, { emit, slots }) {
        const { isOpen } = toRefs(props);
        const { fallback = false, defaultSlots, close: modalRouterClose } = injectModalRouter();

        const modalBody = ref(null);

        const { height } = useElementSize(modalBody);

        const hasParentModal = isModalNested();

        provide(NestedModalProviderKey, { parent: true });

        const transition = computed(() => {
            switch (props.transitionMode ?? ModalTransitionMode.parentOnly) {
            case ModalTransitionMode.parentOnly:
                return !hasParentModal;
            case ModalTransitionMode.none:
                return false;
            case ModalTransitionMode.force:
                return true;
            default:
                return false;
            }
        });

        const open = ref<boolean>(!transition.value);

        watch(isOpen, (current) => {
            if (current != null) {
                open.value = current;
            }
        });

        if (transition.value) {
            onMounted(async () => {
                await nextTick();

                if (props.isOpen !== false) {
                    open.value = true;
                }
            });
        }

        return {
            slots,
            closeModal: props.emitClose || fallback ? () => emit('close') : modalRouterClose,
            defaultSlots,
            hasHeader: computed(() => props.showBack || props.showClose || props.title || slots.title),
            height,
            transition,
            open,
        };
    },
});

</script>
<style lang="scss" scoped>
    @import "../../styles/common";

    @include keyframes(modalSlide) {
        from {
            transform: scale(0.8);
        }

        to {
            transform: scale(1);
        }
    }

    .modal-scaffold-overlay {
        &.overlay {
            @include overlay-background;
        }

        overflow-y: hidden;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        outline: 0;
        border-radius: 0;

        &.modal-transition {
            &:not(.open) {
                display: none;
            }

            &.open {
                display: block;

                .modal-scaffold {
                    @include animation("modalSlide");
                }
            }
        }

        &:not(.modal-transition) {
            display: block;

            .modal-scaffold {
                scale: 1;
            }
        }
    }

    @include keyframes(modalSlide) {
        from {
            transform: scale(0.8);
        }

        to {
            transform: scale(1);
        }
    }

    .modal-header {
        height: $modal-header-height;
    }

    .modal-scaffold {
        background-color: $color-background;
        display: block;
        overflow: hidden;

        &.full-screen {
            height: 100vh;
            width: 100vw;
        }

        &.sm {
            height: 50vh;
            width: 50vw;
            margin: auto;
        }
    }

    .modal-scaffold-body {
        display: block;
        width: 100vw;
        position: relative;
        overflow-x: hidden;

        &.has-header {
            height: calc(100vh - $modal-header-height);
            overflow-y: auto;
        }

        &:not(.has-header) {
            height: 100vh;
            overflow-y: hidden;
        }
    }
</style>
