<template>
    <li :class="['menu-list-divider', {dense}]" />
</template>

<script lang="ts">export default { name: 'MenuListDivider' }; </script>
<script lang="ts" setup>
const props = defineProps<{ dense?: boolean }>();
</script>

<style lang="scss" scoped>
    .menu-list-divider {
        --menu-list-divider-color: #{$color-ink-200};

        &:not(.dense) {
            margin: 0.5rem 0;
        }

        text-decoration: none;
        user-select: none;
        border-bottom: 1px solid var(--menu-list-divider-color);
    }
</style>
