import {
    fallbackFormProvider, FormProvider, FormProviderKey, FormState,
} from '@/model/form/form-provider-types';
import {
    inject, Ref, toRef, toRefs,
} from 'vue';

/**
 * Injects the closts {@link FormProvider}, or a fallback if none exists.
 *
 * @param useDefault Whether to return a fallback.
 */
export function injectFormProvider(useDefault = true): FormProvider {
    return inject(FormProviderKey, useDefault ? fallbackFormProvider() : null);
}

export function injectFormProviderRefs(useDefault = true) {
    const { state } = injectFormProvider(useDefault);

    return toRefs(state);
}

export function injectFormProviderRef<K extends keyof FormState>(key: K): Ref<FormState[K]> {
    const { state } = injectFormProvider(true);

    return toRef(state, key);
}
