import { AuthState, defaultRoleState } from '@/store/auth/types';

export default <AuthState>{
    failed: false,
    hasEverLoggedIn: false,
    user: {},
    roles: defaultRoleState,
    session: {
        jwt: null,
        coreAppId: null,
        coachId: null,
    },
    accounts: null,
    userDetailsFetched: false,
};
