import { HostSystem } from '@/integration/host-system-api';
import { AppointmentTypeStore } from '@/integration/hosts/keap-web/stores/appointment-type-store';
import { Capabilities } from '@/integration/capabilities/capabilities';
import { launchPlayKeapWebFn } from '@/integration/hosts/keap-web/launch-play/launchPlayKeapWeb';
import { DefaultPlayHost } from '@/integration/default-host-system';
import { Capability } from '@/integration/capabilities/capability-keys';
import { navigateKeapWeb } from '@/integration/hosts/keap-web/navigate-keap-web';
import { ExportDestinationKey } from '@/play-editor/play.constants';
import { LaunchPlayMeta, NavigateMeta, NavigateType } from '@/integration/capabilities/capability-types';
import { AssetType, NavigateDestination } from '@/generated/play-api';

export const KeapWebHost = new DefaultPlayHost(HostSystem.KEAP_WEB, {
    name: 'Keap',
    initialize(appId: string) {
        const localCapabilities = Capabilities.of();

        const navigateCapabilityMeta = {
            handler: navigateKeapWeb(appId),
            meta: <NavigateMeta>{
                navType: NavigateType.external,
            },
        };

        localCapabilities.registerInfo(Capability.launchPlay, {
            handler: launchPlayKeapWebFn(appId),
            meta: <LaunchPlayMeta>{
                launchLabel: 'Create Easy Automation',
                exportRules: {
                    [AssetType.EMAIL]: ExportDestinationKey.simpleHtml,
                    [AssetType.SMS]: ExportDestinationKey.text,
                },
            },
        });
        localCapabilities.registerInfo(Capability.navigate(NavigateDestination.EASY_AUTOMATION), navigateCapabilityMeta);
        localCapabilities.registerInfo(Capability.navigate(NavigateDestination.LANDING_PAGE), navigateCapabilityMeta);

        return {
            localCapabilities,
            stores: [
                AppointmentTypeStore(appId),
            ],
        };
    },
});
