/* eslint-disable @typescript-eslint/no-explicit-any */

import { State } from '@/store/play-root-state';
import { ActionTree } from 'vuex';
import { KeyValues } from '@/types/core-types';
import { ConfigState } from '@/store/config/types';
import { LogSettings } from '@/shared/logging';

export default <ActionTree<ConfigState, State>>{
    SET_CONFIG_OVERRIDE: ({ commit }, { path, value }:{ path:string; value:LogSettings; }) => {
        const config = <KeyValues>{};
        let next = config;

        const parts = path.split('.');
        const last = parts.pop();

        for (const k of parts) {
            next[k] = {};
            next = next[k] as KeyValues;
        }

        next[last] = value;

        commit('MERGE_CONFIG_OVERRIDE', config);
    },
};
