export enum RouteName {
    compose = 'compose',
    play = 'play',
    coachPlay = 'coachPlay',
    coachRoot = 'coachRoot',
    coachAdmin = 'coachAdmin',
    coachTemplates = 'coachTemplates',
    coachTemplate = 'coachTemplate',
    clientPlaybookEditor = 'clientPlaybookEditor',
    questions = 'questions',
    plays = 'plays',
    embed = 'embed',
    playBookembed = 'playbookEmbed',
    app = 'app',
    playbook = 'playbook',
    coachPlaybook = 'coachPlaybook',
    frameTest = 'frameTest',
    mergeTest = 'mergeTest',
    loggedOut = 'loggedOut',
    accessDenied = 'access-denied',
    logout = 'logout',
    loggedOutEmbed = 'loggedOutEmbed',
    error = 'error',
    fallback = 'fallback',
}
