<script lang="ts">export default { name: 'AbsolutePlacement' }; </script>

<template>
    <div class="absolute-container" :class="[{ block }]">
        <div
            ref="reference"
            class="popover-reference"
        >
            <slot name="reference" />
        </div>

        <div
            ref="positioned"
            class="positioned"
            :class="[{ open: true }]"
        >
            <slot />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { createPopper, Instance, VirtualElement } from '@popperjs/core';
import {
    PopoverOffset,
    PopoverOffsetCalculation,
    PopoverPlacement,
    PopoverPosition, PopperOffset,
} from '@/play-editor/PopoverPosition';
import {
    computed, onMounted, ref, toRefs, watch,
} from 'vue';

/**
 * Places a widget in relation to a reference widget.  By default, the widget will be placed to the right of the
 * reference widget
 */

const props = withDefaults(defineProps<{
    offset?: PopoverOffset;

    /**
     * Position of the popover, top bottom left right top-start top-end right-start right-end bottom-start bottom-end left-start left-end.
     */
    position?: PopoverPosition;

    /**
     * Make popover reference a block instead of default inline-block
     */
    block?: boolean;
    /**
     * Fix popover to window - great for getting around overflow: hidden containers
     */
    positionFixed?: boolean;
}>(), {
    offset: () => ({ skidding: 0, distance: 8 }),
    position: PopoverPosition.rightStart,
    block: true,
});

const {
    positionFixed, position, offset,
} = toRefs(props);

const popperJs = ref(null as Instance);
const reference = ref();
const positioned = ref();

watch(position, (value) => {
    if (popperJs.value) {
        popperJs.value.setOptions({
            placement: value,
        });
    }
});

const popperOffset = computed(() => convertParamToPopper(offset.value));

function convertParamToPopper(offsetValue: PopoverOffset):PopperOffset {
    if (typeof offsetValue === 'function') {
        return (input: PopoverPlacement) => {
            const { distance, skidding } = (offsetValue as PopoverOffsetCalculation)(input);

            return [skidding, distance];
        };
    }

    return [offsetValue?.skidding ?? 0, offsetValue?.distance ?? 0];
}

function initializeDefaultPopper(): void {
    if (!popperJs.value) {
        popperJs.value = createNewPopperInstance(positioned.value as HTMLElement, position.value, popperOffset.value);
    }
}

function createNewPopperInstance(positioned: HTMLElement, position: PopoverPosition, offset: PopperOffset) {
    return createPopper(reference.value as VirtualElement, positioned, {
        placement: position,
        strategy: positionFixed.value ? 'fixed' : 'absolute',
        modifiers: [
            {
                name: 'eventListeners',
                options: {
                    scroll: true,
                    resize: true,
                },
            },
            {
                name: 'preventOverflow',
                options: {
                    tether: true,
                    boundary: 'clippingParents',
                },
            },

            {
                name: 'offset',
                options: {
                    offset,
                },
            },
        ],
    });
}

onMounted(() => {
    initializeDefaultPopper();
});

</script>

<style lang="scss" scoped>
    @import "~@infusionsoft/design-system/src/styles/common";

    $background-color: var(--popover-background-color, #{$popover-background-color});

    .popover-container {
        display: inline-block;
        position: relative;

        &.block {
            display: block;

            .popover-reference {
                display: block;
            }
        }

        &:not(.block) {
            &.inline {
                display: inline-block;
            }

            &:not(.inline) {
                display: inline-block;
            }
        }
    }

    .positioned {
        z-index: $zindex-popover;
    }
</style>
