/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { V1GoalMetricTypes } from '../models/V1GoalMetricTypes';
import type { V1IndustryTypes } from '../models/V1IndustryTypes';
import type { V1LcaPhases } from '../models/V1LcaPhases';
import type { V1PlaybookCategories } from '../models/V1PlaybookCategories';
import type { V1PlayProgresses } from '../models/V1PlayProgresses';
import type { V3NavigateDestinations } from '../models/V3NavigateDestinations';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LookupsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List GoalMetricTypes
     * Lists all goal metric types
     * @returns V1GoalMetricTypes Success
     * @throws ApiError
     */
    public listGoalMetricTypes(): CancelablePromise<V1GoalMetricTypes> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/lookups/goal-metric-types',
        });
    }

    /**
     * List IndustryTypes
     * Lists all industry types
     * @returns V1IndustryTypes Success
     * @throws ApiError
     */
    public listIndustryTypes(): CancelablePromise<V1IndustryTypes> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/lookups/industry-types',
        });
    }

    /**
     * List LcaPhases
     * Lists all LCA phases
     * @returns V1LcaPhases Success
     * @throws ApiError
     */
    public listLcaPhases(): CancelablePromise<V1LcaPhases> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/lookups/lca-phases',
        });
    }

    /**
     * List Navigation Destinations
     * Lists all navigate destinations
     * @returns V3NavigateDestinations Success
     * @throws ApiError
     */
    public listNavigationDestinations(): CancelablePromise<V3NavigateDestinations> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/lookups/navigate-destinations',
        });
    }

    /**
     * List PlayProgresses
     * Lists all play progress options
     * @returns V1PlayProgresses Success
     * @throws ApiError
     */
    public listPlayProgresses(): CancelablePromise<V1PlayProgresses> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/lookups/play-progresses',
        });
    }

    /**
     * List PlaybookCategories
     * Lists all Playbook categories
     * @returns V1PlaybookCategories Success
     * @throws ApiError
     */
    public listPlaybookCategories(): CancelablePromise<V1PlaybookCategories> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/lookups/playbook-categories',
        });
    }

}
