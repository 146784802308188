import moment, { Duration, unitOfTime } from 'moment';
import { TimingUnit } from '@/play-editor/play.constants';
import { MomentUnit } from '@/shared/date.utils';

export type EasyAutomationDuration = {
    count:number,
    unit: TimingUnit,
}

function convertMomentToKeap(momentUnit: unitOfTime.Base): TimingUnit {
    switch (momentUnit) {
    case 'month':
    case 'months':
    case 'M':
        return TimingUnit.MONTHS;
    case 'week':
    case 'weeks':
    case 'w':
        return TimingUnit.WEEKS;
    case 'day':
    case 'days':
    case 'd':
        return TimingUnit.DAYS;
    case 'hour':
    case 'hours':
    case 'h':
        return TimingUnit.HOURS;
    case 'minute':
    case 'minutes':
    case 'm':
        return TimingUnit.MINUTES;
    case 'second':
    case 'seconds':
    case 's':
    case 'millisecond':
    case 'milliseconds':
    case 'ms':
    case 'year':
    case 'years':
    case 'y':
    default:
        throw new Error('Years are not supporter');
    }
}

export function convertToAutomationDuration(duration:Duration|string): EasyAutomationDuration {
    const isoDuration = moment.duration(duration) ?? moment.duration();

    const momentUnit:unitOfTime.Base = Object.values(MomentUnit).find((u) => isoDuration.get(u) > 0) ?? MomentUnit.minutes;
    const unit = convertMomentToKeap(momentUnit);

    return { unit, count: isoDuration.get(momentUnit) };
}
