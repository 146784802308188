/* eslint-disable */
import '../types/core-ext';
import '@/shared/directives';
import {App, watchEffect} from 'vue';
import {State} from '@/store/play-root-state';

import axios from 'axios';
import {asyncBus, getBus} from '@/events';
import {setupLog} from '@/setup/setup-logger';
import {RouteName} from '@/router/route-names';
import {KeapIcon} from '@/generated/play-api';
import {Composer, I18n,} from 'vue-i18n';
import {env} from '@/env';
import * as FullStory from '@fullstory/browser';
import {Store} from 'vuex';


const log = setupLog('globals');

export function setupVueGlobals(app: App, i18n: I18n, store: Store<State>) {
    window.__KEAP__ = env;

    try {
        FullStory.init({
            orgId: '97PF1',
            devMode: env.isDev,
            debug: false,
        });
    } catch (e) {
        log.error('Error initializing FullStory', e);
    }

    watchEffect(() => {
        // Set fullStory user variables
        if (store.state.auth.user) {
            updateFsUser(store);
        }
    });

    Object.entries({
        $http: {
            get() {
                return axios;
            },
        },

        $i18nInstance: {
            get() {
                return i18n.global;
            },
        },

        $fullStory: {
            get() {
                return FullStory;
            },
        },

        KeapIcon: {
            get() {
                return KeapIcon;
            },
        },

        RouteName: {
            get() {
                return RouteName;
            },
        },

        $bus: {
            get() {
                return getBus();
            },
        },

        $asyncBus: {
            value: asyncBus,
            writable: false,
        },

        $register: {
            value(this, eventName: string, fn: ((...args: unknown[]) => unknown)) {
                asyncBus.addListener(eventName, fn);
                this.$once('hook:beforeDestroy', () => asyncBus.removeListener(eventName, fn));
            },
            writable: false,
        },
        $check: {
            value(...args: unknown[]) {
                return asyncBus.check.call(this, ...args);
            },
            writable: false,
        },
        $send:
        {
            value(this, ...args: unknown[]) {
                return asyncBus.send.call(this, ...args);
            },
            writable: false,
        },
    } as Record<string, PropertyDescriptor & ThisType<Record<string, any>>>).forEach(([key, definer]) => {
        try {
            Object.defineProperty(app.config.globalProperties, key, definer);
            log.info(` - global.${key}`);
        } catch (e) {
            log.error('Unable to define property', e);
        }
    });

    app.mixin({
        beforeCreate() {
            const instance = getCurrentInstance();
            /* istanbul ignore if */
            asserts(instance, "Should have an instance");
            const compI18n: Composer = useI18n(this.$options);
            const variablesToBind = [
                ['t', ['t', '$t', 'tc']],
                ['n', ['n', '$n']]
            ];
            variablesToBind.forEach(([prop, others]) => {
                for (const name of others) {
                    try {
                        if (canAssign(this, name)) {
                            this[name] = compI18n[prop as keyof Composer];
                        }
                    } catch (e) {
                        console.warn(`Error adding ${name} to ${this.$options.name}`, e);
                    }
                }
            })
        },

        unmounted() {
            const instance = getCurrentInstance();
            (i18n as any).__deleteInstance(instance);
        },
    });
}

function canAssign(self: any, propName: string) {
    if (Object.hasOwn(self, propName)) return false;
    return !Object.hasOwn(self.$?.setupState ?? {}, propName);
}

function updateFsUser(store: Store<State>): void {
    FullStory.setUserVars({
        displayName: `${store.state.auth.user?.givenName} ${store.state.auth.user?.familyName}`,
        email: store.state.auth.user?.username,
        playsRole: store.state.auth.roles.isAdmin ? 'Admin' : store.state.auth.roles.isCoachAdmin ? 'Coach Admin' : store.state.auth.roles.isCoach ? 'Coach' : 'None',
    });
}


