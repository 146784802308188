import state from './state';
import mutations from './mutations';
import { Module } from 'vuex';
import { State } from '@/store/play-root-state';
import { SuggestionsState } from '@/store/suggestions/types';

export default <Module<SuggestionsState, State>>{
    namespaced: true,
    state,
    mutations,
};
