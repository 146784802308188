export type CustomElementDefinitions = {
    [key:string]: CustomElementDefinition;
};

export type CustomElementDefinition = {
    attributes?: string[];
    inline?: boolean;
}

export const CUSTOM_ELEMENT_DEFINITIONS: CustomElementDefinitions = {
    'keap-form-template': { attributes: <string[]>[] },
    'keap-button-template': { attributes: <string[]>[], inline: true },
    'keap-field-template': { attributes: ['name', 'label'] },
    'keap-call-to-action': { attributes: ['type'], inline: true },
    'keap-user-signature': {},
    'keap-merge': { attributes: ['expression', 'edited'], inline: true },
    'keap-loop': { attributes: ['expression', 'class', 'id', 'var', 'for', 'var', 'body', 'loop-type', 'edited', 'loop-limit', 'loop-type'] },
    'keap-div': { attributes: ['id', 'class'] },
    'keap-ol': { attributes: ['id', 'class'] },
    'keap-ul': { attributes: ['id', 'class'] },
    'keap-form': { attributes: ['id', 'class'] },
    'keap-form-field': { attributes: ['id', 'class'] },
    'keap-button': { attributes: ['id', 'class'] },
    'keap-hero': { attributes: ['id', 'class'] },
    'keap-graphic': { attributes: ['id', 'name', 'class'] },
};
