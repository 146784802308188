/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormProvider, FormState } from '@/model/form/form-provider-types';
import { ModelProperty, PropertyPrimitiveType } from '@/client/play-client';
import { ArrayEvent, ArrayEventPayload, metaKeys } from '@/play-editor/play.constants';
import { IndexedItem, reorderMeta } from '@/play-editor/suggestion.util';
import asserts from '@/shared/asserts';
import { provideDataScope } from '@/model/form/DataScope';
import { reactivePick } from '@vueuse/core';
import { Dict, KeyValues, Struct } from '@/types/core-types';
import { watchCalculated } from '@/play-editor/mixins/v3/computedReactive';
import { store } from '@/store';
import cloneDeep from 'lodash/cloneDeep';
import { ValidationErrors } from '@/play-editor/provider/vue3/provider-types';
import { reactive, watch } from 'vue';
import range from 'lodash/range';
import { verifyModelRawData } from '@/play-editor/model/model.utils';

export function setupFormProviderState(self: FormProvider, initialAnswers: KeyValues) {
    const { source } = self;

    /// 2. Set up state
    const state = reactive(<FormState>cloneDeep({
        meta: {},
        focusedQuestion: null,
        answers: initialAnswers,
        expandedRefs: {},
        validationErrors: {},
        validationErrorsByPage: {},
    }));

    self.scope = provideDataScope(source.name, reactivePick(state, 'answers', 'expandedRefs') as unknown as Dict<KeyValues>);

    watch(() => cloneDeep(state.answers), (savedData) => {
        verifyModelRawData(savedData);
    }, { immediate: true });

    watchCalculated(state, {
        isModelValid() {
            for (const property of self.properties.keySet()) {
                if (state.validationErrors[property]?.find((e) => e.error == null || e.error === true)) {
                    return false;
                }
            }

            return true;
        },
        legacyQuestions: () => store.state.flags.legacyQuestions,
        validationErrorsByPage() {
            const errorsByPage = <Dict<ValidationErrors>>{};

            const validationErrorValue = cloneDeep(state.validationErrors);

            const addErrors = (pageNumber: number, name: string) => {
                const page = `${pageNumber}`;
                const refErrors = validationErrorValue[name] ?? [];
                const removeWarnings = refErrors.filter((e: any) => e.error);

                if (removeWarnings.length > 0) {
                    errorsByPage[page] ??= <ValidationErrors>{};
                    errorsByPage[page][name] = refErrors;
                }
            };

            if (Object.keys(validationErrorValue).length > 0) {
                for (const { name } of self.refs.valueSet()) {
                    addErrors(-1, name);
                }

                self.groups.forEach((group, i) => {
                    for (const { name } of group.properties) {
                        addErrors(i, name);
                    }
                });
            }

            return errorsByPage;
        },

        isValidByPage() {
            return range(-1, self.groups.length).map((e) => e.toString()).reduce((prev, pageKey) => {
                const pageErrors = state.validationErrorsByPage[pageKey] ?? {};

                prev[pageKey] = pageErrors.valueSet().find((e: unknown[]) => e.length > 0) == null;

                return prev;
            }, <Struct<string, boolean>>{});
        },
    }, { deep: true });
    self.state = state;

    // Runs when a question order or index changes, and allows us to sync up our meta
    self.syncSuggestionsMeta = (question: ModelProperty, change: ArrayEvent, payload: ArrayEventPayload) => {
        self.mutateMeta(question, metaKeys.suggestions, (existing: IndexedItem[]) => {
            return reorderMeta(existing ?? [], change, payload);
        });

        return Promise.resolve();
        // return self.saveAnswers();
    };

    self.acceptSuggestion = (question: ModelProperty, suggestion: any, resultId: string) => {
        asserts(resultId, 'Must have resultId');

        if (question.type.base === PropertyPrimitiveType.ARRAY) {
            const answerArray = self.answerAsArray(question);

            return self.updateAnswer(question, [...answerArray, suggestion], {
                [metaKeys.suggestions]: (sugg: IndexedItem[]) => [
                    ...(sugg ?? []),
                    {
                        index: answerArray.length,
                        item: suggestion,
                        resultId,
                    },
                ],
            });
        }

        return self.updateAnswer(question, suggestion, {
            [metaKeys.suggestions]: () => [{
                index: 0,
                item: suggestion,
                resultId,
            }],
        });
    };
}
