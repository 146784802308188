import { mapState } from 'vuex';
import { isExperimental } from '@/shared/utils/env.utils';
import { AppIdProviderKey } from '@/play-editor/provider/provide-app-id';
import { PlayService } from '@/play-editor/play-service';

export default {
    data() {
        return {
            reloadDelay: 2000,
            isConnecting: false,
        };
    },

    inject: {
        appIdProvider: {
            from: AppIdProviderKey,
            default: null,
        },
    },

    computed: {
        ...mapState({
            casId: ({ auth }) => auth.user.casId,
            appIdFromVuex: ({ auth }) => auth.session.coreAppId,
            coachId: ({ auth }) => auth.session.coachId,
        }),

        appId() {
            return this.appIdProvider?.value || this.appIdFromVuex;
        },

        playService() {
            return PlayService({
                appId: this.appId,
            });
        },

        experimental() {
            return isExperimental();
        },
    },
};
