import { KeyValues } from '@/types/core-types';

export type LogFactory = (name?: string, appendName?: boolean) => Logger;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LogFunction = (...args: any[]) => void;

export type Logger = {
    name: string;
    simpleName: string;
    debug: LogFunction;
    info: LogFunction;
    warn: LogFunction;
    severe: LogFunction;
    error: LogFunction;
    child: LogFactory;
    settings: LevelSettings;
    configure(settings?: LevelSettings): void;
};

export type LevelSettings = Record<string, unknown> & {
    level?: level | null;
    bg?: string | null;
    color?: string | null;
    style?: KeyValues;
};

export type LogSettings = {
    isDevelopment?: boolean | null;
    levels?: LevelSettings;
}

export enum level {
    debug = 0,
    info = 1,
    warn = 2,
    severe = 3,
    off = 4
}

export const levels = level;
