import { PlayModelDataStore } from '@/integration/datastore/data-store-play-model';
import { ModelKeyName, ModelKeyOrName, ModelKeys } from '@/integration/datastore/model-keys';
import { DataStore } from '@/integration/datastore/data-store-api';
import asserts from '@/shared/asserts';
import { dict } from '@/types/core-types';

type TenantDataStoreRegistry = { [key in ModelKeyName]?: DataStore};
let storesByTenantId = dict<TenantDataStoreRegistry>();

function registerStore(store: DataStore) {
    asserts(store.appId != null, 'Store must have a valid appId');
    getRegistryForAppId(store.appId)[store.modelType.key] = store;
}

function getRegistryForAppId(appId:string):TenantDataStoreRegistry {
    if (!Object.hasOwn(storesByTenantId, appId)) {
        storesByTenantId[appId] = {};
    }

    return storesByTenantId[appId];
}

function getStore(appId:string, key: ModelKeyOrName):DataStore|null {
    asserts(appId, 'Must provide valid appId');
    const k = ModelKeys.of(key);
    let found = getRegistryForAppId(appId)[k.key];

    if (!found) {
        found = PlayModelDataStore(appId, k);
        registerStore(found);
    }

    return found;
}

function reset() {
    storesByTenantId = dict();
}

export const DataStoreRegistry = {
    registerStore, getStore, reset,
};
