import { PropertyValidation, PropertyValidator, SizeValidationType } from '@/generated/play-api';
import cloneDeep from 'lodash/cloneDeep';
import { ValidationErrors } from '@/play-editor/provider/vue3/provider-types';

export function maxSize(validators: PropertyValidator[], orElse?: number): number {
    return validators?.find((e) => e.size?.sizeType === SizeValidationType.MAX)?.size?.size ?? orElse;
}

export function minSize(validators: PropertyValidator[], orElse?: number): number {
    return validators?.find((e) => e.size?.sizeType === SizeValidationType.MIN)?.size?.size ?? orElse;
}

export function required(validators: PropertyValidator[]) {
    return validators?.find((e) => e.required === true) != null;
}

export function QuestionValidation(start?:ValidationErrors) {
    const internalErrors = start ?? <ValidationErrors>{};

    return {
        get value() {
            return internalErrors;
        },
        clearError(propertyId:string) {
            internalErrors[propertyId] = [];
        },

        addError(propertyId: string, error: PropertyValidation | PropertyValidation[]) {
            const append = (errors: PropertyValidation[]) => {
                internalErrors[propertyId] = cloneDeep([...(internalErrors[propertyId] ?? []), ...errors]);
            };

            if (Array.isArray(error)) {
                append(error);
            } else if (typeof error === 'string') {
                append([{
                    error,
                    propertyId,
                    code: 'unknown',
                    message: error,
                    additionalHelp: null,
                }]);
            } else if (error) {
                append([error]);
            }
        },

        setError(propertyId: string, error?: PropertyValidation | PropertyValidation[]) {
            if (!error) {
                delete internalErrors[propertyId];
            } else {
                internalErrors[propertyId] = Array.isArray(error) ? error : [error];
            }
        },

        get(propertyId: string): PropertyValidation[] {
            return internalErrors[propertyId] || [];
        },
    };
}
