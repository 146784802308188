/* eslint-disable @typescript-eslint/no-explicit-any */
import debounce from 'lodash/debounce';
import { Completer } from '@/shared/Completer';
import { logger } from '@/shared/logging';
import { KeyValues } from '@/types/core-types';
import { mergeDefaults } from '@/shared/type.utils';

const log = logger('util.async-debounce');

/**
 * The normal debounce has an issue where it sometimes returns undefined, instead of a Promise.
 *
 * This approach ensures that each invocation will be completed as soon as the next invocation
 * is done.
 *
 * @param fn The original function
 * @param debounceTime The amount of time to debounce
 * @param settings The settings to use
 */
export function asyncDebounce<I extends any[], T>(fn: (...params:I)=> Promise<T>, debounceTime:number, settings?: KeyValues): ((...params:I)=>Promise<T>)&KeyValues {
    settings = mergeDefaults(settings ?? {}, {
        leading: true,
    });
    settings.trailing = true;
    let pending = <Completer<T>[]>[];

    // @ts-ignore Ignore this
    const debounced = debounce((...params) => {
        // Capture all pending items
        const consumed = [...pending];

        /// Clear out consumed list after execution
        pending = [];

        fn(...params).then((result) => {
            consumed.forEach((completer) => completer.complete(result));
        }).catch((error) => {
            consumed.forEach((completer) => completer.error(error));
        });
        // @ts-ignore Ignore this
    }, debounceTime, settings);

    const invoker = (...params:I) => {
        const completer = Completer.create<T>();

        pending.push(completer);

        if (pending.length > 5) {
            log.info(`asyncDebounce has ${pending.length} observers`, fn, pending);
        } else {
            log.debug(`asyncDebounce has ${pending.length} observers`, fn, pending);
        }
        void debounced(...params);

        return completer.done;
    };

    invoker.getPending = () => pending;

    return invoker;
}
