/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { PlayApi } from '@/play-editor/play-api';
import { AiResponse, GenerateSuggestionsRequest } from '@/generated/play-api';
import { playClientTs } from '@/client/play-client';
import debounce from 'lodash/debounce';

export async function generateSuggestions(
    endpoint:string,
    params: GenerateSuggestionsRequest,
): Promise<AiResponse> {
    const { data } = await axios.post(PlayApi.url(`${endpoint}/suggestions`), params);

    return data as AiResponse;
}

function doAddAcceptInteraction(appId: string, resultId: string, acceptedItem: any, change?: any) {
    return playClientTs.aiOperation.recordAiInteraction(
        appId,
        resultId,
        {
            interactionType: 'accept',
            interactionDetails: {
                item: acceptedItem,
                change,
            },

        },
    );
}

export const addAcceptInteraction = debounce(doAddAcceptInteraction, 2000);

export function addRejectInteraction(appId:string, resultId:string, rejectedItem:any): Promise<unknown> {
    return playClientTs.aiOperation.recordAiInteraction(
        appId,
        resultId,
        {
            interactionType: 'reject',
            interactionDetails: {
                item: rejectedItem,
            },
        },
    );
}

export function addRefreshInteraction(appId: string, resultId: string, rejectedItems: string[]) {
    return playClientTs.aiOperation.recordAiInteraction(
        appId,
        resultId,
        {
            interactionType: 'refresh',
            interactionDetails: {
                items: rejectedItems,
            },
        },
    );
}
