import {
    defaultSuggestionGenerator,
    FormSourceType,
    ModelFormSource,
    PlayFormSource,
    PropertyProvide,
    SuggestionProviderKey,
    SuggestionsProvider,
} from '@/model/form/form-provider-types';

import { provide } from 'vue';
import { playClientTs, PropertyPrimitiveType } from '@/client/play-client';
import { illegalState } from '@/shared/shared.utils';
import { suggestionSetup } from '@/play-editor/mixins/v3/suggestionsMixin';
import { metaKeys, ProviderName } from '@/play-editor/play.constants';
import { useCore } from '@/shared/shared-providers';
import { FallbackSuggestionsProvide } from '@/play-editor/mixins/questionMixin';
import asserts from '@/shared/asserts';
import { toArray } from '@/shared/type.utils';
import { IndexedItem } from '@/play-editor/suggestion.util';
import { SuggestionItem } from '@/model/form/SuggestionItem';

export function providePropertySuggestions<T>(prop: PropertyProvide<T>): SuggestionsProvider {
    const { form, name } = prop;
    const { appId } = form;

    const generatesSuggestions = prop.property.suggestionGenerator != null;

    if (!generatesSuggestions) {
        return FallbackSuggestionsProvide;
    }

    const { dependentFields, numResults } = prop.property.suggestionGenerator ?? defaultSuggestionGenerator(prop.property);

    const { store } = useCore();
    const suggestionProvider = suggestionSetup(
        {
            generatesSuggestions,
            initialResults: [],
            excludes: [],
            suggestionKey: `${form.source.name}.${prop.name}`,
            onAcceptSuggestion(suggestion: SuggestionItem) {
                const { resultId, item } = suggestion;

                asserts(resultId, 'Must have resultId');

                if (prop.property.type.base === PropertyPrimitiveType.ARRAY) {
                    const answerArray = toArray(prop.state.value);

                    void prop.updateAnswer([...answerArray, item] as T, {
                        [metaKeys.suggestions]: (sugg: IndexedItem[]) => [
                            ...(sugg ?? []),
                            {
                                index: answerArray.length,
                                item,
                                resultId,
                            },
                        ],
                    });
                } else {
                    void prop.updateAnswer(item as T, {
                        [metaKeys.suggestions]: () => [{
                            index: 0,
                            item,
                            resultId,
                        }],
                    });
                }
            },
            generateSuggestions(generateParams) {
                const self = form;
                const {
                    attemptCount, skipExtraTokenization,
                    expectedResultCount,
                } = generateParams;

                switch (self.sourceType) {
                case FormSourceType.play:
                    // eslint-disable-next-line no-case-declarations
                    const sc = self.source as PlayFormSource;

                    return playClientTs.playTemplateV2.generateSuggestionsV2(
                        appId,
                        sc.playTemplateId,
                        prop.name,
                        {
                            playId: form.source.mergePlayId,
                            answers: self.scope.flatten(),
                            adjustments: {
                                // Snake case is because these are passed through the to the python service
                                temperature_adjust: attemptCount * 0.1,
                            },
                            attemptCount,
                            initialResults: [],
                            skipExtraTokenization,
                            expectedResultCount,
                        },
                    );

                case FormSourceType.modelRef:
                    // eslint-disable-next-line no-case-declarations
                    const ms = self.source as ModelFormSource;

                    return playClientTs.modelDefinition.generateSuggestionsForModel(
                        appId,
                        ms.refType.category,
                        ms.refType.name,
                        prop.name,
                        {
                            playId: form.source.mergePlayId,
                            answers: self.scope.flatten(),
                            adjustments: {},
                            attemptCount: 0,
                            initialResults: [],
                            skipExtraTokenization: false,
                            expectedResultCount,
                        },
                    );

                default:
                    return illegalState('Invalid state; should be model or play');
                }
            },
            dependentFields: dependentFields.map((path:string[]) => path.join('.')),
            expectedResultCount: numResults,
            questionName: name,
        },
        form.scope,
        store,
    );

    provide(SuggestionProviderKey, suggestionProvider);
    // Add legacy provider
    provide(ProviderName.suggestionsProvider, suggestionProvider);

    return suggestionProvider;
}
