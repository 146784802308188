// noinspection UnnecessaryLocalVariableJS

import { ModelKey, ModelKeys } from '@/integration/datastore/model-keys';
import { computed } from 'vue';
import { RefType } from '@/generated/play-api';
import { Dict } from '@/types/core-types';
import { PreparedModel } from '@/integration/datastore/base-types';
import {
    ComposeParams, injectModelService, ModelTypeService,
} from '@/play-editor/model/TenantModelService';
import { usePageStackNavigator } from '@/shared/components/navigation-inject';
import { useLog } from '@/shared/shared-providers';
import asserts from '@/shared/asserts';

/**
 * Provides convenience methods for working with a single model.
 */
export function modelMixin(appId:string, refType: RefType|ModelKey, component: unknown): ModelTypeService {
    asserts(refType != null, 'Must have a refType');
    asserts(appId != null, 'Must have a appId');

    const log = useLog('model');
    const modelService = injectModelService(appId);

    const stackNavigator = usePageStackNavigator();

    const refTypeObj = ModelKeys.of(refType);
    const refTypeKey = refTypeObj.key;

    const computedProps = {
        modelsById: computed<Dict<PreparedModel>>(() => {
            return modelService.models[refTypeKey] ?? <Dict<PreparedModel>>{};
        }),
    };

    function loadModel(id: string): Promise<PreparedModel> {
        return modelService.loadModel(refTypeKey, id);
    }

    const modelList = modelService.loadList(refTypeKey);
    const modelSchema = modelService.loadModelSchema(refTypeKey);

    function deleteModel(modelId: string):Promise<boolean> {
        return modelService.deleteModel(refTypeKey, modelId);
    }

    function searchList(searchTerm: string) {
        return modelService.searchList(refTypeKey, searchTerm);
    }

    async function composeModel(params: ComposeParams): Promise<PreparedModel> {
        const { dataScope, pageTitle, pageDescription } = params ?? {};

        if (pageTitle || pageDescription) {
            log.info('Got page details', { pageTitle, pageDescription });
        }

        const { category: modelCategory, name: modelName } = ModelKeys.of(refTypeKey);

        try {
            const result = await stackNavigator.push<PreparedModel>(() => ({
                key: `${refTypeKey}`,
                component,
                params: {
                    modelCategory,
                    modelName,
                    parentScope: dataScope,
                    // pageTitle,
                    // pageDescription,
                },
            }));

            return result;
        } catch (e) {
            log.severe('Error creating', e);
            throw e;
        }
    }

    async function editModel(modelId: string, params: ComposeParams): Promise<PreparedModel> {
        const { pageTitle, pageDescription, dataScope } = params ?? {};
        const { category: modelCategory, name: modelName } = ModelKeys.of(refTypeKey);

        try {
            const result = await stackNavigator.push<PreparedModel>(() => ({
                key: `${refTypeKey}:${modelId}`,
                component,
                params: {
                    modelCategory,
                    modelName,
                    modelId,
                    pageTitle,
                    pageDescription,
                    parentScope: dataScope,
                },
            }));

            return result;
        } catch (e) {
            log.severe('Error editing model:', e);
            throw e;
        }
    }

    return <ModelTypeService>{
        saveModel(modelId: string | null, data: PreparedModel): Promise<PreparedModel> {
            if (modelId == null) {
                return modelService.createModel(refTypeKey, data);
            }

            return modelService.updateModel(refTypeKey, modelId, data);
        },
        modelListState: modelList,
        modelSchemaState: modelSchema,
        refTypeKey,
        refTypeObj,
        loadModel,
        editModel,
        deleteModel,
        composeModel,
        ...computedProps,
        searchList,
        isReady: computed(() => modelSchema.isReady.value && modelList.isReady.value),
    };
}
