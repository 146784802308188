/* eslint-disable no-void */
import { MAX_AI_ATTEMPTS, PlayEventTypes } from '@/play-editor/play.constants';
import asserts from '@/shared/asserts';
import { amplitude } from '@/analytics/amplitude';
import { reactive } from 'vue';
import { logger } from '@/shared/logging';
import { addAcceptInteraction, addRefreshInteraction, addRejectInteraction } from '@/client/play-client-ext';
import { GenerateSuggestions, SuggestionsData, SuggestionsProvider } from '@/model/form/form-provider-types';
import { toArray } from '@/shared/type.utils';
import { watchCalculated } from '@/play-editor/mixins/v3/computedReactive';
import cloneDeep from 'lodash/cloneDeep';
import { DataScopeData } from '@/model/form/DataScope';
import { Store } from 'vuex';
import { State } from '@/store/play-root-state';
import { SuggestionItem } from '@/model/form/SuggestionItem';
import { KeyValues } from '@/types/core-types';
import { useAppId } from '@/play-editor/provider/provide-app-id';

export type SuggestionSetupProps = {
    /**
     * A unique key to identify the scope of these suggestions.
     */
    suggestionKey: string;
    playId?: string;
    questionName?: string;
    generatesSuggestions: boolean;
    excludes: string | string[];
    dependentFields: string[];
    /**
     * This invokes the actual endpoint - Different suggestion contexts may hit different backend endpoints
     */
    generateSuggestions: GenerateSuggestions;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initialResults: any[];
    expectedResultCount: number;
    onAcceptSuggestion(item: SuggestionItem):void;
}

/**
 * A more generic suggestions mixin that can handle contexts outside of properties, such as within the content.
 *
 * Typically, you'll want to use one of the specific wrappers, eg. propertySuggestionMixin
 *
 * This function does not register any providers.
 */
export function suggestionSetup(
    {
        generatesSuggestions,
        dependentFields,
        questionName,
        excludes,
        playId,
        expectedResultCount,
        generateSuggestions: generateSuggestionStrategy,
        initialResults,
        suggestionKey,
        onAcceptSuggestion,
    }: SuggestionSetupProps,
    dataScope: DataScopeData,
    store: Store<State>,
): SuggestionsProvider {
    const log = logger('suggestionProvider');
    const appId = useAppId();

    let initialSuggestions: SuggestionItem[];

    if (!suggestionKey) {
        log.warn('Missing suggestionKey:  caching will not work for', { playId, questionName });
        initialSuggestions = [];
    } else {
        log.info(`Initializing cache for ${suggestionKey}`);
        initialSuggestions = store.state.suggestions.latestSuggestions[suggestionKey] ?? [];
    }

    const data = reactive(<SuggestionsData>{
        generatesSuggestions,
        suggestions: cloneDeep(initialSuggestions),
        generating: false,
        replacing: false,
        errors: [],
        numAttempts: 0,
        expectedResultCount,
        minimumResults: Math.min(3, Math.ceil(expectedResultCount * 0.5)),
        visibleSuggestions: null,
        error: null,
        missingFields: null,
    });

    watchCalculated(data, {
        /**
             * Finds any fields that are not filled out yet, but that are required for this AI operation.
             */
        missingFields() {
            const ourDependentFields = dependentFields ?? [];

            const foundFalues = <KeyValues>{};
            const missing = ourDependentFields.filter((fieldPath) => {
                const foundValue = dataScope.get(fieldPath);

                foundFalues[fieldPath] = foundValue;

                return foundValue == null;
            });

            if (missing.length > 0) {
                log.warn(`${questionName}: missing`, missing, dataScope);
            } else if (dependentFields?.length > 0) {
                log.info(`${questionName}: ready for suggestions`, foundFalues, dataScope);
            }

            return missing;
        },

        error() {
            return data.errors.length > 3 ? 'There were some issues fetching results' : null;
        },

        visibleSuggestions() {
            return data.suggestions.slice(0, expectedResultCount);
        },

    }, {
        log: log.child('watch', true),
        deep: true,
    });

    function rejectSuggestion(suggestion: SuggestionItem): Promise<unknown> {
        const { resultId, item } = suggestion;

        asserts(resultId && item, 'Must be valid suggestion!');

        removeSuggestion(suggestion);

        if (data.suggestions.length < data.minimumResults) {
            // noinspection JSIgnoredPromiseFromCall
            // eslint-disable-next-line no-void
            void generateSuggestions(false, 'Auto refreshed after rejecting');
        }

        // eslint-disable-next-line no-void
        return addRejectInteraction(
            appId.value,
            resultId,
            item,
        );
    }

    async function generateSuggestions(replace = true, eventSource = 'Unknown') {
        if (replace) {
            const groupedByResultId = data.visibleSuggestions.groupBy(({ resultId }) => resultId);

            for (const [resultId, rejectedItems] of groupedByResultId.entrySet()) {
                // eslint-disable-next-line no-void
                void addRefreshInteraction(
                    appId.value,
                    resultId as string,
                    rejectedItems?.map(({ item }) => item) ?? [],
                );
            }
        }

        if (!data.generating) {
            amplitude.logEvent(PlayEventTypes.PLAY_SUGGESTION_REQUESTED, {
                // 'Play Template ID': this.playTemplate.id,
                // 'Play Template Name': this.playTemplate.title,
                'Question Name': questionName,
                'Source of Request': eventSource,
            });
            data.generating = true;
            data.numAttempts = 0;
            data.replacing = replace;
            data.errors = [];
            await fetchSuggestions();
        }
    }

    function stopSuggestions() {
        data.numAttempts = 0;
        data.generating = false;
    }

    async function fetchSuggestions() {
        if (data.numAttempts >= MAX_AI_ATTEMPTS) {
            log.warn(
                'Stopping before we got the expected results: wanted',
                expectedResultCount,
                ' got: ',
                data.suggestions.length,
            );
            stopSuggestions();
        } else {
            try {
                if (data.replacing) {
                    data.replacing = false;
                    const withoutVisible = data.suggestions.slice(expectedResultCount - 1);

                    updateSuggestions(withoutVisible);

                    data.replacing = false;
                }

                const response = await generateSuggestionStrategy({
                    initialResults,
                    expectedResultCount,
                    attemptCount: data.numAttempts,
                    skipExtraTokenization: false,
                });

                const suggestions = toArray(response.responseData).map((item) => ({
                    item,
                    resultId: response.resultId,
                }));

                // Include the resultId with each suggestion item.
                addMoreSuggestions(suggestions);
            } catch (e) {
                log.severe('Error running operation - trying again', e);
                data.errors.push(e);
            } finally {
                if (data.suggestions.length < expectedResultCount) {
                    data.numAttempts += 1;

                    // noinspection ES6MissingAwait
                    void fetchSuggestions();
                } else {
                    stopSuggestions();
                }
            }
        }
    }

    /**
     * @param newValues The suggestions
     */
    function addMoreSuggestions(newValues: SuggestionItem[]) {
        newValues ??= [];

        if (newValues.length > 0) {
            updateSuggestions([...data.suggestions, ...newValues]);
        }
    }

    function updateSuggestions(suggestions?: SuggestionItem[]) {
        suggestions = suggestions ?? [];

        // Ensure the list is distinct by using the item property (ignoring resultId)
        const newSuggestionsValue = cloneDeep(suggestions.without(({ item }) => !excludes?.includes(item)).distinct('item'));

        data.suggestions = newSuggestionsValue;

        if (suggestionKey) {
            store.commit('suggestions/SET_SUGGESTIONS', {
                key: suggestionKey,
                suggestions: newSuggestionsValue,
            });
        }
        log.info('Updated suggestions with: ', data.suggestions);
    }

    function acceptSuggestion(suggestion: SuggestionItem) {
        const { item, resultId } = suggestion;

        asserts(item && resultId, 'Must have both components');
        void addAcceptInteraction(appId.value, resultId, item);

        removeSuggestion(suggestion);

        if (data.suggestions.length < data.minimumResults) {
            // eslint-disable-next-line no-void
            void generateSuggestions(false, 'Auto refreshed after accepting');
        }

        onAcceptSuggestion(suggestion);
    }

    function removeSuggestion(removeItem: SuggestionItem) {
        updateSuggestions(data.suggestions.filter(({ item }) => item !== removeItem.item));
    }

    return <SuggestionsProvider>{
        data,

        rejectSuggestion,
        removeSuggestion,
        acceptSuggestion,
        generateSuggestions,
    };
}
