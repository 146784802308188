/* eslint-disable @typescript-eslint/no-explicit-any */
import { Emit } from '@/types/core-types';
import {
    computed, reactive, Ref, UnwrapNestedRefs, WritableComputedRef,
} from 'vue';
import {
    cloneFnJSON, get, MaybeRef, UseVModelOptions,
} from '@vueuse/core';
import cloneDeep from 'lodash/cloneDeep';
import noop from 'lodash/noop';
import {
    getCurrentInstance, isVue2, ref, UnwrapRef, watch,
} from 'vue-demi';
import { isDef } from '@vueuse/shared';
import isEqual from 'lodash/isEqual';
import { logger, Logger } from '@/shared/logging';
import { fillModel, ModelMetaSchema } from '@/client/play-client';
import kebabCase from 'lodash/kebabCase';
import isFunction from 'lodash/isFunction';

export type VModelOptions<V, Passive extends boolean=false> = UseVModelOptions<V, Passive> & {
    defaultValue?: V;

}

export const DEFAULT_EVENT_NAME = 'update:model-value';
export const DEFAULT_PROP_NAME = 'modelValue';

export type VModelProps<V> = {
    modelValue?: V;
}

/**
 * A backwards compatible clone for vueuse [useVModel] that emits events as Vue2 expects them.
 * @param props The component props
 * @param key The key in the value map
 * @param emit An emitter
 * @param ext
 */
// eslint-disable-next-line default-param-last
export function useVModel2<V, Passive extends boolean=false>(props: VModelProps<V>, options?: VModelOptions<V, Passive>): Ref<V> {
    const {
        passive = true, deep = true, defaultValue, eventName: evtName,
    } = options ?? {};

    const eventName = evtName || DEFAULT_EVENT_NAME;

    return useVModel<VModelProps<V>, 'modelValue', 'update:model-value', Passive>(props, 'modelValue', {
        deep,
        eventName,
        defaultValue,
        clone: cloneDeep,
        passive: passive as Passive,
    }) as Ref<V>;
}

export type UseVModelOptionsWithEq<T, Name extends string, Passive extends boolean=false> = UseVModelOptions<T, Passive> & {
    equality?: (a: T, b: T) => boolean;
    emit?: Emit<Name>;
    schema?: ModelMetaSchema;
    onUpdate?: (newValue: T, oldValue: T) => void;
}

function getEmitter(emit: Emit<string>, vnode: any, name: string): (value?: any) => void {
    emit ??= (vnode.$emit || vnode.emit).bind(vnode);

    if (!emit) {
        throw new Error(`No emitter could be extracted from ${vnode}`);
    }

    return (...value: unknown[]) => emit(name, ...value);
}

const log = logger('VModel');

export function syncProperties<T extends object>(source: T, target: T, l?: Logger) {
    const allKeys = [...source.keySet(), ...target.keySet()].distinct() as (keyof T)[];
    const lg = (l ?? log);

    lg.info(`Merging ${allKeys}`);

    for (const key of allKeys) {
        const t = target[key];
        const s = source[key];

        if (!isEqual(t, s)) {
            lg.debug(` - ${String(key)}`, { oldValue: t, newValue: s });
            vset(target, key as string, s);
        }
    }
}

/**
 * An alternative to vueuse that handles deep equality and that doesn't double-emit for deep changes
 *
 * @param props
 * @param key
 * @param options
 */
export function useReactiveVModel<P extends object, K extends keyof P, Name extends string, Passive extends boolean=false>(props: MaybeRef<P>|Readonly<P>, key?: K, options?: UseVModelOptionsWithEq<P[K], Name, Passive>): UnwrapNestedRefs<P[K]> {
    const {
        clone = cloneDeep,
        deep = false,
        defaultValue,
        equality = isEqual,
        emit,
        schema,
        onUpdate = noop,
    } = options ?? {};

    let { eventName } = options ?? {};

    const vm = getCurrentInstance();

    if (!key) {
        if (isVue2) {
            // eslint-disable-next-line no-cond-assign
            const modelOptions = vm?.proxy?.$options?.model;

            key = modelOptions?.value || 'value';
            eventName ??= modelOptions?.event || 'input' as Name;
        } else {
            key = 'modelValue' as K;
        }
    }
    eventName ??= (isVue2 ? 'input' : `update:${kebabCase(key.toString())}`);

    const _emit = getEmitter(emit as Emit<string>, vm.proxy, eventName);

    const cloneFn = (val: P[K]) => {
        return (!clone ? val : isFunction(clone) ? clone(val) : cloneFnJSON(val));
    };
    const getValue = () => (isDef(get(props)[key]) ? cloneFn(get(props)[key]) : defaultValue);

    const initialValue = getValue();
    const proxy = reactive({
        ...(fillModel(schema)),
        ...(defaultValue ?? {}),
        ...(initialValue as object),
    }) as UnwrapNestedRefs<P[K]>;

    let updating = false;

    watch(() => cloneDeep(get(props)[key]), (v) => {
        updating = true;
        syncProperties(v as object, proxy as object);
        updating = false;
    });

    watch(() => cloneDeep(proxy), (v) => {
        if (!updating && !equality(v, get(props)[key])) {
            onUpdate(v, get(props)[key]);

            _emit(v);
        }
    }, { deep });

    return proxy;
}

export function syncReactive<P extends object, Name extends string>(props: MaybeRef<P>, options?: UseVModelOptionsWithEq<P, Name>): UnwrapNestedRefs<P> {
    const {
        clone = cloneDeep,
        deep = false,
        defaultValue,
        equality = isEqual,
        schema,
        onUpdate = noop,
    } = options ?? {};

    const cloneFn = (val: P) => {
        return (!clone ? val : isFunction(clone) ? clone(val) : cloneFnJSON(val));
    };
    const getValue = () => (isDef(get(props)) ? cloneFn(get(props)) : defaultValue);

    const initialValue = getValue();
    const proxy = reactive({
        ...(fillModel(schema)),
        ...(defaultValue ?? {}),
        ...(initialValue as object),
    }) as UnwrapNestedRefs<P>;

    let updating = false;

    watch(() => cloneDeep(get(props)), (v) => {
        updating = true;
        syncProperties(v as object, proxy as object);
        updating = false;
    });

    watch(() => cloneDeep(proxy), (v) => {
        if (!updating && !equality(v, get(props))) {
            onUpdate(v, get(props));
        }
    }, { deep });

    return proxy;
}

export function useVModel<P extends object, K extends keyof P, Name extends string, Passive extends boolean=true>(props: P, key?: K, options?: UseVModelOptionsWithEq<P[K], Name, Passive>): WritableComputedRef<P[K]> | Ref<UnwrapRef<P[K]>> {
    const {
        clone = false,
        passive = false,
        deep = false,
        defaultValue,
        equality = isEqual,
        emit,
    } = options ?? {};

    let { eventName } = options ?? {};

    const vm = getCurrentInstance();

    // eslint-disable-next-line no-cond-assign

    if (!key) {
        if (isVue2) {
            // eslint-disable-next-line no-cond-assign
            const modelOptions = vm?.proxy?.$options?.model;

            key = modelOptions?.value || 'value';
            eventName ??= modelOptions?.event || 'input' as Name;
        } else {
            key = 'modelValue' as K;
        }
    }
    eventName ??= `update:${kebabCase(key.toString())}`;

    const _emit = getEmitter(emit as Emit<string>, vm.proxy, eventName);

    const cloneFn = (val: P[K]) => (!clone ? val : isFunction(clone) ? clone(val) : cloneFnJSON(val));
    const getValue = () => (isDef(props[key]) ? cloneFn(props[key]) : defaultValue);

    if (passive) {
        const initialValue = getValue();
        const proxy = ref<P[K]>(initialValue);

        // Removed some code here that was trying to prevent emitting the event multiple
        // times. It was creating other issues, so we removed it.  If you find yourself debugging
        // a stackoverflow error with events, we may need to look into this again.
        watch(() => props[key], (v) => {
            proxy.value = cloneFn(v) as UnwrapRef<P[K]>;
        });

        watch(proxy, (v) => {
            if (!equality(v as P[K], props[key])) {
                _emit(v);
            }
        }, { deep });

        return proxy;
    }

    return computed({
        get() {
            return getValue();
        },
        set(value) {
            _emit(value);
        },
    });
}
