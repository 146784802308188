import {
    onActivated, onDeactivated, onMounted, onUnmounted,
} from 'vue';
import { useLog } from '@/shared/shared-providers';
import { logger } from '@/shared/logging';

const llog = logger('lifecycle');

export function logLifecycle() {
    const clog = useLog();
    const log = llog.child(clog.name, true);

    onUnmounted(() => {
        log.info('UNMOUNTED');
    });

    onMounted(() => {
        log.info('MOUNTED');
    });

    onActivated(() => {
        log.info('Activate');
    });

    onDeactivated(() => {
        log.info('Deactivate');
    });
}
