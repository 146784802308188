/* eslint-disable @typescript-eslint/no-explicit-any */
import { VoidCallback } from '@/types/core-types';
import asserts from '@/shared/asserts';
import isFunction from 'lodash/isFunction';
import { mergeDefaultsAny } from '@/shared/type.utils';

export type Done<T> = ((value?: T | PromiseLike<T>) => void);
export type Cancel = ((reason?: any) => void);

export type Completion<T> = {
    value?: T;
    error?: unknown;
};

export type RunOptions = {
    onError?: (error: unknown) => void;
    finalizer?: VoidCallback;
};

export type CompleterCreateOptions<T> = {
    finalizer?: (completion: Completion<T>) => void;
    stopped?: boolean;
    runnable?: () => Promise<T>;
}

export type Runner<T> = () => Promise<T>;

export enum CompleterStatus {
    idle, running, done
}

export class Completer<T> {
    private resolve: Done<T>;

    private reject: Cancel;

    private internalStatus: CompleterStatus;

    private readonly promise: Promise<T>;

    private finalizer: VoidCallback;

    private runFinalizer() {
        try {
            this.finalizer?.call(null);
        } catch (e) {
            // We'll ignore finalizer errors
        }
    }

    static create<T>(args: Runner<T> | CompleterCreateOptions<T> = {}, moreArgs: CompleterCreateOptions<T> = {}): Completer<T> {
        return new Completer<T>(args, moreArgs);
    }

    private constructor(args: Runner<T> | CompleterCreateOptions<T>, moreArgs: CompleterCreateOptions<T> = {}) {
        const { runnable, stopped, finalizer } = mergeDefaultsAny(isFunction(args)
            ? { runnable: args, stopped: true, finalize: null }
            : args, moreArgs);

        this.internalStatus = (stopped || runnable) ? CompleterStatus.idle : CompleterStatus.running;

        this.promise = new Promise<T>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });

        this.finalizer = finalizer;

        if (runnable != null) {
            void this.run(runnable);
        }
    }

    complete(value: T): void {
        if (this.internalStatus !== CompleterStatus.done) {
            this.runFinalizer();
            this.internalStatus = CompleterStatus.done;
            this.resolve(value);
        }
    }

    error(error: unknown): void {
        if (this.internalStatus !== CompleterStatus.done) {
            this.runFinalizer();
            this.internalStatus = CompleterStatus.done;
            this.reject(error);
        }
    }

    get isComplete() {
        return this.internalStatus === CompleterStatus.done;
    }

    get isIdle() {
        return this.internalStatus === CompleterStatus.idle;
    }

    get isRunning() {
        return this.internalStatus === CompleterStatus.running;
    }

    get isNotComplete() {
        return !this.isComplete;
    }

    get done(): Promise<T> {
        return this.promise;
    }

    run(runnable: () => Promise<T>, options: RunOptions = {}) {
        asserts(this.internalStatus === CompleterStatus.idle, 'Status must be idle');

        if (options.finalizer != null) {
            asserts(this.finalizer == null);
            this.finalizer = options.finalizer;
        }

        void (async () => {
            try {
                const result = await runnable();

                this.complete(result);
            } catch (e) {
                this.error(e);
                options.onError?.call(null, e);
            }
        })();

        return this.done;
    }
}
