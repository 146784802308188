/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IndustryType {
    COACH_CONSULTANT = 'COACH_CONSULTANT',
    AUTHOR_SPEAKER = 'AUTHOR_SPEAKER',
    ONLINE_STORE = 'ONLINE_STORE',
    ONLINE_LEARNING = 'ONLINE_LEARNING',
    PROFESSIONAL_SERVICES = 'PROFESSIONAL_SERVICES',
    SOFTWARE = 'SOFTWARE',
    LOCAL_SERVICE_PROVIDER = 'LOCAL_SERVICE_PROVIDER',
    REAL_ESTATE = 'REAL_ESTATE',
    MEDICAL_AND_DENTAL = 'MEDICAL_AND_DENTAL',
    HEALTH_AND_FITNESS = 'HEALTH_AND_FITNESS',
    OTHER = 'OTHER',
}
